import * as React from 'react'
import * as bootbox from 'bootbox';
import {
    BootstrapTable, TableHeaderColumn, TextFilter,
    CustomFilter, SelectFilter, CustomSelectProps,
    ToolBarProps
} from 'react-bootstrap-table';
import "react-bootstrap-table/css/react-bootstrap-table.css";
import { DaysRangeOptionsList, EngagementType, IOfficeLocation, engagementType } from '../../common/TaxReturn';
import { Loader, LoadingOverlay } from 'react-overlay-loader';
import K1DeliveredStatusActions from './K1DeliveredStatusActions'
import { CheckBoxComponent, CheckBoxSize } from "../../common/CheckBoxComponent";
import { CustomMultiSelect } from "../../common/MultipleSelectComponent";
import * as Helper from '../../helper/HelperFunctions';
import { Filters, IFilters, ReportFilterType } from '../Filters';
import { DeliveredReturnsConstants, DeliveredReturnsTableConstants } from './../../helper/Constants'
import { VenusNotifier } from './../../helper/VenusNotifier';
import { IK1DeliveredStatusViewModel } from './../../../store/reports/K1DeliveredStatusReport/K1DeliveredStatusState'
import { CustomEngagementTypeFilter } from '../../../components/common/CustomEngagementTypeFilter';
import { RenderK1TypeFilterOptions, RenderTypeFilterOptions, TypeDropdownValues } from '../../../Core/Common/Common';
let moment = require('moment');

interface K1DeliveredStatusTableProps {
    isLoading: boolean;
    onOpenPartnerModal: (rowIndex: number) => void;
    currentFilter: IFilters;
    pageSize: number;
    pageNo: number;
    selectedRows: number[];
    onPageChange: (page: number, sizePerPage: number) => void;
    onSortChange: any;
    onFilterChange: any;
    onRowSelect: (row: any, isSelected: any, e: any) => void;
    onSelectAll: (isSelected: any, rows: any, e: any) => void;
    K1DeliveredStatus: IK1DeliveredStatusViewModel;
    onFilterNameChange: any;
    onFilterSave: any;
    onExportToExcel: (onExportToComplete: () => void, resourceId: string) => void;
    filterList: IFilters[];
    onSetDefaultFilter: any;
    onRemoveDefaultFilter: any;
    onFilterDelete: any;
    saveFilterShow: any;
    onSaveFilterHide: any;
    defaultFilter: any;
    loadGrid: any;
    onSaveFilterShow: any;
    onFilterUpdate: any;
    onTaxpayerView: (rowIndex: number) => void;
    proxyFilter: string;
    filterType: ReportFilterType,
    onPageReload: () => void;
    refreshDelay?: boolean;
    loadSpinner?: boolean;
    officeLocationList: any[];
    parentResourceIdentifier: string;
}

interface K1DeliveredStatusTableState {
    officeLocationList: any[];
}

export default class K1DeliveredStatusTable extends React.Component<K1DeliveredStatusTableProps, K1DeliveredStatusTableState> {
    private filterChanged: boolean = false;
    public refClearSearchBtn: any;
    private customTaxYearMultiSelect: any;
    private customLocationMultiSelect: any;
    private customEngagementTypeFilter: any;
    public isAppiedFilter: boolean = false;

    public componentWillReceiveProps(nextProps: K1DeliveredStatusTableProps) {
            this.setState({
                officeLocationList: nextProps.officeLocationList
            });
    }

    private defaultType = (cell: any, row: any) => {
        return <span title={cell} className="ellipsis">{cell}</span>;
    }


    private customTypeTooltip = (cell: any, row: any) => {
        return `${row.engagementType}`;
    }

    private percentageFormat = (cell: any, row: any) => {
        return <span title={cell + "%"} className="ellipsis">{cell + "%"}</span>;
    }
    private k1DeliveredStatusAction = (cell: any, row: any) => {
        return (
            <K1DeliveredStatusActions
                title={cell}
                rowIndex={row.rowIndex}
                onOpenPartnerModal={this.props.onOpenPartnerModal}
                onTaxpayerView={this.props.onTaxpayerView}
                parentResourceId={this.props.parentResourceIdentifier}
            />
        );
    }
    private createCustomCheckbox = (props: CustomSelectProps): any => {
        return (<CheckBoxComponent size={CheckBoxSize.sm}
            id={"k1-delivered-status-checkbox-" + props.rowIndex}
            indeterminate={props.indeterminate}
            checked={props.checked}
            disabled={props.disabled}
            onChange={(e: any) => props.onChange(e, props.rowIndex)}
            ref={(input: any) => {
                if (input) {
                    input.indeterminate = props.indeterminate;
                }
            }}
            text={""} />);
    }
    private renderShowsTotal = (start: number, to: number, total: number) => {
        return (
            <p>
                Showing {start} to {to} of {total} entries
            </p>
        );
    }
    private onFilterChange = (dataField: any) => {
        if (!this.filterChanged && !this.isAppiedFilter) {
            this.filterChanged = true;
            this.props.onFilterChange(dataField, this.props.filterType);
            this.filterChanged = false;
        }
    }
    private setNoContent = () => {
        if (this.props.isLoading) {
            return (<LoadingOverlay style={{ height: '400px' }}>
                <Loader loading={this.props.isLoading} />
            </LoadingOverlay>)
        } else {
            return "";
        }
    }
    private createCustomToolBar = (props: ToolBarProps) => {
        return (
            <div className="col-sm-12 col-md-12 col-lg-12" style={{ height: 33, position: 'unset' }}>
                <Filters ref='Filters'
                    onFilterNameChange={this.props.onFilterNameChange}
                    onFilterSave={this.props.onFilterSave}
                    onClearFilter={this.onClearFilter}
                    onExportToExcel={this.props.onExportToExcel}
                    filterList={this.props.filterList}
                    onSetDefaultFilter={this.props.onSetDefaultFilter}
                    onRemoveDefaultFilter={this.props.onRemoveDefaultFilter}
                    onFilterDelete={this.props.onFilterDelete}
                    show={this.props.saveFilterShow}
                    onFilterSaveClick={this.onFilterSaveClick}
                    onSaveFilterHide={this.props.onSaveFilterHide}
                    onLoadSelectedFilter={this.onLoadSelectedFilter}
                    defaultFilter={this.props.defaultFilter}
                    loadGrid={this.props.loadGrid}
                    showExportExcel={false}
                    proxyFilter={this.props.proxyFilter}
                    onPageReload={this.props.onPageReload}
                    refreshDelay={this.props.refreshDelay}
                    loadSpinner={this.props.loadSpinner}
                    totalRows={this.props.K1DeliveredStatus.count}
                    parentResourceIdentifier={this.props.parentResourceIdentifier}
                />
            </div>
        );
    }

    private getYearMultiSelectDropDown = (filterHandler: any, customFilterParameters: any) => {
        const enableAllFilter = customFilterParameters.enableAllFilter;
        const placeholder = customFilterParameters.placeholder;
        return (
            <CustomMultiSelect onRef={(ref: any) => (this.customTaxYearMultiSelect = ref)} filterHandler={filterHandler} options={Helper.PrepareTaxYear()} enableAllFilter={enableAllFilter} placeholder={placeholder} />
        );
    }

    private getOfficeSelectDropDown = (filterHandler: any, customFilterParameters: any) => {
        const options = this.state?.officeLocationList ? this.state?.officeLocationList : [];
        const placeholder = customFilterParameters.placeholder;
        return (
            <CustomMultiSelect onRef={(ref: any) => (this.customLocationMultiSelect = ref)} 
                filterHandler={filterHandler} 
                options={options} 
                placeholder={placeholder} 
                handleTextOverflow = {true}
            />
        );
    }

    private getCustomEngagementTypeFilter = (filterHandler: any, customFilterParameters: any) => {
        const options = customFilterParameters.options;
        const placeholder = customFilterParameters.placeholder;
        return (
            <CustomEngagementTypeFilter
                onRef={(ref: any) => (this.customEngagementTypeFilter = ref)}
                filterHandler={filterHandler}
                options={options}
                placeholder={placeholder}
                selectedOptions={`${TypeDropdownValues.All}`}
            />
        );
    };

    public onClearFilter = (clearAll?: boolean) => {
        this.filterChanged = true;
        (this.refs.taxDocumentName as TableHeaderColumn)?.cleanFiltered();
        (this.refs.clientId as TableHeaderColumn)?.cleanFiltered();
        (this.refs.engagementType as TableHeaderColumn)?.cleanFiltered();
        (this.refs.partner as TableHeaderColumn)?.cleanFiltered();
        (this.refs.deliveredDate as TableHeaderColumn)?.cleanFiltered();
        this.customTaxYearMultiSelect?.cleanFiltered(clearAll);
        this.customLocationMultiSelect?.cleanFiltered(clearAll);
        this.customEngagementTypeFilter?.cleanFiltered(clearAll);
        this.filterChanged = false;
    }
    private onFilterSaveClick = (filterName: string) => {

        if (!Helper.validateFilter(this.props.currentFilter)) {
            VenusNotifier.Warning(DeliveredReturnsConstants.OtherMessage.FilterFieldsAreEmpty, null);
            return;
        }
        let temThis = this;
        if (typeof filterName == 'undefined' || filterName.length == 0 || filterName == this.props.proxyFilter) {
            this.props.onSaveFilterShow();
        }
        else {
            bootbox.dialog({
                title: DeliveredReturnsTableConstants.Title.FilterSaveUpdate,
                message: DeliveredReturnsTableConstants.OtherMessage.DoYouWantToCreateNewFilterOrUpdateTheCurrent,
                buttons: {
                    cancel: {
                        label: '<i class="fas fa-times"></i>' + DeliveredReturnsTableConstants.FormLabel.NoUpdateTheCurrent,
                        className: 'btn-white',
                        callback: function () { temThis.props.onFilterUpdate(filterName) }
                    },
                    confirm: {
                        label: '<i class="fas fa-check"></i>' + DeliveredReturnsTableConstants.FormLabel.YesCreateNew,
                        className: 'btn-info',
                        callback: function () { temThis.props.onSaveFilterShow(); }
                    }
                }
            });
        }
    }

    public onLoadSelectedFilter = (filter?: IFilters) => {
        this.isAppiedFilter = true;
        this.onClearFilter();
        if (filter) {
            this.filterChanged = true;
            for (let field of Object.keys(filter.fields)) {
                switch (field) {
                    case 'taxDocumentName':
                        (this.refs.taxDocumentName as TableHeaderColumn)?.applyFilter(filter.fields[field]);
                        break;
                    case 'clientId':
                        (this.refs.clientId as TableHeaderColumn)?.applyFilter(filter.fields[field]);
                        break;
                    case 'partner':
                        (this.refs.partner as TableHeaderColumn)?.applyFilter(filter.fields[field]);
                        break;
                    case 'deliveredBy':
                        (this.refs.deliveredBy as TableHeaderColumn)?.applyFilter(filter.fields[field]);
                        break;
                    case 'deliveredDate':
                        let deliveredDateFilter: any = filter.fields[field];
                        if (deliveredDateFilter) {
                            (this.refs.deliveredDate as TableHeaderColumn)?.applyFilter(deliveredDateFilter);
                        }
                        break;
                    case 'engagementType':
                        (this.customEngagementTypeFilter as TableHeaderColumn)?.applyFilter(filter.fields[field]);
                        break;
                    case 'downloadedRation':
                        (this.refs.downloadedRation as TableHeaderColumn)?.applyFilter(filter.fields[field]);
                        break;
                    case 'deliveredRatio':
                        (this.refs.deliveredRatio as TableHeaderColumn)?.applyFilter(filter.fields[field]);
                        break;
                    case 'taxYear':
                        this.customTaxYearMultiSelect?.applyFilter((filter.fields[field]));
                        break;
                    case 'locationName':
                        this.customLocationMultiSelect?.applyFilter((filter.fields[field]));
                        break;
                }
                this.filterChanged = false;
            }

            this.isAppiedFilter = false;
            this.onFilterChange(filter.fields);
            (this.refs.k1DeliveredTable as BootstrapTable)?.handleSearch(filter.searchText);
        }
    }
    private columns = [
        {
            header: '',
            key: 'index',
            isKey: true,
            dataFormat: this.defaultType,
            columnClassName: '',
            dataSort: true,
            toolTip: false,
            isHidden: true,
            width: 'auto',
            filter: { type: 'TextFilter', placeholder: 'index', style: { font: 'bold' } } as TextFilter

        },
        {
            header: 'Name',
            key: 'taxDocumentName',
            isKey: false,
            dataFormat: this.defaultType,
            columnClassName: '',
            dataSort: true,
            toolTip: false,
            isHidden: false,
            width: '20%',
            filter: { type: 'TextFilter', placeholder: 'Name', style: { font: 'bold' }, delay: 2500 } as TextFilter
        },
        {
            header: 'Client ID',
            key: 'clientId',
            isKey: false,
            dataFormat: this.defaultType,
            columnClassName: '',
            dataSort: true,
            toolTip: false,
            isHidden: false,
            width: '20%',
            filter: { type: 'TextFilter', placeholder: 'Client ID', style: { font: 'bold' }, delay: 2500 } as TextFilter
        },
        {
            header: 'Type',
            key: 'engagementType',
            isKey: false,
            dataFormat: this.defaultType,
            columnClassName: '',
            dataSort: true,
            toolTip: this.customTypeTooltip,
            isHidden: false,
            width: 'auto',
            filter: {
                type: "CustomFilter",
                getElement: this.getCustomEngagementTypeFilter,
                customFilterParameters: {
                    options: RenderK1TypeFilterOptions,
                    enableAllFilter: false,
                    placeholder: "Select Type..."
                }
            }
        },
        {
            header: 'Tax Year',
            key: 'taxYear',
            isKey: false,
            dataFormat: this.defaultType,
            columnClassName: '',
            dataSort: true,
            toolTip: true,
            isHidden: false,
            width: 'auto',
            filter: { type: 'CustomFilter', getElement: this.getYearMultiSelectDropDown, customFilterParameters: { enableAllFilter: true, placeholder: 'Select Year...' } as any } as CustomFilter

        },
        {
            header: 'ERO / Signer',
            key: 'partner',
            isKey: false,
            dataFormat: this.defaultType,
            columnClassName: '',
            dataSort: true,
            toolTip: false,
            isHidden: false,
            width: 'auto',
            filter: { type: 'TextFilter', placeholder: 'ERO / Signer', style: { font: 'bold' }, delay: 2500 } as TextFilter
        },
        {
            header: 'Office Location',
            key: 'locationName',
            isKey: false,
            dataFormat: this.defaultType,
            columnClassName: '',
            dataSort: true,
            toolTip: true,
            isHidden: false,
            width: '130px',
            filter: {
                type: 'CustomFilter', getElement: this.getOfficeSelectDropDown,
                customFilterParameters: { options: this.state?.officeLocationList ? this.state?.officeLocationList : [], 
                    enableAllFilter: false, placeholder: 'Select Office Location...' } as any
            } as CustomFilter
        },
        {
            header: 'Return Delivered',
            key: 'deliveredDate',
            isKey: false,
            dataFormat: this.defaultType,
            columnClassName: '',
            dataSort: true,
            toolTip: false,
            isHidden: false,
            width: 'auto',
            filter: { type: 'SelectFilter', placeholder: 'Select Delivered Date Limit', options: DaysRangeOptionsList } as SelectFilter
        },
        {
            header: 'Delivered %',
            key: 'deliveredRatio',
            isKey: false,
            dataFormat: this.percentageFormat,
            columnClassName: '',
            dataSort: false,
            toolTip: false,
            isHidden: false,
            width: 'auto',
            filter: { type: 'TextFilter', placeholder: 'Delivered %', style: { display: 'none' } } as TextFilter
        },
        {
            header: 'Downloaded %',
            key: 'downloadedRatio',
            isKey: false,
            dataFormat: this.percentageFormat,
            columnClassName: '',
            dataSort: false,
            toolTip: false,
            isHidden: false,
            width: 'auto',
            filter: { type: 'TextFilter', placeholder: 'Downloaded %', style: { display: 'none' } } as TextFilter
        },
        {
            header: 'Action',
            key: 'button',
            isKey: false,
            dataFormat: this.k1DeliveredStatusAction,
            columnClassName: 'button-cell',
            dataSort: false,
            toolTip: false,
            isHidden: false,
            width: 'auto',
            filter: { type: 'TextFilter', placeholder: '', style: { display: 'none' } } as TextFilter
        },
    ];


    render() {

        const options = {
            onSortChange: this.props.onSortChange,
            onPageChange: this.props.onPageChange,
            sizePerPage: this.props.pageSize,
            page: this.props.pageNo,
            paginationShowsTotal: this.renderShowsTotal,
            onRowDoubleClick: function (row: any) {
            },
            onFilterChange: this.onFilterChange,
            noDataText: this.setNoContent(),
            toolBar: this.createCustomToolBar,
            clearSearch: true,
            nextPage: <span className="fa fa-angle-right" />,
            prePage: <span className="fa fa-angle-left" />,
            firstPage: <span className="fa fa-angle-double-left" />,
            lastPage: <span className="fa fa-angle-double-right" />
        };


        let data: any = [];

        if (this.props.K1DeliveredStatus.documents) {
            data = this.props.K1DeliveredStatus.documents.map((model, index) => {
                return {
                    taxDocumentName: model.name,
                    clientId: model.clientId,
                    engagementType: engagementType((EngagementType[model.engagementType] as any) as EngagementType),
                    taxYear: model.taxYear,
                    partner: model.partnerLastName + ", " + model.partnerFirstName,
                    locationName: model.officeLocation.locationName,
                    deliveredDate: moment(model.deliveredDate).format('MM/DD/YYYY'),
                    deliveredRatio: model.deliveredRatio.toFixed(2),
                    downloadedRatio: model.downloadedRatio.toFixed(2),
                    index: ((this.props.pageNo - 1) * this.props.pageSize) + index,
                    rowIndex: index,
                    button: 'Actions',
                }
            });

        }

        let selected: number[] = [];
        if (this.props.selectedRows.length > 0) {
            for (var i in this.props.selectedRows) {
                let rowIndex = this.props.selectedRows[i];
                selected.push(((this.props.pageNo - 1) * this.props.pageSize) + rowIndex);
            }
        }

        let selectRowProp: any =
        {
            mode: 'checkbox',
            clickToSelect: true,
            onSelect: this.props.onRowSelect,
            onSelectAll: this.props.onSelectAll,
            selected: selected,
            customComponent: this.createCustomCheckbox,
            className: 'row-selected'
        };

        return (
            <div className="pos-relative k1DeliveredStatus-table">

                <BootstrapTable
                    ref='k1DeliveredTable'
                    data={data}
                    remote={true}
                    fetchInfo={{ dataTotalSize: this.props.K1DeliveredStatus.count }}
                    options={options}
                    striped
                    hover={true}
                    pagination={true}
                    selectRow={selectRowProp}>
                    {this.columns.map((value, index) => {
                        return <TableHeaderColumn
                            key={index}
                            ref={value.key}
                            isKey={value.isKey}
                            dataField={value.key}
                            hidden={value.isHidden}
                            width={value.width}
                            dataFormat={value.dataFormat}
                            columnClassName={value.columnClassName}
                            columnTitle={value.toolTip}
                            filter={value.filter}
                            dataSort={value.dataSort}
                        >
                            {
                                (value.width === 'auto') ?
                                    <span title={value.header} className="table-text-sort">{value.header}</span> :
                                    value.header
                            }
                        </TableHeaderColumn>;
                    })}
                </BootstrapTable>
            </div>
        );
    }
}
