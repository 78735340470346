import * as React from "react";
import { ICompanySettings } from "../../../Core/ViewModels/Company/CompanySettingsViewModel";
import { ITaxReturn } from "../../common/TaxReturn";
import { FC, useEffect, useState } from "react";
import { Alert, Button, Modal } from "react-bootstrap";
import { Tabs } from "react-bootstrap";
import { Tab } from "react-bootstrap";
import SendReminderNow from "./SendReminderNow";
import { BulkVoucherReminders } from "./BulkVoucherReminders";
import { VenusNotifier } from "../../helper/VenusNotifier";
import * as Constants from "../../helper/Constants";
import { VoucherReminder } from "./VoucherReminder";

interface ISendReminderProps {
    show: boolean;
    onHide: () => void;
    selectedDocumentsIds: number[];
    isSigningReminderEnabled: boolean;
    onSendReminderNow: () => void;
    companySettings: ICompanySettings;
    bulkReturnsSelected: ITaxReturn[];
    onBulkNotificationReminderSave: (
        allowVoucherReminder: boolean,
        voucherReminderInDays: number,
        allowSigningReminder: boolean,
        signingReminderInDays: number
    ) => void;
    isSendReminderFromHeader: boolean;
    onNotificationReminderSave: (
        id: number,
        allowVoucherReminder: boolean,
        voucherReminderInDays: number,
        allowSigningReminder: boolean,
        signingReminderInDays: number
    ) => void;
    isUserAllowedToEnableVoucherReminder: boolean;
    isUserAllowedToEnableSigningReminder: boolean;
    individualReturnSelected: ITaxReturn;
    totalCount: number;
    isGroupedReturnExists: boolean;
}

const SendReminder: FC<ISendReminderProps> = ({
    show,
    onHide,
    selectedDocumentsIds,
    isSigningReminderEnabled,
    onSendReminderNow,
    companySettings,
    bulkReturnsSelected,
    onBulkNotificationReminderSave,
    isSendReminderFromHeader,
    onNotificationReminderSave,
    isUserAllowedToEnableVoucherReminder,
    isUserAllowedToEnableSigningReminder,
    individualReturnSelected,
    totalCount,
    isGroupedReturnExists
}) => {
    const [key, setKey] = useState<string>("send_reminder_now");
    const [allowVoucherReminder, setallowVoucherReminder] = useState<boolean>(false);
    const [allowSigningReminder, setallowSigningReminder] = useState<boolean>(false);
    const [voucherReminderInDays, setvoucherReminderInDays] = useState<number>(0);
    const [signingReminderInDays, setsigningReminderInDays] = useState<number>(0);
    const [signingReminder, setSigningReminder] = useState<boolean>(false);

    const isSendReminderNow = (key: string) => key === "send_reminder_now";

    const handleVoucherReminderChange = (allowVoucherReminder: boolean) => {
        setallowVoucherReminder(allowVoucherReminder);
    };
    const handleSigningReminderChange = (allowSigningReminder: boolean) => {
        setallowSigningReminder(allowSigningReminder);
    };
    const handleVoucherReminderInDaysChange = (voucherReminderInDays: number) => {
        setvoucherReminderInDays(voucherReminderInDays);
    };
    const handleSigningReminderInDaysChange = (signingReminderInDays: number) => {
        setsigningReminderInDays(signingReminderInDays);
    };

    let enableVoucherReminderSetting: boolean = false;
    let enableSigningReminderSetting: boolean = false;
    let noOfDaysForVoucherReminderSetting: number = 0;
    let noOfDaysForSigningReminderSetting: number = 0;

    useEffect(() => {
        if (isSendReminderFromHeader && totalCount > 1) {
            enableVoucherReminderSetting =
                companySettings.notificationSettingsModel.paymentVoucherNotificationSettings.enablePaymentVoucherReminder;
            enableSigningReminderSetting =
                companySettings.notificationSettingsModel.signingReminderNotificationSettings.enableSigningReminder;
            noOfDaysForVoucherReminderSetting =
                companySettings.notificationSettingsModel.paymentVoucherNotificationSettings.noOfDaysForVoucherReminder;
            noOfDaysForSigningReminderSetting =
                companySettings.notificationSettingsModel.signingReminderNotificationSettings.noOfDaysForSigningReminder;
        } else {
            enableVoucherReminderSetting =
                individualReturnSelected?.documentSettings?.documentNotificationSetting.paymentVoucherNotificationSettingsModel
                    .enablePaymentVoucherReminder;
            enableSigningReminderSetting =
                individualReturnSelected?.documentSettings?.documentNotificationSetting.signingReminderNotificationSettingsModel
                    .enableSigningReminder;
            noOfDaysForVoucherReminderSetting =
                individualReturnSelected?.documentSettings?.documentNotificationSetting.paymentVoucherNotificationSettingsModel
                    .noOfDaysForVoucherReminder;
            noOfDaysForSigningReminderSetting =
                individualReturnSelected?.documentSettings?.documentNotificationSetting.signingReminderNotificationSettingsModel
                    .noOfDaysForSigningReminder;
        }

        handleVoucherReminderChange(enableVoucherReminderSetting);
        handleSigningReminderChange(enableSigningReminderSetting);
        handleVoucherReminderInDaysChange(noOfDaysForVoucherReminderSetting < 1 ? 1 : noOfDaysForVoucherReminderSetting);
        handleSigningReminderInDaysChange(noOfDaysForSigningReminderSetting < 1 ? 1 : noOfDaysForSigningReminderSetting);
    }, [isSendReminderFromHeader, companySettings, individualReturnSelected]);

    useEffect(() => {
        if (!show) {
            setKey("send_reminder_now");
            setSigningReminder(false);
        }
    }, [show]);

    const handleSend = () => {
        if (key === "send_reminder_now") {
            onSendReminderNow();
        } else {
            let isValid: boolean = true;
            if (allowVoucherReminder && voucherReminderInDays == null) {
                VenusNotifier.Warning(Constants.DeliveryOptionsValidation.InvalidNumberofDaysVoucherReminder, null);
                isValid = false;
            }
            if (allowSigningReminder && signingReminderInDays == null) {
                VenusNotifier.Warning(Constants.DeliveryOptionsValidation.InvalidNumberofDaysSigningReminder, null);
                isValid = false;
            }
            if (isValid) {
                isSendReminderFromHeader
                    ? onBulkNotificationReminderSave(
                          allowVoucherReminder,
                          voucherReminderInDays,
                          allowSigningReminder,
                          signingReminderInDays
                      )
                    : onNotificationReminderSave(
                          selectedDocumentsIds[0],
                          allowVoucherReminder,
                          voucherReminderInDays,
                          allowSigningReminder,
                          signingReminderInDays
                      );
            }
        }
    };
    return (
        <Modal show={show} className="reminder-modal" onHide={onHide}>
            <Modal.Header closeButton>
                <Modal.Title>Send Reminder</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Tabs
                    className="custom-tab"
                    fill
                    id="send_reminder"
                    activeKey={key}
                    onSelect={(key) => setKey(key || "send_reminder_now")}
                >
                    <Tab
                        eventKey="send_reminder_now"
                        title={
                            <div>
                                <i className="fas fa-paper-plane"></i>
                                <span className="ml-2">Send Reminder Now</span>
                            </div>
                        }
                    >
                        {totalCount > 0 && (
                            <SendReminderNow
                                isAutomatedSigningReminderEnabled={isSigningReminderEnabled}
                                signingReminder={signingReminder}
                                setSigningReminder={setSigningReminder}
                                totalCount={totalCount}
                                models={bulkReturnsSelected}
                                isGroupedReturnExists={isGroupedReturnExists}
                            />
                        )}
                    </Tab>
                    <Tab
                        eventKey="scheduled_automated_reminder"
                        title={
                            <div>
                                <i className="fas fa-calendar-check"></i>
                                <span className="ml-2">Scheduled Automated Reminder</span>
                            </div>
                        }
                    >
                        {isSendReminderFromHeader && totalCount > 1? (
                            <BulkVoucherReminders
                                companySettings={companySettings}
                                models={bulkReturnsSelected}
                                handleVoucherReminderChange={handleVoucherReminderChange}
                                handleSigningReminderChange={handleSigningReminderChange}
                                handleVoucherReminderInDaysChange={handleVoucherReminderInDaysChange}
                                handleSigningReminderInDaysChange={handleSigningReminderInDaysChange}
                                totalCount={totalCount}
                                isGroupedReturnExists={isGroupedReturnExists}
                            />
                        ) : (
                            <VoucherReminder
                                taxReturn={individualReturnSelected}
                                isUserAllowedToEnableVoucherReminder={isUserAllowedToEnableVoucherReminder}
                                isUserAllowedToEnableSigningReminder={isUserAllowedToEnableSigningReminder}
                                handleVoucherReminderChange={handleVoucherReminderChange}
                                handleSigningReminderChange={handleSigningReminderChange}
                                handleVoucherReminderInDaysChange={handleVoucherReminderInDaysChange}
                                handleSigningReminderInDaysChange={handleSigningReminderInDaysChange}
                                show={show && !isSendReminderFromHeader}
                            />
                        )}
                    </Tab>
                </Tabs>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="default" onClick={onHide}>
                    <i className="fas fa-times"></i> Cancel
                </Button>
                <Button variant="info" onClick={handleSend} disabled={isSendReminderNow(key) && !signingReminder}>
                    {isSendReminderNow(key) ? (
                        <>
                            <i className="fas fa-paper-plane"></i>Send
                        </>
                    ) : (
                        <>
                            <i className="fas fa-save"></i>Save
                        </>
                    )}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default SendReminder;
