import * as React from 'react';
import { Modal, Form, Button } from 'react-bootstrap';
import { DropdownComponent } from '../../../common/controls/DropdownComponent';
import * as AttachmentInstructionStore from '../../../../store/settings/AttachmentInstructionStore';
import { ReplaceHelperVariableForEditor } from '../../../helper/HelperFunctions';
import { VariableList } from '../../../settings/ClientInstructions/VariableList';
import { CommonMessages } from '../../../../components/helper/Constants';
import LexicalEditorComponent from '../../../settings/SavedMessage/LexicalEditor';
var htmlencode = require('htmlencode');

interface AddViewInstructionProps {
    instructionMessage: string;
    instructionId: number;
    attachmentInstructions: AttachmentInstructionStore.IAttachmentInstructionDictionary;
    showState: boolean;
    onHide(): void;
    onSubmitInstruction(instruction: string, instructionId: number): void;
    isGroupReturn?: boolean;
}

interface AddViewInstructionState {
    instructionMessage: string;
    instructionId: number;
    editedInstruction: string;
}

export class AddViewAttachmentInstruction extends React.Component<AddViewInstructionProps, AddViewInstructionState>{
    state = {
        instructionMessage: "",
        instructionId: 0,
        editedInstruction: ""
    }

    UNSAFE_componentWillReceiveProps(nextProps: AddViewInstructionProps) {
        this.setState({
            instructionId: nextProps.instructionId,
            instructionMessage: nextProps.instructionMessage
        });
    }

    onSelectedInstructionChange = (selectedValue: any) => {
        const instruction = this.props.attachmentInstructions[selectedValue] ? this.props.attachmentInstructions[selectedValue].attachmentInstruction.text : "";
        this.setState({
            instructionId: selectedValue,
            instructionMessage: instruction,
            editedInstruction: instruction
        });
    }

    onInstructionBodyChange = (value: any) => {
        this.setState({
            editedInstruction: value,
            instructionMessage: value
        });
    }

    onSubmitInstruction = () => {
        this.props.onSubmitInstruction(this.state.editedInstruction, this.state.instructionId);
        this.setState({
            editedInstruction: "",
            instructionMessage: ""
        });
    }

    onHide = (event: any) => {
        this.props.onHide();
        this.setState({
            editedInstruction: "",
            instructionMessage: ""
        });
    }

    public render() {
        const isEditable = this.props.attachmentInstructions[this.state.instructionId]
            ? this.props.attachmentInstructions[this.state.instructionId].attachmentInstruction.isEditable
            : true;
        return <div>
            <Modal className="attachment-instruction-modal" show={this.props.showState} onHide={this.props.onHide}>
                <Modal.Header closeButton data-test-auto="28623804-8A03-4E39-A831-EB03BB0E31BC">
                    <Modal.Title>Add Instructions</Modal.Title>
                </Modal.Header>
                <Form data-test-auto="E726F3AC-C39C-4032-99BF-37E2BA2647E0">
                    <Modal.Body>

                        <div className="row">
                            <div className="col-sm-6 col-md-6 col-xl-6">
                                <DropdownComponent
                                    id="attachmentInstruction"
                                    className="attachmentInstructionDropdown"
                                    options={
                                        Object.keys(this.props.attachmentInstructions).map((key, index) => {
                                            return {
                                                value: this.props.attachmentInstructions[parseInt(key)].attachmentInstruction.id,
                                                label: this.props.attachmentInstructions[parseInt(key)].attachmentInstruction.name
                                            }
                                        })
                                    }
                                    onChange={this.onSelectedInstructionChange}
                                    selectedValue={this.state.instructionId}
                                    clearable={false}
                                />
                            </div>
                        </div>

                        <div className="row" style={{ padding: 15 }}>
                            <div className="width100">
                                {/* <TinyMCEComponent
                                    key={this.state.instructionId}
                                    messageBody={ReplaceHelperVariableForEditor(this.state.instructionMessage ? htmlencode.htmlDecode(this.state.instructionMessage) : "" )}
                                    changeStateTinymceBody={this.onInstructionBodyChange}
                                    readOnly={isEditable ? 0 : 1}
                                    placeholderText={CommonMessages.PlaceholderText}
                                /> */}
                                <LexicalEditorComponent
                                    readOnly={isEditable ? 0 : 1}
                                    changeStateEditorBody={this.onInstructionBodyChange}
                                    placeholderText={CommonMessages.PlaceholderText}
                                    messageBody={ReplaceHelperVariableForEditor(this.state.instructionMessage ? htmlencode.htmlDecode(this.state.instructionMessage) : "" )}
                                />
                            </div>
                        </div>

                        {isEditable ? <VariableList isGroupReturn={this.props.isGroupReturn} /> : ""}

                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            variant="default"
                            data-test-auto="80FA18FD-ED80-4D83-94CC-4FE05B57661B"
                            onClick={this.onHide}>
                            <i className='fas fa-times'></i>Cancel
                                </Button>
                        <Button
                            variant="info"
                            data-test-auto="2E2752F0-5331-4641-A105-DF12DDEBD4F9"
                            onClick={this.onSubmitInstruction}
                            disabled={this.state.editedInstruction ? false : true}
                        >
                            <i className='fas fa-save'></i>Save
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </div>
    }
}
