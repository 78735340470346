import * as React from "react";
import { RouteComponentProps } from "react-router";
import { LoadingOverlay, Loader } from "react-overlay-loader";
import { BusinessReturnInstructions } from "./BusinessReturnsComponents/BusinessReturnInstructions";
import { VenusNotifier } from "../helper/VenusNotifier";
import FilePreview from "../common/FilePreview";
import { BusinessReturnInstruction } from "../../Core/ViewModels/Common/BusinessReturnInstructionViewModel";
import * as BusinessReturnsStore from "../../store/settings/BusinessReturnsStore";
import { EngagementType } from "../common/TaxReturn";
import { validatePdfFile, getFileExtension } from "../helper/Validations";
import { GetFileMagicNumber } from "../helper/HelperFunctions";
import * as CompanyStore from "../../store/company/CompanyStore";
import * as WelcomeMessageStore from "../../store/settings/WelcomeMessageStore";
import * as EmailMessageStore from "../../store/settings/EmailMessageStore";
import { WelcomeMessage } from "./ClientInstructions/WelcomeMessage/WelcomeMessage";
import {
    IEmailTemplateMessage,
    IWelcomeMessage,
    clientInstructions,
    initialEmailMessage,
    initialWelcomeMessage
} from "../../Core/ViewModels/CompanySettings/ClientInstructionViewModel";
import { WelcomeMessagePopUp } from "./ClientInstructions/WelcomeMessage/WelcomeMessagePopUp";
import { ICompanySettings } from "../../Core/ViewModels/Company/CompanySettingsViewModel";
import * as Constants from "../helper/Constants";
import { EmailMessagePopUp } from "./ClientInstructions/EmailMessage/EmailMessagePopUp";
import { EmailMessage } from "./ClientInstructions/EmailMessage/EmailMessage";
import * as bootbox from "bootbox";
import * as UserSettingsStore from "../../store/userManagement/UserSettingStore";
import Select from "react-select";
import { PrepareTaxYear } from "../helper/HelperFunctions";
import { SettingsHeader } from "../common/settings/SettingsHeader";
import { API_BASE_URL } from "../../utils/constants";
import { uploadedFile } from "../../components/common/CommonUpload";
import { handleResponse } from "../../store/Library";
import { CustomRouterPrompt } from "./CustomRouterPrompt";
import * as ReminderStore from "../../store/common/ReminderStore";
import { initialSSRSettings, ISSRSettings, SSRSettings } from "../../Core/ViewModels/Company/Settings/SSRSettingsViewModel";
import * as SSRSettingsStore from "../../store/company/settings/SSRSettingsStore";
import { TaxReturnSettingsResources } from "../../components/helper/ResourceIdConstants";
import { MaskingSSNSetting } from "./BusinessReturnsComponents/MaskingSSNSetting";
import { CompanySettingsConstants } from "../../components/helper/Constants";
import * as ApplicationHelptextStore from "../../store/common/ApplicationHelptextStore";
import { OverlayLoader } from "../../components/helper/OverlayLoader";

type BusinessReturnProps = {
    businessReturnInstructions: BusinessReturnsStore.IBusinessReturnsState;
    welcomeMessageList: WelcomeMessageStore.IWelcomeMessageStoreState;
    emailMessageList: EmailMessageStore.IEmailMessageStoreState;
    companyData: CompanyStore.ICompanyData;
    helptexts: ApplicationHelptextStore.IHelptextState;
} & typeof BusinessReturnsStore.actionCreators &
    typeof WelcomeMessageStore.ActionCreators &
    typeof EmailMessageStore.ActionCreators &
    typeof CompanyStore.actionCreators &
    typeof UserSettingsStore.actionCreators &
    typeof ReminderStore.actionCreators &
    typeof SSRSettingsStore.actionCreators &
    typeof ApplicationHelptextStore.actionCreators &
    RouteComponentProps<{}>;

interface BusinessReturnState {
    url: string;
    showPDFPreview: boolean;
    fileType: string | undefined;
    fileName: string;
    businessReturnInstructions: BusinessReturnInstruction[];
    selectedK1DistributionMessage: number;
    defaultK1DistributionMessage: number;
    selectedMessageId: number;
    showLoader: boolean;
    loaderText: string;
    showPopUpLoader: boolean;
    showEmailMessagePopup: boolean;
    showWelcomeMessagePopUp: boolean;
    defaultMessageId: number;
    buttonText: string;
    title: string;
    isUpdateMessage: boolean;
    typeId: number;
    defaultK1PartnerInfoMessage: number;
    defaultK1ClientInfoMessage: number;
    defaultK1PartnerUpdatePageMessage: number;
    selectedK1PartnerInfoMessage: number;
    selectedK1ClientInfoMessage: number;
    selectedK1PartnerUpdatePageMessage: number;
    selectedTaxYear: number;
    showCustomPrompt: boolean;
    resourceId?: string;
}

export class BusinessReturns extends React.Component<BusinessReturnProps, BusinessReturnState> {
    constructor(props: BusinessReturnProps) {
        super(props);
        this.state = {
            url: "",
            showPDFPreview: false,
            fileType: "",
            fileName: "",
            businessReturnInstructions: [],
            selectedK1DistributionMessage: 0,
            defaultK1DistributionMessage: 0,
            selectedMessageId: 0,
            showLoader: false,
            loaderText: "",
            showPopUpLoader: false,
            showEmailMessagePopup: false,
            showWelcomeMessagePopUp: false,
            defaultMessageId: 0,
            buttonText: "",
            title: "",
            isUpdateMessage: false,
            typeId: 0,
            defaultK1PartnerInfoMessage: 0,
            defaultK1ClientInfoMessage: 0,
            defaultK1PartnerUpdatePageMessage: 0,
            selectedK1PartnerInfoMessage: 0,
            selectedK1ClientInfoMessage: 0,
            selectedK1PartnerUpdatePageMessage: 0,
            selectedTaxYear: new Date().getFullYear() - 1,
            showCustomPrompt: false,
            resourceId: ""
        };
        this.handleApplyButton = this.handleApplyButton.bind(this);
        this.uploadBusinessReturnInstruction = this.uploadBusinessReturnInstruction.bind(this);
    }
    UNSAFE_componentWillMount() {
        this.props.requestCompanySettings(true);
        this.props.requestAllSSRSettings(true);
        this.props.requestWelcomeMessages(true);
        this.props.requestEmailMessages(true);
        this.props.requestHelptextsForApplication(true);
        this.setState({ showLoader: true, loaderText: "Loading..." });
    }
    componentDidMount() {
        this.props.requestBusinessReturnInstructions();
        this.props.requestUserSettings(true);

        const element = document.getElementById("content-wrapper");
        element && element.scrollTo(0, 0);
    }
    UNSAFE_componentWillReceiveProps(nextProps: BusinessReturnProps) {
        this.setState(
            {
                selectedK1DistributionMessage: nextProps.companyData.ssrSettings
                    ? nextProps.companyData.ssrSettings.defaultMessageSettings.k1Distribution
                    : 0,
                defaultK1DistributionMessage: nextProps.companyData.ssrSettings
                    ? nextProps.companyData.ssrSettings.defaultMessageSettings.k1Distribution
                    : 0,
                defaultK1PartnerInfoMessage: nextProps.companyData.ssrSettings
                    ? nextProps.companyData.ssrSettings.defaultMessageSettings.k1PartnerInfoUpdate
                    : 0,
                defaultK1ClientInfoMessage: nextProps.companyData.ssrSettings
                    ? nextProps.companyData.ssrSettings.defaultMessageSettings.k1ClientInfoUpdate
                    : 0,
                selectedK1PartnerInfoMessage: nextProps.companyData.ssrSettings
                    ? nextProps.companyData.ssrSettings.defaultMessageSettings.k1PartnerInfoUpdate
                    : 0,
                selectedK1ClientInfoMessage: nextProps.companyData.ssrSettings
                    ? nextProps.companyData.ssrSettings.defaultMessageSettings.k1ClientInfoUpdate
                    : 0,
                showLoader:
                    !nextProps.welcomeMessageList.isLoading &&
                    !nextProps.emailMessageList.isLoading &&
                    !nextProps.companyData.isLoading
                        ? false
                        : true,
                showPopUpLoader:
                    !nextProps.welcomeMessageList.isLoading &&
                    !nextProps.emailMessageList.isLoading &&
                    !nextProps.companyData.isLoading
                        ? false
                        : true,
                defaultK1PartnerUpdatePageMessage: nextProps.companyData.ssrSettings
                    ? nextProps.companyData.ssrSettings.defaultMessageSettings.k1PartnerUpdatePageMessage
                    : 0,
                selectedK1PartnerUpdatePageMessage: nextProps.companyData.ssrSettings
                    ? nextProps.companyData.ssrSettings.defaultMessageSettings.k1PartnerUpdatePageMessage
                    : 0
            },
            () => {
                if (!this.state.showPopUpLoader)
                    this.setState({
                        showEmailMessagePopup: false,
                        showWelcomeMessagePopUp: false
                    });
            }
        );
    }
    private enableCustomRouterPrompt = () => {
        this.setState({ showCustomPrompt: true });
    };
    private disableCustomRouterPrompt = () => {
        this.setState({ showCustomPrompt: false });
    };
    private getBusinessInstructionFileName = (type: string, taxYear: number) => {
        const instruction = this.props.businessReturnInstructions.instructions.find(
            (x) => x.taxYear == taxYear && EngagementType[x.engagementType] == type
        );
        if (instruction) {
            return instruction.fileName;
        } else {
            return "Unknown";
        }
    };

    private getBusinessInstruction = (type: string, taxYear: number, resourceId?: string) => {
        const fileName = this.getBusinessInstructionFileName(type, taxYear);
        this.props.requestBusinessInstructionLink(type, taxYear, fileName, undefined, resourceId);
    };

    private generateFilePreview = (type: string, taxYear: number) => {
        const fileName = this.getBusinessInstructionFileName(type, taxYear);
        this.props.requestPreviewBusinessInstructionLink(type, taxYear, (url) => {
            this.setState(
                {
                    url: url
                },
                () => {
                    this.setState({ showPDFPreview: true, fileType: "pdf", fileName: fileName });
                }
            );
        });
    };

    private processBusinessInstruction = (file: FileList, instruction: BusinessReturnInstruction, resourceId?: string) => {
        GetFileMagicNumber(file[0]).then((result) => {
            if (validatePdfFile(getFileExtension(file[0].name), result)) {
                this.uploadBusinessReturnInstruction(file, instruction, resourceId);
            }
        });
    };

    private uploadBusinessReturnInstruction(file: FileList, instruction: BusinessReturnInstruction, resourceId: string = "") {
        fetch(
            API_BASE_URL +
                "/api/Upload/GetBusinessInstructionUploadLinkAsync/?type=" +
                EngagementType[instruction.engagementType].toString() +
                "&taxYear=" +
                instruction.taxYear,
            {
                credentials: "include",
                headers: {
                    "X-Resource-Id": resourceId
                }
            }
        )
            .then(handleResponse)
            .then((url) => {
                let fileData: File = file[0] as File;
                uploadedFile(fileData, url, file[0].name, () => {
                    this.props.updateBusinessReturnInstruction(file, instruction);
                });
            })
            .catch(function (error) {
                VenusNotifier.Error("Failed to fetch Business Return Instruction", "Business Instruction");
            });
    }

    private onHidePDFPreview = () => {
        this.setState({
            showPDFPreview: false,
            url: "",
            fileName: "",
            fileType: ""
        });
    };
    private onWelcomeInstructionAddButtonClick = (
        defaultMessageId: number,
        selectedMessageId: number,
        typeId: number,
        resourceId?: string
    ) => {
        this.setState({
            defaultMessageId: defaultMessageId,
            selectedMessageId: selectedMessageId,
            showWelcomeMessagePopUp: true,
            buttonText: "Add",
            isUpdateMessage: false,
            title: "Add K1 Client Instruction",
            typeId: typeId,
            resourceId: resourceId
        });
    };
    private onWelcomeInstructionEditButtonClick = (
        defaultMessageId: number,
        selectedMessageId: number,
        typeId: number,
        resourceId?: string
    ) => {
        this.setState({
            defaultMessageId: defaultMessageId,
            selectedMessageId: selectedMessageId,
            showWelcomeMessagePopUp: true,
            buttonText: "Save",
            isUpdateMessage: true,
            title: "Edit K1 Client Instruction",
            typeId: typeId,
            resourceId: resourceId
        });
    };

    private addWelcomeMessage = (messageDetail: IWelcomeMessage, isSetDefault: boolean, isToggled: boolean) => {
        let companyData: ISSRSettings = this.props.companyData.ssrSettings
            ? this.props.companyData.ssrSettings
            : initialSSRSettings;
        messageDetail.type = this.state.typeId;
        this.props.saveWelcomeMessageDetail(messageDetail, companyData, isSetDefault, this.state.typeId, this.state.resourceId);
        this.setState({
            showPopUpLoader: true
        });
    };

    private editWelcomeMessage = (message: IWelcomeMessage, isSetDefault: boolean, isToggled: boolean) => {
        this.props.updateWelcomeMessageDetail(message, this.state.resourceId);
        if (isToggled && isSetDefault) {
            this.saveDefaultMessage(message.id, undefined, this.state.resourceId);
        }
        this.setState({
            showPopUpLoader: true
        });
    };
    private saveDefaultMessage = (messageId: number, callback?: () => void, resourceId?: string) => {
        let companyData = new SSRSettings(
            this.props.companyData.ssrSettings
                ? this.props.companyData.ssrSettings.defaultMessageSettings
                : initialSSRSettings.defaultMessageSettings
        );
        if (companyData.defaultMessageSettings) {
            switch (this.state.typeId) {
                case clientInstructions.k1Distribution: {
                    companyData.defaultMessageSettings.k1Distribution = messageId;
                    break;
                }
                case clientInstructions.k1ClientInfoUpdate: {
                    companyData.defaultMessageSettings.k1ClientInfoUpdate = messageId;
                    break;
                }
                case clientInstructions.k1PartnerInfoUpdate: {
                    companyData.defaultMessageSettings.k1PartnerInfoUpdate = messageId;
                    break;
                }
                case clientInstructions.k1PartnerUpdatePageMessage: {
                    companyData.defaultMessageSettings.k1PartnerUpdatePageMessage = messageId;
                    break;
                }
            }
            this.props.updateSSRSettings(companyData, callback, resourceId);
        }
    };
    private onCancelButtonClick = () => {
        this.setState({
            showEmailMessagePopup: false,
            showWelcomeMessagePopUp: false
        });
    };

    private deleteWelcomeMessage = (selectedMessageId: number, defaultMessageId: number, resourceId?: string) => {
        if (this.props.welcomeMessageList.data[selectedMessageId].welcomeMessage.id == defaultMessageId) {
            VenusNotifier.Warning(Constants.ClientInstructionConstants.DeleteInstructionWarning, "");
        } else {
            let _currentObject = this;
            bootbox.confirm({
                title: "Delete Instruction",
                message: "Are you sure, You want to delete this Instruction ?",
                buttons: {
                    cancel: {
                        label: '<i class="fas fa-times"></i> Cancel',
                        className: "btn-white btn-default"
                    },
                    confirm: {
                        label: '<i class="fas fa-check"></i> Delete',
                        className: "btn-info"
                    }
                },
                callback: (result: boolean) => {
                    if (result == true) {
                        _currentObject.props.deleteWelcomeMessageDetail(
                            _currentObject.props.welcomeMessageList.data[selectedMessageId].welcomeMessage,
                            resourceId
                        );
                        _currentObject.setState({ showLoader: true });
                    }
                }
            });
        }
    };

    private onEmailInstructionAddButtonClick = (
        defaultMessageId: number,
        selectedMessageId: number,
        typeId: number,
        resourceId?: string
    ) => {
        this.setState({
            defaultMessageId: defaultMessageId,
            selectedMessageId: selectedMessageId,
            showEmailMessagePopup: true,
            buttonText: "Add",
            isUpdateMessage: false,
            title: "Add K1 Client Instruction",
            typeId: typeId,
            resourceId: resourceId
        });
    };

    private onEmailInstructionEditButtonClick = (
        defaultMessageId: number,
        selectedMessageId: number,
        typeId: number,
        resourceId?: string
    ) => {
        this.setState({
            defaultMessageId: defaultMessageId,
            selectedMessageId: selectedMessageId,
            showEmailMessagePopup: true,
            buttonText: "Save",
            isUpdateMessage: true,
            title: "Edit K1 Client Instruction",
            typeId: typeId,
            resourceId: resourceId
        });
    };

    private deleteEmailMessage = (selectedMessageId: number, defaultMessageId: number, resourceId?: string) => {
        if (this.props.emailMessageList.data[selectedMessageId].emailMessage.id == defaultMessageId) {
            VenusNotifier.Warning(Constants.ClientInstructionConstants.DeleteInstructionWarning, "");
        } else {
            let _currentObject = this;
            bootbox.confirm({
                title: "Delete Instruction",
                message: "Are you sure, You want to delete this Instruction ?",
                buttons: {
                    cancel: {
                        label: '<i class="fas fa-times"></i> Cancel',
                        className: "btn-white btn-default"
                    },
                    confirm: {
                        label: '<i class="fas fa-check"></i> Delete',
                        className: "btn-info"
                    }
                },
                callback: (result: boolean) => {
                    if (result == true) {
                        _currentObject.props.deleteEmailMessageDetail(
                            _currentObject.props.emailMessageList.data[selectedMessageId].emailMessage,
                            resourceId
                        );
                        _currentObject.setState({ showLoader: true });
                    }
                }
            });
        }
    };

    private saveEmailMessage = (message: IEmailTemplateMessage, isSetDefault: boolean, isToggled: boolean) => {
        let companyData: ISSRSettings = this.props.companyData.ssrSettings
            ? this.props.companyData.ssrSettings
            : initialSSRSettings;
        message.type = this.state.typeId;
        switch (this.state.typeId) {
            case clientInstructions.k1PartnerInfoUpdate:
                this.props.saveEmailMessageDetail(
                    message,
                    companyData,
                    isSetDefault,
                    this.state.typeId,
                    undefined,
                    this.state.resourceId
                );
                break;
            case clientInstructions.k1ClientInfoUpdate:
                this.props.saveEmailMessageDetail(
                    message,
                    companyData,
                    isSetDefault,
                    this.state.typeId,
                    undefined,
                    this.state.resourceId
                );
                break;
            default:
                this.props.saveEmailMessageDetail(
                    message,
                    companyData,
                    isSetDefault,
                    this.state.typeId,
                    undefined,
                    this.state.resourceId
                );
        }
        this.setState({
            showPopUpLoader: true
        });
    };

    private editEmailMessage = (message: IEmailTemplateMessage, isSetDefault: boolean, isToggled: boolean) => {
        this.props.updateEmailMessageDetail(message, this.state.resourceId);
        if (isToggled && isSetDefault) {
            switch (this.state.typeId) {
                case clientInstructions.k1PartnerInfoUpdate:
                    this.saveDefaultMessage(message.id, undefined, this.state.resourceId);
                    break;
                case clientInstructions.k1ClientInfoUpdate:
                    this.saveDefaultMessage(message.id, undefined, this.state.resourceId);
                    break;
                default:
                    this.saveDefaultMessage(message.id, undefined, this.state.resourceId);
            }
        }
        this.setState({
            showPopUpLoader: true
        });
    };
    private onK1DistributionListItemClick = (messageId: number) => {
        this.setState({ selectedK1DistributionMessage: messageId });
    };

    private onK1PartnerInfoListItemClick = (messageId: number) => {
        this.setState({ selectedK1PartnerInfoMessage: messageId });
    };
    private onK1ClientInfoListItemClick = (messageId: number) => {
        this.setState({ selectedK1ClientInfoMessage: messageId });
    };

    private onK1PartnerUpdatePageListItemClick = (messageId: number) => {
        this.setState({ selectedK1PartnerUpdatePageMessage: messageId });
    };

    private onTaxYearSelect = (eventKey: any) => {
        this.setState({ selectedTaxYear: eventKey.value });
    };

    private updateK1Settings = (companySettings: any, resourceId?: string) => {
        let temp = companySettings as ICompanySettings;
        this.props.updateCompanySettings(temp, undefined, resourceId);
    };

    handleApplyButton = (event: any, resourceId?: string) => {
        let companySettings = this.props.companyData.companySettings as ICompanySettings;
        this.props.updateCompanySettings(companySettings, undefined, resourceId);
        this.disableCustomRouterPrompt();
    };

    public render() {
        return (
            <div className="company-settings font14" style={{ height: "100%" }}>
                {this.props.companyData.companySettings != undefined ? (
                    <>
                        <CustomRouterPrompt enabled={this.state.showCustomPrompt} history={this.props.history} />
                        <SettingsHeader
                            handleApplyButton={this.handleApplyButton}
                            header="K1 Settings"
                            showPopOver={false}
                            parentResourceIdentifier={
                                TaxReturnSettingsResources.K1SettingsAndInstructionsPrefix +
                                TaxReturnSettingsResources.HeaderPrefix
                            }
                        />
                    </>
                ) : (
                    ""
                )}
                {/*Commented as a part US: 103985
             <div>
                <K1Settings
                    companySettings={this.props.companyData.companySettings as ICompanySettings}
                    updateCompanySettings={this.props.updateCompanySettings}
                    isLoading={this.props.companyData.isLoading}
                    message={this.props.companyData.message}
                    enableCustomRouterPrompt={this.enableCustomRouterPrompt}
                ></K1Settings>
            </div>
            <hr /> */}
                <OverlayLoader show={this.props.companyData.isLoading} text={this.props.companyData.message} />
                <MaskingSSNSetting
                    updateCompanySettings={this.updateK1Settings}
                    companySettings={this.props.companyData.companySettings as ICompanySettings}
                    helptexts={this.props.helptexts.helpTexts}
                    showLoader={this.state.showLoader}
                    loaderText={CompanySettingsConstants.OverlayMessage.UpdatingCompanySettings}
                    parentResourceIdentifier={
                        TaxReturnSettingsResources.K1SettingsAndInstructionsPrefix +
                        TaxReturnSettingsResources.K1MaskUnMask +
                        TaxReturnSettingsResources.ToggleEnableOrDisable
                    }
                />
                <hr></hr>
                <div>
                    <div className="row">
                        <div className="col-sm-3">
                            <h3>K1 Instructions</h3>
                        </div>
                        <div className="col-sm-9">
                            <div className="div-flex" style={{ marginTop: 18 }}>
                                <label style={{ padding: 6, marginRight: 12 }}>Tax Year</label>
                                <Select
                                    className="year-dropdown-width"
                                    name="form-field-name"
                                    value={this.state.selectedTaxYear}
                                    onChange={this.onTaxYearSelect}
                                    options={PrepareTaxYear(2015)}
                                    data-test-auto="79E63979-089E-43D1-8F2C-AD00767DA788"
                                    clearable={false}
                                />
                            </div>
                        </div>
                    </div>
                    <LoadingOverlay>
                        <br />
                        <div>
                            <div className="new-main-content">
                                <h3>{this.state.selectedTaxYear} Tax Year</h3>
                            </div>
                            {this.props.businessReturnInstructions &&
                                this.props.businessReturnInstructions.instructions &&
                                this.props.businessReturnInstructions.instructions
                                    ?.filter((element) => element.taxYear === this.state.selectedTaxYear)
                                    .map((instruction) => {
                                        return (
                                            <div>
                                                <BusinessReturnInstructions
                                                    key={instruction.engagementType + "-" + instruction.taxYear}
                                                    name={instruction.taxYear + " Tax Year"}
                                                    downloadInstruction={this.getBusinessInstruction}
                                                    showFilePreview={this.generateFilePreview}
                                                    businessReturnInstruction={instruction}
                                                    replaceInstruction={this.processBusinessInstruction}
                                                    parentResourceIdentifier={
                                                        TaxReturnSettingsResources.K1SettingsAndInstructionsPrefix +
                                                        TaxReturnSettingsResources.K1Instructions
                                                    }
                                                />
                                            </div>
                                        );
                                    })}
                        </div>
                        <FilePreview
                            url={this.state.url}
                            showPDFPreview={this.state.showPDFPreview}
                            onHidePDFPreview={this.onHidePDFPreview}
                            fileType={this.state.fileType}
                            fileName={this.state.fileName}
                        />
                        <Loader
                            loading={this.props.businessReturnInstructions.isLoading}
                            text={this.props.businessReturnInstructions.loaderMessage}
                        />
                    </LoadingOverlay>
                </div>
                <hr></hr>
                <EmailMessage
                    heading={"K1 Distribution Instructions"}
                    description={"How to electronically or manually distribute"}
                    emailMessages={Object.keys(this.props.emailMessageList.data)
                        .filter(
                            (k, i) =>
                                this.props.emailMessageList.data[parseInt(k)].emailMessage.type ==
                                clientInstructions.k1Distribution
                        )
                        .reduce((obj: any, key: any) => {
                            obj[key] = this.props.emailMessageList.data[key];
                            return obj;
                        }, {})}
                    defaultMessage={this.state.defaultK1DistributionMessage}
                    selectedMessage={this.state.selectedK1DistributionMessage}
                    onListItemClick={this.onK1DistributionListItemClick}
                    typeId={clientInstructions.k1Distribution}
                    onAddButtonClick={this.onEmailInstructionAddButtonClick}
                    onEditButtonClick={this.onEmailInstructionEditButtonClick}
                    onDeleteButtonClick={this.deleteEmailMessage}
                    showLoader={this.state.showLoader}
                    loaderText={this.state.loaderText}
                    parentResourceIdentifier={
                        TaxReturnSettingsResources.K1SettingsAndInstructionsPrefix +
                        TaxReturnSettingsResources.K1DistibutionInstructions
                    }
                />
                <hr></hr>
                <EmailMessage
                    heading={"K1 Recipient Info Update Email"}
                    description={"Email sent to update K1 Partnership Info"}
                    emailMessages={Object.keys(this.props.emailMessageList.data)
                        .filter(
                            (k, i) =>
                                this.props.emailMessageList.data[parseInt(k)].emailMessage.type ==
                                clientInstructions.k1PartnerInfoUpdate
                        )
                        .reduce((obj: any, key: any) => {
                            obj[key] = this.props.emailMessageList.data[key];
                            return obj;
                        }, {})}
                    defaultMessage={this.state.defaultK1PartnerInfoMessage}
                    selectedMessage={this.state.selectedK1PartnerInfoMessage}
                    onListItemClick={this.onK1PartnerInfoListItemClick}
                    typeId={clientInstructions.k1PartnerInfoUpdate}
                    onAddButtonClick={this.onEmailInstructionAddButtonClick}
                    onEditButtonClick={this.onEmailInstructionEditButtonClick}
                    onDeleteButtonClick={this.deleteEmailMessage}
                    showLoader={this.state.showLoader}
                    loaderText={this.state.loaderText}
                    parentResourceIdentifier={
                        TaxReturnSettingsResources.K1SettingsAndInstructionsPrefix +
                        TaxReturnSettingsResources.K1RecipientUpdateEmail
                    }
                />
                <hr></hr>
                <EmailMessage
                    heading={"K1 Client Info Update Email"}
                    description={"Email sent to update K1 Client Info"}
                    emailMessages={Object.keys(this.props.emailMessageList.data)
                        .filter(
                            (k, i) =>
                                this.props.emailMessageList.data[parseInt(k)].emailMessage.type ==
                                clientInstructions.k1ClientInfoUpdate
                        )
                        .reduce((obj: any, key: any) => {
                            obj[key] = this.props.emailMessageList.data[key];
                            return obj;
                        }, {})}
                    defaultMessage={this.state.defaultK1ClientInfoMessage}
                    selectedMessage={this.state.selectedK1ClientInfoMessage}
                    onListItemClick={this.onK1ClientInfoListItemClick}
                    typeId={clientInstructions.k1ClientInfoUpdate}
                    onAddButtonClick={this.onEmailInstructionAddButtonClick}
                    onEditButtonClick={this.onEmailInstructionEditButtonClick}
                    onDeleteButtonClick={this.deleteEmailMessage}
                    showLoader={this.state.showLoader}
                    loaderText={this.state.loaderText}
                    parentResourceIdentifier={
                        TaxReturnSettingsResources.K1SettingsAndInstructionsPrefix +
                        TaxReturnSettingsResources.K1ClientUpdateEmail
                    }
                />
                <hr></hr>

                <WelcomeMessage
                    heading={"K1 Recipient Update Page"}
                    description={"Landing page to update and view their K-1 partner details"}
                    welcomeMessages={Object.keys(this.props.welcomeMessageList.data)
                        .filter(
                            (k, i) =>
                                this.props.welcomeMessageList.data[parseInt(k)].welcomeMessage.type ==
                                clientInstructions.k1PartnerUpdatePageMessage
                        )
                        .reduce((obj: any, key: any) => {
                            obj[key] = this.props.welcomeMessageList.data[key];
                            return obj;
                        }, {})}
                    defaultMessage={this.state.defaultK1PartnerUpdatePageMessage}
                    selectedMessage={this.state.selectedK1PartnerUpdatePageMessage}
                    onListItemClick={this.onK1PartnerUpdatePageListItemClick}
                    typeId={clientInstructions.k1PartnerUpdatePageMessage}
                    onAddButtonClick={this.onWelcomeInstructionAddButtonClick}
                    onEditButtonClick={this.onWelcomeInstructionEditButtonClick}
                    onDeleteButtonClick={this.deleteWelcomeMessage}
                    showLoader={this.state.showLoader}
                    loaderText={this.state.loaderText}
                    parentResourceIdentifier={
                        TaxReturnSettingsResources.K1SettingsAndInstructionsPrefix +
                        TaxReturnSettingsResources.K1RecipientUpdatePage
                    }
                />
                <hr></hr>

                <WelcomeMessagePopUp
                    showState={this.state.showWelcomeMessagePopUp}
                    onHide={this.onCancelButtonClick}
                    submitButtonClick={this.state.isUpdateMessage ? this.editWelcomeMessage : this.addWelcomeMessage}
                    buttonText={this.state.buttonText}
                    selectedMessageDetail={
                        this.state.isUpdateMessage
                            ? this.props.welcomeMessageList.data[this.state.selectedMessageId]
                                ? this.props.welcomeMessageList.data[this.state.selectedMessageId].welcomeMessage
                                : initialWelcomeMessage
                            : initialWelcomeMessage
                    }
                    title={this.state.title}
                    defaultMessageId={this.state.defaultMessageId}
                    isUpdate={this.state.isUpdateMessage}
                    loaderText={"Saving..."}
                    showLoader={this.state.showPopUpLoader}
                    welcomeMessages={Object.keys(this.props.welcomeMessageList.data)
                        .filter(
                            (k, i) => this.props.welcomeMessageList.data[parseInt(k)].welcomeMessage.type == this.state.typeId
                        )
                        .reduce((obj: any, key: any) => {
                            obj[key] = this.props.welcomeMessageList.data[key];
                            return obj;
                        }, {})}
                />
                <EmailMessagePopUp
                    showState={this.state.showEmailMessagePopup}
                    onHide={this.onCancelButtonClick}
                    submitButtonClick={this.state.isUpdateMessage ? this.editEmailMessage : this.saveEmailMessage}
                    buttonText={this.state.buttonText}
                    selectedMessageDetail={
                        this.state.isUpdateMessage
                            ? this.props.emailMessageList.data[this.state.selectedMessageId]
                                ? this.props.emailMessageList.data[this.state.selectedMessageId].emailMessage
                                : initialEmailMessage
                            : initialEmailMessage
                    }
                    title={this.state.title}
                    defaultMessageId={this.state.defaultMessageId}
                    isUpdate={this.state.isUpdateMessage}
                    loaderText={"Saving..."}
                    showLoader={this.state.showPopUpLoader}
                    emailMessages={Object.keys(this.props.emailMessageList.data)
                        .filter((k, i) => this.props.emailMessageList.data[parseInt(k)].emailMessage.type == this.state.typeId)
                        .reduce((obj: any, key: any) => {
                            obj[key] = this.props.emailMessageList.data[key];
                            return obj;
                        }, {})}
                />
            </div>
        );
    }
}
