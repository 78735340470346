import * as React from "react";
import { ProgressBar, FormControl, Modal, Button, Alert, Nav } from "react-bootstrap";
import "react-bootstrap-table/css/react-bootstrap-table.css";
import { DocumentUploadDropzoneComponent } from "../../../common/UploadDocument/DocumentUploadDropzoneComponent";
import { UploadedDocumentsTable } from "../../../common/UploadDocument/UploadedDocumentsTable";
import { UploadtaxReturnConstants } from "../../../helper/Constants";
import { getFileSize, getPdfFilePageCount, extractClientId } from "../../../helper/HelperFunctions";
import { ShowLoader, HideLoader } from "../../../helper/Loader";
import * as Validation from "../../../helper/Validations";
import * as bootbox from "bootbox";
import { ISSRUserModel, IParterUserModel } from "../../../../Core/ViewModels/User/UserViewModel";
import { IAssignmentsState } from "../../../../store/assignments/AssignmentsState";
import { Redirect } from "react-router-dom";
import { ITaxReturn, initialTaxReturnState } from "../../TaxReturn";
import * as UploadTaxReturnStore from "../../../../store/common/uploadTaxReturn";
import { ButtonFormatter } from "./UploadCommonFormatters";
import {
    UploadFunctions,
    isFileExist,
    GetFileMagicNumber,
    getFileExtension,
    validatePdfFile
} from "@sssuite-js-packages/file-utility";
import { SasContainer, TaxReturnUploadData, UploadStatus } from "../../../../Core/ViewModels/Common/UploadDocumentViewModel";
import { UploadCorrectedReturnWarning } from "../../../helper/Constants";
import UploadDocumentModalWarning from "../UploadDocumentModalWarning";
import { CommonResources } from "../../../helper/ResourceIdConstants";
import SuiteModal from "src/components/common/SuiteModal";
import {
    IAccessingUsersAndUserGroups,
    ISetAccessData,
    IUserDataFromDB,
    IUserGroupDataFromDB
} from "src/components/common/SetAccessCommonModal/SetAccessCommonModal.model";
import { getInitialListOfDataFromDB } from "src/components/common/SetAccessCommonModal/SetAccessCommonModal.api";
import SetAccessCommonModal from "src/components/common/SetAccessCommonModal";
import { IReturnAccessSettingsModel } from "src/Core/ViewModels/Company/CompanySettingsViewModel";
import { VenusNotifier } from "../../../helper/VenusNotifier";
import { IClientManagementSetting } from "../../../../store/company/SuiteClientManagementSettingStore";

interface ProSystemUploadRecallModalProps {
    documentKey?: string;
    onShow: boolean;
    onHide(): void;
    users: ISSRUserModel[];
    EROs: IParterUserModel[];
    routing?: boolean;
    selectedERO: number;
    getUploadLink: (
        url: string,
        callback?: (data?: UploadTaxReturnStore.IUploadTaxReturnState) => void,
        resourceId?: string
    ) => void;
    processTaxReturn: (url: string, callback: () => void) => void;
    submitTaxReturn: (url: string, taxData: string, callback: () => void, resourceId?: string) => void;
    uploadConfirmation: (documentKey: string, clientId: string) => void;
    isRecalled?: boolean;
    returnAccessSettings?: IReturnAccessSettingsModel;
    parentResourceIdentifier?: string;
    ssravailableUsers: ISSRUserModel[];
    isGroupedReturn: boolean;
    clientManagementSetting: IClientManagementSetting;
    requestClientManagementSetting: () => void;
}

export interface ProSystemUploadRecallModalState {
    process: number;
    fileName: string;
    fileSize: number;
    clientId: string;
    TaxReturnUploadData: TaxReturnUploadData[];
    gridColumnDisable: boolean;
    showUpload: boolean;
    showSetAccess: boolean;
    defaultERO: number;
    EROs: IParterUserModel[];
    sasContainer: SasContainer[];
    config: {
        dropzoneSelector: string;
        iconFiletypes: [".pdf"];
        showFiletypeIcon: boolean;
        postUrl: string;
    };
    setAccessAccessingUsers: ISSRUserModel[];
    setAccessCurrentUsers: ISSRUserModel[];
    taxReturnIndex: number;
    abortRequests: boolean;
    fileAdded: boolean;
    companyAssignmentsRedirect: boolean;
    assignments: IAssignmentsState;
    taxReturns: ITaxReturn;
    availableUsers: IUserDataFromDB[];
    selectedUsers: IUserDataFromDB[];
    availableUserGroups: IUserGroupDataFromDB[];
    selectedUserGroups: IUserGroupDataFromDB[];    
}

export class ProSystemUploadRecalledDocumentModal extends React.Component<
    ProSystemUploadRecallModalProps,
    ProSystemUploadRecallModalState
> {
    constructor(props: ProSystemUploadRecallModalProps) {
        super(props);
        this.state = {
            process: 0,
            fileName: "",
            fileSize: 0,
            clientId: "",
            TaxReturnUploadData: [],
            gridColumnDisable: true,
            showUpload: false,
            showSetAccess: false,
            defaultERO: 0,
            EROs: [],
            sasContainer: [],
            config: {
                dropzoneSelector: "div.filepicker",
                iconFiletypes: [".pdf"],
                showFiletypeIcon: true,
                postUrl: "/api/"
            },
            setAccessAccessingUsers: [],
            setAccessCurrentUsers: [],
            taxReturnIndex: 0,
            abortRequests: false,
            fileAdded: false,
            companyAssignmentsRedirect: false,
            assignments: {
                assignments: [],
                isLoading: true,
                query: "",
                totalRowCount: 0
            },
            taxReturns: initialTaxReturnState,
            availableUsers: [],
            selectedUsers: [],
            availableUserGroups: [],
            selectedUserGroups: []
        };
        this.deletingReturn = this.deletingReturn.bind(this);
        this.deleteReturnRow = this.deleteReturnRow.bind(this);
        this.onSetAccess = this.onSetAccess.bind(this);
        this.onSetAccessHide = this.onSetAccessHide.bind(this);
        this.handleClose = this.handleClose.bind(this);

        this.setAcessShow = this.setAcessShow.bind(this);
        this.onSetAccessSave = this.onSetAccessSave.bind(this);
        this.closeUploadWindow = this.closeUploadWindow.bind(this);
        this.uploadProgressCallback = this.uploadProgressCallback.bind(this);
    }

    componentDidMount() {
        this.setState({
            defaultERO: this.props.selectedERO
        });
        this.loadSetAccessData();
        this.props.requestClientManagementSetting();
    }

    loadSetAccessData = () => {
        getInitialListOfDataFromDB((setAccessData?: ISetAccessData) => {
            if (!setAccessData) {
                VenusNotifier.Error("Error fetching list of users and user groups", "");
            } else {
                const { availableUsers, selectedUsers, availableUserGroups, selectedUserGroups } = setAccessData;
                this.setState({
                    availableUsers,
                    selectedUsers,
                    availableUserGroups,
                    selectedUserGroups
                });
            }
        }, this.props.isGroupedReturn);
    };

    //===========================================================================
    // Bootstrap Table Formatters start
    //===========================================================================

    defaultType(cell: any, row: any) {
        return cell;
    }

    statusFormatter(cell: any, row: any) {
        return <span>{UploadStatus[cell].toString()}</span>;
    }

    buttonFunction(cell: any, row: any) {
        return (
            <ButtonFormatter
                deleteReturn={() => this.deletingReturn(row)}
                disabled={row.progressBar != undefined && row.progressBar != 100 ? true : false}
                data-test-auto="B07622E7-FEB7-4759-BA19-4DF7D79BE7E9"
            />
        );
    }

    progressbar(cell: any, row: any) {
        return (
            <ProgressBar
                striped
                variant={cell != undefined && cell != 100 ? "warning" : "success"}
                now={cell}
                className="uploaded-file-progressbar"
            />
        );
    }

    textBox(cell: any, row: any) {
        return (
            <FormControl
                type="text"
                disabled={row.gridRowDisable}
                defaultValue={cell}
                onChange={this.onClientIdChange.bind(this, row)}
                data-test-auto="8E584D37-7EF3-4D2A-8CC8-E21FA295F6EB"
            />
        );
    }

    linkFormat(cell: any, row: any) {
        return (
            <Nav.Link
                href="javascript:"
                style={{ textDecoration: "underline" }}
                onClick={this.onSetAccess.bind(this, row)}
                data-test-auto="7FDF51B6-41F6-4E58-9A03-C52D36983AF0"
            >
                {cell}
            </Nav.Link>
        );
    }

    //===========================================================================
    // Bootstrap Table Formatters End
    //===========================================================================

    djsConfig = {
        uploadMultiple: false,
        parallelUploads: 1,
        maxFiles: 1,
        addRemoveLinks: true,
        acceptedFiles: "application/pdf",
        headers: { "Access-Control-Allow-Origin": "*", "x-ms-blob-type": "BlockBlob" },
        method: "PUT",
        previewsContainer: false
    };

    eventHandlers = {
        init: () => {
            this.reset();
        },
        addedfiles: (files: any) => {
            bootbox.hideAll();
            let _self = this;
            let tempGridData = _self.state.TaxReturnUploadData;
            let uploadHelperFunctions = new UploadFunctions();

            let rowCount = tempGridData.length;
            if (rowCount > 0) {
                bootbox.alert(UploadtaxReturnConstants.Recall.FileAddingWarning);
                return;
            }
            if (files.length > 1) {
                bootbox.alert(UploadtaxReturnConstants.Recall.MultipleFileAddingWarning);
                return false;
            }

            let file = files[0];
            if (Validation.validateFileSize(file)) {
                this.props.getUploadLink(
                    "/api/Upload/ReplaceGetTaxReturnUploadLinkAsync?documenGUID=" + this.props.documentKey,
                    (data?: UploadTaxReturnStore.IUploadTaxReturnState) => {                        
                        if (data) {
                        _self.setState({ gridColumnDisable: true, fileAdded: true });
                        let fileName = file.name;
                        let documentKey = _self.props.documentKey;
                        let fileExtension = getFileExtension(fileName);
                        GetFileMagicNumber(file).then((result) => {
                            if (validatePdfFile(fileExtension, result)) {
                                let _fetchThis = this;
                                _self.setState({ gridColumnDisable: true });
                                let filecount = 1;
                                while (isFileExist(fileName, tempGridData)) {
                                    fileName = file.name;
                                    fileName = fileName.replace("." + fileExtension, "");
                                    fileName = fileName + " - Copy (" + filecount + ")." + fileExtension;
                                    filecount++;
                                }
                                getPdfFilePageCount(file as File).then((pageCount: number) => {
                                    tempGridData.push({
                                        name: fileName,
                                        clientName: "clname",
                                        clientId: this.props.clientManagementSetting.extractClientIdEnabled ?
                                            extractClientId(fileName, fileExtension) : fileName.replace("." + fileExtension, ""),
                                        partnerId: _self.state.defaultERO,
                                        number: tempGridData.length + 1,
                                        progressBar: _self.state.process,
                                        setAccess:
                                            this.props.isGroupedReturn
                                                ? "Everyone" :
                                            !(
                                            this.props.returnAccessSettings != undefined &&
                                            this.props.returnAccessSettings!.isSetAccessToEveryone)
                                            ? "Restricted"
                                            : "Everyone",
                                        size: getFileSize(file?.size),
                                        status: UploadStatus.Uploading,
                                        sasGuid: documentKey,
                                        storageAccountId: data? data.storageAccountId : 0,
                                        gridRowDisable: true,
                                        documentAccess: {
                                            documents: [],
                                            userGroups: this.state.selectedUserGroups.map((group) => {
                                                return group.id;
                                            }),
                                            users: this.state.selectedUsers.map((user) => {
                                                return user.id;
                                            })
                                        },
                                        numberOfPages: pageCount
                                    });

                                    _self.setState({ TaxReturnUploadData: tempGridData });

                                    _self.setState(() =>
                                        uploadHelperFunctions.uploadFile(
                                            file,
                                            data,
                                            fileName,
                                            _fetchThis.uploadProgressCallback,
                                            _fetchThis.uploadCommittCallBack,
                                            _self.uploadFailureCallback
                                        )
                                    );
                                });
                            }
                        });
                        }  
                    },
                    this.props.parentResourceIdentifier + CommonResources.CCHReUploadButton
                );
            }
        }
    };

    public uploadProgressCallback(percent: number, fileToUpload: any) {
        let tempGridData = this.state.TaxReturnUploadData;
        tempGridData.map((tempGridDataValue, index) => {
            if (tempGridDataValue.sasGuid == fileToUpload.fileGUID) {
                tempGridDataValue.progressBar = percent - 10;
            }
        });
        this.setState({
            TaxReturnUploadData: tempGridData
        });
    }

    uploadCommittCallBack = (fileToUpload: any) => {
        if (this.state.TaxReturnUploadData.length > 0) {
            let tempGridData = this.state.TaxReturnUploadData;
            tempGridData.map((tempGridDataValue, index) => {
                if (tempGridDataValue.name == fileToUpload.fileName) {
                    tempGridDataValue.progressBar = 100;
                    tempGridDataValue.status = UploadStatus.Uploaded;
                    tempGridDataValue.gridRowDisable = false;
                }
            });
            this.setState(
                {
                    TaxReturnUploadData: tempGridData
                },
                () => {
                    if (!this.isUploadOnGoing()) {
                        this.setState({ gridColumnDisable: false });
                    }
                }
            );
        }
    };

    //Implementation of this callback will be done later
    uploadFailureCallback = (fileToUpload: any) => {
        console.log("upload failed for file:", fileToUpload.fileName);
    };

    private isUploadOnGoing = (): boolean => {
        let isuploading: boolean = false;
        this.state.TaxReturnUploadData.map((value) => {
            if (value.progressBar == undefined ? 0 : value.progressBar < 100) {
                isuploading = true;
                return;
            }
        });
        return isuploading;
    };

    deletingReturn(row: any) {
        let _self = this;
        bootbox.confirm(UploadtaxReturnConstants.DeleteFileMessage, function (result) {
            if (result) {
                _self.deleteReturnRow(row);
            }
        });
    }

    deleteReturnRow(row: any) {
        let _self = this;
        var _gridData = [..._self.state.TaxReturnUploadData];
        _gridData = _gridData.filter((i) => i.number != row.number);
        let _tempgridData = _self.state.TaxReturnUploadData;
        _tempgridData = _gridData;

        let gridColumnDisable = this.state.gridColumnDisable;
        if (_gridData.length == 0) {
            gridColumnDisable = true;
        }

        let _uploadData = [..._self.state.sasContainer];
        _uploadData = _uploadData.filter((i) => i.sasGuid != row.sasGuid);

        let _tempUploadData = _self.state.sasContainer;
        _tempUploadData = _uploadData;

        _self.setState({
            TaxReturnUploadData: _tempgridData,
            sasContainer: _tempUploadData,
            fileAdded: false,
            gridColumnDisable: gridColumnDisable
        });
    }

    private onClientIdChange(row: any, event: any) {
        var _gridData = [...this.state.TaxReturnUploadData];
        _gridData.map((value, index) => {
            if (value.number == row.number) {
                value.clientId = event.target.value;
            }
        });
    }

    //===========================================================================
    // Set Access End
    //===========================================================================

    setAcessShow() {
        this.setState({ showSetAccess: true });
    }

    onSetAccess(taxReturn: any, event: any) {
        this.setState({
            taxReturnIndex: taxReturn.number,
            showSetAccess: true
        });
    }

    onSetAccessHide() {
        this.setState({
            showSetAccess: false
        });
    }
    private onSetAccessSave(accessingUsersAndUserGroups: IAccessingUsersAndUserGroups) {
        let index = this.state.taxReturnIndex;
        const { selectedUsers, selectedUserGroups, availableUsers, availableUserGroups } = accessingUsersAndUserGroups;
        this.state.TaxReturnUploadData.map((value) => {
            if (value.number == index) {
                let isAllUsers = false;
                let isAllUserGroups = false;
                if (
                    (selectedUsers.length > 0 && selectedUsers.indexOf(0) !== -1) ||
                    availableUsers.length === 0 ||
                    (availableUsers.length === 1 && availableUsers[0] === 0)
                ) {
                    isAllUsers = true;
                }

                if (
                    (selectedUserGroups.length > 0 && selectedUserGroups.indexOf(0) !== -1) ||
                    availableUserGroups.length === 0 ||
                    (availableUserGroups.length === 1 && availableUserGroups[0] === 0)
                ) {
                    isAllUserGroups = true;
                }

                if (isAllUsers && isAllUserGroups) {
                    // value.documentAccess.userId = userIds;
                    value.setAccess = "Everyone";
                } else {
                    // value.documentAccess.userId = [];
                    value.setAccess = "Restricted";
                }

                value.documentAccess = {
                    documents: [],
                    userGroups: accessingUsersAndUserGroups.selectedUserGroups,
                    users: accessingUsersAndUserGroups.selectedUsers
                };
            }
        });
        this.setState({
            showSetAccess: false,
            selectedUsers: this.getSelectedUser(accessingUsersAndUserGroups),
            availableUsers: this.getAvailableUser(accessingUsersAndUserGroups),
            selectedUserGroups: this.getSelectedUserGroups(accessingUsersAndUserGroups),
            availableUserGroups: this.getAvailableUserGroups(accessingUsersAndUserGroups)
        });
    }

    getSelectedUser = (accessingUsersAndUserGroups: IAccessingUsersAndUserGroups) => {
        const allUsers = [...this.state.selectedUsers, ...this.state.availableUsers];
        let selectedUser: IUserDataFromDB[] = [];
        accessingUsersAndUserGroups.selectedUsers.map((userId) => {
            let index = allUsers.findIndex((x) => x.id == userId);
            if (index != -1) {
                selectedUser.push(allUsers[index]);
            }
        });
        return selectedUser;
    };

    getAvailableUser = (accessingUsersAndUserGroups: IAccessingUsersAndUserGroups) => {
        const allUsers = [...this.state.selectedUsers, ...this.state.availableUsers];
        let availableUser: IUserDataFromDB[] = [];
        accessingUsersAndUserGroups.availableUsers.map((userId) => {
            let index = allUsers.findIndex((x) => x.id == userId);
            if (index != -1) {
                availableUser.push(allUsers[index]);
            }
        });
        return availableUser;
    };

    getSelectedUserGroups = (accessingUsersAndUserGroups: IAccessingUsersAndUserGroups) => {
        const allUserGroups = [...this.state.selectedUserGroups, ...this.state.availableUserGroups];
        let selectedUserGroups: IUserGroupDataFromDB[] = [];
        accessingUsersAndUserGroups.selectedUserGroups.map((userGroupId) => {
            let index = allUserGroups.findIndex((x) => x.id == userGroupId);
            if (index != -1) {
                selectedUserGroups.push(allUserGroups[index]);
            }
        });
        return selectedUserGroups;
    };

    getAvailableUserGroups = (accessingUsersAndUserGroups: IAccessingUsersAndUserGroups) => {
        const allUserGroups = [...this.state.selectedUserGroups, ...this.state.availableUserGroups];
        let availableUserGroups: IUserGroupDataFromDB[] = [];
        accessingUsersAndUserGroups.availableUserGroups.map((userGroupId) => {
            let index = allUserGroups.findIndex((x) => x.id == userGroupId);
            if (index != -1) {
                availableUserGroups.push(allUserGroups[index]);
            }
        });
        return availableUserGroups;
    };
    //===========================================================================
    // Set Access End
    //===========================================================================

    private reset = () => {
        let tempUploadData: SasContainer[] = [];
        let tempGridData: TaxReturnUploadData[] = [];
        this.state.sasContainer.slice(0, this.state.sasContainer.length);
        this.setState({
            sasContainer: tempUploadData,
            TaxReturnUploadData: tempGridData,
            taxReturns: initialTaxReturnState,
            gridColumnDisable: true
        });
    };

    private handleClose() {
        let _self = this;
        let rowCount = this.state.TaxReturnUploadData.length;
        //If file is uploading
        if (this.isUploadOnGoing()) {
            bootbox.alert(UploadtaxReturnConstants.AbortingUploadWarning);
            return false;
        }
        //If there is an uploaded file
        if (rowCount > 0) {
            bootbox.confirm({
                message: UploadtaxReturnConstants.CloseConfirmationMessage,
                buttons: {
                    cancel: {
                        label: '<i class="fas fa-times"></i> Cancel',
                        className: "btn-white btn-default"
                    },
                    confirm: {
                        label: '<i class="fas fa-check"></i> OK',
                        className: "btn-info"
                    }
                },
                callback: (result: boolean) => {
                    if (result) {
                        _self.closeUploadWindow();
                    }
                }
            });
        } else {
            this.closeUploadWindow();
        }
    }

    private closeUploadWindow() {
        this.reset();
        //Request.
        this.setState({ abortRequests: true });
        this.props.onHide();
        this.setState({
            showSetAccess: false
        });
    }

    onSubmitTaxreturns = () => {
        ShowLoader();
        const uploadData = this.state.TaxReturnUploadData;
        const docKey = this.props.documentKey;

        uploadData[0].documentAccess = {
            documents: [],
            userGroups: this.state.selectedUserGroups.map((group) => {
                return group.id;
            }),
            users: this.state.selectedUsers.map((user) => {
                return user.id;
            })
        };

        this.props.submitTaxReturn(
            "/api/Upload/SubmitRecallTaxReturnsAync",
            JSON.stringify(uploadData),
            () => {
                this.props.uploadConfirmation(docKey || "", uploadData[0].clientId || "");
                this.uploadConfirmation();
                this.closeUploadWindow();
                HideLoader();
            },
            this.props.parentResourceIdentifier + CommonResources.CCHReSubmitButton
        );
    };

    private uploadConfirmation() {
        let message: string = UploadtaxReturnConstants.SubmitTaxReturnSuccess + ": <br/><ul class='padding-left-6'>";
        this.state.TaxReturnUploadData.map((value, index) => {
            message = message + "<li>" + value.clientId + "</li>";
        });
        message = message + "</ul>";
        bootbox.alert(message, function () { });
        if (this.props.routing) {
            this.redirect();
        }
    }

    private redirect() {
        this.setState({
            assignments: {
                assignments: [] as ITaxReturn[],
                query: "",
                isLoading: false,
                totalRowCount: 0
            },
            companyAssignmentsRedirect: true
        });
    }

    public render() {
        const columns = [
            {
                header: "#",
                key: "number",
                isKey: true,
                dataFormat: this.defaultType,
                width: "30px",
                columnClassName: ""
            },
            {
                header: "Name",
                key: "name",
                isKey: false,
                dataFormat: this.defaultType,
                width: "auto",
                columnClassName: "word-Visible"
            },
            {
                header: "Upload Progress",
                key: "progressBar",
                isKey: false,
                dataFormat: this.progressbar,
                width: "auto",
                columnClassName: ""
            },
            {
                header: "Size",
                key: "size",
                isKey: false,
                dataFormat: this.defaultType,
                width: "auto",
                columnClassName: ""
            },
            {
                header: "Status",
                key: "status",
                isKey: false,
                dataFormat: this.statusFormatter,
                width: "auto",
                columnClassName: ""
            },
            {
                header: "Client ID *",
                key: "clientId",
                isKey: false,
                dataFormat: this.textBox.bind(this),
                width: "240px",
                columnClassName: ""
            },
            {
                header: "Set Access",
                key: "setAccess",
                isKey: false,
                dataFormat: this.linkFormat.bind(this),
                width: "auto",
                columnClassName: ""
            },
            {
                header: "Remove",
                key: "button",
                isKey: false,
                dataFormat: this.buttonFunction.bind(this),
                width: "auto",
                columnClassName: ""
            }
        ];

        if (this.state.companyAssignmentsRedirect) {
            return <Redirect to="/CompanyAssignments" />;
        }

        return (
            <div>
                <Modal
                    className="upload-doc-modal"
                    show={this.props.onShow}
                    onHide={this.handleClose}
                    keyboard={false}
                    backdrop={false}
                    enforceFocus={false}
                >
                    <Modal.Header closeButton data-test-auto="9DFD99E6-5A5C-4A9A-B40F-975AB2D109B5">
                        <Modal.Title style={{ display: "block" }} className="upload-modal-title">
                            {" "}
                            Upload Documents
                            <UploadDocumentModalWarning
                                warningMessages={[
                                    UploadtaxReturnConstants.PdfDocumentGroupedReturnWarning,
                                    UploadtaxReturnConstants.PdfDocumentUploadWarning
                                ]}
                            />
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="row">
                            <div className="col-sm-2 text-center">
                                <DocumentUploadDropzoneComponent
                                    componentConfig={this.state.config}
                                    djsConfig={this.djsConfig}
                                    eventHandlers={this.eventHandlers}
                                    autoTestId={"87079975-3E83-4449-863F-0019EC15A9AF"}
                                />
                            </div>
                            <div className="col-sm-10" id="uploadDocument">
                                <UploadedDocumentsTable
                                    column={columns}
                                    data={this.state.TaxReturnUploadData}
                                    bodyContainerClass={"overflowVisible"}
                                />
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                         
                        <Button
                            variant="default"
                            onClick={this.handleClose}
                            data-test-auto="B1C823D3-8D5E-4B9B-9D59-11F3A0D9BEED"
                        >
                            <i className="fas fa-times"></i>Close
                        </Button>
                        <Button
                            variant="info"
                            onClick={this.onSubmitTaxreturns}
                            disabled={this.state.gridColumnDisable}
                            data-test-auto="5D0B9B03-68DE-47E7-8681-6EDBD54E005B"
                            data-resource-id={this.props.parentResourceIdentifier + CommonResources.CCHReSubmitButton}
                        >
                            <i className="fas fa-save"></i>Submit
                        </Button>
                    </Modal.Footer>
                </Modal>

                {this.state.showSetAccess && (
                    <SuiteModal width="787" theme="light" title="Set Access" hide={this.onSetAccessHide}>
                        <SetAccessCommonModal
                            onCancel={this.onSetAccessHide}
                            onApplyAccess={this.onSetAccessSave}
                            returnAccessSettings={this.props.returnAccessSettings}
                            availableUsers={this.state.availableUsers}
                            selectedUsers={this.state.selectedUsers}
                            availableUserGroups={this.state.availableUserGroups}
                            selectedUserGroups={this.state.selectedUserGroups}
                            isDragAndDropDisabled={!this.props.isGroupedReturn}
                        />
                    </SuiteModal>
                )}
            </div>
        );
    }
}
