import * as React from "react";
import { RouteComponentProps } from "react-router";
import * as ClientMessageStore from "../../store/settings/ClientMessageStore";
import * as CompanyStore from "../../store/company/CompanyStore";
import * as UserSettingsStore from "../../store/userManagement/UserSettingStore";
import { EmailMessage } from "./ClientInstructions/EmailMessage/EmailMessage";
import * as EmailMessageStore from "../../store/settings/EmailMessageStore";
import * as ReminderStore from "../../store/common/ReminderStore";
import {
    clientInstructions,
    IClientMessage,
    IEmailTemplateMessage,
    initialClientMessage,
    initialEmailMessage
} from "../../Core/ViewModels/CompanySettings/ClientInstructionViewModel";
import * as Constants from "../helper/Constants";
import { ISSRSettings, initialSSRSettings, SSRSettings } from "../../Core/ViewModels/Company/Settings/SSRSettingsViewModel";
import { VenusNotifier } from "../../components/helper/VenusNotifier";
import { EmailMessagePopUp } from "./ClientInstructions/EmailMessage/EmailMessagePopUp";
import { decodeText } from "../../components/helper/HelperFunctions";
import * as bootbox from "bootbox";
import { ClientMessage } from "./ClientInstructions/ClientMessage/ClientMessage";
import { ClientMessagePopUp } from "./ClientInstructions/ClientMessage/ClientMessagePopUp";
import * as ApplicationHelptextStore from "../../store/common/ApplicationHelptextStore";
import * as SSRSettingsStore from "../../store/company/settings/SSRSettingsStore";
import { GroupReturnSettingsResources } from "../../components/helper/ResourceIdConstants";
import { ClientInstructionReturnsState } from "./ClientInstructionReturns";

type ClientInstructionGroupReturnsProps = {
    clientMessageList: ClientMessageStore.IClientMessageStoreState;
    emailMessageList: EmailMessageStore.IEmailMessageStoreState;
    companyData: CompanyStore.ICompanyData;
    helptexts: ApplicationHelptextStore.IHelptextState;
} & typeof ClientMessageStore.ActionCreators &
    typeof EmailMessageStore.ActionCreators &
    typeof CompanyStore.actionCreators &
    typeof UserSettingsStore.actionCreators &
    typeof ApplicationHelptextStore.actionCreators &
    typeof ReminderStore.actionCreators &
    typeof SSRSettingsStore.actionCreators &
    RouteComponentProps<{ page: string }>; // ... plus incoming routing parameters

export interface ClientInstructionGroupReturnsState extends ClientInstructionReturnsState {
    showWelcomeMessagePopUp: boolean;
    selectedWelcomeMessage: number;
    selectedDownloadTaxDocumentMessage: number;
    defaultDownloadTaxDocumentMessage: number;
}

export class ClientInstructionGroupReturns extends React.Component<
    ClientInstructionGroupReturnsProps,
    ClientInstructionGroupReturnsState
> {
    constructor(props: ClientInstructionGroupReturnsProps) {
        super(props);

        this.state = {
            showEmailMessagePopup: false,
            showClientMessagePopup: false,
            showWelcomeMessagePopUp: false,

            defaultMessageId: 0,
            headerTitle: "",
            typeId: 0,
            ssrSettings: initialSSRSettings,
            buttonText: "",
            title: "",
            isUpdateMessage: false,
            selectedWelcomeMessage: 0,
            selectedInitialWelcomeMessage: 0,
            selectedVoucherReminderMessage: 0,
            selectedDownloadTaxDocumentMessage: 0,
            selectedDownloadEsignMessage: 0,
            selectedDownloadInstruction: 0,
            selectedPaymentInstruction: 0,
            selectedSigningReminder: 0,
            defaultWelcomeMessage: 0,
            defaultInitialWelcomeMessage: 0,
            defaultVoucherReminderMessage: 0,
            defaultDownloadTaxDocumentMessage: 0,
            defaultDownloadEsignMessage: 0,
            defaultDownloadInstruction: 0,
            defaultPaymentInstruction: 0,
            defaultSigningReminder: 0,
            defaultVoucherRevisionMessage: 0,
            selectedMessageId: 0,
            showLoader: false,
            loaderText: "",
            showPopUpLoader: false,
            defaultInvoicePaymentSuccessMessage: 0,
            selectedInvoicePaymentSuccessMessage: 0,
            selectedVoucherRevisionMessage: 0,
            initialMailType: 1,
            defaultInitialEmailManualSign: 0,
            selectedInitialEmailManualSign: 0
        };
    }

    componentDidMount() {
        const element = document.getElementById("content-wrapper");
        element && element.scrollTo(0, 0);
    }
    UNSAFE_componentWillMount() {
        this.props.requestCompanySettings(true);
        this.props.requestAllSSRSettings(true);
        this.props.requestUserSettings(true);
        this.props.requestEmailMessages(true);
        this.props.requestClientMessages(true);
        this.props.requestHelptextsForApplication(true);
        this.setState({ showLoader: true, loaderText: "Loading..." });
    }
    UNSAFE_componentWillReceiveProps(nextProps: ClientInstructionGroupReturnsProps) {
        this.setState({
            selectedInitialWelcomeMessage:
                nextProps.companyData.ssrSettings && nextProps.companyData.ssrSettings.defaultGroupMessageSettings
                    ? nextProps.companyData.ssrSettings.defaultGroupMessageSettings.groupInitialEmail
                    : 0,
            selectedVoucherReminderMessage:
                nextProps.companyData.ssrSettings && nextProps.companyData.ssrSettings.defaultGroupMessageSettings
                    ? nextProps.companyData.ssrSettings.defaultGroupMessageSettings.groupVoucherReminders
                    : 0,
            selectedDownloadTaxDocumentMessage:
                nextProps.companyData.ssrSettings && nextProps.companyData.ssrSettings.defaultGroupMessageSettings
                    ? nextProps.companyData.ssrSettings.defaultGroupMessageSettings.groupDownloadTaxDocuments
                    : 0,
            selectedDownloadEsignMessage:
                nextProps.companyData.ssrSettings && nextProps.companyData.ssrSettings.defaultGroupMessageSettings
                    ? nextProps.companyData.ssrSettings.defaultGroupMessageSettings.groupDownloadSignedForms
                    : 0,
            selectedDownloadInstruction:
                nextProps.companyData.ssrSettings && nextProps.companyData.ssrSettings.defaultGroupMessageSettings
                    ? nextProps.companyData.ssrSettings.defaultGroupMessageSettings.groupDownloadInstruction
                    : 0,
            selectedPaymentInstruction:
                nextProps.companyData.ssrSettings && nextProps.companyData.ssrSettings.defaultGroupMessageSettings
                    ? nextProps.companyData.ssrSettings.defaultGroupMessageSettings.groupPaymentInstruction
                    : 0,
            selectedSigningReminder:
                nextProps.companyData.ssrSettings && nextProps.companyData.ssrSettings.defaultGroupMessageSettings
                    ? nextProps.companyData.ssrSettings.defaultGroupMessageSettings.groupSigningReminder
                    : 0,
            selectedVoucherRevisionMessage:
                nextProps.companyData.ssrSettings && nextProps.companyData.ssrSettings.defaultGroupMessageSettings
                    ? nextProps.companyData.ssrSettings.defaultGroupMessageSettings.groupVoucherRevision
                    : 0,
            defaultInitialWelcomeMessage:
                nextProps.companyData.ssrSettings && nextProps.companyData.ssrSettings.defaultGroupMessageSettings
                    ? nextProps.companyData.ssrSettings.defaultGroupMessageSettings.groupInitialEmail
                    : 0,
            defaultVoucherReminderMessage:
                nextProps.companyData.ssrSettings && nextProps.companyData.ssrSettings.defaultGroupMessageSettings
                    ? nextProps.companyData.ssrSettings.defaultGroupMessageSettings.groupVoucherReminders
                    : 0,
            defaultDownloadTaxDocumentMessage:
                nextProps.companyData.ssrSettings && nextProps.companyData.ssrSettings.defaultGroupMessageSettings
                    ? nextProps.companyData.ssrSettings.defaultGroupMessageSettings.groupDownloadTaxDocuments
                    : 0,
            defaultDownloadEsignMessage:
                nextProps.companyData.ssrSettings && nextProps.companyData.ssrSettings.defaultGroupMessageSettings
                    ? nextProps.companyData.ssrSettings.defaultGroupMessageSettings.groupDownloadSignedForms
                    : 0,
            defaultDownloadInstruction:
                nextProps.companyData.ssrSettings && nextProps.companyData.ssrSettings.defaultGroupMessageSettings
                    ? nextProps.companyData.ssrSettings.defaultGroupMessageSettings.groupDownloadInstruction
                    : 0,
            defaultPaymentInstruction:
                nextProps.companyData.ssrSettings && nextProps.companyData.ssrSettings.defaultGroupMessageSettings
                    ? nextProps.companyData.ssrSettings.defaultGroupMessageSettings.groupPaymentInstruction
                    : 0,
            defaultSigningReminder:
                nextProps.companyData.ssrSettings && nextProps.companyData.ssrSettings.defaultGroupMessageSettings
                    ? nextProps.companyData.ssrSettings.defaultGroupMessageSettings.groupSigningReminder
                    : 0,
            defaultVoucherRevisionMessage:
                nextProps.companyData.ssrSettings && nextProps.companyData.ssrSettings.defaultGroupMessageSettings
                    ? nextProps.companyData.ssrSettings.defaultGroupMessageSettings.groupVoucherRevision
                    : 0
        });
        this.setState(
            {
                showLoader:
                    !nextProps.clientMessageList.isLoading &&
                    !nextProps.emailMessageList.isLoading &&
                    !nextProps.companyData.isLoading
                        ? false
                        : true,
                showPopUpLoader:
                    !nextProps.clientMessageList.isLoading &&
                    !nextProps.emailMessageList.isLoading &&
                    !nextProps.companyData.isLoading
                        ? false
                        : true
            },
            () => {
                if (!this.state.showPopUpLoader)
                    this.setState({
                        showEmailMessagePopup: false,
                        showClientMessagePopup: false,
                        showWelcomeMessagePopUp: false
                    });
            }
        );
    }

    private onInitialMessageListItemClick = (messageId: number) => {
        this.setState({ selectedInitialWelcomeMessage: messageId });
    };
    private onEmailInstructionAddButtonClick = (
        defaultMessageId: number,
        selectedMessageId: number,
        typeId: number,
        resourceId?: string
    ) => {
        this.setState({
            defaultMessageId: defaultMessageId,
            selectedMessageId: selectedMessageId,
            showEmailMessagePopup: true,
            buttonText: "Add",
            isUpdateMessage: false,
            title: "Add Client Instruction",
            typeId: typeId,
            resourceId: resourceId
        });
    };
    private onEmailInstructionEditButtonClick = (
        defaultMessageId: number,
        selectedMessageId: number,
        typeId: number,
        resourceId?: string
    ) => {
        this.setState({
            defaultMessageId: defaultMessageId,
            selectedMessageId: selectedMessageId,
            showEmailMessagePopup: true,
            buttonText: "Save",
            isUpdateMessage: true,
            title: "Edit Client Instruction",
            typeId: typeId,
            resourceId: resourceId
        });
    };
    private deleteEmailMessage = (selectedMessageId: number, defaultMessageId: number, resourceId?: string) => {
        if (this.props.emailMessageList.data[selectedMessageId].emailMessage.id == defaultMessageId) {
            VenusNotifier.Warning(Constants.ClientInstructionConstants.DeleteInstructionWarning, "");
        } else {
            let _currentObject = this;
            bootbox.confirm({
                title: "Delete Instruction",
                message: "Are you sure, You want to delete this Instruction ?",
                buttons: {
                    cancel: {
                        label: '<i class="fas fa-times"></i> Cancel',
                        className: "btn-white btn-default"
                    },
                    confirm: {
                        label: '<i class="fas fa-check"></i> Delete',
                        className: "btn-info"
                    }
                },
                callback: (result: boolean) => {
                    if (result == true) {
                        _currentObject.props.deleteEmailMessageDetail(
                            _currentObject.props.emailMessageList.data[selectedMessageId].emailMessage,
                            resourceId
                        );
                        _currentObject.setState({ showLoader: true });
                    }
                }
            });
        }
    };

    private onCancelButtonClick = () => {
        this.setState({
            showClientMessagePopup: false,
            showEmailMessagePopup: false,
            showWelcomeMessagePopUp: false
        });
    };

    private saveEmailMessage = (message: IEmailTemplateMessage, isSetDefault: boolean, isToggled: boolean) => {
        let companyData: ISSRSettings = this.props.companyData.ssrSettings
            ? this.props.companyData.ssrSettings
            : initialSSRSettings;
        message.type = this.state.typeId;
        message.subject = decodeText(message.subject);
        message.name = decodeText(message.name);

        switch (this.state.typeId) {
            case clientInstructions.groupVoucherReminder:
                this.props.saveGroupEmailMessageDetail(
                    message,
                    companyData,
                    isSetDefault,
                    this.state.typeId,
                    undefined,
                    this.state.resourceId
                );
                break;
            case clientInstructions.groupSigningReminder:
                this.props.saveGroupEmailMessageDetail(
                    message,
                    companyData,
                    isSetDefault,
                    this.state.typeId,
                    undefined,
                    this.state.resourceId
                );
                break;
            default:
                this.props.saveGroupEmailMessageDetail(
                    message,
                    companyData,
                    isSetDefault,
                    this.state.typeId,
                    undefined,
                    this.state.resourceId
                );
        }

        this.setState({
            showPopUpLoader: true
        });
    };

    private editEmailMessage = (message: IEmailTemplateMessage, isSetDefault: boolean, isToggled: boolean) => {
        message.subject = decodeText(message.subject);
        message.name = decodeText(message.name);
        this.props.updateEmailMessageDetail(message, this.state.resourceId);
        if (isToggled && isSetDefault) {
            switch (this.state.typeId) {
                case clientInstructions.groupVoucherReminder:
                    this.saveDefaultMessage(message.id, undefined, this.state.resourceId);
                    break;
                case clientInstructions.groupSigningReminder:
                    this.saveDefaultMessage(message.id, undefined, this.state.resourceId);
                    break;
                default:
                    this.saveDefaultMessage(message.id, undefined, this.state.resourceId);
            }
        }
        this.setState({
            showPopUpLoader: true
        });
    };
    private saveDefaultMessage = (messageId: number, callback?: () => void, resourceId?: string) => {
        let companyData: any = new SSRSettings(
            undefined,
            this.props.companyData.ssrSettings
                ? this.props.companyData.ssrSettings.defaultGroupMessageSettings
                : initialSSRSettings.defaultGroupMessageSettings
        );
        if (this.state.typeId == clientInstructions.groupInitialEmail)
            companyData.defaultGroupMessageSettings.groupInitialEmail = messageId;
        else if (this.state.typeId == clientInstructions.groupSigningReminder)
            companyData.defaultGroupMessageSettings.groupSigningReminder = messageId;
        else if (this.state.typeId == clientInstructions.groupVoucherReminder)
            companyData.defaultGroupMessageSettings.groupVoucherReminders = messageId;
        else if (this.state.typeId == clientInstructions.groupDownloadTaxDocument)
            companyData.defaultGroupMessageSettings.groupDownloadTaxDocuments = messageId;
        else if (this.state.typeId == clientInstructions.groupDownloadEsignedFroms)
            companyData.defaultGroupMessageSettings.groupDownloadSignedForms = messageId;
        else if (this.state.typeId == clientInstructions.k1Distribution)
            companyData.defaultGroupMessageSettings.k1Distribution = messageId;
        else if (this.state.typeId == clientInstructions.groupDownloadInstruction)
            companyData.defaultGroupMessageSettings.groupDownloadInstruction = messageId;
        else if (this.state.typeId == clientInstructions.groupPaymentInstruction)
            companyData.defaultGroupMessageSettings.groupPaymentInstruction = messageId;
        else if (this.state.typeId == clientInstructions.ResendSignedGroupReturn)
            companyData.defaultGroupMessageSettings.groupResendSignedReturn = messageId;
        else if (this.state.typeId == clientInstructions.groupVoucherRevision)
            companyData.defaultGroupMessageSettings.groupVoucherRevision = messageId;

        this.props.updateSSRSettings(companyData, callback, resourceId);
    };

    private onSigningReminderListItemClick = (messageId: number) => {
        this.setState({ selectedSigningReminder: messageId });
    };

    private onVoucherReminderListItemClick = (messageId: number) => {
        this.setState({ selectedVoucherReminderMessage: messageId });
    };

    private onDownloadEsignListItemClick = (messageId: number) => {
        this.setState({ selectedDownloadEsignMessage: messageId });
    };

    private onDownloadInstructionListItemClick = (messageId: number) => {
        this.setState({ selectedDownloadInstruction: messageId });
    };

    private onVoucherRevisionListItemClick = (messageId: number) => {
        this.setState({ selectedVoucherRevisionMessage: messageId });
    };

    private onClientInstructionAddButtonClick = (
        defaultMessageId: number,
        selectedMessageId: number,
        typeId: number,
        resourceId?: string
    ) => {
        this.setState({
            defaultMessageId: defaultMessageId,
            selectedMessageId: selectedMessageId,
            buttonText: "Add",
            title: "Add Client Instruction",
            isUpdateMessage: false,
            showClientMessagePopup: true,
            typeId: typeId,
            resourceId: resourceId
        });
    };

    private onClientInstructionEditButtonClick = (
        defaultMessageId: number,
        selectedMessageId: number,
        typeId: number,
        resourceId?: string
    ) => {
        this.setState({
            defaultMessageId: defaultMessageId,
            selectedMessageId: selectedMessageId,
            buttonText: "Save",
            title: "Edit Client Instruction",
            isUpdateMessage: true,
            showClientMessagePopup: true,
            typeId: typeId,
            resourceId: resourceId
        });
    };

    private deleteClientMessage = (selectedMessageId: number, defaultMessageId: number, resourceId?: string) => {
        if (this.props.clientMessageList.data[selectedMessageId].clientMessage.id == defaultMessageId) {
            VenusNotifier.Warning(Constants.ClientInstructionConstants.DeleteInstructionWarning, "");
        } else {
            let _currentObject = this;
            bootbox.confirm({
                title: "Delete Instruction",
                message: "Are you sure, You want to delete this Instruction ?",
                buttons: {
                    cancel: {
                        label: '<i class="fas fa-times"></i> Cancel',
                        className: "btn-white btn-default"
                    },
                    confirm: {
                        label: '<i class="fas fa-check"></i> Delete',
                        className: "btn-info"
                    }
                },
                callback: (result: boolean) => {
                    if (result == true) {
                        _currentObject.props.deleteClientMessageDetail(
                            _currentObject.props.clientMessageList.data[selectedMessageId].clientMessage,
                            resourceId
                        );
                        _currentObject.setState({ showLoader: true });
                    }
                }
            });
        }
    };

    private saveClientMessage = (message: IClientMessage, isSetDefault: boolean, isToggled: boolean) => {
        let companyData: ISSRSettings = this.props.companyData.ssrSettings
            ? this.props.companyData.ssrSettings
            : initialSSRSettings;
        message.type = this.state.typeId;
        message.name = decodeText(message.name);
        this.props.saveGroupClientMessageDetail(message, companyData, isSetDefault, this.state.typeId, this.state.resourceId);
        this.setState({
            showPopUpLoader: true
        });
    };

    private editClientMessage = (message: IClientMessage, isSetDefault: boolean, isToggled: boolean) => {
        message.name = decodeText(message.name);
        this.props.updateClientMessageDetail(message, this.state.resourceId);
        if (isToggled && isSetDefault) {
            this.saveDefaultMessage(message.id, undefined, this.state.resourceId);
        }
        this.setState({
            showPopUpLoader: true
        });
    };

    private onPaymentInstructionListItemClick = (messageId: number) => {
        this.setState({ selectedPaymentInstruction: messageId });
    };

    public render() {
        return (
            <div className="font14" style={{ marginTop: "20px" }}>
                <EmailMessage
                    heading={"Initial Email"}
                    description={"Initial email sent to clients"}
                    emailMessages={Object.keys(this.props.emailMessageList.data)
                        .filter(
                            (k, i) =>
                                this.props.emailMessageList.data[parseInt(k)].emailMessage.type ==
                                clientInstructions.groupInitialEmail
                        )
                        .reduce((obj: any, key: any) => {
                            obj[key] = this.props.emailMessageList.data[key];
                            return obj;
                        }, {})}
                    defaultMessage={this.state.defaultInitialWelcomeMessage}
                    selectedMessage={this.state.selectedInitialWelcomeMessage}
                    onListItemClick={this.onInitialMessageListItemClick}
                    typeId={clientInstructions.groupInitialEmail}
                    onAddButtonClick={this.onEmailInstructionAddButtonClick}
                    onEditButtonClick={this.onEmailInstructionEditButtonClick}
                    onDeleteButtonClick={this.deleteEmailMessage}
                    showLoader={this.state.showLoader}
                    loaderText={this.state.loaderText}
                    parentResourceIdentifier={
                        GroupReturnSettingsResources.GRSettingsPrefix + GroupReturnSettingsResources.InitialEmail
                    }
                />
                <hr></hr>

                <EmailMessage
                    heading={"Review and Sign Reminder Email"}
                    description={"Signing reminder sent to client"}
                    emailMessages={Object.keys(this.props.emailMessageList.data)
                        .filter(
                            (k, i) =>
                                this.props.emailMessageList.data[parseInt(k)].emailMessage.type ==
                                clientInstructions.groupSigningReminder
                        )
                        .reduce((obj: any, key: any) => {
                            obj[key] = this.props.emailMessageList.data[key];
                            return obj;
                        }, {})}
                    defaultMessage={this.state.defaultSigningReminder}
                    selectedMessage={this.state.selectedSigningReminder}
                    onListItemClick={this.onSigningReminderListItemClick}
                    typeId={clientInstructions.groupSigningReminder}
                    onAddButtonClick={this.onEmailInstructionAddButtonClick}
                    onEditButtonClick={this.onEmailInstructionEditButtonClick}
                    onDeleteButtonClick={this.deleteEmailMessage}
                    showLoader={this.state.showLoader}
                    loaderText={this.state.loaderText}
                    parentResourceIdentifier={
                        GroupReturnSettingsResources.GRSettingsPrefix + GroupReturnSettingsResources.ReviewAndSign
                    }
                />
                <hr></hr>

                <EmailMessage
                    heading={"Voucher Reminders"}
                    description={"Payment and Estimated Payment voucher reminders sent to clients"}
                    emailMessages={Object.keys(this.props.emailMessageList.data)
                        .filter(
                            (k, i) =>
                                this.props.emailMessageList.data[parseInt(k)].emailMessage.type ==
                                clientInstructions.groupVoucherReminder
                        )
                        .reduce((obj: any, key: any) => {
                            obj[key] = this.props.emailMessageList.data[key];
                            return obj;
                        }, {})}
                    defaultMessage={this.state.defaultVoucherReminderMessage}
                    selectedMessage={this.state.selectedVoucherReminderMessage}
                    onListItemClick={this.onVoucherReminderListItemClick}
                    typeId={clientInstructions.groupVoucherReminder}
                    onAddButtonClick={this.onEmailInstructionAddButtonClick}
                    onEditButtonClick={this.onEmailInstructionEditButtonClick}
                    onDeleteButtonClick={this.deleteEmailMessage}
                    showLoader={this.state.showLoader}
                    loaderText={this.state.loaderText}
                    parentResourceIdentifier={
                        GroupReturnSettingsResources.GRSettingsPrefix + GroupReturnSettingsResources.VoucherReminders
                    }
                />
                <hr></hr>

                <EmailMessage
                    heading={"Download E-Signed Forms"}
                    description={"Once all parties have e-signed all documents"}
                    emailMessages={Object.keys(this.props.emailMessageList.data)
                        .filter(
                            (k, i) =>
                                this.props.emailMessageList.data[parseInt(k)].emailMessage.type ==
                                clientInstructions.groupDownloadEsignedFroms
                        )
                        .reduce((obj: any, key: any) => {
                            obj[key] = this.props.emailMessageList.data[key];
                            return obj;
                        }, {})}
                    defaultMessage={this.state.defaultDownloadEsignMessage}
                    selectedMessage={this.state.selectedDownloadEsignMessage}
                    onListItemClick={this.onDownloadEsignListItemClick}
                    typeId={clientInstructions.groupDownloadEsignedFroms}
                    onAddButtonClick={this.onEmailInstructionAddButtonClick}
                    onEditButtonClick={this.onEmailInstructionEditButtonClick}
                    onDeleteButtonClick={this.deleteEmailMessage}
                    showLoader={this.state.showLoader}
                    loaderText={this.state.loaderText}
                    parentResourceIdentifier={
                        GroupReturnSettingsResources.GRSettingsPrefix + GroupReturnSettingsResources.DownloadForms
                    }
                />
                <hr></hr>

                <ClientMessage
                    heading={"Download Instruction"}
                    description={"Header in download step client side"}
                    clientMessages={Object.keys(this.props.clientMessageList.data)
                        .filter(
                            (k, i) =>
                                this.props.clientMessageList.data[parseInt(k)].clientMessage.type ==
                                clientInstructions.groupDownloadInstruction
                        )
                        .reduce((obj: any, key: any) => {
                            obj[key] = this.props.clientMessageList.data[key];
                            return obj;
                        }, {})}
                    defaultMessage={this.state.defaultDownloadInstruction}
                    selectedMessage={this.state.selectedDownloadInstruction}
                    onListItemClick={this.onDownloadInstructionListItemClick}
                    typeId={clientInstructions.groupDownloadInstruction}
                    onAddButtonClick={this.onClientInstructionAddButtonClick}
                    onEditButtonClick={this.onClientInstructionEditButtonClick}
                    onDeleteButtonClick={this.deleteClientMessage}
                    showLoader={this.state.showLoader}
                    loaderText={this.state.loaderText}
                    parentResourceIdentifier={
                        GroupReturnSettingsResources.GRSettingsPrefix + GroupReturnSettingsResources.DownloadInstructions
                    }
                />
                <hr></hr>

                <ClientMessage
                    heading={"Payment Instruction"}
                    description={"Header in payment step client side"}
                    clientMessages={Object.keys(this.props.clientMessageList.data)
                        .filter(
                            (k, i) =>
                                this.props.clientMessageList.data[parseInt(k)].clientMessage.type ==
                                clientInstructions.groupPaymentInstruction
                        )
                        .reduce((obj: any, key: any) => {
                            obj[key] = this.props.clientMessageList.data[key];
                            return obj;
                        }, {})}
                    defaultMessage={this.state.defaultPaymentInstruction}
                    selectedMessage={this.state.selectedPaymentInstruction}
                    onListItemClick={this.onPaymentInstructionListItemClick}
                    typeId={clientInstructions.groupPaymentInstruction}
                    onAddButtonClick={this.onClientInstructionAddButtonClick}
                    onEditButtonClick={this.onClientInstructionEditButtonClick}
                    onDeleteButtonClick={this.deleteClientMessage}
                    showLoader={this.state.showLoader}
                    loaderText={this.state.loaderText}
                    parentResourceIdentifier={
                        GroupReturnSettingsResources.GRSettingsPrefix + GroupReturnSettingsResources.PaymentInstructions
                    }
                />
                <hr></hr>

                <EmailMessage
                    heading={"Voucher Revision Mail Notification"}
                    description={"Once new voucher or transmittal is added in grouped returns report"}
                    emailMessages={Object.keys(this.props.emailMessageList.data)
                        .filter(
                            (k, i) =>
                                this.props.emailMessageList.data[parseInt(k)].emailMessage.type ==
                                clientInstructions.groupVoucherRevision
                        )
                        .reduce((obj: any, key: any) => {
                            obj[key] = this.props.emailMessageList.data[key];
                            return obj;
                        }, {})}
                    defaultMessage={this.state.defaultVoucherRevisionMessage}
                    selectedMessage={this.state.selectedVoucherRevisionMessage}
                    onListItemClick={this.onVoucherRevisionListItemClick}
                    typeId={clientInstructions.groupVoucherRevision}
                    onAddButtonClick={this.onEmailInstructionAddButtonClick}
                    onEditButtonClick={this.onEmailInstructionEditButtonClick}
                    onDeleteButtonClick={this.deleteEmailMessage}
                    showLoader={this.state.showLoader}
                    loaderText={this.state.loaderText}
                    parentResourceIdentifier={
                        GroupReturnSettingsResources.GRSettingsPrefix + GroupReturnSettingsResources.VoucherRevisionNotification
                    }
                />
                <hr></hr>

                <EmailMessagePopUp
                    isGroupReturn={true}
                    showState={this.state.showEmailMessagePopup}
                    onHide={this.onCancelButtonClick}
                    submitButtonClick={this.state.isUpdateMessage ? this.editEmailMessage : this.saveEmailMessage}
                    buttonText={this.state.buttonText}
                    selectedMessageDetail={
                        this.state.isUpdateMessage
                            ? this.props.emailMessageList.data[this.state.selectedMessageId]
                                ? this.props.emailMessageList.data[this.state.selectedMessageId].emailMessage
                                : initialEmailMessage
                            : initialEmailMessage
                    }
                    title={this.state.title}
                    defaultMessageId={this.state.defaultMessageId}
                    isUpdate={this.state.isUpdateMessage}
                    loaderText={"Saving..."}
                    showLoader={this.state.showPopUpLoader}
                    emailMessages={Object.keys(this.props.emailMessageList.data)
                        .filter((k, i) => this.props.emailMessageList.data[parseInt(k)].emailMessage.type == this.state.typeId)
                        .reduce((obj: any, key: any) => {
                            obj[key] = this.props.emailMessageList.data[key];
                            return obj;
                        }, {})}
                />
                <ClientMessagePopUp
                    onHide={this.onCancelButtonClick}
                    showState={this.state.showClientMessagePopup}
                    submitButtonClick={this.state.isUpdateMessage ? this.editClientMessage : this.saveClientMessage}
                    buttonText={this.state.buttonText}
                    selectedMessageDetail={
                        this.state.isUpdateMessage
                            ? this.props.clientMessageList.data[this.state.selectedMessageId]
                                ? this.props.clientMessageList.data[this.state.selectedMessageId].clientMessage
                                : initialClientMessage
                            : initialClientMessage
                    }
                    title={this.state.title}
                    defaultMessageId={this.state.defaultMessageId}
                    isUpdate={this.state.isUpdateMessage}
                    loaderText={"Saving..."}
                    showLoader={this.state.showPopUpLoader}
                    clientMessages={Object.keys(this.props.clientMessageList.data)
                        .filter((k, i) => this.props.clientMessageList.data[parseInt(k)].clientMessage.type == this.state.typeId)
                        .reduce((obj: any, key: any) => {
                            obj[key] = this.props.clientMessageList.data[key];
                            return obj;
                        }, {})}
                />
            </div>
        );
    }
}
