import * as React from "react";
import * as bootbox from "bootbox";
import { Loader, LoadingOverlay } from "react-overlay-loader";
//Internal
import { VenusNotifier } from "../helper/VenusNotifier";
import * as Helper from "../helper/HelperFunctions";

import { ClientInfo } from "../common/ClientInfo";
import { ReportProblemModal } from "../common/ReportProblemModal";
import {
    ICompanySettings,
    TaxSoftwareType,
    initialCompanySettings,
    ISuiteTaxSoftwareSetting
} from "../../Core/ViewModels/Company/CompanySettingsViewModel";
import { Guid } from "../../Core/Utilities/Guid";
import {
    ITaxReturn,
    TaxCaddyLookupResult,
    IDocumentAccess,
    EngagementType,
    DocumentStatus,
    VoucherNo,
    ITaxingAuthority,
    ClientTypes,
    IVoucher,
    DocumentGroups,
    IOfficeLocation,
    IDocumentAccessSaveModel,
    engagementType,
    ITaxDocumentViewModel,
    IK1SSNDetails,
    PaperReturnDeliveryType,
    IFormBase,
    IEFile,
    ITaxDocumentAccessViewModel,
    SignatureControlTypes
} from "../common/TaxReturn";
import { getClientName, DaysRangeOptionsList, ISignerModel, TaxReturnScreen, isK1ShareHolder } from "../common/TaxReturn";
import {
    ArrangementList,
    DraftFilter,
    RenderTypeFilterOptions,
    TypeDropdownValues,
    StatusRadioButtonValues,
    RenderAllOptions,
    DraftStatus
} from "../../Core/Common/Common";
import {
    IUserBaseModel,
    ISSRUserModel,
    IUserModel,
    SSORevokeStatus,
    UserReviewStatus
} from "../../Core/ViewModels/User/UserViewModel";
import { AssignmentChangeStatusModal } from "../common/AssignmentChangeStatusModal";
import { UploadDocumentModal } from "../common/UploadDocumentModal";
import { UploadRecalledDocumentModal } from "../common/UploadDocument/UploadRecalledDocumentModal";
import { TaxReturnUploadData } from "../../Core/ViewModels/Common/UploadDocumentViewModel";
import { IUserProfileModel } from "../../components/navigation/profile/ProfileObjects";
import { ClientTrackingModal } from "../common/ClientTracking";
import { ProcessReturnModal, IProcessReturnProps } from "../common/ProcessReturnModal/ProcessReturnModal";
import { AssignmentsToolbar } from "./AssignmentsToolbar";
import { AssignmentTable, ColumnValues } from "./AssignmentTable";
import { GroupConfigurationModal } from "../GroupedReturns/GroupConfiguration";
import { AssignModal } from "../common/AssignModal";
import * as TaxDocumentStore from "../../store/common/TaxDocumentStore";
import * as UserSettingStore from "../../store/userManagement/UserSettingStore";
import * as UserSignatureStore from "../../store/common/UserSignatureStore";
import * as VoucherStore from "../../store/ProcessReturn/voucher/VoucherStore";
import { IAssignmentsState } from "../../store/assignments/AssignmentsState";
import { IColumnValues, ICustomColumn } from "../settings/GeneralSettings";
import * as PdfStore from "../../store/pdf/PdfStore";
import * as CompanyStore from "../../store/company/CompanyStore";
import * as MailingReturnAddressStore from "../../store/common/MailingReturnAddressStore";
import { IUserProfile } from "../navigation/profile/ProfileObjects";
import { AttachmentsTableModel } from "../../store/ProcessReturn/AttachmentsState";
import * as SavedMessageStore from "../../store/settings/SavedMessageStore";
import { ISubDocument } from "../../Core/Utilities/PdfDocumentFacade";
import {
    ProcessReturnConstants,
    AssignUserConstants,
    SetAccessConstants,
    ChangeStatusConstants,
    DeleteTaxReturnConstants,
    ReProcessReturnConstants,
    DeliveredReturnsConstants,
    ReportFilterConstants,
    GroupReturnConstants,
    SendGroupedReturnsHeaderConstants,
    FinishProcessReturn,
    ClientInfoValidation
} from "../helper/Constants";
import { EditGroupInfoModal } from "../GroupedReturns/EditGroupInfoModal";
import { IGroupedTaxDocumentDictionary } from "../../store/groupedreturns/GroupedTaxDocumentState";
import { HideLoader, ShowLoader } from "../helper/Loader";
import { CheckBoxComponent, CheckBoxSize } from "../common/CheckBoxComponent";
import { CustomSelectProps, TextFilter, SelectFilter } from "react-bootstrap-table";
import { AssignmentAction } from "./AssignmentAction";
import { IBusinessReturnsState } from "../../store/settings/BusinessReturnsStore";
import { CustomMultiSelect } from "../common/MultipleSelectComponent";
import { CustomEngagementTypeFilter } from "../common/CustomEngagementTypeFilter";
import * as AttachmentInstructionStore from "../../store/settings/AttachmentInstructionStore";
import { IFilters, SortDirections, ReportFilterType } from "../reports/Filters";
import * as FilterStore from "../../store/reports/FilterStore";
import * as AdditionalDocumentTypeStore from "../../store/common/AdditionalDocumentTypeStore";
import { IWatermarkModel } from "../../components/settings/GeneralSettingsComponents/WatermarkComponents/WatermarkSetting";
import { IGroupAccess } from "../GroupedReturns/GroupConfiguration";
import {
    IGroupInfo,
    IControllerInfo,
    IGroupedReturnsClientInfoModel,
    IGroupLevelReturnDictionary,
    initialGroupRetrunClientInfo,
    IGroupedReturnsModel
} from "../../store/groupedreturns/GroupedReturnProcessState";
import {
    EfileInfoView,
    IShareHolder,
    DocumentFilterType,
    ProcessReturnTabs
} from "../../components/common/ProcessReturnModal/ProcessReturnModels";
import { ReportProblemTypes, IReportProblemDetails, ReportedStep } from "../common/ReportProblem/ReportProblemModel";
import { BulkOperationQuery } from "../../Core/ViewModels/Common/BulkOperationQuery";
import * as PaperFileMessageStore from "../../store/settings/PaperFiledMessageStore";
import { logger } from "../../routes/LoggedIn";
import { SetAccessType } from "../../store/reports/GroupedReturns/SendGroupedReturnsState";
import { IBlobFile } from "../../store/common";
import { ISSRSettings } from "../../Core/ViewModels/Company/Settings/SSRSettingsViewModel";
import { SendTaxReturnResources } from "../helper/ResourceIdConstants";
import { IAuthState } from "../../store/auth/reducer";
import { DefaultDownloadInfoType, DefaultSenderInfoType } from "../navigation/profile/MySettings";
import { IDropdown } from "../../Core/Common/Dropdown";
import { ISelectedDocumentForDeliveryViewModel } from "../../store/common/TaxDocumentStore";
import {
    IAccessingUsersAndUserGroups,
    IUserDataFromDB,
    IUserGroupDataFromDB
} from "../common/SetAccessCommonModal/SetAccessCommonModal.model";
import { initialGroupInfo } from "../../store/groupedreturns/GroupedReturnProcessStore";
import SuiteModal from "../common/SuiteModal";
import SetAccessCommonModal from "../common/SetAccessCommonModal";
import { IDataToUpdateSMSNotificationDetails, ISMSNotificationDetails } from "../../store/common/SMSNotificationDetailsStore";
import { IClientManagementSetting } from "../../store/company/SuiteClientManagementSettingStore";
import { IK1SSNDictionary, IPageWiseK1SSNDictionary } from "../../store/ProcessReturn/K1SSNStore";
import SingleSelectSearchableDropdown from "../common/SingleSelectSearchableDropdown";
import { DeliverGroup } from "../common/DeliverGroupModal";
import { GroupReturnClientInfo } from "../common/GroupReturnClientInfo";
import { IOrderList } from "../common/Generic/ReorderList";
import { ControllerInfoProperty } from "../../store/groupedreturns/GroupedReturnProcessStore";
import { IHelptextModel } from "../../Core/ViewModels/Common/IHelptextModel";
import { IHelptextState } from "../../store/common/ApplicationHelptextStore";
import { DraftStatusIcon } from "../../assets/images/DraftStatus";
import { CustomStatusTypeFilter } from "../../components/common/CustomStatusTypeFilter";
import { ActionModal } from "../common/ActionModal";
import { ImportantModal } from "../common/ImportantModal";

export interface IAssignmentsProps {
    action: string;
    id: string;
    title: string;
    automationId: string;
    currentPage: IAssignmentsState;
    taxDocuments: TaxDocumentStore.ITaxDocumentDictionary;
    users: IUserModel[];
    ssrUsers: ISSRUserModel[];
    defaultERO: number;
    defaultGroup: string;
    company: CompanyStore.ICompanyData;
    pdfDocuments: PdfStore.IPdfDocumentDictionary;
    profile: IUserProfile;
    userDetails: IUserProfileModel;
    requestAssignments: (query: string, reload: boolean, callback?: () => void) => void;
    assignedUser: (selected: ITaxReturn[]) => number;
    requestTaxDocument: (
        id: number,
        force: boolean,
        requestTaxPayerView?: boolean,
        clientType?: ClientTypes,
        callback?: (data: any) => void,
        isEditClientInfoRequest?: boolean
    ) => void;
    updateTaxDocument: (taxDocument: ITaxReturn, callback?: () => void) => void;
    saveTaxDocument: (
        taxReturn: ITaxReturn,
        customColumn?: IColumnValues,
        callback?: (data?: any) => void,
        emailCallback?: (id: number, isMailSent: boolean) => void,
        isMailSent?: boolean,
        clientGUIDs?: string[],
        isEditDeliveredClientInfoRequest?: boolean,
        signerInfo?: ISignerModel,
        resourceId?: string
    ) => void;
    requestGroupedReturnDocumentsByGroupId: (groupId: number, callback?: (taxretuns: ITaxReturn[]) => void) => void;
    requestGroupedTaxDocument: (
        id: number,
        forceRefresh: boolean,
        requestTaxPayerView?: boolean,
        clientType?: ClientTypes,
        callback?: (data: any) => void,
        isEditClientInfoRequest?: boolean
    ) => void;
    saveGroupedProcessReturnInfo: (
        taxReturn: ITaxReturn,
        subDocuments: ISubDocument[],
        taxDocumentsForDelivery: ISelectedDocumentForDeliveryViewModel[],
        isK1Replaced: boolean,
        isK1Restored: boolean,
        isMFJChanged: boolean,
        apiEndPoint?: string,
        callback?: (data?: any) => void,
        clientType?: ClientTypes,
        groupInfo?: IGroupInfo,
        resourceId?: string,
        isShareHolderUpdated?: boolean,
        updatedShareHolders?: IShareHolder[],
        isReturnMarkedReadyForDelivery?: boolean,
        isMarkAsReadyForDeliveryChanged?: boolean
    ) => void;
    groupedTaxDocuments: ITaxReturn[];
    groupData: IGroupInfo;
    markAsReadyForDelivery(groupId: number, callback?: (id: number) => void, resourceId?: string): void;
    assignTaxDocument: (taxDocument: ITaxReturn) => void;
    deleteTaxDocument: (ids: number[], callback: () => void, resourceId?: string) => void;
    requestUsersList: (reload: boolean) => void;
    requestSSRUsersList: (reload: boolean) => void;
    requestSSRChangeStatusUsers: (locationId: number, taxReturnScreen: TaxReturnScreen, callback?: (response) => void) => void;
    getTaxDocumentsAccessMaps: (
        ids: number[],
        force: boolean,
        callback?: (result: IDocumentAccess) => void,
        resourceId?: string
    ) => void;
    getDocumentAccess: (
        accessMaps: ITaxDocumentViewModel[],
        force: boolean,
        callback?: (result: IDocumentAccess) => void,
        resourceId?: string,
        errorCallback?: (error: any) => void
    ) => void;
    setTaxDocumentsAccessMaps: (accessMaps: ITaxDocumentAccessViewModel, callback?: () => void, resourceId?: string) => void;
    requestTaxDocumentClientTracking: (id: number, resourceId?: string, successCallback?: () => void) => void;
    reportTaxDocumentProblem: (problemDetails: IReportProblemDetails, callback?: () => void, resourceId?: string) => void;
    reportDocumentGroupProblem: (
        problemDetails: IReportProblemDetails,
        groupId: number,
        callback?: () => void,
        resourceId?: string
    ) => void;
    onExportToExcel(onExportToComplete: () => void, bulkOpnQuery?: BulkOperationQuery, resourceId?: string): void;
    requestCompanySettings: (force: boolean) => void;
    requestPdfDocument: (taxReturn: ITaxReturn, reload: boolean) => void;
    requestUserProfile: () => void;
    requestTaxingAuthorities: () => void;
    sendToERO: (taxDocument: ITaxReturn) => void;
    sendForReview: (taxDocument: ITaxReturn) => void;
    approveForDelivery: (taxDocument: ITaxReturn) => void;
    deliverToClient: (taxDocument: ITaxReturn) => void;
    requestMailingReturnAddressList: () => void;
    mailingReturnAddressList: MailingReturnAddressStore.IMailingReturnAddressDictionary;
    requestAttachments: (
        taxReturn: ITaxReturn,
        fileName: string,
        file: any,
        processData: (data: AttachmentsTableModel, fileName: string, file: any) => void
    ) => void;
    downloadAttachments: (
        taxReturn: ITaxReturn,
        fileName: string,
        documentId: number,
        attachmentId: number,
        isArchivedReturn: boolean,
        callback: (url: string, fileName: string) => void
    ) => void;
    getK1InstructionFileName: (guid: string, taxYear: number, documentId: number, engagementType: EngagementType) => void;
    deleteK1Instruction: (guid: string, taxYear: number) => void;
    uploadK1Instruction: (
        engagementType: EngagementType,
        documentGuid: string,
        taxYear: number,
        fileName: string,
        documentId: number,
        callback?: () => void
    ) => void;
    userSettings: UserSettingStore.UserSettings;
    requestDelegatedSigners: (userId: number) => void;
    generateTaxPayerView: (taxDocument: ITaxReturn) => void;
    savedMessages: SavedMessageStore.ISavedMessageDictionary;
    getAllSavedMessages: (reload: boolean) => void;
    downloadK1DocumentLink: (
        documentGuid: string,
        type: string,
        taxYear: number,
        openPreviewCallBack: (url: string) => void
    ) => void;
    saveProcessReturnInfo: (
        taxReturn: ITaxReturn,
        subDocuments: ISubDocument[],
        isK1Replaced: boolean,
        isK1Restored: boolean,
        isMFJChanged: boolean,
        apiEndPoint?: string,
        callback?: (data?: any) => void,
        taxCaddyLookupResult?: TaxCaddyLookupResult,
        clientType?: ClientTypes,
        groupInfo?: IGroupInfo,
        resourceId?: string,
        isShareHolderUpdated?: boolean,
        updatedShareHolders?: IShareHolder[]
    ) => void;
    getVoucherUploadLink: (taxReturn: ITaxReturn, callback: (data: any) => void) => void;
    getMultiVoucherUploadLink: (
        taxReturn: ITaxReturn,
        cbVoucher: IVoucher,
        callback: (data: any, voucher: IVoucher) => void,
        isFinalVoucher?: boolean
    ) => void;
    downloadUserSignature: (userId: number, callback?: (data?: any) => void) => void;
    userSignatures: UserSignatureStore.IUserSignatures;
    getTaxCaddyLookupDetails: (
        taxpayerName: string,
        email: string,
        ssn: string,
        taxYear: number,
        taxClientId: string,
        docId: number
    ) => void;
    voucherStore: VoucherStore.IVoucherState;
    requestVoucherDueDates: (taxReturn: ITaxReturn, authorityId?: number, voucherNo?: VoucherNo) => void;
    requestPaymentUrls: (taxReturn: ITaxReturn) => void;
    requestVoucherFormNames: (taxReturn: ITaxReturn) => void;
    isK1Restored: boolean;
    restoreK1Instruction: (fileName: string, documentId: number) => void;
    processTaxReturn: (url: string, callback: () => void) => void;
    getUploadLink: (url: string, callback?: () => void, resourceId?: string) => void;
    getStorageAccountIdByFileId: (fileId: string, callback?: (data?: any) => void) => void;
    submitTaxReturn: (url: string, taxData: string, callback: () => void, resourceId?: string) => void;
    getAllTaxingAuthorities: ITaxingAuthority[];
    submitRecallTaxReturn: (
        docId: number,
        uploadData: TaxReturnUploadData[],
        onResponse: (response: any, error: any) => void
    ) => void;
    changeStatusTaxDocument: (taxDocument: ITaxReturn, callback?: () => void, resourceId?: string) => void;
    businessInstructionStore: IBusinessReturnsState;
    requestBusinessReturnInstructions: (reload: boolean) => void;
    resetTaxDocument: (id: number) => void;
    updateDocumentInitialStatus: (
        taxReturn: ITaxReturn,
        callback?: () => void,
        failureCallback?: () => void,
        resourceId?: string
    ) => void;
    statusChangeEvent: (taxReturn: ITaxReturn, isDocStatus: boolean, resourceId?: string) => void;
    previewAttachments: (
        taxReturn: ITaxReturn,
        fileName: string,
        documentId: number,
        attachmentId: number,
        isArchivedReturn: boolean,
        callback: (url: string, fileName: string) => void
    ) => void;
    requestDocumentStatus: (id: number, callback: () => void, isGroupedReturn: boolean) => void;
    reprocessTaxDocument: (taxDocument: ITaxReturn, callback: (id: number) => void, resourceId?: string) => void;
    checkDuplicateTaxReturn: (
        id: number,
        engagementType: EngagementType,
        taxyear: number,
        callback: (result: number) => void
    ) => void;
    saveTaxingAuthority: (
        taxingAuthority: ITaxingAuthority,
        callback: () => void,
        callback2?: () => void,
        resourceId?: string
    ) => void;
    requestTaxingAuthority: (value: boolean) => void;
    attachementInstructions: AttachmentInstructionStore.IAttachmentInstructionDictionary;
    getAttachmentInstructions: () => void;
    updateDocumentGroupOrder: (engagementType: EngagementType, order: DocumentGroups[]) => void;
    validateAssignTo: (
        documentIds: number[],
        assignedTo: number,
        callback?: (ids: number[], isValidAssign: boolean) => void,
        resourceId?: string
    ) => void;
    reportFilters: FilterStore.IFilterState;
    getAllReportFilter: (reload: boolean, filterType: ReportFilterType) => void;
    //addReportFilter: (name: string, filter: IFilters, callback?: () => void) => void;
    addReportFilter: (name: string, filter: IFilters, callback?: any) => void;
    updateReportFilter: (name: string, filter: IFilters, callback?: any) => void;
    deleteReportFilter: (name: string, filterType: ReportFilterType, callback?: any) => void;
    addDefaultFilter: (name: string, filterType: ReportFilterType, callback?: any) => void;
    removeDefaultFilter: (name: string, filterType: ReportFilterType, callback?: any) => void;
    getAddtionalEsignDocumentUploadLink: (url: string, callback?: (data?: IBlobFile) => void) => void;
    deleteUploadedAdditionalDocumentBlobFile: (
        documentGuid: string,
        fileName: string,
        taxYear: number,
        callback?: () => void
    ) => void;
    deleteAdditionalDocument: (documentId: number, fileName: string, callback?: () => void) => void;
    getAddtionalEsignDocumentDownLoadLink: (url: string, callback?: (data?: IBlobFile) => void) => void;
    convertDocToPdfArtifactAsync: (url: string, callback?: (data?: IBlobFile) => void) => void;
    requestAdditionalDocumentType: (reload: boolean) => void;
    additionalDocumentTypeData: AdditionalDocumentTypeStore.IDocumentTypeState;
    requestCompanyLogo: (reload?: boolean, isLogoUpdate?: boolean) => void;
    watermarkList: IWatermarkModel[];
    requestWatermarkList: () => void;

    groupInfo: IGroupInfo[];
    addGroup: (
        groupInfo: IGroupInfo,
        callback?: (groupId: number) => void,
        failurecallback?: () => void,
        disableSuccessNotification?: boolean,
        resourceId?: string
    ) => void;
    mapReturnstoGroup: (
        ids: number[],
        groupId: number,
        callback?: () => void,
        failurecallback?: () => void,
        includeGroupSavedNotificationMessage?: boolean,
        resourceId?: string
    ) => void;
    groupAccess: IGroupAccess[];
    unMapReturnsFromGroup: (
        ids: number[],
        callback?: () => void,
        callbackData?: IGroupInfo,
        failurecallback?: () => void,
        resourceId?: string
    ) => void;
    requestGroupInfo(groupId: number, callback?: (groupInfo: IGroupInfo) => void): void;
    updateGroup: (
        groupInfo: IGroupInfo,
        callback?: () => void,
        failurecallback?: () => void,
        disableSuccessNotification?: boolean,
        resourceId?: string
    ) => void;
    updateGroupInfoState: (reload: boolean) => void;
    checkAssignmentProperty?: (documentId: Number, currentAssign: boolean, rowIndex: any, callBack: any) => void;
    checkAssignmentPropertyAction?: (documentId: Number, currentAssign: boolean, callBack: any, rowIndex?: any) => void;
    customColumnData: ICustomColumn;
    loadSpinner?: boolean;
    getPaperFileUploadLink: (url: string, callback?: (data?: IBlobFile) => void) => void;
    getPaperFileDownLoadLink: (url: string, callback?: (data?: IBlobFile, retrycallback?: () => void) => void) => void;
    deleteUploadedPaperFileFromBlob: (documentGuid: string, fileName: string, taxYear: number, callback?: () => void) => void;
    paperFileMessage: PaperFileMessageStore.IPaperFiledMessageDictionary;
    getAllPaperFileMessages: (reload: boolean) => void;
    convertToPdfAsync: (url: string) => void;
    getk1AttachmentUploadLink: (url: string, callback?: (data?: IBlobFile) => void) => void;
    requestAllSSRSettings: (reload: boolean) => void;
    auth?: IAuthState;
    taxSoftwareSetting: ISuiteTaxSoftwareSetting;
    requestPartnerUser: (reload: boolean, callback?: () => void) => void;
    getOfficeLocationDropdown: (reload: boolean) => void;
    locationDropdown: IDropdown[];
    smsNotificationDetails: ISMSNotificationDetails;
    getSMSNotificationDetails: (
        documentId: number,
        isEditClientInfoRequest: boolean,
        callback: (smsNotificationDetails: ISMSNotificationDetails) => void
    ) => void;
    updateSMSNotificationDetails: (dataToUpdateSMSNotificationDetails: IDataToUpdateSMSNotificationDetails) => void;
    requestClientManagementSetting: () => void;
    clientManagementSetting: IClientManagementSetting;
    k1SSNDetails: IK1SSNDictionary;
    pageWiseSSNDetails: IPageWiseK1SSNDictionary;
    getK1SSNDetails: (documentId: number) => void;
    getK1SSNDetailsForPage: (
        documentId: number,
        ssn: string,
        pageNum: number,
        entityType: EntityType,
        callBack: (ssnPlacements: IK1SSNDetails[]) => void
    ) => void;
    resetK1SSNState: (documentId: number) => void;
    resetPageWiseK1SSNState: (documentId: number, pages: number[]) => void;
    allssrUsers: ISSRUserModel[];
    onPrintForPaperDelivery: (
        groupedReturnsModel: IGroupedReturnsModel,
        paperReturnDeliveryType: PaperReturnDeliveryType,
        orderList: DocumentGroups[],
        isNotifyUserEnabled: boolean,
        isSaveAsDefaultOrder: boolean,
        resourceId: string,
        callback?: () => void
    ) => void;
    groupedReturnsDeliverToController: (
        groupedReturnsModel: IGroupedReturnsModel,
        resourceId: string,
        callback?: () => void
    ) => void;
    updateControllerInfoData: (data: IControllerInfo) => void;
    groupedReturnProcessState: IGroupLevelReturnDictionary;
    saveGroupReturnClientInfoData: (
        groupedReturnsClientInfoModel: IGroupedReturnsClientInfoModel,
        successCallback: () => void,
        resourceId: string
    ) => void;
    requestApplicationHelptext: (reload: boolean) => void;
    helpTextState: IHelptextState;

    isDeliveryPermissionAssigned: boolean;
    getStorageAccountIdByDocumentGuid: (documentGuid: string, callback?: (data?: any) => void) => void;
}

export interface IPopupStateGroupInfo extends IPopupState {
    model: IGroupInfo | undefined;
}

export interface IPopupState {
    show: boolean;
}
export interface IPopupStateSingle extends IPopupState {
    model: ITaxReturn | undefined;
    saveCheckId: number | undefined;
}
export interface IPopupStateMultiple extends IPopupState {
    selected: ITaxReturn[] | undefined;
    saveCheckIds: number[] | undefined;
}

export interface IGroupedReturnClientInfoViewModel extends IPopupState {
    model: ITaxReturn | undefined;
    groupInfo: IGroupInfo | undefined;
    saveCheckId: number | undefined;
}

export interface IAssignmentsPageState {
    page: number;
    pageSize: number;
    searchString: string;
    sortName: string;
    sortOrder: string;
    filterName: string;
    filterClientID: string;
    filterPartner: string;
    filterLocation: number[];
    filterAssignTo: string;
    filterStatus: number[];
    filterEngagement: number;
    filterArrangement: number;
    filterDraftStatus: DraftFilter;
    filterUploadedOn?: Date;
    filterGroupType: number;
    description: string;
    taxPayerName: string;
    taxYear: number;
    ssrChangeStatusUsers: IUserModel[];
    groupedtaxReturns: ITaxReturn[];
    //General States
    selectedTaxYear: number;
    defaultAssignUser: number;
    //Grid States
    selectedRows: number[];
    //Popups
    assignReturnsState: IPopupStateMultiple;
    setAccessState: IPopupStateMultiple;
    deleteReturnsState: IPopupStateMultiple;
    changeStatusState: IPopupStateSingle;
    showLoader: boolean;
    editClientInfoState: IPopupStateSingle;
    editControllerInfoState: IGroupedReturnClientInfoViewModel;
    reportProblemState: IPopupStateSingle;
    groupReportProblemState: IPopupStateSingle;
    recallReturnState: IPopupStateSingle;
    clientTrackingState: IPopupStateSingle;
    groupConfigurationState: IPopupStateMultiple;
    deliverGroupState: IPopupStateMultiple;
    groupInfoState: IGroupInfo;
    editGroupInfoState: IPopupStateGroupInfo;
    //Upload
    showUpload: boolean;
    selectedTaxSoftware: TaxSoftwareType;
    processReturnWindows: IProcessReturnProps[];
    processReturnModalLoader: boolean;
    reProcessReturnModalLoader: boolean;

    filter: IFilters;
    saveFilterShow: boolean;

    refreshDelay?: boolean;
    isLoading: boolean;
    mergeGroupAddedAndMapReturnToGroupMessage: boolean;
    selectedDocumentGuid: string;
    officeLocationList: any[];
    loggedInUserLocations: number[];
    skipEroValidation: boolean;
    availableUsers: IUserDataFromDB[];
    selectedUsers: IUserDataFromDB[];
    availableUserGroups: IUserGroupDataFromDB[];
    selectedUserGroups: IUserGroupDataFromDB[];
    paperReturnDeliveryType: PaperReturnDeliveryType;
    orderList: DocumentGroups[];
    isNotifyUserEnabled: boolean;
    isSaveAsDefaultOrder: boolean;
    isGroupedReturnExists: boolean;
    renderStatusOptions: any[];
    actionModalPopup: IPopupStateSingle;
    importantModalPopup: IPopupStateSingle;
}
export interface INameValue {
    [index: string]: string;
}
export const successMsg: INameValue = {
    editClientInfoState: "Successfully updated client info for the tax-return",
    changeStatusState: "Status has been successfully changed for the Tax Returns",
    assignReturnsState: "Successfully assigned the document(s) to selected user",
    setAccessState: "Document Access set successfully for all returns",
    deleteReturnsState: "Successfully deleted the selected return(s)!",
    groupConfigurationState: "Successfully moved the selected return(s)!",
    deliverGroupState: "Successfully delivered the selected return(s)!"
};

export const errorMsg: INameValue = {
    editClientInfoState: "ClientInfo Edit failed! Error returned from server while updating the client info!",
    changeStatusState: "Failed to update the changed status!",
    assignReturnsState: "Failed to assign the document(s) to user!",
    setAccessState: "Setting document access for the users failed!",
    deleteReturnsState: "Failed to delete the selected document(s)!",
    groupConfigurationState: "Failed to move the selected return(s)!",
    deliverGroupState: "Failed to deliver the selected return(s)"
};

const pageSize: number = 20;
const NO_INDEX = -1;
let moment = require("moment");
let interval: any;

export class Assignments extends React.Component<IAssignmentsProps, IAssignmentsPageState> {
    private taxYearList: number[] = [];
    private customMultiSelect: any;
    private customEngagementTypeFilter: any;
    private customStatusFilter: any;
    private customOfficeSelect: any;
    private customGroupNameFilter: any;
    private proxyFilter: any;
    private reportFilterType: any;
    private setAccessResourceId: string;
    constructor(props: IAssignmentsProps) {
        super(props);
        this.state = {
            page: 1,
            pageSize: pageSize,
            searchString: "",
            sortName: "uploadedOn",
            sortOrder: "desc",
            filterName: "",
            filterClientID: "",
            filterPartner: "",
            filterLocation: [],
            filterAssignTo: "",
            filterStatus: [],
            filterEngagement: 0,
            filterArrangement: -1,
            filterDraftStatus: DraftFilter.All,
            description: "",
            taxPayerName: "",
            filterGroupType: -1,
            taxYear: 0,
            ssrChangeStatusUsers: [],
            groupedtaxReturns: [],
            selectedTaxYear: new Date().getFullYear() - 1,
            defaultAssignUser: 0,
            selectedRows: [],
            filter: {
                name: "",
                searchText: "",
                sort: {
                    column: "",
                    direction: SortDirections.None
                },
                fields: {},
                isDefaultFilter: false,
                filterType: ReportFilterType.MyAssignments,
                isMasterFilter: false
            },
            saveFilterShow: false,
            //Assign States
            assignReturnsState: {
                selected: undefined,
                show: false,
                saveCheckIds: undefined
            },
            //SetAccess States
            setAccessState: {
                selected: undefined,
                show: false,
                saveCheckIds: undefined
            },
            //Delete Action states
            deleteReturnsState: {
                selected: undefined,
                show: false,
                saveCheckIds: undefined
            },
            //ChangeStatus states
            changeStatusState: {
                model: undefined,
                show: false,
                saveCheckId: undefined
            },
            showLoader: false,
            //EditClientInfo states
            editClientInfoState: {
                model: undefined,
                show: false,
                saveCheckId: undefined
            },
            editControllerInfoState: {
                model: undefined,
                show: false,
                groupInfo: undefined,
                saveCheckId: undefined
            },
            //Report a Problem states
            reportProblemState: {
                model: undefined,
                show: false,
                saveCheckId: undefined
            },
            groupReportProblemState: {
                model: undefined,
                show: false,
                saveCheckId: undefined
            },
            //client Tracking states
            clientTrackingState: {
                model: undefined,
                show: false,
                saveCheckId: undefined
            },
            //Recall Return
            recallReturnState: {
                model: undefined,
                show: false,
                saveCheckId: undefined
            },
            //GroupReturn States
            groupConfigurationState: {
                selected: undefined,
                show: false,
                saveCheckIds: undefined
            },
            deliverGroupState: {
                selected: undefined,
                show: false,
                saveCheckIds: undefined
            },
            groupInfoState: Object.assign({}, initialGroupInfo),
            //GroupEdit States
            editGroupInfoState: {
                show: false,
                model: undefined
            },
            //Upload
            showUpload: false,
            selectedTaxSoftware: TaxSoftwareType.None,
            processReturnWindows: [],
            processReturnModalLoader: false,
            reProcessReturnModalLoader: false,
            isLoading: false,
            mergeGroupAddedAndMapReturnToGroupMessage: false,
            selectedDocumentGuid: "",
            officeLocationList: [],
            loggedInUserLocations: [],
            skipEroValidation: true,
            availableUsers: [],
            selectedUsers: [],
            availableUserGroups: [],
            selectedUserGroups: [],
            paperReturnDeliveryType: PaperReturnDeliveryType.MultipleFiles,
            orderList: [],
            isNotifyUserEnabled: false,
            isSaveAsDefaultOrder: false,
            isGroupedReturnExists: false,
            renderStatusOptions: RenderAllOptions,
            actionModalPopup: {
                model: undefined,
                show: false,
                saveCheckId: undefined
            },
            importantModalPopup: {
                model: undefined,
                show: false,
                saveCheckId: undefined
            }
        };

        this.onPageReload = this.onPageReload.bind(this);
        this.onAssignOpen = this.onAssignOpen.bind(this);
        this.onAssignSave = this.onAssignSave.bind(this);
        this.onAssignCancel = this.onAssignCancel.bind(this);
        this.requestTaxDocumentInfo = this.requestTaxDocumentInfo.bind(this);
        this.checkIfReturnIsNotAssignToLoggedinUser = this.checkIfReturnIsNotAssignToLoggedinUser.bind(this);
        this.checkIfReturnIsInUse = this.checkIfReturnIsInUse.bind(this);
        this.onDeleteTaxReturnOpen = this.onDeleteTaxReturnOpen.bind(this);
        this.onDeleteTaxReturnSave = this.onDeleteTaxReturnSave.bind(this);

        this.onSetAccessOpen = this.onSetAccessOpen.bind(this);
        this.onSetAccessSave = this.onSetAccessSave.bind(this);
        this.onSetAccessCancel = this.onSetAccessCancel.bind(this);

        this.onEditClientInfoOpen = this.onEditClientInfoOpen.bind(this);
        this.onEditClientInfoCancel = this.onEditClientInfoCancel.bind(this);
        this.onEditClientInfoSave = this.onEditClientInfoSave.bind(this);

        this.onUploadReturnsOpen = this.onUploadReturnsOpen.bind(this);
        this.onUploadReturnsCancel = this.onUploadReturnsCancel.bind(this);

        this.onChangeStatusOpen = this.onChangeStatusOpen.bind(this);
        this.onChangeStatusCancel = this.onChangeStatusCancel.bind(this);
        this.onChangeStatusSave = this.onChangeStatusSave.bind(this);

        this.onRecallReturnOpen = this.onRecallReturnOpen.bind(this);
        this.onRecallReturnCancel = this.onRecallReturnCancel.bind(this);
        this.onRecallUploadConfirmation = this.onRecallUploadConfirmation.bind(this);

        this.onClientTrackingOpen = this.onClientTrackingOpen.bind(this);
        this.onClientTrackingCancel = this.onClientTrackingCancel.bind(this);

        this.onReportProblemOpen = this.onReportProblemOpen.bind(this);
        this.onReportProblemCancel = this.onReportProblemCancel.bind(this);
        this.onReportProblemSave = this.onReportProblemSave.bind(this);

        this.onGroupConfigurationOpen = this.onGroupConfigurationOpen.bind(this);
        this.onGroupCreateNew = this.onGroupCreateNew.bind(this);
        this.onGroupConfigurationCancel = this.onGroupConfigurationCancel.bind(this);
        this.onGroupConfigurationSaveCompletion = this.onGroupConfigurationSaveCompletion.bind(this);
        this.mapReturntoGroup = this.mapReturntoGroup.bind(this);
        this.onAddtoExistingGroup = this.onAddtoExistingGroup.bind(this);
        this.UnMapGroupedReturns = this.UnMapGroupedReturns.bind(this);

        //Grid Actions
        this.onPageChange = this.onPageChange.bind(this);
        this.onSortChange = this.onSortChange.bind(this);
        this.onFilterChange = this.onFilterChange.bind(this);
        this.clearFiler = this.clearFiler.bind(this);
        this.onRowSelect = this.onRowSelect.bind(this);
        this.onSelectAll = this.onSelectAll.bind(this);
        //Support Methods
        this.buildQueryString = this.buildQueryString.bind(this);
        this.selectedDocumentIds = this.selectedDocumentIds.bind(this);
        this.setSelectedDocumentsGuid = this.setSelectedDocumentsGuid.bind(this);
        this.selectedDocuments = this.selectedDocuments.bind(this);
        this.lazyLoadSelected = this.lazyLoadSelected.bind(this);
        this.getUserModel = this.getUserModel.bind(this);
        this.getStoreItem = this.getStoreItem.bind(this);
        this.updateStates = this.updateStates.bind(this);
        this.onUpdateCompletion = this.onUpdateCompletion.bind(this);
        this.onUpdateCompletionMultiple = this.onUpdateCompletionMultiple.bind(this);
        this.checkForPopupCompletion = this.checkForPopupCompletion.bind(this);
        this.onDeleteCompletion = this.onDeleteCompletion.bind(this);

        //Filter Methods
        this.applyDefaultFilter = this.applyDefaultFilter.bind(this);
        this.onFilterSave = this.onFilterSave.bind(this);
        this.onFilterUpdate = this.onFilterUpdate.bind(this);
        this.onFilterDelete = this.onFilterDelete.bind(this);
        this.onSetDefaultFilter = this.onSetDefaultFilter.bind(this);
        this.onSetDefaultFilterSuccess = this.onSetDefaultFilterSuccess.bind(this);
        this.onRemoveDefaultFilter = this.onRemoveDefaultFilter.bind(this);
        this.onRemoveDefaultFilterSuccess = this.onRemoveDefaultFilterSuccess.bind(this);
        //this.onFilterChange = this.onFilterChange.bind(this);
        this.onSaveFilterShow = this.onSaveFilterShow.bind(this);
        this.onSaveFilterHide = this.onSaveFilterHide.bind(this);
        this.onFilterNameChange = this.onFilterNameChange.bind(this);
        this.onExportToExcel = this.onExportToExcel.bind(this);
        this.onPrintForPaperDelivery = this.onPrintForPaperDelivery.bind(this);
        this.deliverToController = this.deliverToController.bind(this);
        this.changeControllerInfo = this.changeControllerInfo.bind(this);
        this.onChangePaperDeliveryType = this.onChangePaperDeliveryType.bind(this);
    }

    UNSAFE_componentWillMount() {
        this.initData();
        // This method runs when the component is first added to the page
        this.props.requestUsersList(true);
        this.fetchAssignments();
        this.props.requestCompanySettings(true);
        this.props.requestAllSSRSettings(true);
        this.props.requestTaxingAuthorities();
        this.props.getAllSavedMessages(true);
        this.props.requestMailingReturnAddressList();
        this.props.requestBusinessReturnInstructions(true);
        this.props.getAttachmentInstructions();
        this.taxYearList = Helper.PrepareTaxYear().map((x) => x.value);
        this.props.getAllReportFilter(true, this.reportFilterType);
        this.props.getAllPaperFileMessages(true);
        this.props.requestSSRUsersList(true);
        Helper.fetchUserOfficeLocation(this.setOfficeLocation);
        this.props.getOfficeLocationDropdown(false);
        this.props.requestApplicationHelptext(true);
    }

    private updateStatusOptions = (statusOptions: any[]) => {
        this.setState({ renderStatusOptions: statusOptions });
    };

    private setOfficeLocation = (officeLocationList: IOfficeLocation[]) => {
        var list;
        let userLocations: number[] = [];
        if (officeLocationList?.length) {
            list = officeLocationList.map(({ locationName: label, locationId: value }) => ({ label, value }));
            officeLocationList.map((item) => {
                if (item.locationId) {
                    userLocations.push(item.locationId);
                }
            });
        } else {
            list = [];
        }
        var blank = { label: "Blanks", value: -100 };
        list.push(blank);
        this.setState({ officeLocationList: list, loggedInUserLocations: userLocations });
    };

    private setGroupNameList = (groupNameList: IGroupInfo[]) => {
        const list = groupNameList.map((x: IGroupInfo) => ({ label: x.name, value: x.id }));
        const options = [{ label: "Standard Returns", value: -2 }, { label: "Grouped Returns", value: -3 }, ...list];
        return options;
    };

    UNSAFE_componentWillReceiveProps(nextProps: IAssignmentsProps) {
        this.initData();
        this.updateStates(nextProps);
        this.checkForPopupCompletion(nextProps.taxDocuments);
    }
    initData = () => {
        this.proxyFilter =
            ReportFilterType[
                this.props.id == "company" ? ReportFilterType.AllAssignments : ReportFilterType.MyAssignments
            ].toUpperCase() + ReportFilterConstants.DefaultFilterBuilder;
        this.reportFilterType = this.props.id == "company" ? ReportFilterType.AllAssignments : ReportFilterType.MyAssignments;
    };

    createCustomCheckbox = (props: CustomSelectProps): any => {
        return (
            <CheckBoxComponent
                size={CheckBoxSize.sm}
                id={"assignment-checkbox-" + props.rowIndex}
                indeterminate={props.indeterminate}
                checked={props.checked}
                disabled={props.disabled}
                onChange={(e: any) => props.onChange(e, props.rowIndex)}
                ref={(input: any) => {
                    if (input) {
                        input.indeterminate = props.indeterminate;
                    }
                }}
                text={""}
            />
        );
    };

    private createActionCell = (cell: any, row: any) => {
        return (
            <AssignmentAction
                key={row.rowIndex}
                title={cell}
                taxReturn={this.props.currentPage.assignments[row.rowIndex]}
                rowIndex={row.rowIndex}
                onProcessReturnOpen={this.handleProcessReturnOpen}
                onEditClientInfoOpen={this.onEditClientInfoOpen}
                onSetAccessOpen={this.onSetAccessOpen}
                onChangeStatusOpen={this.onChangeStatusOpen}
                onReportProblemOpen={this.onReportProblemOpen}
                onDeleteTaxReturnOpen={this.onDeleteTaxReturnOpen}
                onRecallReturnOpen={this.onRecallReturnOpen}
                onClientTrackingOpen={this.onClientTrackingOpen}
                onReprocessReturnOpen={this.onReprocessReturnOpen}
                userSettings={this.props.userSettings}
                currentPageAssignment={this.props.currentPage}
                checkAssignmentProperty={this.props.checkAssignmentProperty}
                checkAssignmentPropertyAction={this.props.checkAssignmentPropertyAction}
                OnEditGroupInfoOpen={this.onEditGroupInfoOpen}
                parentResourceIdentifier={SendTaxReturnResources.GridButtonPrefix}
                onGroupedReportProblemOpen={this.onGroupedReportProblemOpen}
            />
        );
    };

    private showImportantModalPopup = (taxReturn: ITaxReturn) => {
        this.setState({
            importantModalPopup: {
                show: true,
                model: taxReturn ? taxReturn : undefined,
                saveCheckId: undefined
            }
        });
    };

    private onCancelImportantModalPopup = () => {
        this.setState({
            importantModalPopup: {
                show: false,
                model: undefined,
                saveCheckId: undefined
            },
            processReturnModalLoader: false
        });
    };

    onImportantSubmit = (taxReturn: ITaxReturn) => {
        this.setState({ processReturnModalLoader: true });
        this.requestTaxDocumentInfo(taxReturn.id, true, undefined, undefined, this.handleRequest, false, taxReturn.groupId > 0);
        if (isK1ShareHolder(taxReturn)) {
            this.props.getK1SSNDetails(taxReturn.id);
        }
        if (taxReturn.groupId > 0) {
            this.props.requestGroupedReturnDocumentsByGroupId(taxReturn.groupId, this.setGroupedReturnDocuments);
            this.props.requestGroupInfo(taxReturn.groupId);
        }
        this.setState({
            importantModalPopup: {
                show: false,
                model: undefined,
                saveCheckId: undefined
            }
        });
    };

    handleActionModalPopup = (taxReturn: ITaxReturn) => {
        this.setState({
            actionModalPopup: {
                show: true,
                model: taxReturn ? taxReturn : undefined,
                saveCheckId: undefined
            }
        });
    };

    private onCancelActionPopup = () => {
        this.setState({
            actionModalPopup: {
                show: false,
                model: undefined,
                saveCheckId: undefined
            },
            processReturnModalLoader: false
        });
    };

    onActionSubmit = (taxReturn: ITaxReturn) => {
        this.setState({ processReturnModalLoader: true });
        const resourceId = SendTaxReturnResources.GridButtonPrefix + SendTaxReturnResources.GridProcessButton;
        this.requestTaxDocumentInfo(
            taxReturn.id,
            true,
            undefined,
            undefined,
            (reloadedTaxReturn) => {
                if (reloadedTaxReturn.documentStatus == DocumentStatus.READY) {
                    reloadedTaxReturn.documentStatus = DocumentStatus.ERROR;
                    reloadedTaxReturn.recallReturn = taxReturn.recallReturn;
                    if (taxReturn.documentStatus !== reloadedTaxReturn.documentStatus) {
                        this.props.updateDocumentInitialStatus(reloadedTaxReturn, undefined, undefined, resourceId);
                        this.props.statusChangeEvent(reloadedTaxReturn, true, resourceId);
                        if (taxReturn.groupId > 0) {
                            this.props.requestGroupedReturnDocumentsByGroupId(taxReturn.groupId, this.setGroupedReturnDocuments);
                            this.props.requestGroupInfo(taxReturn.groupId);
                        }
                    }
                }
                this.setState({ processReturnModalLoader: false });
            },
            false,
            taxReturn.groupId > 0
        );
        this.setState({
            actionModalPopup: {
                show: false,
                model: undefined,
                saveCheckId: undefined
            }
        });
    };

    //--------------------------------Edit Group Name Start-------------------------------------
    private onEditGroupInfoOpen = (groupId: number) => {
        ShowLoader();
        this.props.requestGroupInfo(groupId, this.handleEditGroupInfoOpenRequest);
    };

    handleEditGroupInfoOpenRequest = (groupInfo: IGroupInfo) => {
        HideLoader();
        if (groupInfo) {
            this.setState({
                editGroupInfoState: {
                    show: true,
                    model: groupInfo
                }
            });
        }
    };

    private onEditGroupInfoCancel = () => {
        this.setState({
            editGroupInfoState: {
                show: false,
                model: undefined
            }
        });
    };

    onGroupInfoUpdate = (groupInfo: IGroupInfo, resetLoadingMessageState: () => void, failureCallback: () => void) => {
        logger.trackTrace(`Assignments --> onGroupInfoUpdate executes for groupId: ${groupInfo.id}`, {
            GroupId: groupInfo.id,
            GroupName: groupInfo.name
        });

        this.props.updateGroup(
            groupInfo,
            () => {
                resetLoadingMessageState();
                this.setState({
                    editGroupInfoState: {
                        show: false,
                        model: undefined
                    }
                });
                this.props.updateGroupInfoState(true);
                this.onPageReload();
            },
            () => {
                failureCallback();
            },
            undefined,
            SendTaxReturnResources.GridButtonPrefix + SendTaxReturnResources.EditGroupName
        );
    };
    //--------------------------------Edit Group Name End-------------------------------------

    private getMultiSelectDropDown = (filterHandler: any, customFilterParameters: any) => {
        const options = customFilterParameters.options;
        const placeholder = customFilterParameters.placeholder;
        return (
            <CustomMultiSelect
                onRef={(ref: any) => (this.customMultiSelect = ref)}
                filterHandler={filterHandler}
                options={options}
                placeholder={placeholder}
            />
        );
    };

    private getCustomEngagementTypeFilter = (filterHandler: any, customFilterParameters: any) => {
        const options = customFilterParameters.options;
        const placeholder = customFilterParameters.placeholder;
        return (
            <CustomEngagementTypeFilter
                onRef={(ref: any) => (this.customEngagementTypeFilter = ref)}
                filterHandler={filterHandler}
                options={options}
                placeholder={placeholder}
                selectedOptions={`${TypeDropdownValues.All}`}
            />
        );
    };

    private getCustomStatusTypeFilter = (filterHandler: any, customFilterParameters: any) => {
        const options = customFilterParameters.options;
        const placeholder = customFilterParameters.placeholder;
        const updateStatusOptions = customFilterParameters.updateStatusOptions;
        return (
            <CustomStatusTypeFilter
                onRef={(ref: any) => (this.customStatusFilter = ref)}
                filterHandler={filterHandler}
                options={options}
                placeholder={placeholder}
                selectedOptions={`${StatusRadioButtonValues.All}`}
                updateStatusOptions={updateStatusOptions}
                isInUseReturnsPage={false}
            />
        );
    };

    private getOfficeSelectDropDown = (filterHandler: any, customFilterParameters: any) => {
        const options = customFilterParameters.options;
        const placeholder = customFilterParameters.placeholder;
        return (
            <CustomMultiSelect
                onRef={(ref: any) => (this.customOfficeSelect = ref)}
                filterHandler={filterHandler}
                options={options}
                placeholder={placeholder}
                handleTextOverflow={true}
            />
        );
    };

    private getGroupNameDropdown = (filterHandler: any, customFilterParameters: any) => {
        const options = customFilterParameters.options;
        const placeholder = customFilterParameters.placeholder;

        return (
            <SingleSelectSearchableDropdown
                id="groupNameFilter"
                onRef={(ref: any) => (this.customGroupNameFilter = ref)}
                filterHandler={filterHandler}
                options={options}
                customPlaceHolder={placeholder}
                className={"groupNameFilter"}
            />
        );
    };

    //Filter Methods
    private applyDefaultFilter(filter: IFilters) {
        this.setState({ filter: filter }, () => this.fetchAssignmentsData(1, pageSize));
    }

    onFilterSave(onApplyFilter: (filter: IFilters) => void) {
        if (this.state.filter.name.trim() != "") {
            let filter = this.props.reportFilters.filters.find((x) => x.name == this.state.filter.name);
            if (filter) {
                VenusNotifier.Warning(DeliveredReturnsConstants.OtherMessage.FilterNameAlreadyExists, null);
            } else {
                this.props.addReportFilter(this.state.filter.name, this.state.filter, () => {
                    onApplyFilter(this.state.filter);
                    this.onSaveFilterHide();
                });
            }
        } else {
            VenusNotifier.Warning(DeliveredReturnsConstants.OtherMessage.PleaseEnterFilterName, null);
        }
    }
    onFilterUpdate(name: string) {
        let filter = this.props.reportFilters.filters.find((x) => x.name == name);
        if (!filter) {
            VenusNotifier.Warning(DeliveredReturnsConstants.OtherMessage.FilterDoesNotExists, null);
        } else {
            filter.fields = this.state.filter.fields;
            filter.name = name;
            filter.searchText = this.state.filter.searchText;
            filter.sort = this.state.filter.sort;
            filter.isDefaultFilter = this.state.filter.isDefaultFilter;
            filter.filterType = this.state.filter.filterType;

            this.props.updateReportFilter(name, filter);
            this.onSaveFilterHide();
        }
    }
    onFilterDelete(filterName: string, filterType: ReportFilterType) {
        this.props.deleteReportFilter(filterName, filterType);
    }
    onSetDefaultFilter(name: string, filterType: ReportFilterType) {
        this.props.addDefaultFilter(name, filterType, () => {
            this.onSetDefaultFilterSuccess();
        });
    }
    onSetDefaultFilterSuccess() {
        VenusNotifier.Success(DeliveredReturnsConstants.StatusMessage.SetDefaultFilterSuccess, null);
    }
    onRemoveDefaultFilter(name: string, filterType: ReportFilterType) {
        this.props.removeDefaultFilter(name, filterType, () => {
            this.onRemoveDefaultFilterSuccess();
        });
        let AssignmentTable: any = this.refs.AssignmentTable;
        AssignmentTable.refs.Filters?.setState({ activeFilter: "", appliedFilters: [] });
        this.onPageReload;
    }
    onRemoveDefaultFilterSuccess() {
        VenusNotifier.Success(DeliveredReturnsConstants.StatusMessage.RemoveDefaultFilterSuccess, null);
    }
    onSaveFilterShow() {
        this.setState({
            saveFilterShow: true
        });
    }
    onSaveFilterHide() {
        this.setState({
            saveFilterShow: false
        });
    }
    private onFilterNameChange(event: any) {
        let temp: IFilters = { ...this.state.filter };
        temp.name = event.target.value;
        this.setState({
            filter: temp
        });
    }

    private getDefaultFilter = (filters: IFilters[]): string | undefined => {
        let existsMasterFilter = filters.findIndex((x) => x.isMasterFilter) > -1 ? true : false;
        let existsDefaultFilter = filters.findIndex((x) => x.isDefaultFilter) > -1 ? true : false;

        if (existsMasterFilter) {
            let mName = filters.find((x) => x.isMasterFilter);
            return mName ? mName.name : undefined;
        } else if (existsDefaultFilter) {
            let dName = filters.find((x) => x.isDefaultFilter);
            return dName ? dName.name : undefined;
        }
        return undefined;
    };
    loadDeliveredReturns = () => {
        let queryString = this.buildQueryString(this.state.page, this.state.pageSize);
        this.props.requestAssignments(queryString, false);
    };
    onExportToExcel = (callback?: any, resourceId: string = "") => {
        this.props.onExportToExcel(callback, this.buildBulkOperationQuery(), resourceId);
    };

    clearSelectedRows = () => {
        this.setState({ selectedRows: [] });
    };

    public render() {
        if (!this.props.currentPage || !this.props.currentPage.assignments) {
            return (
                <div>
                    <h3>Loading...</h3>
                </div>
            );
        }
        let selected: number[] = [];
        if (this.state.selectedRows.length > 0) {
            for (var i in this.state.selectedRows) {
                let row = this.state.selectedRows[i];
                if (this.props.currentPage.assignments[row] !== undefined) {
                    selected.push(this.props.currentPage.assignments[row].id);
                }
            }
        }
        let unselectable: number[] = [];

        let data: ColumnValues[] = [];

        if (this.props.currentPage.assignments) {
            data = this.props.currentPage.assignments.map((model, index) => {
                if (Helper.isLocked(model)) {
                    unselectable.push(model.id);
                }

                let locationName = model.location?.locationName;
                if (model.locationId && !locationName) {
                    const index = this.props.locationDropdown.findIndex((x) => x.value == model.locationId);
                    if (index != NO_INDEX) {
                        locationName = this.props.locationDropdown[index].name;
                    }
                }

                return {
                    taxDocumentName: getClientName(model),
                    clientId: model.clientId,
                    partner:
                        model.partnerId == 0 || model.partnerId == null
                            ? ""
                            : model.partner.firstName + " " + model.partner.lastName,
                    locationName: locationName ? locationName : "",
                    assignToUserName:
                        model.assignedUser?.userId == 0 || model.assignedUser.firstName === undefined
                            ? ""
                            : model.assignedUser.firstName + " " + model.assignedUser.lastName,
                    documentStatus: Helper.GetDocumentStatus(DocumentStatus, model.documentStatus),
                    uploadedOn: moment(model.uploadedOn).format("MM/DD/YYYY"),
                    engagementType: engagementType(model.engagementType),
                    taxYear: model.taxYear,
                    assignTo: model.assignTo,
                    button: "Actions",
                    assignments: this.props.currentPage.assignments,
                    groupid: model.groupId,
                    groupName: model.groupName,
                    index: model.id,
                    rowIndex: index,
                    draftStatus: model.draftStatus
                };
            });
        }

        const DocumentStatusType = [
            { label: "UPLOADED", value: 1 },
            { label: "ERROR", value: 2 },
            { label: "READY", value: 3 },
            { label: "PROCESSING", value: 4 },
            { label: "REVIEW", value: 5 },
            { label: "APPROVED FOR DELIVERY", value: 8 },
            { label: "PREPARING FOR DELIVERY", value: 9 },
            { label: "DELIVERY FAILED", value: 10 },
            { label: "NON SUPPORTED", value: 11 },
            { label: "RECALLED", value: 12 },
            { label: "REPROCESSING", value: 14 },
            { label: "TAXCADDY DELIVERY FAILED", value: 16 },
            { label: "DUPLICATE", value: 17 },
            { label: "DOWNLOADING FAILED", value: 19 },
            { label: "PREPARING FOR PDF DOWNLOAD", value: 20 },
            { label: "TO BE REPROCESSED", value: 21 },
            { label: "READY FOR DELIVERY", value: 22 }
        ];

        const columns = [
            {
                header: "",
                key: "index",
                isKey: true,
                dataFormat: this.defaultType,
                columnClassName: "",
                dataSort: true,
                toolTip: false,
                isHidden: true,
                width: "auto",
                filter: { type: "TextFilter", placeholder: "index", style: { font: "bold" } } as TextFilter
            },
            {
                header: "Name",
                key: "taxDocumentName",
                isKey: false,
                dataFormat: this.defaultType,
                columnClassName: "",
                dataSort: true,
                toolTip: false,
                isHidden: false,
                width: "auto",
                filter: { type: "TextFilter", placeholder: "Name", style: { font: "bold" }, delay: 2500 } as TextFilter
            },
            {
                header: "Client ID",
                key: "clientId",
                isKey: false,
                dataFormat: this.clientIdColumn,
                columnClassName: "nameColumnClass",
                dataSort: true,
                toolTip: false,
                isHidden: false,
                width: "120px",
                filter: { type: "TextFilter", placeholder: "Client ID", style: { font: "bold" }, delay: 2500 } as TextFilter
            },
            {
                header: "Group Name",
                key: "groupName",
                isKey: false,
                dataFormat: this.defaultType,
                columnClassName: "",
                dataSort: true,
                toolTip: false,
                isHidden: false,
                width: "auto",
                filter: {
                    type: "CustomFilter",
                    getElement: this.getGroupNameDropdown,
                    customFilterParameters: {
                        options: this.setGroupNameList(this.props.groupInfo),
                        enableAllFilter: false,
                        placeholder: "Group Name"
                    }
                }
            },
            {
                header: "ERO / Signer",
                key: "partner",
                isKey: false,
                dataFormat: this.defaultType,
                columnClassName: "",
                dataSort: true,
                toolTip: false,
                isHidden: false,
                width: "auto",
                filter: { type: "TextFilter", placeholder: "ERO / Signer", style: { font: "bold" }, delay: 2500 } as TextFilter
            },
            {
                header: "Office Location",
                key: "locationName",
                isKey: false,
                dataFormat: this.defaultType,
                columnClassName: "",
                dataSort: true,
                toolTip: false,
                isHidden: false,
                width: "130px",
                filter: {
                    type: "CustomFilter",
                    getElement: this.getOfficeSelectDropDown,
                    customFilterParameters: {
                        options: this.state.officeLocationList,
                        enableAllFilter: false,
                        placeholder: "Select Office Location..."
                    }
                }
            },
            {
                header: "Assigned To",
                key: "assignToUserName",
                isKey: false,
                dataFormat: this.defaultType,
                columnClassName: "",
                dataSort: true,
                toolTip: false,
                isHidden: this.props.id == "company" ? false : true,
                width: "auto",
                filter: { type: "TextFilter", placeholder: "Assigned To", style: { font: "bold" }, delay: 2500 } as TextFilter
            },
            {
                header: "Status",
                key: "documentStatus",
                isKey: false,
                dataFormat: this.defaultType,
                columnClassName: "",
                dataSort: true,
                toolTip: false,
                isHidden: false,
                width: "100px",
                filter: {
                    type: "CustomFilter",
                    getElement: this.getCustomStatusTypeFilter,
                    customFilterParameters: {
                        options: this.state.renderStatusOptions,
                        enableAllFilter: false,
                        placeholder: "Select Status...",
                        updateStatusOptions: this.updateStatusOptions
                    }
                }
            },
            {
                header: "Date",
                key: "uploadedOn",
                isKey: false,
                dataFormat: this.defaultType,
                columnClassName: "",
                dataSort: true,
                toolTip: false,
                isHidden: false,
                width: "auto",
                filter: {
                    type: "SelectFilter",
                    placeholder: "Select Uploaded Date Limit",
                    options: DaysRangeOptionsList
                } as SelectFilter
            },
            {
                header: "Type",
                key: "engagementType",
                isKey: false,
                dataFormat: this.defaultType,
                columnClassName: "",
                dataSort: true,
                toolTip: false,
                isHidden: false,
                width: "80px",
                filter: {
                    type: "CustomFilter",
                    getElement: this.getCustomEngagementTypeFilter,
                    customFilterParameters: {
                        options: RenderTypeFilterOptions,
                        enableAllFilter: false,
                        placeholder: "Select Type..."
                    }
                }
            },
            {
                header: "Tax Year",
                key: "taxYear",
                isKey: false,
                dataFormat: this.defaultType,
                columnClassName: "",
                dataSort: true,
                toolTip: true,
                isHidden: false,
                width: "auto",
                filter: { type: "SelectFilter", placeholder: "Select Tax Year...", options: Object.assign({}, this.taxYearList) }
            },
            //{
            //    header: 'Year',
            //    key: 'taxYear', // String-based value accessors!
            //    isKey: false,
            //    dataFormat: this.defaultType,
            //    columnClassName: '',
            //    dataSort: true,
            //    toolTip: true,
            //    isHidden: false
            //},
            {
                header: "Action",
                key: "button", // String-based value accessors!
                isKey: false,
                dataFormat: this.createActionCell,
                columnClassName: "button-cell",
                dataSort: false,
                toolTip: false,
                isHidden: false,
                width: "130px",
                filter: { type: "TextFilter", placeholder: "", style: { display: "none" } } as TextFilter
            }
        ];
        const pagination = true;
        const selectRowProp = {
            mode: "checkbox",
            clickToSelect: true,
            onSelect: this.onRowSelect,
            onSelectAll: this.onSelectAll,
            unselectable: unselectable,
            selected: selected,
            customComponent: this.createCustomCheckbox,
            className: "row-selected"
        };
        const searchEnable = true;
        let selectedDocuments = this.selectedDocuments();
        return (
            <div>
                <AssignmentsToolbar
                    key={this.props.id + "-toolbar"}
                    pageTitle={this.props.title}
                    taxYear={this.state.selectedTaxYear}
                    selectedRows={this.state.selectedRows}
                    onAssignOpen={this.onAssignOpen}
                    onDeleteReturnsOpen={this.onDeleteTaxReturnOpen}
                    onSetAccessOpen={this.onSetAccessOpen}
                    onDeliverGroupOpen={this.onDeliverGroupOpen}
                    onUploadReturnsOpen={this.onUploadReturnsOpen}
                    selectedDocuments={selectedDocuments}
                    companySettings={
                        this.props.company.companySettings ? this.props.company.companySettings : initialCompanySettings
                    }
                    userDefaultSettings={this.props.userSettings.settings}
                    onGroupConfigurationOpen={this.onGroupConfigurationOpen}
                    parentResourceIdentifier={SendTaxReturnResources.HeaderButtonPrefix}
                    taxSoftwareSetting={this.props.taxSoftwareSetting}
                />
                <br />
                <LoadingOverlay style={{ height: "100%" }}>
                    <AssignmentTable
                        ref="AssignmentTable"
                        key={this.props.id + "-table"}
                        data={data}
                        column={columns}
                        pagination={pagination}
                        selectRow={selectRowProp}
                        search={searchEnable}
                        onPageChange={this.onPageChange}
                        onSortChange={this.onSortChange}
                        pageNo={this.state.page}
                        totalRows={this.props.currentPage.totalRowCount}
                        onFilterChange={this.onFilterChange}
                        tableAutomationId={this.props.automationId}
                        pageSize={this.state.pageSize}
                        isLoading={this.props.currentPage.isLoading}
                        models={this.props.currentPage.assignments}
                        onProcessReturnOpen={this.handleProcessReturnOpen}
                        onEditClientInfoOpen={this.onEditClientInfoOpen}
                        onSetAccessOpen={this.onSetAccessOpen}
                        onChangeStatusOpen={this.onChangeStatusOpen}
                        onReportProblemOpen={this.onReportProblemOpen}
                        onDeleteTaxReturnOpen={this.onDeleteTaxReturnOpen}
                        onRecallReturnOpen={this.onRecallReturnOpen}
                        onClientTrackingOpen={this.onClientTrackingOpen}
                        onRowDoubleClick={this.handleRowDoubleClick}
                        onFilterNameChange={this.onFilterNameChange}
                        onFilterSave={this.onFilterSave}
                        onFilterUpdate={this.onFilterUpdate}
                        onFilterDelete={this.onFilterDelete}
                        filterList={this.props.reportFilters.filters}
                        currentFilter={this.state.filter}
                        onSetDefaultFilter={this.onSetDefaultFilter}
                        onRemoveDefaultFilter={this.onRemoveDefaultFilter}
                        saveFilterShow={this.state.saveFilterShow}
                        onSaveFilterHide={this.onSaveFilterHide}
                        onSaveFilterShow={this.onSaveFilterShow}
                        defaultFilter={this.getDefaultFilter(this.props.reportFilters.filters)}
                        proxyFilter={this.proxyFilter}
                        filterType={this.reportFilterType}
                        onExportToExcel={this.onExportToExcel}
                        loadGrid={this.loadDeliveredReturns}
                        customMultiSelect={this.customMultiSelect}
                        customEngagementTypeFilter={this.customEngagementTypeFilter}
                        customStatusFilter={this.customStatusFilter}
                        customOfficeSelect={this.customOfficeSelect}
                        customGroupNameFilter={this.customGroupNameFilter}
                        id={this.props.id}
                        onPageReload={this.onPageReload}
                        refreshDelay={this.state.refreshDelay}
                        loadSpinner={this.props.loadSpinner}
                        profile={this.props.profile}
                        checkAssignmentProperty={this.props.checkAssignmentProperty}
                    />
                    <Loader
                        loading={this.state.processReturnModalLoader}
                        text={ProcessReturnConstants.ProcessReturnOpenMessage}
                    />
                    <Loader
                        loading={this.state.reProcessReturnModalLoader}
                        text={ReProcessReturnConstants.ReProcessInitiatedModalLoader}
                    />
                </LoadingOverlay>
                <input type="hidden" id="selectedDocumentsGuid" value={this.state.selectedDocumentGuid} />
                <ClientInfo
                    show={this.state.editClientInfoState.show}
                    model={this.state.editClientInfoState.model as ITaxReturn}
                    onCancel={this.onEditClientInfoCancel}
                    onSave={this.onEditClientInfoSave}
                    updateTaxDocument={this.props.updateTaxDocument}
                    isDeliveredReturn={false}
                    states={this.props.company.companyProfile.countryStates}
                    isGroupedReturn={false}
                    skipEroValidation={this.state.skipEroValidation}
                />
                <ReportProblemModal
                    show={this.state.reportProblemState.show}
                    onCancel={this.onReportProblemCancel}
                    onReportProblemSave={this.onReportProblemSave}
                    model={this.state.reportProblemState.model as ITaxReturn}
                    loggedInCPA={this.props.profile}
                    companyName={this.props.company.companyProfile.companyInfo.companyName}
                    reportProblemType={ReportProblemTypes.ReturnSpecific}
                    problemStep={ReportedStep.Assignments}
                />

                <ReportProblemModal
                    show={this.state.groupReportProblemState.show}
                    onCancel={this.onGroupedReportProblemCancel}
                    onReportProblemSave={this.onGroupedReportProblemSave}
                    model={this.state.groupReportProblemState.model as ITaxReturn}
                    loggedInCPA={this.props.profile}
                    companyName={this.props.company.companyProfile.companyInfo.companyName}
                    reportProblemType={ReportProblemTypes.Generic}
                    problemStep={ReportedStep.Report}
                />

                <AssignModal
                    show={this.state.assignReturnsState.show}
                    count={this.state.selectedRows.length}
                    ssrUsers={this.props.ssrUsers}
                    defaultAssignUser={this.state.defaultAssignUser}
                    onCancel={this.onAssignCancel}
                    onAssign={this.onAssignSave}
                />

                <GroupReturnClientInfo
                    show={this.state.editControllerInfoState.show}
                    model={this.state.editControllerInfoState.model as ITaxReturn}
                    groupInfo={this.state.editControllerInfoState.groupInfo as IGroupInfo}
                    onCancel={this.onGroupReturnClientInfoCancel}
                    onEditInfoSave={this.onGroupReturnClientInfoSave}
                    updateTaxDocument={this.props.updateTaxDocument}
                    isDeliveredReturn={false}
                    states={this.props.company.companyProfile.countryStates}
                    updateControllerInfoData={this.props.updateControllerInfoData}
                    skipEroValidation={this.state.skipEroValidation}
                    isGroupedReturn={true}
                />

                {this.state.setAccessState.show && (
                    <SuiteModal width="787" theme="light" title="Set Access" hide={this.onSetAccessCancel}>
                        <SetAccessCommonModal
                            onCancel={this.onSetAccessCancel}
                            onApplyAccess={this.onSetAccessSave}
                            returnAccessSettings={this.props.company.companySettings.returnAccessSettings}
                            availableUsers={this.state.availableUsers}
                            selectedUsers={this.state.selectedUsers}
                            availableUserGroups={this.state.availableUserGroups}
                            selectedUserGroups={this.state.selectedUserGroups}
                            isDragAndDropDisabled={false}
                            isGroupedReturnExists={this.state.isGroupedReturnExists}
                        />
                    </SuiteModal>
                )}

                <AssignmentChangeStatusModal
                    model={this.state.changeStatusState.model as ITaxReturn}
                    show={this.state.changeStatusState.show}
                    ssrUsers={this.props.ssrUsers}
                    selectedUser={this.state.defaultAssignUser}
                    onCancel={this.onChangeStatusCancel}
                    onChangeStatusSave={this.onChangeStatusSave}
                    showLoader={this.state.showLoader}
                    ssrChangeStatusUsers={this.state.ssrChangeStatusUsers}
                />
                <UploadDocumentModal
                    onShow={this.state.showUpload}
                    onHide={this.onUploadReturnsCancel}
                    taxSoftware={this.state.selectedTaxSoftware}
                    selectedERO={this.props.defaultERO}
                    getUploadLink={this.props.getUploadLink}
                    getStorageAccountIdByFileId={this.props.getStorageAccountIdByFileId}
                    processTaxReturn={this.props.processTaxReturn}
                    submitTaxReturn={this.props.submitTaxReturn}
                    onPageReload={this.onPageReload}
                    selectedGroup={this.props.defaultGroup}
                    groupInfo={this.props.groupInfo}
                    groupAccess={this.props.groupAccess}
                    updateGroupInfoState={this.props.updateGroupInfoState}
                    userProfile={this.props.profile}
                    returnAccessSettings={this.props.company.companySettings.returnAccessSettings}
                    parentResourceIdentifier={SendTaxReturnResources.Prefix}
                    ssrUsers={this.props.ssrUsers}
                    clientManagementSetting={this.props.clientManagementSetting}
                    requestClientManagementSetting={this.props.requestClientManagementSetting}
                />
                <GroupConfigurationModal
                    showGroupConfiguration={this.state.groupConfigurationState.show}
                    onCancel={this.onGroupConfigurationCancel}
                    selectedAssignments={this.state.groupConfigurationState.selected as ITaxReturn[]}
                    count={this.state.groupConfigurationState.selected ? this.state.groupConfigurationState.selected.length : 1}
                    groupInfo={this.props.groupInfo}
                    onCreateNew={this.onGroupCreateNew}
                    onAddtoExisting={this.onAddtoExistingGroup}
                    UnMapGroupedReturns={this.UnMapGroupedReturns}
                    hideUnGroupReturn={false}
                    disableUnGroupReturn={this.isDisableUnGroupReturn()}
                />
                {this.state.deliverGroupState.show && (
                    <DeliverGroup
                        groupedTaxDocuments={this.state.deliverGroupState.selected as ITaxReturn[]}
                        companySettings={
                            this.props.company.companySettings ? this.props.company.companySettings : initialCompanySettings
                        }
                        deliverToController={this.deliverToController}
                        onPrintForPaperDelivery={this.onPrintForPaperDelivery}
                        updateDocumentGroupOrder={this.props.updateDocumentGroupOrder}
                        handleSaveAsDefaultOrderChange={this.handleSaveAsDefaultOrderChange}
                        isDefaultOrder={this.state.isSaveAsDefaultOrder}
                        groupInfo={this.state.groupInfoState}
                        changeControllerInfo={this.changeControllerInfo}
                        onChangePaperDeliveryType={this.onChangePaperDeliveryType}
                        onReorderList={this.reorderList}
                        handleNotifyUserChange={this.handleNotifyUserChange}
                        isNotifyUser={this.state.isNotifyUserEnabled}
                        showDeliverGroup={this.state.deliverGroupState.show}
                        onCancel={this.onDeliverGroupCancel}
                    />
                )}

                <EditGroupInfoModal
                    groupInfoList={this.props.groupInfo}
                    onCancel={this.onEditGroupInfoCancel}
                    onGroupInfoChange={this.onGroupInfoUpdate}
                    showEditGroupInfo={this.state.editGroupInfoState.show}
                    groupInfo={this.state.editGroupInfoState.model as IGroupInfo}
                />

                {this.state.recallReturnState.model && (
                    <UploadRecalledDocumentModal
                        taxSoftwareType={this.state.recallReturnState.model.taxSoftware}
                        documentKey={this.state.recallReturnState.model ? this.state.recallReturnState.model.documentGuid : ""}
                        onShow={this.state.recallReturnState.show}
                        onHide={this.onRecallReturnCancel}
                        selectedERO={this.props.defaultERO}
                        getUploadLink={this.props.getUploadLink}
                        processTaxReturn={this.props.processTaxReturn}
                        submitTaxReturn={this.props.submitTaxReturn}
                        uploadConfirmation={this.onRecallUploadConfirmation}
                        isRecalled={true}
                        parentResourceIdentifier={SendTaxReturnResources.Prefix}
                        ssrUsers={this.props.ssrUsers}
                        returnAccessSettings={this.props.company.companySettings.returnAccessSettings}
                        isGroupedReturn={false}
                        clientManagementSetting={this.props.clientManagementSetting}
                        requestClientManagementSetting={this.props.requestClientManagementSetting}
                        getStorageAccountIdByDocumentGuid={this.props.getStorageAccountIdByDocumentGuid}
                    />
                )}
                <ClientTrackingModal
                    show={this.state.clientTrackingState.show}
                    onCancel={this.onClientTrackingCancel}
                    taxReturn={this.state.clientTrackingState.model as ITaxReturn}
                />
                <ActionModal
                    showActionPopup={this.state.actionModalPopup.show}
                    taxReturn={this.state.actionModalPopup.model}
                    onCancel={this.onCancelActionPopup}
                    onSubmit={this.onActionSubmit}
                />
                <ImportantModal
                    showImportantPopup={this.state.importantModalPopup.show}
                    taxReturn={this.state.importantModalPopup.model}
                    onCancel={this.onCancelImportantModalPopup}
                    onSubmit={this.onImportantSubmit}
                />
                <div>
                    {this.state.processReturnWindows.map((item: IProcessReturnProps, index: number) => (
                        <ProcessReturnModal
                            {...item}
                            key={"process-return-model" + item.key}
                            auth={this.props.auth}
                            taxDocuments={this.props.taxDocuments}
                            pdfDocuments={this.props.pdfDocuments}
                            users={this.props.ssrUsers}
                            companySettings={this.props.company.companySettings as ICompanySettings}
                            authorities={this.props.company.taxingAuthorities}
                            authorityLookup={this.props.company.authorityLookup}
                            userBasicProfile={this.props.profile}
                            requestMailingReturnAddressList={this.props.requestMailingReturnAddressList}
                            mailingReturnAddressList={this.props.mailingReturnAddressList}
                            getK1InstructionFileName={this.props.getK1InstructionFileName}
                            deleteK1Instruction={this.props.deleteK1Instruction}
                            uploadK1Instruction={this.props.uploadK1Instruction}
                            userSettings={this.props.userSettings}
                            requestDelegatedSigners={this.props.requestDelegatedSigners}
                            company={this.props.company}
                            generateTaxPayerView={this.props.generateTaxPayerView}
                            savedMessages={this.props.savedMessages}
                            getAllSavedMessages={this.props.getAllSavedMessages}
                            downloadK1DocumentLink={this.props.downloadK1DocumentLink}
                            saveProcessReturnInfo={this.props.saveProcessReturnInfo}
                            userSignatures={this.props.userSignatures}
                            getTaxCaddyLookupDetails={this.props.getTaxCaddyLookupDetails}
                            voucherStore={this.props.voucherStore}
                            isK1Restored={this.props.isK1Restored}
                            restoreK1Instruction={this.props.restoreK1Instruction}
                            getAllTaxingAuthorities={this.props.getAllTaxingAuthorities}
                            businessInstructionStore={this.props.businessInstructionStore}
                            updateDocumentInitialStatus={this.updateDocumentInitialStatus}
                            statusChangeEvent={this.props.statusChangeEvent}
                            previewAttachments={this.props.previewAttachments}
                            onReportProblemSave={this.onReportProblemSave}
                            onReportProblemOpen={this.onReportProblemOpen}
                            onReportProblemCancel={this.onReportProblemCancel}
                            reportProblemState={this.state.reportProblemState}
                            saveTaxingAuthority={this.props.saveTaxingAuthority}
                            requestTaxingAuthorities={this.props.requestTaxingAuthority}
                            updateDocumentGroupOrder={this.props.updateDocumentGroupOrder}
                            attachmentInstructions={this.props.attachementInstructions}
                            requestEroSignaturePath={this.props.downloadUserSignature}
                            getAddtionalEsignDocumentUploadLink={this.props.getAddtionalEsignDocumentUploadLink}
                            deleteUploadedAdditionalDocumentBlobFile={this.props.deleteUploadedAdditionalDocumentBlobFile}
                            deleteAdditionalDocument={this.props.deleteAdditionalDocument}
                            getAddtionalEsignDocumentDownLoadLink={this.props.getAddtionalEsignDocumentDownLoadLink}
                            convertDocToPdfArtifactAsync={this.props.convertDocToPdfArtifactAsync}
                            requestAdditionalDocumentType={this.props.requestAdditionalDocumentType}
                            additionalDocumentTypeData={this.props.additionalDocumentTypeData}
                            requestCompanyLogo={this.props.requestCompanyLogo}
                            requestWatermarkList={this.props.requestWatermarkList}
                            watermarkList={this.props.watermarkList}
                            groups={this.props.groupInfo}
                            customColumnData={this.props.customColumnData}
                            isLoading={this.state.isLoading}
                            updateGroupInfoState={this.props.updateGroupInfoState}
                            getPaperFileUploadLink={this.props.getPaperFileUploadLink}
                            getPaperFileDownLoadLink={this.props.getPaperFileDownLoadLink}
                            deleteUploadedPaperFileFromBlob={this.props.deleteUploadedPaperFileFromBlob}
                            paperFileMessage={this.props.paperFileMessage}
                            convertToPdfAsync={this.props.convertToPdfAsync}
                            refreshAssignmentPage={this.onPageReload}
                            requestDocumentStatus={this.props.requestDocumentStatus}
                            getk1AttachmentUploadLink={this.props.getk1AttachmentUploadLink}
                            ssrSettings={this.props.company.ssrSettings as ISSRSettings}
                            parentResourceIdentifier={SendTaxReturnResources.Prefix}
                            userroles={this.props.auth?.user?.profile?.role?.split(",") ?? []}
                            requestPartnerUser={this.props.requestPartnerUser}
                            locationDropdown={this.props.locationDropdown}
                            loggedInUserLocations={this.state.loggedInUserLocations}
                            setTaxDocumentsAccessMaps={this.props.setTaxDocumentsAccessMaps}
                            smsNotificationDetails={this.props.smsNotificationDetails}
                            getSMSNotificationDetails={this.props.getSMSNotificationDetails}
                            updateSMSNotificationDetails={this.props.updateSMSNotificationDetails}
                            requestClientManagementSetting={this.props.requestClientManagementSetting}
                            clientManagementSetting={this.props.clientManagementSetting}
                            k1SSNDetails={this.props.k1SSNDetails}
                            resetK1SSNState={this.props.resetK1SSNState}
                            getK1SSNDetailsForPage={this.props.getK1SSNDetailsForPage}
                            pageWiseSSNDetails={this.props.pageWiseSSNDetails}
                            resetPageWiseK1SSNState={this.props.resetPageWiseK1SSNState}
                            inactiveusers={this.props.allssrUsers}
                            markAsReadyForDelivery={this.props.markAsReadyForDelivery}
                            onGroupedReturnProcessOpen={this.handleGroupedReturnProcessOpen}
                            groupData={this.props.groupData}
                            saveGroupedProcessReturnInfo={this.props.saveGroupedProcessReturnInfo}
                            groupedTaxDocuments={this.state.groupedtaxReturns}
                            requestTaxDocument={this.requestTaxDocumentInfo}
                            profile={this.props.profile}
                            helpTexts={this.props.helpTextState.helpTexts}
                            isDeliveryPermissionAssigned={this.props.isDeliveryPermissionAssigned}
                            clearSelectedRows={this.clearSelectedRows}
                            updateGroupedReturnDocument={this.updateGroupedReturnDocument}
                        />
                    ))}
                </div>
            </div>
        );
    }
    //=======================================================================================

    setGroupedReturnDocuments = (taxReturns: ITaxReturn[]) => {
        this.setState({ groupedtaxReturns: taxReturns });
    };

    updateGroupedReturnDocument = (updatedReturn: ITaxReturn) => {
        const groupedReturns = [...this.state.groupedtaxReturns];
        let updatedgroupedReturns = groupedReturns.map((taxReturn: ITaxReturn) => {
            if (taxReturn.id == updatedReturn.id) {
                taxReturn = updatedReturn;
                this.props.updateTaxDocument(updatedReturn);
            }
            return taxReturn;
        });

        this.setState({ groupedtaxReturns: updatedgroupedReturns });
    };

    private handleGroupedReturnProcessOpen = (nextProcessingReturnId: number, groupId: number, callback?: () => void) => {
        this.requestTaxDocumentInfo(
            nextProcessingReturnId,
            true,
            undefined,
            undefined,
            (taxReturn: ITaxReturn) => {
                this.setState(
                    (prevState) => {
                        return { ...prevState, groupId: groupId };
                    },
                    () => {
                        this.props.checkDuplicateTaxReturn(
                            taxReturn.id,
                            taxReturn.engagementType,
                            taxReturn.taxYear,
                            (result) => {
                                if (result && taxReturn.documentStatus == DocumentStatus.READY) {
                                    bootbox.confirm({
                                        message: ProcessReturnConstants.DuplicateReturnMessage,
                                        buttons: {
                                            cancel: {
                                                label: '<i class="fas fa-times"></i> Cancel',
                                                className: "btn-white btn-default"
                                            },
                                            confirm: {
                                                label: '<i class="fas fa-check"></i> OK',
                                                className: "btn-info"
                                            }
                                        },
                                        callback: (result: boolean) => {
                                            if (result == true) {
                                                if (this.state.processReturnWindows.find((x) => x.docId === taxReturn.id)) {
                                                    VenusNotifier.Warning(
                                                        ProcessReturnConstants.ReturnAlreadyOpenedMessage,
                                                        null
                                                    );
                                                    return;
                                                }
                                                this.setState({ processReturnModalLoader: true });
                                                this.handleRequest(taxReturn);
                                                if (isK1ShareHolder(taxReturn)) {
                                                    this.props.getK1SSNDetails(taxReturn.id);
                                                }
                                                return;
                                            } else {
                                                if (Helper.isProcessable(taxReturn)) {
                                                    taxReturn.documentStatus = DocumentStatus.DUPLICATE;
                                                    if (taxReturn.documentStatus !== taxReturn.documentStatus) {
                                                        this.props.updateDocumentInitialStatus(
                                                            taxReturn,
                                                            undefined,
                                                            undefined,
                                                            SendTaxReturnResources.GridButtonPrefix +
                                                                SendTaxReturnResources.GridProcessButton
                                                        );
                                                        this.props.statusChangeEvent(
                                                            taxReturn,
                                                            true,
                                                            SendTaxReturnResources.GridButtonPrefix +
                                                                SendTaxReturnResources.GridProcessButton
                                                        );
                                                        if (isK1ShareHolder(taxReturn)) {
                                                            this.props.getK1SSNDetails(taxReturn.id);
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    });
                                } else {
                                    if (this.state.processReturnWindows.find((x) => x.docId === taxReturn.id)) {
                                        VenusNotifier.Warning(ProcessReturnConstants.ReturnAlreadyOpenedMessage, null);
                                        return;
                                    }
                                    this.setState({ processReturnModalLoader: true });
                                    this.handleRequest(taxReturn);
                                    if (isK1ShareHolder(taxReturn)) {
                                        this.props.getK1SSNDetails(taxReturn.id);
                                    }
                                    return;
                                }
                            }
                        );
                        if (callback) {
                            callback();
                        }
                    }
                );
            },
            false,
            true
        );
    };

    private fetchAssignments() {
        let query = this.buildQueryString(this.state.page, pageSize);
        this.props.requestAssignments(query, false);
    }
    private fetchAssignmentsData(page: number, sizePerPage: number) {
        let query = this.buildQueryString(page, sizePerPage);
        this.props.requestAssignments(query, false);
    }

    private onPageReload() {
        this.setState({ refreshDelay: true, selectedDocumentGuid: "" }, () => {
            const query = this.buildQueryString(this.state.page, pageSize);
            this.props.requestAssignments(query, true, () => {
                this.setState({ refreshDelay: false });
            });
        });
    }

    private buildQueryString(page: number, pageSize: number) {
        return (
            "?pageNo=" +
            page +
            "&pageSize=" +
            pageSize +
            "&sortBy=" +
            this.state.sortName +
            "&sortOrder=" +
            this.state.sortOrder +
            "&taxYear=" +
            this.state.taxYear +
            "&filterName=" +
            encodeURIComponent(this.state.filterName) +
            "&filterClientID=" +
            encodeURIComponent(this.state.filterClientID) +
            "&filterPartner=" +
            encodeURIComponent(this.state.filterPartner) +
            "&filterLocation=" +
            this.state.filterLocation +
            "&filterAssignTo=" +
            encodeURIComponent(this.state.filterAssignTo) +
            "&filterStatus=" +
            this.state.filterStatus +
            "&filterEngagement=" +
            this.state.filterEngagement +
            "&filterArrangementType=" +
            this.state.filterArrangement +
            "&filterUploadedOn=" +
            this.state.filterUploadedOn +
            "&filterGroupType=" +
            this.state.filterGroupType +
            "&filterDraftStatus=" +
            this.state.filterDraftStatus
        );
    }

    private buildBulkOperationQuery() {
        let query: BulkOperationQuery = {
            sortBy: this.state.sortName,
            sortOrder: this.state.sortOrder,
            searchText: this.state.searchString,
            clientId: this.state.filterClientID,
            customColumn: "",
            downloadedBy: "",
            name: this.state.filterName,
            partner: this.state.filterPartner,
            engagementType: this.state.filterEngagement,
            sentBy: "",
            status: this.state.filterStatus.length > 0 ? this.state.filterStatus.toString() : "",
            officeLocation: this.state.filterLocation.length > 0 ? this.state.filterLocation.toString() : "",
            isArchived: false,
            taxYear: this.state.taxYear !== 0 ? this.state.taxYear.toString() : "",
            unSelectedIds: [],
            assignedTo: this.state.filterAssignTo,
            reportType: ReportFilterType.AllAssignments,
            uploadedOn:
                this.state.filterUploadedOn !== undefined && new Date(this.state.filterUploadedOn).toString() !== "Invalid Date"
                    ? this.state.filterUploadedOn
                    : undefined,
            documentFilingType: 0,
            isPaperFileEnabled: false,
            quickReportStatus: "",
            groupName: Helper.getGroupName(this.state.filterGroupType, this.props.groupInfo),
            taxSoftware: "",
            createdBy: "",
            globalSearch: "",
            setAccess: SetAccessType.None,
            filterGroupType: this.state.filterGroupType,
            arrangement: this.state.filterArrangement,
            draftStatus: this.state.filterDraftStatus
        };
        return query;
    }

    private defaultType(cell: any, row: any) {
        return (
            <div title={cell} className="ellipsis">
                {cell}
            </div>
        );
    }

    private clientIdColumn = (cell: any, row: any) => {
        return (
            <div className="draftReturnCell">
                <div title={cell} className="ellipsis">
                    {cell}
                </div>
                {row.draftStatus === DraftStatus[DraftStatus.Draft] && (
                    <DraftStatusIcon className="assignments_draftIcon" width={11} height={11} />
                )}
            </div>
        );
    };

    private onPageChange(page: number, sizePerPage: number) {
        this.setState(
            {
                page: page,
                selectedRows: [],
                selectedDocumentGuid: ""
            },
            this.fetchAssignments
        );
    }

    private onSortChange(sortName: string, sortOrder: string) {
        let temp: IFilters = { ...this.state.filter };
        temp.sort.column = sortName;
        temp.sort.direction = sortOrder == "asc" ? SortDirections.Ascending : SortDirections.Descending;
        Helper.SessionStore.set(this.proxyFilter, JSON.stringify(temp));

        this.setState(
            {
                filter: temp,
                sortName: sortName,
                sortOrder: sortOrder == "asc" ? "asc" : "desc",
                selectedDocumentGuid: ""
            },
            () => this.fetchAssignmentsData(1, pageSize)
        );
    }
    private fetchFilterValue(data: any) {}

    private onFilterChange(dataField: any, filterType: ReportFilterType) {
        let newState: IAssignmentsPageState = {
            filterName: "",
            filterClientID: "",
            filterGroupType: -1,
            filterPartner: "",
            filterLocation: [] as any,
            filterStatus: [] as any,
            filterEngagement: 0,
            filterArrangement: -1,
            filterAssignTo: "",
            taxYear: 0,
            filterUploadedOn: moment(null).format("MM/DD/YYYY")
        } as IAssignmentsPageState;
        let isClearFilter = true;
        var dict: { [columnName: string]: string } = {};

        for (let field of Object.keys(dataField)) {
            var data = dataField[field.valueOf()].value ? dataField[field.valueOf()].value : dataField[field.valueOf()];
            if (isClearFilter && data !== "" && data !== "-1") {
                isClearFilter = false;
            }
            dict[field.valueOf().toString()] = data;

            switch (field) {
                case "taxDocumentName":
                    newState.filterName = dataField[field].value ? dataField[field].value : dataField[field];
                    break;
                case "clientId":
                    newState.filterClientID = dataField[field].value ? dataField[field].value : dataField[field];
                    break;
                case "partner":
                    newState.filterPartner = dataField[field].value ? dataField[field].value : dataField[field];
                    break;
                case "locationName":
                    let filterLocation = dataField[field].value ? dataField[field].value : dataField[field];
                    filterLocation = filterLocation == "-1" ? "" : filterLocation;
                    newState.filterLocation = filterLocation;
                    break;
                case "assignToUserName":
                    newState.filterAssignTo = dataField[field].value ? dataField[field].value : dataField[field];
                    break;
                case "documentStatus":
                    let filterStatusPositive = dataField[field].value ? dataField[field].value : dataField[field];
                    let filterStatusNegative: DraftFilter;
                    const { positiveValues: positiveStatusValues, negativeValue } =
                        Helper.separateDraftStatusValues(filterStatusPositive);
                    filterStatusPositive = positiveStatusValues;
                    filterStatusNegative =
                        negativeValue === StatusRadioButtonValues.Draft
                            ? DraftFilter.DraftReturn
                            : negativeValue === StatusRadioButtonValues.Final
                            ? DraftFilter.FinalReturn
                            : DraftFilter.All;
                    newState.filterStatus = filterStatusPositive;
                    newState.filterDraftStatus = filterStatusNegative;
                    break;
                case "uploadedOn":
                    let noOfDays = Helper.GetNumberOfDays(dataField[field].value ? dataField[field].value : dataField[field]);
                    newState.filterUploadedOn = moment(noOfDays).format("MM/DD/YYYY");
                    break;
                case "engagementType":
                    let filterEngagement = dataField[field].value ? dataField[field].value : dataField[field];
                    let filterArrangement = -1;
                    const { positiveValues, negativeValues } = Helper.separatePositiveAndNegativeValues(filterEngagement);
                    filterEngagement = positiveValues;
                    filterArrangement =
                        negativeValues == TypeDropdownValues.FullReturn
                            ? ArrangementList.FullReturn
                            : negativeValues == TypeDropdownValues.StateOnly
                            ? ArrangementList.StateOnly
                            : ArrangementList.All;
                    newState.filterEngagement = filterEngagement;
                    newState.filterArrangement = filterArrangement;
                    break;
                case "taxYear":
                    newState.taxYear = +this.taxYearList[dataField[field].value ? dataField[field].value : dataField[field]];
                    break;
                case "groupName":
                    newState.filterGroupType = dataField[field].value ? dataField[field].value : dataField[field];
                    break;
            }
        }

        let tempfilter: IFilters = { ...this.state.filter };
        tempfilter.fields = dict;
        tempfilter.filterType = filterType;
        if (isClearFilter) {
            Helper.SessionStore.remove(this.proxyFilter);
        } else {
            Helper.SessionStore.set(this.proxyFilter, JSON.stringify(tempfilter));
        }

        if (this.isFilterChanged(newState)) {
            this.setState({ ...newState, filter: tempfilter, page: 1, pageSize: pageSize, selectedDocumentGuid: "" }, () => {
                let assignmentTable: any = this.refs.AssignmentTable;
                if (!assignmentTable.isAppliedFilter)
                    this.props.requestAssignments(this.buildQueryString(this.state.page, this.state.pageSize), false);
            });
        }
    }
    private isFilterChanged(newState: IAssignmentsPageState): boolean {
        return (
            newState.filterName !== this.state.filterName ||
            newState.filterClientID !== this.state.filterClientID ||
            newState.filterPartner !== this.state.filterPartner ||
            newState.filterLocation !== this.state.filterLocation ||
            newState.filterAssignTo !== this.state.filterAssignTo ||
            newState.filterStatus !== this.state.filterStatus ||
            newState.filterEngagement !== this.state.filterEngagement ||
            newState.filterArrangement !== this.state.filterArrangement ||
            newState.filterDraftStatus !== this.state.filterDraftStatus ||
            newState.filterUploadedOn != this.state.filterUploadedOn ||
            newState.filterGroupType !== this.state.filterGroupType ||
            newState.taxYear !== this.state.taxYear
        );
    }

    private clearFiler() {
        this.setState(
            {
                filterName: "",
                filterClientID: "",
                filterGroupType: -1,
                filterPartner: "",
                filterLocation: [],
                filterStatus: [],
                filterEngagement: 0,
                filterArrangement: -1,
                filterDraftStatus: DraftFilter.All,
                filterAssignTo: "",
                taxYear: 0,
                filterUploadedOn: new Date(""),
                selectedDocumentGuid: ""
            },
            this.fetchAssignments
        );
        let assignmentTable: any = this.refs.AssignmentTable;
        assignmentTable.refs.Filters.setState({ activeFilter: "", appliedFilters: [] });
    }

    private onRowSelect(row: ColumnValues, isSelected: any, e: React.BaseSyntheticEvent) {
        let newList = [...this.state.selectedRows];
        if (
            e.target.tagName !== "BUTTON" &&
            e.target.tagName !== "I" &&
            e.target.tagName !== "SPAN" &&
            e.target.tagName !== "A"
        ) {
            if (isSelected) {
                newList.push(row.rowIndex);
            } else {
                var index = newList.indexOf(row.rowIndex);
                if (index > -1) {
                    newList.splice(index, 1);
                }
            }
            this.setState({ selectedRows: newList }, this.setSelectedDocumentsGuid);
        }
        this.forceUpdate();
    }

    private onRowUnSelect = (index: number) => {
        let newList = [...this.state.selectedRows];
        var index = newList.indexOf(index);
        if (index > -1) {
            newList.splice(index, 1);
        }
        this.setState({ selectedRows: newList }, this.setSelectedDocumentsGuid);
        this.forceUpdate();
    };

    private onSelectAll(isSelected: boolean, rows: ColumnValues[], e: any) {
        let selectedRows: number[] = [];
        if (isSelected) {
            rows.forEach((value: ColumnValues, index: number) => {
                selectedRows.push(value.rowIndex);
            });
        }
        this.setState({ selectedRows: selectedRows }, this.setSelectedDocumentsGuid);
    }
    //=======================================================================================

    onAssignOpen = (rowIndex: number = NO_INDEX, resourceId: string = "") => {
        let ids = this.onPopupOpen(rowIndex);
        this.props.requestUserProfile();
        this.props.validateAssignTo(ids, this.props.profile?.userId, this.assignReturns, resourceId);
    };

    assignReturns = (ids: number[], isValidAssign: boolean) => {
        if (this.checkIfReturnIsNotAssignToLoggedinUser(ids) || !isValidAssign) {
            VenusNotifier.Warning(ProcessReturnConstants.ReturnNotAssignedToLoggedInUser, null);
        } else if (this.checkIfReturnIsInUse(ids)) {
            VenusNotifier.Warning(ProcessReturnConstants.ReturnInUse, null);
        } else {
            this.handleAssignOpenRequest(ids);
        }
    };

    private handleAssignOpenRequest(ids: number[]) {
        if (ids.length == 0) {
            VenusNotifier.Warning(AssignUserConstants.SelectDocumentWarning, null);
            return;
        } else if (
            this.selectedDocuments().find((x) => x.documentStatus === DocumentStatus.RECALLED) &&
            this.selectedDocuments().some((x) => x.assignTo != this.props.profile?.userId) &&
            this.isAdmin()
        ) {
            VenusNotifier.Warning(AssignUserConstants.RecalledReturnWarning, null);
            return;
        }

        let defaultUser = this.props.assignedUser(this.selectedDocuments());

        this.lazyLoadSelected(ids);
        this.setState({
            assignReturnsState: {
                show: true,
                selected: this.selectedDocuments(),
                saveCheckIds: undefined
            },
            defaultAssignUser: defaultUser
        });
    }

    private onAssignCancel() {
        this.setState({
            assignReturnsState: {
                show: false,
                selected: undefined,
                saveCheckIds: undefined
            }
        });
    }

    private onAssignSave(userId: number) {
        let ids = this.selectedDocumentIds();
        let validIds: number[] = [];
        let user = this.getUserModel(userId);

        var promises: any[] = [];
        ids.forEach((id, i) => {
            let taxDocument = this.props.taxDocuments[id]?.taxReturn;
            var promise: any = new Promise((resolve) => {
                this.props.requestDocumentStatus(
                    taxDocument.id,
                    () => {
                        let taxReturn: ITaxReturn = Object.assign({}, this.props.taxDocuments[taxDocument.id]?.taxReturn);
                        if (Helper.isAssignable(taxReturn.documentStatus)) {
                            taxDocument.assignTo = userId;
                            taxDocument.assignedUser = user as IUserBaseModel;
                            if (
                                taxDocument.documentStatus !== DocumentStatus.PROCESSING &&
                                taxDocument.documentStatus !== DocumentStatus.RECALLED &&
                                taxDocument.documentStatus !== DocumentStatus.READY
                            ) {
                                taxDocument.documentStatus = DocumentStatus.PROCESSING;
                            }
                            taxDocument.isModified = true;
                            validIds.push(taxDocument.id);
                            this.props.updateDocumentInitialStatus(
                                taxDocument,
                                undefined,
                                undefined,
                                SendTaxReturnResources.HeaderButtonPrefix + SendTaxReturnResources.HeaderAssignButton
                            );
                        }
                        resolve(true);
                    },
                    taxDocument.groupId > 0
                );
            });
            promises.push(promise);
        });
        Promise.all(promises).then(() => {
            if (validIds && validIds.length > 0) {
                this.setState({
                    assignReturnsState: {
                        show: true,
                        selected: undefined,
                        saveCheckIds: validIds
                    }
                });
            } else {
                VenusNotifier.Error(AssignUserConstants.IncorrectStatusWarning, "");

                this.setState({
                    assignReturnsState: {
                        show: false,
                        selected: undefined,
                        saveCheckIds: undefined
                    }
                });
            }
        });
    }

    updateDocumentInitialStatus = (taxReturn: ITaxReturn) => {
        this.setState({
            isLoading: true
        });
        this.props.updateDocumentInitialStatus(
            taxReturn,
            () => {
                this.requestTaxDocumentInfo(
                    taxReturn.id,
                    true,
                    undefined,
                    undefined,
                    () => {
                        this.removeDateControls(taxReturn, () => {
                            this.setState({ isLoading: false });
                        });
                    },
                    false,
                    taxReturn.groupId > 0
                );
            },
            undefined,
            SendTaxReturnResources.GridButtonPrefix + SendTaxReturnResources.GridProcessButton
        );
    };

    removeDateControls = (taxReturn, callback) => {
        let taxReturnTemp = Helper.cloneTaxReturn(taxReturn);
        const eFileInfo = new EfileInfoView(taxReturnTemp);
        if (taxReturn?.documentSettings && taxReturn.documentSettings.documentSignatureSetting.isDoNotDisplayDatesEnabled) {
            eFileInfo.removeDateControls();
        }
        this.props.updateTaxDocument(taxReturnTemp, callback);
    };
    //=======================================================================================
    onSetAccessOpen = (rowIndex: number = NO_INDEX, resourceId: string = "") => {
        let ids = this.onPopupOpen(rowIndex);

        if (this.checkIfReturnIsNotAssignToLoggedinUser(ids)) {
            VenusNotifier.Warning(ProcessReturnConstants.ReturnNotAssignedToLoggedInUser, null);
        } else if (this.checkIfReturnIsInUse(ids)) {
            VenusNotifier.Warning(ProcessReturnConstants.ReturnInUse, null);
        } else if (!this.isAdmin()) {
            if (
                this.props.company.companySettings.returnAccessSettings.isSetAccessToIndividual &&
                !this.props.company.companySettings.returnAccessSettings.isSetAccessToOtherUsers
            ) {
                VenusNotifier.Warning(SetAccessConstants.AccessRetricted, null);
            } else {
                this.handleSetAccessOpenRequest(ids, resourceId);
            }
        } else {
            this.handleSetAccessOpenRequest(ids, resourceId);
        }
    };

    private handleSetAccessOpenRequest(ids: number[], resourceId: string = "") {
        if (ids.length == 0) {
            VenusNotifier.Warning(SetAccessConstants.SelectDocumentWarning, null);
            return;
        } else if (
            this.selectedDocuments().find((x) => x.documentStatus === DocumentStatus.RECALLED) &&
            this.selectedDocuments().some((x) => x.assignTo != this.props.profile?.userId) &&
            !this.isAdmin()
        ) {
            VenusNotifier.Warning(AssignUserConstants.RecalledReturnWarning, null);
            return;
        }

        this.setAccessResourceId = resourceId;

        this.props.requestSSRUsersList(false);

        this.lazyLoadSelected(ids);

        let accessMaps = ids
            .map((documentId) => {
                return {
                    documentId: documentId,
                    isGroupedReturn: this.props.taxDocuments[documentId]?.taxReturn.groupId > 0,
                    groupId: this.props.taxDocuments[documentId]?.taxReturn.groupId
                } as ITaxDocumentViewModel;
            })
            .filter((y) => y.documentId > 0);

        this.props.getDocumentAccess(
            accessMaps,
            true,
            (result: IDocumentAccess) => {
                this.setState({
                    availableUserGroups: result.availableUserGroups,
                    availableUsers: result.availableUsers,
                    selectedUserGroups: result.selectedUserGroups,
                    selectedUsers: result.selectedUsers
                });
                let selectedAssignments = ids.map((id, i) => {
                    return this.props.taxDocuments[id]?.taxReturn;
                });
                const isGroupedReturnExists = selectedAssignments.some((x) => x.groupId > 0);
                this.setState({
                    setAccessState: {
                        show: true,
                        selected: selectedAssignments,
                        saveCheckIds: undefined
                    },
                    isGroupedReturnExists: isGroupedReturnExists
                });
            },
            resourceId
        );
    }

    private onSetAccessSave(accessingUsersAndUserGroups: IAccessingUsersAndUserGroups) {
        let ids = this.state.setAccessState.selected!.map((taxReturn, i) => {
            return taxReturn.id;
        });

        let accessMaps: ITaxDocumentAccessViewModel = {
            taxDocuments: ids
                .map((documentId) => {
                    return {
                        documentId: documentId,
                        isGroupedReturn: this.props.taxDocuments[documentId].taxReturn.groupId > 0,
                        groupId: this.props.taxDocuments[documentId].taxReturn.groupId
                    } as ITaxDocumentViewModel;
                })
                .filter((y) => y.documentId > 0),
            userGroups: accessingUsersAndUserGroups.selectedUserGroups,
            users: accessingUsersAndUserGroups.selectedUsers,
            documents: []
        };

        this.props.setTaxDocumentsAccessMaps(
            accessMaps,
            () => {
                this.onPageReload();
            },
            this.setAccessResourceId
        );
        this.setState({
            setAccessState: {
                show: true,
                selected: undefined,
                saveCheckIds: ids
            },
            selectedRows: [],
            selectedDocumentGuid: "",
            availableUserGroups: [],
            availableUsers: [],
            selectedUserGroups: [],
            selectedUsers: [],
            isGroupedReturnExists: false
        });
    }

    private onSetAccessCancel() {
        this.setState({
            setAccessState: {
                show: false,
                selected: undefined,
                saveCheckIds: undefined
            },
            availableUserGroups: [],
            availableUsers: [],
            selectedUserGroups: [],
            selectedUsers: [],
            isGroupedReturnExists: false
        });
    }
    //=======================================================================================

    onEditClientInfoOpen = (rowIndex: number) => {
        let item = this.getStoreItem(rowIndex);
        const taxReturn: ITaxReturn = item?.taxReturn;
        let ids = this.onPopupOpen(rowIndex);
        if (this.checkIfReturnIsNotAssignToLoggedinUser(ids)) {
            VenusNotifier.Warning(ProcessReturnConstants.ReturnNotAssignedToLoggedInUser, null);
        } else if (this.checkIfReturnIsInUse(ids)) {
            VenusNotifier.Warning(ProcessReturnConstants.ReturnInUse, null);
        } else if (taxReturn && taxReturn.groupId > 0) {
            this.handleEditControllerInfoOpenRequest(taxReturn);
        } else {
            this.handleEditClientInfoOpenRequest(taxReturn);
        }
    };

    private handleEditControllerInfoOpenRequest(taxReturn: ITaxReturn) {
        if (!Helper.isClientInfoLoaded(taxReturn)) {
            this.requestTaxDocumentInfo(
                taxReturn.id,
                true,
                false,
                undefined,
                (data) => {
                    this.setState({
                        editControllerInfoState: {
                            ...this.state.editControllerInfoState,
                            model: data
                        }
                    });
                },
                true,
                taxReturn.groupId > 0
            );
        }
        this.props.requestGroupInfo(taxReturn.groupId, (groupInfo: IGroupInfo) => {
            this.setState({
                editControllerInfoState: {
                    show: true,
                    model: taxReturn ? taxReturn : undefined,
                    groupInfo: groupInfo,
                    saveCheckId: undefined
                }
            });
        });
    }

    private onGroupReturnClientInfoCancel = () => {
        let item = this.state.editControllerInfoState.model as ITaxReturn;
        this.requestTaxDocumentInfo(item.id, true, false, undefined, undefined, true, item.groupId > 0);
        this.setState({
            editControllerInfoState: {
                show: false,
                model: undefined,
                groupInfo: undefined,
                saveCheckId: undefined
            }
        });
    };

    private handleEditClientInfoOpenRequest(taxReturn: ITaxReturn) {
        if (!Helper.isClientInfoLoaded(taxReturn)) {
            this.requestTaxDocumentInfo(taxReturn.id, true, false, undefined, undefined, true, taxReturn.groupId > 0);
        }
        this.setState({
            editClientInfoState: {
                show: true,
                model: taxReturn ? taxReturn : undefined,
                saveCheckId: undefined
            }
        });
    }

    private onEditClientInfoCancel(id: number) {
        let item = this.state.editClientInfoState.model as ITaxReturn;
        this.requestTaxDocumentInfo(item.id, true, false, undefined, undefined, true, item.groupId > 0);

        this.setState({
            editClientInfoState: {
                show: false,
                model: undefined,
                saveCheckId: undefined
            }
        });
    }

    updateTaxDocument = (taxReturn: ITaxReturn, callback?: () => void) => {
        this.props.updateTaxDocument(taxReturn, callback);
        let groupedReturns = [...this.state.groupedtaxReturns];
        let index = groupedReturns.findIndex((x) => x.id === taxReturn.id);
        if (index !== NO_INDEX) {
            groupedReturns[index] = taxReturn;
        } else {
            groupedReturns.push(taxReturn);
        }
        this.setState({ groupedtaxReturns: groupedReturns });
    };

    private onEditClientInfoSave(
        id: number,
        isMailSend: boolean,
        clientGUIDs: string[],
        isEmailUpdated: boolean,
        signerInfo: ISignerModel,
        resetLoadingMessageState?: any
    ) {
        let taxReturn: ITaxReturn = this.props.taxDocuments[id]?.taxReturn;
        if (Helper.validateClientInfo(taxReturn, this.state.skipEroValidation)) {
            this.props.saveTaxDocument(
                taxReturn,
                undefined,
                () => {
                    resetLoadingMessageState && resetLoadingMessageState();
                },
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                SendTaxReturnResources.GridButtonPrefix + SendTaxReturnResources.GridEditClientButton
            );
            this.setState({
                editClientInfoState: {
                    show: true,
                    model: taxReturn,
                    saveCheckId: id
                }
            });
        }
    }

    private onGroupReturnClientInfoSave = (
        taxReturn: ITaxReturn,
        controllerInfo: IControllerInfo,
        canSendControllerEmailUpdateMail: boolean,
        callback: () => void
    ) => {
        let groupReturnClientInfo: IGroupedReturnsClientInfoModel = initialGroupRetrunClientInfo;
        groupReturnClientInfo.taxDocument = taxReturn;
        groupReturnClientInfo.controllerInfo = controllerInfo;
        groupReturnClientInfo.isEditDeliveredClientInfoRequest = false;
        this.props.saveGroupReturnClientInfoData(
            groupReturnClientInfo,
            () => {
                this.setState({
                    editControllerInfoState: {
                        show: false,
                        model: undefined,
                        groupInfo: undefined,
                        saveCheckId: undefined
                    }
                });
                callback();
            },
            SendTaxReturnResources.GridButtonPrefix + SendTaxReturnResources.GridEditClientButton
        );
    };
    //=======================================================================================

    onChangeStatusOpen = (rowIndex: number = NO_INDEX) => {
        let ids = this.onPopupOpen(rowIndex);
        let item = this.getStoreItem(rowIndex);
        const taxReturn: ITaxReturn = item?.taxReturn;
        if (this.checkIfReturnIsNotAssignToLoggedinUser(ids)) {
            VenusNotifier.Warning(ProcessReturnConstants.ReturnNotAssignedToLoggedInUser, null);
        } else if (this.checkIfReturnIsInUse(ids)) {
            VenusNotifier.Warning(ProcessReturnConstants.ReturnInUse, null);
        } else {
            this.handleChangeStatusOpenRequest(taxReturn);
        }
    };

    private handleChangeStatusOpenRequest(taxDocument: ITaxReturn) {
        if (
            taxDocument.documentStatus === DocumentStatus.RECALLED ||
            taxDocument.documentStatus == DocumentStatus.PREPARINGFORDELIVERY ||
            taxDocument.documentStatus == DocumentStatus.DELIVERYFAILED
        ) {
            VenusNotifier.Error(ChangeStatusConstants.Error, ChangeStatusConstants.Title);
            return;
        }
        this.requestTaxDocumentInfo(taxDocument.id, true, false, undefined, undefined, false, taxDocument.groupId > 0);
        let locationId = taxDocument.locationId === null ? taxDocument?.location?.locationId : taxDocument.locationId;
        this.props.requestSSRChangeStatusUsers(locationId, TaxReturnScreen.SendTaxReturn, (response) => {
            if (response) {
                this.setState({
                    ssrChangeStatusUsers: response
                });
            }
        });
        let defaultUser = this.props.assignedUser([taxDocument]);
        this.setState({
            changeStatusState: {
                show: true,
                model: taxDocument,
                saveCheckId: undefined
            },
            defaultAssignUser: defaultUser,
            showLoader: false
        });
    }

    private onChangeStatusCancel() {
        this.setState({
            changeStatusState: {
                show: false,
                model: undefined,
                saveCheckId: undefined
            }
        });
    }

    onChangeStatusSaveCallback = (
        docId: number,
        userId: number,
        status: DocumentStatus,
        result: boolean,
        resourceId: string = ""
    ) => {
        if (result) {
            this.props.requestDocumentStatus(
                docId,
                () => {
                    let taxDocument: ITaxReturn = Object.assign({}, this.props.taxDocuments[docId]?.taxReturn);
                    if (Helper.isProcessable(taxDocument)) {
                        const companySettings: ICompanySettings = this.props.company.companySettings
                            ? this.props.company.companySettings
                            : initialCompanySettings;
                        if (
                            status == DocumentStatus.READYFORDELIVERY &&
                            companySettings.displaySettingsModel.isEnableInvoice &&
                            !Helper.validateInvoice(taxDocument, companySettings)
                        ) {
                            VenusNotifier.Error(FinishProcessReturn.StatusMessage.DeliverToClientWithoutInvoiceError, null);
                            return;
                        }
                        taxDocument.documentStatus = status;
                        taxDocument.isModified = true;

                        taxDocument.assignTo = userId;
                        taxDocument.assignedUser = this.getUserModel(userId) as IUserBaseModel;
                        this.setState(
                            {
                                changeStatusState: {
                                    show: true,
                                    model: undefined,
                                    saveCheckId: docId
                                },
                                showLoader: true
                            },
                            () => {
                                this.props.changeStatusTaxDocument(
                                    taxDocument,
                                    () => {
                                        this.onPageReload();
                                    },
                                    resourceId
                                );
                            }
                        );
                    } else {
                        this.setState({
                            changeStatusState: {
                                show: false,
                                model: undefined,
                                saveCheckId: undefined
                            },
                            showLoader: false
                        });
                        VenusNotifier.Error(ProcessReturnConstants.ReturnAlreadyProcessed, "");
                        this.onPageReload();
                    }
                },
                false
            );
        } else {
            this.setState({ showLoader: false });
        }
    };

    private onChangeStatusSave(docId: number, userId: number, status: DocumentStatus) {
        this.onChangeStatusSaveCallback(
            docId,
            userId,
            status,
            true,
            SendTaxReturnResources.GridButtonPrefix + SendTaxReturnResources.GridChangeStatus
        );
    }
    //=======================================================================================

    private onUploadReturnsOpen(rowIndex: number, taxSoftware: TaxSoftwareType) {
        this.setState({ showUpload: true, selectedTaxSoftware: taxSoftware });
    }

    private onUploadReturnsCancel() {
        this.setState({ showUpload: false });
    }
    //=======================================================================================
    private handleRowDoubleClick = (rowIndex: number, resourceId: string = "") => {
        let item = this.getStoreItem(rowIndex)?.taxReturn;

        if (
            Helper.isLocked(item) ||
            item.documentStatus === DocumentStatus.RECALLED ||
            item.documentStatus === DocumentStatus.NONSUPPORTED ||
            item.documentStatus === DocumentStatus.NONSUPPORTEDDESCRIPTION ||
            item.documentStatus === DocumentStatus.REPROCESSING
        ) {
            return;
        }

        this.handleProcessReturnOpen(rowIndex, resourceId);
    };

    handleRequest = (taxReturn: ITaxReturn) => {
        if (taxReturn.documentStatus !== DocumentStatus.READY) {
            if (Helper.isProcessable(taxReturn)) {
                if (taxReturn.lockedBy > 0 && taxReturn.assignTo !== this.props.profile?.userId) {
                    // locked, assigned to other user
                    this.setState({ processReturnModalLoader: false });
                    VenusNotifier.Warning(ProcessReturnConstants.ReturnInUse, null);
                    return;
                } else if (taxReturn.lockedBy <= 0 && taxReturn.assignTo !== this.props.profile?.userId) {
                    taxReturn.assignTo = this.props.profile?.userId != undefined ? this.props.profile?.userId : 0;
                    taxReturn.assignedUser.email = this.props.profile.email;
                    taxReturn.assignedUser.firstName = this.props.profile.firstName;
                    taxReturn.assignedUser.lastName = this.props.profile.lastName;
                    taxReturn.assignedUser.userId = this.props.profile?.userId || 0;
                    this.processReturnModalSetUp(taxReturn);
                } else {
                    this.processReturnModalSetUp(taxReturn);
                }
            } else {
                this.setState({ processReturnModalLoader: false });
                VenusNotifier.Error(ProcessReturnConstants.ReturnAlreadyProcessed, null);
                return;
            }
        } else {
            this.processReturnModalSetUp(taxReturn);
        }
    };

    onChangePaperDeliveryType(event: any, paperDeliveryType: PaperReturnDeliveryType) {
        this.setState({ paperReturnDeliveryType: paperDeliveryType });
    }
    private reorderList = (updatedList: IOrderList[]) => {
        this.setState({
            orderList: updatedList.map((value, index) => {
                return value.id;
            })
        });
    };
    private handleNotifyUserChange = (event: any) => {
        this.setState({ isNotifyUserEnabled: event.target.checked });
    };
    private handleSaveAsDefaultOrderChange = (event: any) => {
        this.setState({ isSaveAsDefaultOrder: event.target.checked });
    };

    onPrintForPaperDelivery = () => {
        let groupReturnModel: IGroupedReturnsModel = {
            groupInfo: this.state.groupInfoState,
            taxReturns: []
        };
        this.selectedDocuments().map((taxReturn, i) => {
            let document: ITaxReturn;
            document = taxReturn;
            groupReturnModel.taxReturns.push(document);
        });
        if (groupReturnModel.taxReturns.length === 0) {
            VenusNotifier.Warning(GroupReturnConstants.StatusMessage.FinishError, "Error");
        } else {
            if (!this.props.company.companySettings.displaySettingsModel.isWatermarkEnabled) {
                groupReturnModel.groupInfo.groupSettings.groupDeliverySettings.watermarkId = 0;
            }
            logger.trackTrace(
                `GroupProcessReturnModal --> onPrintForPaperDelivery executes for GroupId: ${groupReturnModel.groupInfo.id?.toString()}`,
                { GroupId: groupReturnModel.groupInfo.id?.toString() }
            );
            this.props.onPrintForPaperDelivery(
                groupReturnModel,
                this.state.paperReturnDeliveryType,
                this.state.orderList,
                this.state.isNotifyUserEnabled,
                this.state.isSaveAsDefaultOrder,
                SendTaxReturnResources.Prefix + SendTaxReturnResources.DownloadPDFMenu,
                this.onDeliverGroupSaveCompletion
            );
        }
    };

    changeControllerInfo = (item: ControllerInfoProperty, value: any) => {
        const groupInfoStateChanged = { ...this.state.groupInfoState };
        switch (item) {
            case ControllerInfoProperty.name:
                groupInfoStateChanged.controllerInfo.name = value;
                break;
            case ControllerInfoProperty.emailAddress:
                groupInfoStateChanged.controllerInfo.emailAddress = value;
                break;
            case ControllerInfoProperty.ssn:
                groupInfoStateChanged.controllerInfo.ssn = value;
                break;
            case ControllerInfoProperty.countryCode:
                groupInfoStateChanged.controllerInfo.countryCode = value;
                break;
            case ControllerInfoProperty.mobileNumber:
                groupInfoStateChanged.controllerInfo.mobileNumber = value;
                break;
        }
        this.setState({ groupInfoState: groupInfoStateChanged });
    };

    deliverToController() {
        let efileFormIndex = -1;
        let groupReturnModel: IGroupedReturnsModel = {
            groupInfo: this.state.groupInfoState,
            taxReturns: []
        };

        let selectedDocuments = this.selectedDocuments();
        var invalidMailingAddressList: any = [];
        selectedDocuments.map((taxReturn, i) => {
            if (!Helper.validateMailingAddress(taxReturn)) {
                invalidMailingAddressList.push(taxReturn.id);
            }
        });

        if (invalidMailingAddressList.length > 0) {
            VenusNotifier.Warning(FinishProcessReturn.StatusMessage.MailingAddressMissingError, null);
            return;
        }

        selectedDocuments.map((taxReturn, i) => {
            let document: ITaxReturn = taxReturn;
            efileFormIndex = document.formGroups.findIndex((x) => x.group == DocumentGroups.EFile);
            if (efileFormIndex > -1) {
                if (document.formGroups[efileFormIndex] && document.formGroups[efileFormIndex].forms) {
                    document.formGroups[efileFormIndex].forms.map((f: IFormBase) => {
                        let form: IEFile = f as IEFile;
                        form.signatureControls.map((control, index) => {
                            let controlData = control;
                            controlData.required =
                                controlData.type != SignatureControlTypes.Text
                                    ? true
                                    : controlData.isCustomTextControl
                                    ? controlData.required
                                    : true;
                            controlData.tooltip = !controlData.tooltip ? "" : controlData.tooltip;
                            return controlData;
                        });
                    });
                }
            }
            groupReturnModel.taxReturns.push(document);
        });

        if (groupReturnModel.taxReturns.length === 0) {
            VenusNotifier.Warning(GroupReturnConstants.StatusMessage.FinishError, "Error");
        } else {
            if (!this.props.company.companySettings.displaySettingsModel.isWatermarkEnabled) {
                groupReturnModel.groupInfo.groupSettings.groupDeliverySettings.watermarkId = 0;
            }
            logger.trackTrace(
                `GroupProcessReturnModal --> deliverToController executes for GroupId: ${groupReturnModel.groupInfo.id?.toString()}`,
                { GroupId: groupReturnModel.groupInfo.id?.toString(), GroupName: groupReturnModel.groupInfo.name }
            );
            this.props.groupedReturnsDeliverToController(
                groupReturnModel,
                SendTaxReturnResources.Prefix + SendTaxReturnResources.DeliverToClientMenu,
                this.onDeliverGroupSaveCompletion
            );
        }
    }

    processReturnModalSetUp = (taxReturn: ITaxReturn) => {
        this.props.requestPdfDocument(taxReturn, true);
        this.props.requestUserProfile();
        this.props.requestTaxingAuthorities();
        this.props.requestSSRUsersList(false);
        this.props.requestCompanySettings(false);

        // download delgated signers
        if (this.props.profile?.userId) {
            const userId = this.props.profile?.userId as number;
            this.props.requestDelegatedSigners(userId);
        }

        // download the signature default ero signer
        if (taxReturn.partner?.userId) {
            this.props.downloadUserSignature(taxReturn.partner.userId);
        }

        let processReturnWindows = this.state.processReturnWindows;

        let count = processReturnWindows.length;

        let activeTab: ProcessReturnTabs | undefined = undefined;

        if (
            taxReturn.groupId > 0 &&
            this.state.processReturnWindows.length > 0 &&
            this.state.processReturnWindows.find((x) => x.docId !== taxReturn.id) //Handling SSGR next return processing
        ) {
            processReturnWindows = [];

            count = 0;

            activeTab = ProcessReturnTabs.ClientInfo;
        }

        processReturnWindows.push({
            key: Guid.newGuid().toString(),
            show: true,
            onCancel: this.handleProcessReturnCancel,
            docId: taxReturn.id,
            activeTab: activeTab,
            updateTaxDocument: this.updateTaxDocument,
            saveTaxDocument: this.props.saveTaxDocument,
            requestAttachments: this.props.requestAttachments,
            downloadAttachments: this.props.downloadAttachments,
            loadPdf: this.props.requestPdfDocument,
            order: count + 1,
            sendToERO: this.props.sendToERO,
            sendForReview: this.props.sendForReview,
            approveForDelivery: this.props.approveForDelivery,
            deliverToClient: this.props.deliverToClient,
            getVoucherUploadLink: this.props.getVoucherUploadLink,
            getMultiVoucherUploadLink: this.props.getMultiVoucherUploadLink,
            getTaxCaddyLookupDetails: this.props.getTaxCaddyLookupDetails,
            requestVoucherDueDates: this.props.requestVoucherDueDates,
            requestPaymentUrls: this.props.requestPaymentUrls,
            requestVoucherFormNames: this.props.requestVoucherFormNames
        } as IProcessReturnProps);

        this.setState({
            processReturnModalLoader: false,
            processReturnWindows: processReturnWindows
        });
    };

    private handleProcessReturnOpen = (rowIndex: number, resourceId: string = "") => {
        const item = this.getStoreItem(rowIndex);
        const taxReturn: ITaxReturn = item?.taxReturn;
        let taxDocumentId: number[] = [];
        taxDocumentId.push(taxReturn.id);

        logger.trackPageView(
            `Process Return started for document ${taxReturn.documentGuid}, DocumentStatus: ${taxReturn.documentStatus}`,
            { DocumentId: taxReturn.id, DocumentGuid: taxReturn.documentGuid, DocumentStatus: taxReturn.documentStatus }
        );

        let accessMaps = [] as ITaxDocumentViewModel[];
        const docInfo = {
            documentId: taxReturn.id,
            isGroupedReturn: taxReturn.groupId > 0,
            groupId: taxReturn.groupId > 0 ? taxReturn.groupId : 0
        } as ITaxDocumentViewModel;
        accessMaps.push(docInfo);
        this.setState({ processReturnModalLoader: true });
        this.props.getDocumentAccess(
            accessMaps,
            true,
            (documentAccess) => {
                if (documentAccess) {
                    if (!this.isAdmin()) {
                        const user = this.props.auth?.user;
                        const userIndex = documentAccess.selectedUsers.findIndex((x) => x.id == this.props.profile?.userId);
                        const filteredArray = this.props.userDetails.userGroups?.split(",")?.filter((value) =>
                            documentAccess.selectedUserGroups
                                .map((item) => {
                                    return item.id;
                                })
                                .includes(parseInt(value))
                        );

                        const allGroupIndex = documentAccess.selectedUserGroups.findIndex((x) => x.name == "All");
                        const everyOneIndex = documentAccess.selectedUsers.findIndex((x) => x.name == "Everyone");

                        const isHavingAccess =
                            userIndex != -1 ||
                            allGroupIndex != -1 ||
                            everyOneIndex != -1 ||
                            (filteredArray && filteredArray.length > 0);

                        if (!isHavingAccess) {
                            VenusNotifier.Warning(SetAccessConstants.CannotProcessReturn, "");
                            return;
                        }
                    }
                }
                this.props.checkDuplicateTaxReturn(taxReturn.id, taxReturn.engagementType, taxReturn.taxYear, (result) => {
                    if (result == 4 && taxReturn.documentStatus == DocumentStatus.READY) {
                        this.handleActionModalPopup(taxReturn);
                    } else if (result == 3 && taxReturn.documentStatus == DocumentStatus.READY) {
                        this.showImportantModalPopup(taxReturn);
                    } else if (result == 2 && taxReturn.documentStatus == DocumentStatus.READY) {
                        bootbox.confirm({
                            message: ProcessReturnConstants.DuplicateReturnMessage,
                            buttons: {
                                cancel: {
                                    label: '<i class="fas fa-times"></i> Cancel',
                                    className: "btn-white btn-default"
                                },
                                confirm: {
                                    label: '<i class="fas fa-check"></i> OK',
                                    className: "btn-info"
                                }
                            },
                            callback: (result: boolean) => {
                                if (result == true) {
                                    if (this.state.processReturnWindows.find((x) => x.docId == taxReturn.id)) {
                                        VenusNotifier.Warning(ProcessReturnConstants.ReturnAlreadyOpenedMessage, null);
                                        return;
                                    }
                                    //resolved bug - 54122
                                    this.setState({ processReturnModalLoader: true });
                                    this.requestTaxDocumentInfo(
                                        taxReturn.id,
                                        true,
                                        undefined,
                                        undefined,
                                        this.handleRequest,
                                        false,
                                        taxReturn.groupId > 0
                                    );
                                    if (isK1ShareHolder(taxReturn)) {
                                        this.props.getK1SSNDetails(taxReturn.id);
                                    }
                                    if (taxReturn.groupId > 0) {
                                        this.props.requestGroupedReturnDocumentsByGroupId(
                                            taxReturn.groupId,
                                            this.setGroupedReturnDocuments
                                        );
                                        this.props.requestGroupInfo(taxReturn.groupId);
                                    }
                                    return;
                                } else {
                                    this.requestTaxDocumentInfo(
                                        taxReturn.id,
                                        true,
                                        undefined,
                                        undefined,
                                        (reloadedTaxReturn) => {
                                            if (Helper.isProcessable(reloadedTaxReturn)) {
                                                reloadedTaxReturn.documentStatus = DocumentStatus.DUPLICATE;
                                                if (taxReturn.documentStatus !== reloadedTaxReturn.documentStatus) {
                                                    this.props.updateDocumentInitialStatus(
                                                        reloadedTaxReturn,
                                                        undefined,
                                                        undefined,
                                                        resourceId
                                                    );
                                                    this.props.statusChangeEvent(reloadedTaxReturn, true, resourceId);
                                                    if (isK1ShareHolder(taxReturn)) {
                                                        this.props.getK1SSNDetails(taxReturn.id);
                                                    }
                                                    if (taxReturn.groupId > 0) {
                                                        this.props.requestGroupedReturnDocumentsByGroupId(
                                                            taxReturn.groupId,
                                                            this.setGroupedReturnDocuments
                                                        );
                                                        this.props.requestGroupInfo(taxReturn.groupId);
                                                    }
                                                }
                                            }
                                            this.setState({ processReturnModalLoader: false });
                                        },
                                        false,
                                        taxReturn.groupId > 0
                                    );
                                }
                            }
                        });
                    } else {
                        if (this.state.processReturnWindows.find((x) => x.docId == taxReturn.id)) {
                            VenusNotifier.Warning(ProcessReturnConstants.ReturnAlreadyOpenedMessage, null);
                            return;
                        }

                        //resolved bug - 54122
                        this.setState({ processReturnModalLoader: true });
                        this.requestTaxDocumentInfo(
                            taxReturn.id,
                            true,
                            undefined,
                            undefined,
                            this.handleRequest,
                            false,
                            taxReturn.groupId > 0
                        );
                        if (isK1ShareHolder(taxReturn)) {
                            this.props.getK1SSNDetails(taxReturn.id);
                        }
                        if (taxReturn.groupId > 0) {
                            this.props.requestGroupedReturnDocumentsByGroupId(taxReturn.groupId, this.setGroupedReturnDocuments);
                            this.props.requestGroupInfo(taxReturn.groupId);
                        }
                        return;
                    }
                });
            },
            resourceId,
            (error) => {
                this.setState({ processReturnModalLoader: false });
            }
        );
    };

    //private handlesomething = (input: boolean) => {
    //    if()
    //    return true;
    //}

    private handleProcessReturnCancel = (id: number) => {
        let newState = this.state.processReturnWindows.filter((item) => item.docId !== id);
        this.setState({ processReturnWindows: newState });
        this.props.resetTaxDocument(id);
    };

    //=======================================================================================

    private checkIfReturnIsStuckInUploadedOrNonSupported(ids: number[]) {
        let result: boolean = false;
        let listItem: number[] = [];
        ids.forEach((id) => {
            if (
                this.props.taxDocuments[id]?.taxReturn.documentStatus === DocumentStatus.UPLOADED ||
                this.props.taxDocuments[id]?.taxReturn.documentStatus === DocumentStatus.NONSUPPORTED ||
                this.props.taxDocuments[id]?.taxReturn.documentStatus === DocumentStatus.ERROR
            ) {
                listItem.push(1);
            } else {
                listItem.push(0);
            }
        });
        if (listItem.filter((u) => u === 1).length !== 0) {
            return (result = true);
        }
        return result;
    }

    private checkIfReturnIsNotAssignToLoggedinUser(ids: number[]) {
        let result: boolean = false;
        let listItem: number[] = [];
        ids.forEach((id) => {
            if (this.props.taxDocuments[id]?.taxReturn.documentStatus === DocumentStatus.RECALLED) {
                if (this.isAdmin()) {
                    listItem.push(0);
                } else if (this.props.profile?.userId == this.props.taxDocuments[id]?.taxReturn.assignTo) {
                    listItem.push(0);
                } else {
                    listItem.push(1);
                }
            } else if (
                handleIfReturnIsNotAssignToLoggedinUserRequest(
                    this.props.taxDocuments[id]?.taxReturn,
                    this.props.profile,
                    this.isAdmin()
                )
            ) {
                listItem.push(1);
            } else {
                listItem.push(0);
            }
        });
        if (listItem.filter((u) => u === 1).length !== 0) {
            return (result = true);
        }
        return result;
    }

    private checkIfReturnIsInUse(ids: number[]) {
        let result: boolean = false;
        let listItem: number[] = [];
        ids.forEach((id) => {
            if (handleIfReturnIsInUseRequest(this.props.taxDocuments[id]?.taxReturn, this.props.profile, this.isAdmin())) {
                listItem.push(1);
            } else {
                listItem.push(0);
            }
        });
        if (listItem.filter((u) => u === 1).length !== 0) {
            return (result = true);
        }
        return result;
    }

    private onDeleteTaxReturnOpen(rowIndex: number = NO_INDEX, resourceId: string = "") {
        let ids = this.onPopupOpen(rowIndex);

        if (ids.length == 0) {
            VenusNotifier.Warning(DeleteTaxReturnConstants.SelectDocumentWarning, null);
            return;
        }

        if (!this.checkIfReturnIsStuckInUploadedOrNonSupported(ids)) {
            if (this.checkIfReturnIsNotAssignToLoggedinUser(ids)) {
                bootbox.alert({
                    title: DeleteTaxReturnConstants.Title,
                    message: ProcessReturnConstants.ReturnNotAssignedToLoggedInUser,
                    buttons: {
                        ok: {
                            label: '<i class="fas fa-check"></i> OK',
                            className: "btn-info"
                        }
                    }
                });
                return;
            }
            if (this.checkIfReturnIsInUse(ids)) {
                bootbox.alert({
                    title: DeleteTaxReturnConstants.Title,
                    message: DeleteTaxReturnConstants.InUseReturnWarning,
                    buttons: {
                        ok: {
                            label: '<i class="fas fa-check"></i> OK',
                            className: "btn-info"
                        }
                    }
                });
                return;
            }
        }
        let obj = this;
        bootbox.confirm({
            title: DeleteTaxReturnConstants.Title,
            message: DeleteTaxReturnConstants.ConfirmDeletion,
            buttons: {
                cancel: {
                    label: '<i class="fas fa-times"></i> Cancel',
                    className: "btn-white btn-default"
                },
                confirm: {
                    label: '<i class="fas fa-check"></i> Confirm',
                    className: "btn-info"
                }
            },
            callback: (result: boolean) => {
                if (result) {
                    this.onDeleteTaxReturnSave(ids, resourceId);
                }
            }
        });
    }

    private onDeleteCompletion() {
        this.setState({ selectedRows: [], selectedDocumentGuid: "" });
        this.onPageReload();
    }

    private onDeleteTaxReturnSave(ids: number[], resourceId: string = "") {
        this.props.deleteTaxDocument(ids, this.onDeleteCompletion, resourceId);
        this.setState({
            deleteReturnsState: {
                show: false,
                selected: undefined,
                saveCheckIds: ids
            }
        });
    }
    //=======================================================================================

    private onRecallReturnOpen(rowIndex: number) {
        this.setState({
            recallReturnState: {
                show: true,
                model: this.getStoreItem(rowIndex)?.taxReturn,
                saveCheckId: undefined
            }
        });
    }

    private onReprocessReturnOpen = (rowIndex: number, resourceId: string = "") => {
        const item = this.getStoreItem(rowIndex);
        const taxReturn: ITaxReturn = item?.taxReturn;
        this.onRowUnSelect(rowIndex);
        if (!item.isFullyLoaded) {
            this.setState({ reProcessReturnModalLoader: true });
            this.requestTaxDocumentInfo(
                taxReturn.id,
                true,
                undefined,
                undefined,
                (e) => {
                    this.handleReprocessOpen(e.id, resourceId);
                },
                false,
                taxReturn.groupId > 0
            );
            return;
        }
        this.handleReprocessOpen(taxReturn.id, resourceId);
    };

    private handleReprocessOpen = (id: number, resourceId: string = "") => {
        this.setState({ reProcessReturnModalLoader: true }, () => {
            let taxDocument = this.props.taxDocuments[id]?.taxReturn;
            taxDocument.documentStatus = DocumentStatus.REPROCESSING;
            taxDocument.documentSettings.deliverySettings.watermarkId = 0;
            this.props.reprocessTaxDocument(
                taxDocument,
                () => {
                    this.setState({ reProcessReturnModalLoader: false });
                    if (this.state.filterStatus) {
                        let query = this.buildQueryString(this.state.page, this.state.pageSize);
                        this.props.requestAssignments(query, true);
                    }
                    interval = setTimeout(this.getDocumentStatus(id), 10000);
                },
                resourceId
            );
        });
    };
    public isAdmin = () => {
        const user = this.props.auth?.user;
        return user?.profile?.role?.split(",")?.find((element) => element == "Admin") ? true : false;
    };

    private getDocumentStatus(id: number): any {
        let _self = this;
        this.props.requestDocumentStatus(
            id,
            () => {
                if (_self.props.taxDocuments[id]?.taxReturn.documentStatus != DocumentStatus.REPROCESSING) {
                    if (this.state.filterStatus) {
                        let query = this.buildQueryString(this.state.page, this.state.pageSize);
                        this.props.requestAssignments(query, true);
                    }
                    clearTimeout(interval);
                } else {
                    interval = setTimeout(this.getDocumentStatus(id), 10000);
                }
            },
            _self.props.taxDocuments[id]?.taxReturn.groupId > 0
        );
    }

    private onRecallReturnCancel() {
        this.setState({
            recallReturnState: {
                show: false,
                model: undefined,
                saveCheckId: undefined
            }
        });
    }

    private onRecallUploadConfirmation(documentKey: string, clientId: string) {
        const taxDoc = this.state.recallReturnState.model;
        if (taxDoc && taxDoc.documentGuid == documentKey) {
            taxDoc.documentStatus = DocumentStatus.UPLOADED;
            taxDoc.uploadedOn = new Date();
            taxDoc.clientId = clientId;
            this.props.updateTaxDocument(taxDoc);
        }
    }

    //=======================================================================================

    private onClientTrackingOpen(rowIndex: number, resourceId: string = "") {
        let ids = this.onPopupOpen(rowIndex);

        if (ids.length == 0) {
            return;
        }

        this.props.requestTaxDocumentClientTracking(ids[0], resourceId);
        this.setState({
            clientTrackingState: {
                model: this.props.taxDocuments[ids[0]]?.taxReturn,
                show: true,
                saveCheckId: undefined
            }
        });
    }

    private onClientTrackingCancel(rowIndex: number) {
        this.setState({
            clientTrackingState: {
                model: undefined,
                show: false,
                saveCheckId: undefined
            }
        });
    }
    //=======================================================================================

    private onReportProblemOpen(id: number) {
        if (!Helper.isClientInfoLoaded(this.props.taxDocuments[id]?.taxReturn)) {
            this.requestTaxDocumentInfo(
                id,
                true,
                false,
                undefined,
                undefined,
                true,
                this.props.taxDocuments[id]?.taxReturn.groupId > 0
            );
        }

        this.setState({
            reportProblemState: {
                show: true,
                model: this.props.taxDocuments[id]?.taxReturn,
                saveCheckId: undefined
            }
        });
    }

    private onReportProblemCancel() {
        this.setState({
            reportProblemState: {
                show: false,
                model: undefined,
                saveCheckId: undefined
            }
        });
    }

    private onReportProblemSave(id: number, problemDetails: IReportProblemDetails, callback?: () => void) {
        this.props.reportTaxDocumentProblem(
            problemDetails,
            callback,
            SendTaxReturnResources.GridButtonPrefix + SendTaxReturnResources.GridReportAProblemButton
        );
        this.setState({
            reportProblemState: {
                show: true,
                model: undefined,
                saveCheckId: id
            }
        });
    }

    //====================Grouped Report a problem starts here ==============================

    private onGroupedReportProblemOpen = (id: number) => {
        const groupId = this.props.taxDocuments[id]?.taxReturn.groupId;
        const groupReportModel: ITaxReturn = { id: 0, clientId: "" } as ITaxReturn;

        this.setState({
            groupReportProblemState: {
                show: true,
                model: groupReportModel,
                saveCheckId: groupId
            }
        });
    };

    private onGroupedReportProblemCancel = () => {
        this.setState({
            groupReportProblemState: {
                show: false,
                model: undefined,
                saveCheckId: undefined
            }
        });
    };

    private onGroupedReportProblemSave = (id: number, problemDetails: IReportProblemDetails, callback?: () => void) => {
        let groupId: number = this.state.groupReportProblemState.saveCheckId ? this.state.groupReportProblemState.saveCheckId : 0;
        if (problemDetails.description.trim() === "") {
            VenusNotifier.Warning("Please enter problem description to report.", null);
        } else {
            if (callback)
                this.props.reportDocumentGroupProblem(
                    problemDetails,
                    groupId,
                    callback,
                    SendTaxReturnResources.GridButtonPrefix + SendTaxReturnResources.GridReportAProblemButton
                );
            else
                this.props.reportDocumentGroupProblem(
                    problemDetails,
                    groupId,
                    undefined,
                    SendTaxReturnResources.GridButtonPrefix + SendTaxReturnResources.GridReportAProblemButton
                );

            this.setState({
                groupReportProblemState: {
                    show: false,
                    model: undefined,
                    saveCheckId: undefined
                }
            });
        }
    };

    //========================Grouped Report a problem ends here=======================================

    private onPopupOpen(rowIndex: number, documentFilterType: DocumentFilterType = DocumentFilterType.All) {
        let ids = [rowIndex];
        if (rowIndex !== NO_INDEX) {
            return [this.props.currentPage.assignments[rowIndex].id];
        }
        if (this.state.selectedRows.length == 0) {
            return [];
        }
        return this.selectedDocumentIds(documentFilterType);
    }

    private selectedDocuments() {
        let docs: ITaxReturn[] = [];
        for (var i in this.state.selectedRows) {
            let row = this.state.selectedRows[i];
            docs.push(this.props.currentPage.assignments[row]);
        }
        return docs;
    }

    private lazyLoadSelected(ids: number[]) {
        ids.forEach((id, i) => {
            this.props.requestTaxDocument(id, false);
        });
    }

    loadSelectedGroupedReturns = (ids: number[], callback: (groupedReturns: ITaxReturn[]) => void) => {
        var promises: any[] = [];
        var taxReturns: ITaxReturn[] = [];
        ids.forEach((id) => {
            var promise: any = new Promise((resolve) => {
                this.props.requestTaxDocument(id, true, undefined, undefined,
                    (taxReturn) => {
                        taxReturns.push(taxReturn);
                        resolve(true);
                    },
                    undefined);
            });
            promises.push(promise);
        });
        Promise.all(promises).then(() => {
            callback(taxReturns);
        });
    };

    public getUserModel(id: number) {
        for (var i in this.props.ssrUsers) {
            if (this.props.ssrUsers[i]?.id == id) {
                var user: IUserModel = {
                    userId: this.props.ssrUsers[i].id,
                    firstName: this.props.ssrUsers[i].firstName,
                    lastName: this.props.ssrUsers[i].lastName,
                    email: this.props.ssrUsers[i].emailAddress,
                    middleName: "",
                    phoneNumber: "",
                    extension: "",
                    ptin: "",
                    confirmEmail: "",
                    faxNumber: "",
                    title: "",
                    customUserGroup: [],
                    userGroups: [],
                    authenticationProviders: [],
                    metadata: "",
                    isDeceased: false,
                    mobileNumber: "",
                    countryCode: "",
                    isMobileVerify: false,
                    userSettings: {
                        defaultSettings: {
                            manualAddressId: 0,
                            contactPerson: 0,
                            eroUser: 0,
                            notifyUser: 0,
                            reportFilter: "",
                            sender: {
                                senderId: 0,
                                senderType: DefaultSenderInfoType.None
                            },
                            download: {
                                downloadType: DefaultDownloadInfoType.PromptMeEveryTime,
                                rememberDefaultDownload: false
                            },
                            enableLastLogin: false,
                            customColumnValue: 0,
                            showDefaultWarning: false
                        },
                        notificationSettings: {
                            isApprovedForDelivery: false,
                            isReturnAssigned: false,
                            isReturnReady: false,
                            lastPersonNotified: 0,
                            paymentNotification: false
                        },
                        signatureSettings: {
                            isAutoPlaceSignature: false,
                            isEnableSignatureDelegation: false,
                            signaturePath: ""
                        },
                        enableSignerDeligation: false,
                        useSignatureStamp: false,
                        userId: 0,
                        signatureDelegation: {
                            delegate: [],
                            userId: 0
                        },
                        mfaSettings: {
                            isMfaEnabled: false
                        }
                    },
                    lastLogin: moment.utc("0001-01-01"),
                    userReviewStatus: UserReviewStatus.NotApplicable,
                    revokeStatus: SSORevokeStatus.None
                };
                return user;
            }
        }
        this.props.requestSSRUsersList(true);
        throw "no user found with id:" + id;
    }

    private selectedDocumentIds(documentFilterType: DocumentFilterType = DocumentFilterType.All) {
        let ids: number[] = [];
        switch (documentFilterType) {
            case DocumentFilterType.All:
                for (var i in this.state.selectedRows) {
                    let row = this.state.selectedRows[i];
                    ids.push(this.props.currentPage.assignments[row].id);
                }
                break;
            case DocumentFilterType.StandardReturns:
                for (var i in this.state.selectedRows) {
                    let row = this.state.selectedRows[i];
                    if (
                        !this.props.currentPage.assignments[row].groupId ||
                        this.props.currentPage.assignments[row].groupId <= 0
                    ) {
                        ids.push(this.props.currentPage.assignments[row].id);
                    }
                }
                break;
            case DocumentFilterType.GroupedReturn:
                for (var i in this.state.selectedRows) {
                    let row = this.state.selectedRows[i];
                    if (this.props.currentPage.assignments[row].groupId > 0) {
                        ids.push(this.props.currentPage.assignments[row].id);
                    }
                }
                break;
        }
        return ids;
    }

    private setSelectedDocumentsGuid() {
        let documentGuids: string[] = [];
        this.state.selectedRows.map((row, i) => {
            let selectedDocument = this.props.currentPage.assignments[row];
            if (selectedDocument) {
                documentGuids.push(selectedDocument.documentGuid);
            }
        });
        this.setState({
            selectedDocumentGuid: documentGuids.join(",")
        });
    }

    private getStoreItem(index: number) {
        let id = this.props.currentPage.assignments[index].id;
        return this.props.taxDocuments[id];
    }

    private onUpdateCompletion(
        taxDocuments: TaxDocumentStore.ITaxDocumentDictionary,
        stateKey: string,
        state: IPopupStateSingle
    ) {
        if (state.saveCheckId && isLoaded(taxDocuments, [state.saveCheckId])) {
            if (stateKey != "reportProblemState") {
                if (taxDocuments[state.saveCheckId].error) {
                    VenusNotifier.Error(errorMsg[stateKey], stateKey);
                    this.requestTaxDocumentInfo(
                        state.saveCheckId,
                        true,
                        false,
                        undefined,
                        undefined,
                        false,
                        taxDocuments[state.saveCheckId]?.taxReturn.groupId > 0
                    );
                } else {
                    VenusNotifier.Success(successMsg[stateKey], stateKey);
                }
            }

            this.setState((prevState) => ({
                ...prevState,
                [stateKey]: {
                    show: false,
                    model: undefined,
                    saveCheckId: undefined
                }
            }));
        }
    }

    private onUpdateCompletionMultiple(
        taxDocuments: TaxDocumentStore.ITaxDocumentDictionary,
        stateKey: string,
        state: IPopupStateMultiple
    ) {
        if (state.saveCheckIds && isLoaded(taxDocuments, state.saveCheckIds)) {
            let err = false;
            let customErrMessage = null; //Handling Custom Error message from server side.
            state.saveCheckIds.map((id, i) => {
                if (taxDocuments[id] && taxDocuments[id].error) {
                    if (taxDocuments[id].message) {
                        customErrMessage = taxDocuments[id].message;
                    }
                    this.requestTaxDocumentInfo(
                        id,
                        true,
                        false,
                        undefined,
                        undefined,
                        false,
                        taxDocuments[id].taxReturn.groupId > 0
                    );
                    err = true;
                }
            });
            if (err) {
                if (customErrMessage) VenusNotifier.Error(customErrMessage, stateKey);
                else VenusNotifier.Error(errorMsg[stateKey], stateKey);
            } else {
                VenusNotifier.Success(successMsg[stateKey], stateKey);
            }
            this.setState((prevState) => ({
                ...prevState,
                [stateKey]: {
                    show: false,
                    model: undefined,
                    saveCheckId: undefined
                }
            }));
        }
    }

    private checkForPopupCompletion(taxDocuments: TaxDocumentStore.ITaxDocumentDictionary) {
        this.onUpdateCompletion(taxDocuments, "editClientInfoState", this.state.editClientInfoState);
        this.onUpdateCompletion(taxDocuments, "changeStatusState", this.state.changeStatusState);
        this.onUpdateCompletion(taxDocuments, "reportProblemState", this.state.reportProblemState);

        this.onUpdateCompletionMultiple(taxDocuments, "assignReturnsState", this.state.assignReturnsState);
        this.onUpdateCompletionMultiple(taxDocuments, "deleteReturnsState", this.state.deleteReturnsState);
        this.onUpdateCompletionMultiple(taxDocuments, "setAccessState", this.state.setAccessState);
    }

    private updateStates(props: IAssignmentsProps) {
        if (this.state.editClientInfoState.model) {
            this.setState({
                editClientInfoState: {
                    ...this.state.editClientInfoState,
                    model: props.taxDocuments[this.state.editClientInfoState.model.id]?.taxReturn
                }
            });
        }
        if (this.state.changeStatusState.model) {
            this.setState({
                changeStatusState: {
                    ...this.state.changeStatusState,
                    model: props.taxDocuments[this.state.changeStatusState.model.id]?.taxReturn
                }
            });
        }

        if (this.state.reportProblemState.model) {
            this.setState({
                reportProblemState: {
                    ...this.state.reportProblemState,
                    model: props.taxDocuments[this.state.reportProblemState.model.id]?.taxReturn
                }
            });
        }
        if (this.state.recallReturnState.model && props.taxDocuments[this.state.recallReturnState.model.id]) {
            this.setState({
                recallReturnState: {
                    ...this.state.recallReturnState,
                    model: props.taxDocuments[this.state.recallReturnState.model.id]?.taxReturn
                }
            });
        }
    }

    //=======================================================================================

    onDeliverGroupOpen = (rowIndex: number = NO_INDEX) => {
        let ids = this.onPopupOpen(rowIndex);
        this.deliverSelectedGroupedReturns(ids);
    };

    private deliverSelectedGroupedReturns = (ids: number[]) => {
        ShowLoader();
        this.loadSelectedGroupedReturns(ids, (groupedReturns: ITaxReturn[]) => {
            this.props.requestGroupInfo(groupedReturns[0].groupId, (groupInfo: IGroupInfo) => {
                if (groupInfo) {
                    HideLoader();
                    this.setState({
                        groupInfoState: groupInfo,
                        deliverGroupState: {
                            show: true,
                            selected: groupedReturns,
                            saveCheckIds: undefined
                        }
                    });
                }
            });
        });
    };

    private onDeliverGroupSaveCompletion = () => {
        let ids = this.selectedDocumentIds();
        this.setState(
            {
                deliverGroupState: {
                    show: false,
                    selected: undefined,
                    saveCheckIds: ids
                },
                selectedRows: [],
                selectedDocumentGuid: "",
                groupInfoState: Object.assign({}, initialGroupInfo)
            },
            this.onPageReload
        );
    };

    private onDeliverGroupCancel = () => {
        this.setState({
            deliverGroupState: {
                show: false,
                selected: undefined,
                saveCheckIds: undefined
            },
            groupInfoState: Object.assign({}, initialGroupInfo)
        });
    };

    //=======================================================================================

    //Group Configuration

    onGroupConfigurationOpen = (rowIndex: number = NO_INDEX) => {
        let ids = this.onPopupOpen(rowIndex);
        this.handleGroupConfigurationOpenRequest(ids);
    };

    private handleGroupConfigurationOpenRequest = (ids: number[]) => {
        if (ids.length == 0) {
            VenusNotifier.Warning(GroupReturnConstants.SelectDocumentWarning, null);
            return;
        }
        this.lazyLoadSelected(ids);
        let selectedAssignments = ids.map((id, i) => {
            return this.props.taxDocuments[id]?.taxReturn;
        });
        this.setState({
            groupConfigurationState: {
                show: true,
                selected: selectedAssignments,
                saveCheckIds: undefined
            }
        });
    };

    private onGroupCreateNew = (groupInfo: IGroupInfo) => {
        let ids = this.selectedDocumentIds(DocumentFilterType.GroupedReturn);
        if (ids.length > 0) {
            this.props.unMapReturnsFromGroup(
                this.selectedDocumentIds(DocumentFilterType.GroupedReturn),
                this.onNewGroupUnMapReturnsCallback,
                groupInfo,
                undefined,
                SendTaxReturnResources.HeaderButtonPrefix + SendTaxReturnResources.HeaderGroupReturnButton
            );
        } else {
            this.addToNewGroup(groupInfo);
        }
    };

    private onNewGroupUnMapReturnsCallback = (groupInfo?: IGroupInfo) => {
        if (groupInfo) {
            this.addToNewGroup(groupInfo);
        }
    };

    private addToNewGroup = (groupInfo: IGroupInfo) => {
        this.setState({ mergeGroupAddedAndMapReturnToGroupMessage: true });
        logger.trackTrace(`Assignments group actions: addToNewGroup started for groupId: ${groupInfo.id}`, {
            groupId: groupInfo?.id,
            GroupName: groupInfo?.name
        });
        this.props.addGroup(
            groupInfo,
            this.mapReturntoGroup,
            this.onGroupConfigurationFailure,
            true,
            SendTaxReturnResources.HeaderButtonPrefix + SendTaxReturnResources.HeaderGroupReturnButton
        );
    };

    private onAddtoExistingGroup = (groupid: number) => {
        let ids = this.selectedDocumentIds(DocumentFilterType.GroupedReturn);
        let groupInfo = initialGroupInfo;
        groupInfo.id = groupid;
        if (ids.length > 0) {
            logger.trackTrace(`Assignments group actions: onAddtoExistingGroup started for groupId: ${groupid}`, {
                groupId: groupid
            });
            this.props.unMapReturnsFromGroup(
                this.selectedDocumentIds(DocumentFilterType.GroupedReturn),
                this.onExistingGroupUnMapReturnsCallback,
                groupInfo,
                undefined,
                SendTaxReturnResources.HeaderButtonPrefix + SendTaxReturnResources.HeaderGroupReturnButton
            );
        } else {
            this.mapReturntoGroup(groupid);
        }
    };

    private onExistingGroupUnMapReturnsCallback = (groupInfo?: IGroupInfo) => {
        logger.trackTrace(
            `Assignments group actions: unMapReturnsFromGroup updated successfully for groupId: ${groupInfo?.id?.toString()}`,
            { groupId: groupInfo?.id?.toString(), groupName: groupInfo?.name }
        );
        if (groupInfo) {
            this.mapReturntoGroup(groupInfo.id);
        }
    };

    private mapReturntoGroup = (groupId: number) => {
        let ids = this.selectedDocumentIds();
        logger.trackTrace(`Assignments group actions: mapReturnstoGroup started for groupId: ${groupId}`, { groupId: groupId });
        this.props.mapReturnstoGroup(
            ids,
            groupId,
            this.onGroupConfigurationSaveCompletion,
            this.onGroupConfigurationFailure,
            this.state.mergeGroupAddedAndMapReturnToGroupMessage,
            SendTaxReturnResources.HeaderButtonPrefix + SendTaxReturnResources.HeaderGroupReturnButton
        );
        this.setState({ mergeGroupAddedAndMapReturnToGroupMessage: false });
    };

    private onGroupConfigurationSaveCompletion = () => {
        let ids = this.selectedDocumentIds();
        this.setState(
            {
                groupConfigurationState: {
                    show: false,
                    selected: undefined,
                    saveCheckIds: ids
                },
                selectedRows: [],
                selectedDocumentGuid: ""
            },
            this.onPageReload
        );
    };
    private onGroupConfigurationFailure = () => {
        let ids = this.selectedDocumentIds();
        this.setState({
            groupConfigurationState: {
                show: false,
                selected: undefined,
                saveCheckIds: ids
            },
            selectedRows: [],
            selectedDocumentGuid: ""
        });
    };

    private onGroupConfigurationCancel = () => {
        this.setState({
            groupConfigurationState: {
                show: false,
                selected: undefined,
                saveCheckIds: undefined
            }
        });
    };

    private UnMapGroupedReturns = () => {
        this.props.unMapReturnsFromGroup(
            this.selectedDocumentIds(DocumentFilterType.GroupedReturn),
            this.onUnMapGroupedReturnsCompletion,
            undefined,
            undefined,
            SendTaxReturnResources.HeaderButtonPrefix + SendTaxReturnResources.HeaderGroupReturnButton
        );
    };

    private onUnMapGroupedReturnsCompletion = () => {
        VenusNotifier.Success(SendGroupedReturnsHeaderConstants.StatusMessage.UnMapReturnToGroupSuccess, null);
        let ids = this.selectedDocumentIds();
        this.setState(
            {
                groupConfigurationState: {
                    show: false,
                    selected: undefined,
                    saveCheckIds: ids
                },
                selectedRows: [],
                selectedDocumentGuid: ""
            },
            this.onPageReload
        );
    };

    private isDisableUnGroupReturn() {
        if (!this.state.groupConfigurationState.selected) return true;

        return this.selectedDocumentIds(DocumentFilterType.GroupedReturn).length === 0;
    }

    //End of Group Configuration

    //=====================================================================================

    private requestTaxDocumentInfo = (
        id: number,
        force: boolean,
        requestTaxPayerView?: boolean,
        clientType?: ClientTypes,
        callback?: (data: any) => void,
        isEditClientInfoRequest?: boolean,
        isGroupedReturn?: boolean
    ) => {
        if (isGroupedReturn === true) {
            this.props.requestGroupedTaxDocument(id, force, requestTaxPayerView, clientType, callback, isEditClientInfoRequest);
        } else {
            this.props.requestTaxDocument(id, force, requestTaxPayerView, clientType, callback, isEditClientInfoRequest);
        }
    };
}
export default Assignments;

//Generic Methods
function isLoaded(allReturns: TaxDocumentStore.ITaxDocumentDictionary, ids: number[]) {
    for (var i in ids) {
        if (allReturns[ids[i]] && allReturns[ids[i]].isLoading) {
            return false;
        }
    }
    return true;
}
function isReturnsLoaded(allReturns: TaxDocumentStore.ITaxDocumentDictionary, returns: ITaxReturn[]) {
    for (var i in returns) {
        let id = returns[i].id;
        if (allReturns[id].isLoading) {
            return false;
        }
    }
    return true;
}

function handleIfReturnIsNotAssignToLoggedinUserRequest(taxReturn: ITaxReturn, profile: IUserProfile, isAdmin: boolean) {
    if (
        taxReturn.documentStatus !== DocumentStatus.READY &&
        taxReturn.lockedBy <= 0 &&
        taxReturn.assignTo !== profile?.userId &&
        !isAdmin
    ) {
        return true;
    } else if (
        taxReturn.documentStatus !== DocumentStatus.READY &&
        taxReturn.lockedBy > 0 &&
        taxReturn.assignTo !== profile?.userId &&
        !isAdmin
    ) {
        return true;
    }
    return false;
}

function handleIfReturnIsInUseRequest(taxReturn: ITaxReturn, profile: IUserProfile, isAdmin: boolean) {
    if (
        taxReturn.documentStatus !== DocumentStatus.READY &&
        taxReturn.lockedBy > 0 &&
        taxReturn.assignTo !== profile?.userId &&
        isAdmin
    ) {
        return true;
    } else if (
        taxReturn.documentStatus !== DocumentStatus.READY &&
        taxReturn.lockedBy > 0 &&
        taxReturn.assignTo === profile?.userId
    ) {
        return false;
    }

    return false;
}
