import * as React from "react";
import { Card, Row, Col, Button } from "react-bootstrap";
import * as TaxDocument from "../../../common/TaxReturn";
import { phoneNumberDisplay, isIndividualEnagagementType } from "../../../helper/HelperFunctions";
import { DeliverDraftReturnConstants, FinishProcessReturn } from "../../../helper/Constants";
import * as bootbox from "bootbox";
import { EngagementType } from "../../../common/TaxReturn";
import { disableExtensionReminders, IsDuplicateExtension } from "../../../../store/common/ExtensionStore";
import { SendTaxReturnResources } from "../../../helper/ResourceIdConstants";
import * as Helper from "../../../helper/HelperFunctions";
import ReactDOMServer from "react-dom/server";
import CloseIcon from "src/assets/images/CloseIcon";

interface IDeliverToCompany {
    taxreturn: any;
    cancelFinish: () => void;
    deliverToClient: (resourceId?: string) => void;
    draftStatus: boolean;
}

interface IDeliverToCompanyState {
    sendButtonClicked: boolean;
    popupButtonClicked: boolean;
}

export class DeliverToCompany extends React.Component<IDeliverToCompany, IDeliverToCompanyState> {
    constructor(props) {
        super(props);
        this.state = {
            sendButtonClicked: false,
            popupButtonClicked: false
        };
    }

    deliveryActionButton = (callback: any) => { 
        if (this.state.popupButtonClicked == false) {
            this.setState({
                popupButtonClicked:true 
            }, () => { 
                callback();
            })
        }
    }

    public onDeliverToCompany = () => {
        if ((!(this.props.taxreturn.groupId > 0) && Helper.validateDocumentLocation(this.props.taxreturn)) || this.props.taxreturn.groupId > 0) {
            if (this.props.draftStatus) {
                this.showDraftAttentionPopUp();
            } else {
                this.setState({ sendButtonClicked: true });
                this.handleDuplicateExtensionCheck();
            }
        }
    };

    private showDraftAttentionPopUp = () =>{
        bootbox.confirm({
            title: ReactDOMServer.renderToStaticMarkup(
                <>
                    <span>{DeliverDraftReturnConstants.ConfirmationTitle}</span>
                    <span id="custom-close-icon">
                        <CloseIcon className="custom-close-icon" color="#212529" />
                    </span>
                </>
            ),
            backdrop: true,
            closeButton: false,
            message: ReactDOMServer.renderToStaticMarkup(DeliverDraftReturnConstants.Message),
            className: "custom-draft-modal",
            buttons: {
                cancel: {
                    label: DeliverDraftReturnConstants.LeftButton,
                    className: "draftAttentionBtn draftAttentionBtn-left"
                },
                confirm: {
                    label: DeliverDraftReturnConstants.RightButton,
                    className: "draftAttentionBtn draftAttentionBtn-right"
                }
            },
            callback: (result: boolean) => {
                if (result) {
                    this.setState({ sendButtonClicked: true });
                    this.handleDuplicateExtensionCheck();
                } else {
                    return;
                }
            }
        });

        setTimeout(() => {
            const handleModalClose = (event: Event) => {
                event.stopPropagation();
                removeCustomModal();
            };
            const addClickListener = (
                selector: string,
                handler: (event: Event) => void,
                allowCondition?: (event: Event) => boolean
            ) => {
                const element = document.querySelector(selector);
                if (element) {
                    element.addEventListener("click", (event) => {
                        if (!allowCondition || allowCondition(event)) {
                            handler(event);
                        }
                    });
                }
            };

            addClickListener("#custom-close-icon", handleModalClose);
            addClickListener(".custom-draft-modal", handleModalClose);
            addClickListener(
                ".custom-draft-modal .modal-content",
                (event) => event.stopPropagation(),
                (event) => !(event.target as HTMLElement).closest("button")
            );
        }, 0);

        const removeCustomModal = () => {
            document.querySelector(".custom-draft-modal")?.remove();
            document.querySelectorAll(".modal-backdrop").forEach(backdrop => backdrop.remove());
        };
    }

    private handleDuplicateExtensionCheck = () => {
        let taxIdentifier = isIndividualEnagagementType(this.props.taxreturn.engagementType)
        ? this.props.taxreturn.taxpayer.ssn
            : this.props.taxreturn.partnership.ssn;
        let tempThis = this;
        IsDuplicateExtension(taxIdentifier, EngagementType[this.props.taxreturn.engagementType], this.props.taxreturn.taxYear)
        .then((isDuplicate) => {
            if (isDuplicate) {
                bootbox.dialog({
                    message: FinishProcessReturn.StatusMessage.DisableExtensionRemindersConfirmation.replace(
                        "<<taxyear>>",
                        this.props.taxreturn.taxYear
                    ),
                    className: "extensions-modal",
                    buttons: {
                        cancel: {
                            label: "No",
                            className: "btn-cancel btn-default",
                            callback: function () {
                                tempThis.deliveryActionButton(() =>
                                    tempThis.props.deliverToClient(
                                        SendTaxReturnResources.Prefix + SendTaxReturnResources.DeliverToCompanyMenu
                                    ));
                            }
                        },
                        confirm: {
                            label: "Yes, turn it off",
                            className: "btn-confirm",
                            callback: function () {
                                tempThis.deliveryActionButton(() => {
                                    disableExtensionReminders(
                                        taxIdentifier,
                                        EngagementType[tempThis.props.taxreturn.engagementType],
                                        tempThis.props.taxreturn.taxYear,
                                        tempThis.props.taxreturn.id
                                    );
                                    tempThis.props.deliverToClient(
                                        SendTaxReturnResources.Prefix + SendTaxReturnResources.DeliverToCompanyMenu
                                    );
                                });
                            }
                        }
                    }
                });
            } else {
                this.props.deliverToClient(SendTaxReturnResources.Prefix + SendTaxReturnResources.DeliverToCompanyMenu);
            }
        })
        .catch((error) => {
            this.props.deliverToClient(SendTaxReturnResources.Prefix + SendTaxReturnResources.DeliverToCompanyMenu);
        });
    }

    public render() {
        return (
            <Card>
                <Card.Body>
                    {TaxDocument.isPartnership(this.props.taxreturn) ? (
                        <div>
                            <Row className="marB15">
                                <Col className="nopadding-right" sm={3} as={Col}>
                                    Company Name
                                </Col>
                                <Col className="nopadding-left" sm={9} as={Col}>
                                    {this.props.taxreturn.partnership.name}
                                </Col>
                            </Row>
                            <Row className="marB15">
                                <Col className="nopadding-right" sm={3} as={Col}>
                                    Company EIN
                                </Col>
                                <Col className="nopadding-left" sm={9} as={Col}>
                                    {this.props.taxreturn.partnership.ssn}
                                </Col>
                            </Row>
                            <Row className="marB15">
                                <Col className="nopadding-right" sm={3} as={Col}>
                                    Company Email
                                </Col>
                                <Col className="nopadding-left" sm={9} as={Col}>
                                    {this.props.taxreturn.partnership.email}
                                </Col>
                            </Row>
                            {this.props.taxreturn.partnership.mobileNumber && (
                                <Row className="marB15">
                                    <Col className="nopadding-right" sm={3} as={Col}>
                                        Mobile Number
                                    </Col>
                                    <Col className="nopadding-left" sm={9} as={Col}>
                                        {this.props.taxreturn.partnership.countryCode}{" "}
                                        {phoneNumberDisplay(this.props.taxreturn.partnership.mobileNumber)}
                                    </Col>
                                </Row>
                            )}
                        </div>
                    ) : (
                        ""
                    )}
                    <div>
                        <Col sm={12}>
                            <Button
                                type="button"
                                variant="info"
                                className="float-right"
                                data-test-auto="DD34A32B-935B-4D21-AF90-0989DE8BFD3E"
                                onClick={this.onDeliverToCompany}
                                disabled={this.state.sendButtonClicked}
                            >
                                <i className="fa fa-paper-plane"></i>Send
                            </Button>
                            <Button
                                type="button"
                                variant="default"
                                className="float-right marR05 btn-white btn-default"
                                data-test-auto="EAB6FEF8-0152-495A-ACAC-F07B48F6BF09"
                                onClick={this.props.cancelFinish}
                            >
                                <i className="fas fa-times"></i>Cancel
                            </Button>
                        </Col>
                    </div>
                </Card.Body>
            </Card>
        );
    }
}

export default DeliverToCompany;
