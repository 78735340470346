import * as React from "react";
import { RouteComponentProps } from "react-router";
import { IUserProfileModel } from '../../components/navigation/profile/ProfileObjects';
import * as CompanyAssignmentsStore from "../../store/assignments/CompanyAssignmentsStore";
import * as TaxDocumentStore from "../../store/common/TaxDocumentStore";
import * as UserStore from "../../store/userManagement/UserStore";
import * as PdfStore from "../../store/pdf/PdfStore";
import * as CompanyStore from "../../store/company/CompanyStore";
import * as UserProfileStore from "../../store/userManagement/UserProfileStore";
import * as UserSettingStore from "../../store/userManagement/UserSettingStore";
import * as MailingReturnAddressStore from "../../store/common/MailingReturnAddressStore";
import * as SavedMessageStore from "../../store/settings/SavedMessageStore";
import * as UserSignatureStore from "../../store/common/UserSignatureStore";
import * as AttachmentInstructionStore from "../../store/settings/AttachmentInstructionStore";
import * as GroupedTaxDocumentStore from "../../store/groupedreturns/GroupedTaxDocumentStore";
import * as SendGroupedReturnsStore from "../../store/reports/GroupedReturns/SendGroupedReturnsStore";
import { IAssignmentsState } from "../../store/assignments/AssignmentsState";
import { Assignments, IPopupStateMultiple } from "./Assignments";
import { ITaxReturn } from "../common/TaxReturn";
import { IUserProfile } from "../navigation/profile/ProfileObjects";
import * as AttachmentsStore from "../../store/ProcessReturn/AttachmentsStore";
import * as AttachmentsState from "../../store/ProcessReturn/AttachmentsState";
import * as UploadStore from "../../store/common/Upload";
import * as DeliveredReturnsStore from "../../store/reports/DeliveredReturnsStore";
import * as DeliveredReturnsState from "../../store/reports/DeliveredReturnsState";
import * as DownloadStore from "../../store/common/Download";
import * as VoucherStore from "../../store/ProcessReturn/voucher/VoucherStore";
import * as UploadTaxReturnStore from "../../store/common/uploadTaxReturn";
import * as BusinessReturnsStore from "../../store/settings/BusinessReturnsStore";
import * as FilterStore from "../../store/reports/FilterStore";
import * as AdditionalEsignDocumentStore from "../../store/ProcessReturn/AdditionalEsignDocuments/AdditionalEsignDocumentStore";
import * as AdditionalDocumentTypeStore from "../../store/common/AdditionalDocumentTypeStore";
import * as WatermarkStore from "../../store/settings/WatermarkStore";
import * as GroupedReturnStore from "../../store/groupedreturns/GroupedReturnStore";
import { IGroupAccess } from "../GroupedReturns/GroupConfiguration";
import * as CustomColumnStore from "../../store/common/CustomColumnStore";
import { DefaultDownloadInfoType } from "../navigation/profile/MySettings";
import * as BulkOperationsStore from "../../store/common/BulkOperationsStore";
import { BulkOperationQuery } from "../../Core/ViewModels/Common/BulkOperationQuery";
import { ReportFilterType } from "../reports/Filters";
import { DownloadTypeOption } from "../reports/DeliveredReturns/DownloadTypeOption";
import * as PaperFileStore from "../../store/ProcessReturn/PaperFileStore";
import * as PaperFileMessageStore from "../../store/settings/PaperFiledMessageStore";
import { SetAccessType } from "../../store/reports/GroupedReturns/SendGroupedReturnsState";
import * as K1AttachmentStore from "../../store/ProcessReturn/K1AttachmentStore";
import * as SSRSettingsStore from "../../store/company/settings/SSRSettingsStore";
import { IBlobFile } from "../../store/common";
import { CommonResources, SendTaxReturnResources } from "src/components/helper/ResourceIdConstants";
import { IAuthState } from "../../store/auth/reducer";
import { ISuiteTaxSoftwareSetting } from "../../Core/ViewModels/Company/CompanySettingsViewModel";
import * as OfficeLocationStore from "../../store/common/OfficeLocationStore";
import * as SMSNotificationDetailsStore from "../../store/common/SMSNotificationDetailsStore";
import * as ClientManagementSettingStore from "../../store/company/SuiteClientManagementSettingStore";
import * as K1SSNStore from "../../store/ProcessReturn/K1SSNStore";
import * as MyAccountStore from "../../store/userManagement/MyAccountStore";
import * as GroupedReturnProcessStore from "../../store/groupedreturns/GroupedReturnProcessStore";
import { IGroupLevelReturnDictionary } from "../../store/groupedreturns/GroupedReturnProcessState";
import * as ApplicationHelptextStore from '../../store/common/ApplicationHelptextStore';

type CompanyAssignmentsProps = {
    assignments: IAssignmentsState;
    profile: IUserProfile;
    taxDocuments: TaxDocumentStore.ITaxDocumentDictionary;
    userStore: UserStore.ISSRUserData;
    ssrUsers: UserStore.ISSRUserListData;
    pdfDocuments: PdfStore.IPdfDocumentDictionary;
    company: CompanyStore.ICompanyData;
    attachments: AttachmentsState.AttachmentsState;
    uploadStore: UploadStore.IUploadState;
    userSettings: UserSettingStore.UserSettings;
    mailingReturnAddresses: MailingReturnAddressStore.IMailingReturnAddressStoreState;
    savedMessages: SavedMessageStore.SavedMessageStoreState;
    deliveredReturns: DeliveredReturnsState.DeliveredReturnsState;
    downloadStore: DownloadStore.IDownloadState;
    userSignatures: UserSignatureStore.IUserSignatures;
    voucherStore: VoucherStore.IVoucherState;
    uploadTaxReturnStore: UploadTaxReturnStore.IUploadTaxReturnState;
    businessReturnInstructions: BusinessReturnsStore.IBusinessReturnsState;
    attachmentInstructionList: AttachmentInstructionStore.IAttachmentInstructionState;
    reportFilters: FilterStore.IFilterState;
    additionalEsignDocument: IBlobFile;
    additionalDocumentTypeData: AdditionalDocumentTypeStore.IDocumentTypeState;
    watermarkStore: WatermarkStore.IWatermarkSettingsState;
    customColumnState: CustomColumnStore.ICustomColumnState;
    groupedReturnStore: GroupedReturnStore.IGroupInfoState;
    paperFileStore: IBlobFile;
    paperFileMessage: PaperFileMessageStore.PaperFiledMessageStoreState;
    k1AttachmentStore: IBlobFile;
    userResources: UserStore.IUserResources;
    auth: IAuthState;
    taxSoftwareSetting: ISuiteTaxSoftwareSetting;
    officeLocation: OfficeLocationStore.IOfficeLocationState;
    smsNotificationDetails: SMSNotificationDetailsStore.ISMSNotificationDetails;
    clientManagementSetting: ClientManagementSettingStore.IClientManagementSetting;
    k1SSNDetails: K1SSNStore.IK1SSNDictionary;
    pageWiseSSNDetails: K1SSNStore.IPageWiseK1SSNDictionary;
    userDetails: IUserProfileModel;
    groupedReturnProcessState: IGroupLevelReturnDictionary;
    helpTextState: ApplicationHelptextStore.IHelptextState;
    userAssignDeliveryPermission: UserStore.IDeliveryPermission;
} & typeof CompanyAssignmentsStore.actionCreators &
    typeof UserProfileStore.actionCreators &
    typeof TaxDocumentStore.actionCreators &
    typeof FilterStore.actionCreators &
    typeof UserStore.actionCreators &
    typeof PdfStore.actionCreators &
    typeof CompanyStore.actionCreators &
    typeof UserSettingStore.actionCreators &
    typeof MailingReturnAddressStore.actionCreators &
    typeof AttachmentsStore.actionCreators &
    typeof UploadStore.actionCreators &
    typeof SavedMessageStore.actionCreators &
    typeof DeliveredReturnsStore.actionCreators &
    typeof DownloadStore.actionCreators &
    typeof UserSignatureStore.actionCreators &
    typeof VoucherStore.actionCreators &
    typeof UploadTaxReturnStore.actionCreators &
    typeof BusinessReturnsStore.actionCreators &
    typeof AttachmentInstructionStore.actionCreators &
    typeof AdditionalEsignDocumentStore.actionCreators &
    typeof AdditionalDocumentTypeStore.actionCreators &
    typeof WatermarkStore.actionCreators &
    typeof GroupedReturnStore.actionCreators &
    typeof CustomColumnStore.actionCreators &
    typeof BulkOperationsStore.actionCreators &
    typeof PaperFileStore.actionCreators &
    typeof PaperFileMessageStore.actionCreators &
    typeof K1AttachmentStore.actionCreators &
    typeof SSRSettingsStore.actionCreators &
    typeof OfficeLocationStore.actionCreators &
    typeof SMSNotificationDetailsStore.actionCreators &
    typeof ClientManagementSettingStore.actionCreators &
    typeof GroupedTaxDocumentStore.actionCreators &
    typeof SendGroupedReturnsStore.actionCreators &
    typeof K1SSNStore.actionCreators &
    typeof MyAccountStore.actionCreators &
    typeof GroupedReturnProcessStore.actionCreators &
    typeof ApplicationHelptextStore.actionCreators &
    RouteComponentProps<{}>;

interface CompanyAssignmentsState {
    query: string;
    defaultERO: number;
    defaultGroup: string;
    isK1Restored: boolean;
    selectedDownloadType: DefaultDownloadInfoType;
    bulkOpnQuery: BulkOperationQuery;
    selectedDefaultDownloadType: boolean;
    DownloadTypeOptionState: boolean;
    loadSpinner: boolean;
}

export class CompanyAssignments extends React.Component<CompanyAssignmentsProps, CompanyAssignmentsState> {
    constructor(props: CompanyAssignmentsProps) {
        super(props);
        this.state = {
            query: "",
            defaultERO: 0,
            defaultGroup: "",
            isK1Restored: false,
            selectedDownloadType: DefaultDownloadInfoType.PromptMeEveryTime,
            bulkOpnQuery: {
                sortBy: "",
                sortOrder: "",
                searchText: "",
                assignedTo: "",
                clientId: "",
                customColumn: "",
                engagementType: 0,
                downloadedBy: "",
                isArchived: false,
                name: "",
                partner: "",
                reportType: ReportFilterType.AllAssignments,
                sentBy: "",
                officeLocation: "",
                status: "",
                taxYear: "",
                unSelectedIds: [],
                documentFilingType: 0,
                isPaperFileEnabled: false,
                quickReportStatus: "",
                groupName: "",
                taxSoftware: "",
                createdBy: "",
                globalSearch: "",
                setAccess: SetAccessType.None,
                filterEFileStatus: "",
                filterGroupType: 0,
                arrangement: -1
            },
            selectedDefaultDownloadType: false,
            DownloadTypeOptionState: false,
            loadSpinner: false
        };
        this.assignedUser = this.assignedUser.bind(this);
        this.onExportToExcel = this.onExportToExcel.bind(this);
        this.requestAssignments = this.requestAssignments.bind(this);
        this.onDownloadTypeCancel = this.onDownloadTypeCancel.bind(this);
        this.onDownloadOptionSave = this.onDownloadOptionSave.bind(this);
        this.onDownloadOptionChange = this.onDownloadOptionChange.bind(this);
        this.useDefaultDownloadType = this.useDefaultDownloadType.bind(this);
    }

    componentDidMount() {
        const element = document.getElementById("content-wrapper");
        element && element.scrollTo(0, 0);
    }
    UNSAFE_componentWillMount() {
        this.props.requestUserSettings(true);
        this.props.requestCompanySignatureUploadLink();
        this.props.requestWatermark(true);
        this.props.requestGroupConfiguration(true);
        this.props.requestGroupAccess(true);
        this.props.requestCustomColumn(true);
        this.props.requestUserAssignDeliveryPermission();
    }

    UNSAFE_componentWillReceiveProps(nextProps: CompanyAssignmentsProps) {
        nextProps.userSettings.settings &&
            nextProps.userSettings.settings.defaultSettings &&
            this.setState({
                defaultERO: nextProps.userSettings.settings.defaultSettings.eroUser,
                defaultGroup: ""
            });
        nextProps.downloadStore &&
            this.setState({
                isK1Restored: nextProps.downloadStore.isK1Restored
            });
        nextProps.userSettings.settings &&
            nextProps.userSettings.settings.defaultSettings &&
            nextProps.userSettings.settings.defaultSettings.download &&
            this.setState({
                selectedDownloadType: nextProps.userSettings.settings.defaultSettings.download.downloadType
            });
    }

    public render() {
        return (
            <div className="company-assignment-content">
                <Assignments
                    title={"Tax Returns In-Process"}
                    id={"company"}
                    key={"company"}
                    automationId={"8F7A9B08-EC83-4C7F-9C17-D91C499DA9E8"}
                    action={"CompanyAssignments"}
                    currentPage={this.props.assignments}
                    taxDocuments={this.props.taxDocuments}
                    users={this.props.userStore.users}
                    ssrUsers={this.props.ssrUsers.userslist}
                    requestAssignments={this.requestAssignments}
                    assignedUser={this.assignedUser}
                    requestTaxDocument={this.props.requestTaxDocument}
                    updateTaxDocument={this.props.updateTaxDocument}
                    deleteTaxDocument={this.props.deleteTaxDocument}
                    requestUsersList={this.props.requestAllSSRUsers}
                    saveTaxDocument={this.props.saveTaxDocument}
                    assignTaxDocument={this.props.AssignTaxDocument}
                    changeStatusTaxDocument={this.props.ChangeStatusTaxDocument}
                    getTaxDocumentsAccessMaps={this.props.getTaxDocumentsAccessMaps}
                    setTaxDocumentsAccessMaps={this.props.setTaxDocumentsAccessMaps}
                    requestTaxDocumentClientTracking={this.props.requestTaxDocumentClientTracking}
                    reportTaxDocumentProblem={this.props.reportTaxDocumentProblem}
                    onExportToExcel={this.onExportToExcel}
                    profile={this.props.profile}
                    pdfDocuments={this.props.pdfDocuments}
                    company={this.props.company}
                    requestCompanySettings={this.props.requestCompanySettings}
                    requestPdfDocument={this.props.requestTaxReturnPdf}
                    requestUserProfile={this.props.requestUserProfile}
                    requestTaxingAuthorities={this.props.requestTaxingAuthorities}
                    defaultERO={this.state.defaultERO ? this.state.defaultERO : 0}
                    defaultGroup={this.state.defaultGroup ? this.state.defaultGroup : ""}
                    sendToERO={this.props.sendToERO}
                    sendForReview={this.props.sendForReview}
                    approveForDelivery={this.props.approveForDelivery}
                    deliverToClient={this.props.deliverToClient}
                    requestAttachments={this.props.requestAttachments}
                    downloadAttachments={this.props.downloadAttachments}
                    getK1InstructionFileName={this.props.getK1InstructionFileName}
                    deleteK1Instruction={this.props.deleteK1Instruction}
                    uploadK1Instruction={this.props.uploadK1Instruction}
                    requestMailingReturnAddressList={this.props.requestMailingReturnAddressList}
                    mailingReturnAddressList={
                        this.props.mailingReturnAddresses === undefined ? [] : this.props.mailingReturnAddresses.data
                    }
                    userSettings={this.props.userSettings}
                    requestDelegatedSigners={this.props.requestDelegatedSigners}
                    generateTaxPayerView={this.props.generateTaxpayerView}
                    savedMessages={this.props.savedMessages.data}
                    getAllSavedMessages={this.props.requestSavedMessages}
                    downloadUserSignature={this.props.requestDownloadPath}
                    userSignatures={this.props.userSignatures}
                    downloadK1DocumentLink={this.props.requestDownloadK1DocumentLink}
                    saveProcessReturnInfo={this.props.saveProcessReturnInfo}
                    getVoucherUploadLink={this.props.requestVoucherUploadLink}
                    getMultiVoucherUploadLink={this.props.requestMultiVoucherUploadLink}
                    getTaxCaddyLookupDetails={this.props.getTaxCaddyLookupDetails}
                    voucherStore={this.props.voucherStore}
                    requestVoucherDueDates={this.props.requestVoucherDueDates}
                    requestPaymentUrls={this.props.requestPaymentUrls}
                    requestVoucherFormNames={this.props.requestVoucherFormNames}
                    isK1Restored={this.state.isK1Restored}
                    restoreK1Instruction={this.props.restoreK1Instruction}
                    getUploadLink={this.props.getUploadLink}
                    getStorageAccountIdByFileId={this.props.getStorageAccountIdByFileId}
                    processTaxReturn={this.props.processTaxReturn}
                    submitTaxReturn={this.props.submitTaxReturn}
                    getAllTaxingAuthorities={this.props.company.taxingAuthorities}
                    submitRecallTaxReturn={this.props.submitRecallTaxReturn}
                    businessInstructionStore={this.props.businessReturnInstructions}
                    requestBusinessReturnInstructions={this.props.requestBusinessReturnInstructions}
                    updateDocumentInitialStatus={this.props.updateDocumentInitialStatus}
                    statusChangeEvent={this.props.statusChangeEvent}
                    resetTaxDocument={this.props.resetTaxDocument}
                    previewAttachments={this.props.previewAttachments}
                    requestDocumentStatus={this.props.requestDocumentStatus}
                    reprocessTaxDocument={this.props.reprocessTaxDocument}
                    checkDuplicateTaxReturn={this.props.checkDuplicateTaxReturn}
                    saveTaxingAuthority={this.props.saveTaxingAuthority}
                    requestTaxingAuthority={this.props.requestTaxingAuthorities}
                    updateDocumentGroupOrder={this.props.updateDocumentGroupOrder}
                    attachementInstructions={this.props.attachmentInstructionList.attachmentInstructions}
                    getAttachmentInstructions={this.props.requestAttachmentInstructions}
                    reportFilters={this.props.reportFilters}
                    getAllReportFilter={this.props.getAllReportFilter}
                    addReportFilter={this.props.addReportFilter}
                    updateReportFilter={this.props.updateReportFilter}
                    deleteReportFilter={this.props.deleteReportFilter}
                    addDefaultFilter={this.props.addDefaultFilter}
                    removeDefaultFilter={this.props.removeDefaultFilter}
                    getAddtionalEsignDocumentUploadLink={this.props.getAddtionalEsignDocumentUploadLink}
                    deleteUploadedAdditionalDocumentBlobFile={this.props.deleteUploadedAdditionalDocumentBlobFile}
                    deleteAdditionalDocument={this.props.deleteAdditionalDocument}
                    getAddtionalEsignDocumentDownLoadLink={this.props.getAddtionalEsignDocumentDownLoadLink}
                    convertDocToPdfArtifactAsync={this.props.convertDocToPdfArtifactAsync}
                    requestAdditionalDocumentType={this.props.requestAdditionalDocumentType}
                    additionalDocumentTypeData={this.props.additionalDocumentTypeData}
                    requestCompanyLogo={this.props.requestCompanyLogo}
                    requestWatermarkList={this.props.requestWatermark}
                    watermarkList={this.props.watermarkStore.waterMark}
                    groupInfo={this.props.groupedReturnStore.groupInfo}
                    addGroup={this.props.addGroup}
                    updateGroup={this.props.updateGroup}
                    mapReturnstoGroup={this.props.mapReturnstoGroup}
                    unMapReturnsFromGroup={this.props.unMapReturnsFromGroup}
                    groupAccess={this.props.groupedReturnStore.groupAccess}
                    requestGroupInfo={this.props.requestGroupInfo}
                    updateGroupInfoState={this.props.requestGroupConfiguration}
                    customColumnData={this.props.customColumnState.customColumn}
                    validateAssignTo={this.props.validateAssignTo}
                    loadSpinner={this.state.loadSpinner}
                    getPaperFileUploadLink={this.props.getPaperFileUploadLink}
                    getPaperFileDownLoadLink={this.props.getPaperFileDownLoadLink}
                    deleteUploadedPaperFileFromBlob={this.props.deleteUploadedPaperFileFromBlob}
                    paperFileMessage={this.props.paperFileMessage === undefined ? [] : this.props.paperFileMessage.data}
                    getAllPaperFileMessages={this.props.requestPaperFiledMessages}
                    checkAssignmentProperty={this.props.checkAssignmentProperty}
                    checkAssignmentPropertyAction={this.props.checkAssignmentPropertyAction}
                    convertToPdfAsync={this.props.convertToPdfAsync}
                    getk1AttachmentUploadLink={this.props.getk1AttachmentUploadLink}
                    requestAllSSRSettings={this.props.requestAllSSRSettings}
                    auth={this.props.auth}
                    requestSSRUsersList={this.props.requestSSRUsers}
                    requestSSRChangeStatusUsers={this.props.requestSSRChangeStatusUsers}
                    taxSoftwareSetting={this.props.taxSoftwareSetting}
                    requestPartnerUser={this.props.requestPartnerUser}
                    getOfficeLocationDropdown={this.props.getOfficeLocationDropdown}
                    locationDropdown={this.props.officeLocation === undefined ? [] : this.props.officeLocation.locationDropdown}
                    smsNotificationDetails={this.props.smsNotificationDetails}
                    getSMSNotificationDetails={this.props.getSMSNotificationDetails}
                    updateSMSNotificationDetails={this.props.updateSMSNotificationDetails}
                    requestClientManagementSetting={this.props.requestClientManagementSetting}
                    clientManagementSetting={this.props.clientManagementSetting}
                    getK1SSNDetails={this.props.getK1SSNDetails}
                    getK1SSNDetailsForPage={this.props.getK1SSNDetailsForPage}
                    resetK1SSNState={this.props.resetK1SSNState}
                    resetPageWiseK1SSNState={this.props.resetPageWiseK1SSNState}
                    k1SSNDetails={this.props.k1SSNDetails}
                    pageWiseSSNDetails={this.props.pageWiseSSNDetails}
                    allssrUsers={this.props.ssrUsers.alluserlist}
                    userDetails={this.props.userDetails}
                    requestGroupedReturnDocumentsByGroupId={this.props.requestGroupedReturnDocumentsByGroupId}
                    groupData={this.props.groupedReturnStore.groupData}
                    getDocumentAccess={this.props.getDocumentAccess}
                    requestGroupedTaxDocument={this.props.requestGroupedTaxDocumentById}
                    markAsReadyForDelivery={this.props.markAsReadyForDocumentDelivery}
                    saveGroupedProcessReturnInfo={this.props.saveProcessGroupedTaxDocumentInfo}
                    onPrintForPaperDelivery={this.props.PrintForPaperDeliveryAsync}
                    groupedReturnsDeliverToController={this.props.groupedReturnsDeliverToController}
                    updateControllerInfoData={this.props.updateControllerInfoData}
                    saveGroupReturnClientInfoData={this.props.saveGroupReturnClientInfoData}
                    reportDocumentGroupProblem={this.props.reportDocumentGroupProblem}
                    requestApplicationHelptext={this.props.requestHelptextsForApplication}
                    helpTextState={this.props.helpTextState}
                    isDeliveryPermissionAssigned={this.props.userAssignDeliveryPermission.isDeliveryPermissionAssigned}
                    getStorageAccountIdByDocumentGuid={this.props.getStorageAccountIdByDocumentGuid }
                />
                <DownloadTypeOption
                    show={this.state.DownloadTypeOptionState}
                    onCancel={this.onDownloadTypeCancel}
                    selectedDownloadType={this.state.selectedDownloadType}
                    onDownloadOptionChange={this.onDownloadOptionChange}
                    onDownloadOptionSave={this.onDownloadOptionSave}
                    useDefaultDownloadType={this.useDefaultDownloadType}
                    selectedDefaultDownloadType={this.state.selectedDefaultDownloadType}
                />
            </div>
        );
    }

    public requestAssignments(query: string, reload: boolean = false, callback?: () => void) {
        this.setState({ query: query }, () => this.props.requestCompanyAssignments(query, true, callback));
    }

    public assignedUser(selectedAssignments: ITaxReturn[]) {
        if (selectedAssignments.length == 1) {
            return selectedAssignments[0].assignTo;
        }
        return 0;
    }

    private onExportToExcel(callback?: any, bulkOpnQuery?: BulkOperationQuery, resourceId: string = "") {
        resourceId = SendTaxReturnResources.HeaderButtonPrefix + CommonResources.ExportToExcel;
        if (bulkOpnQuery != undefined) {
            this.setState({ bulkOpnQuery });
        }
        if (this.state.selectedDownloadType == DefaultDownloadInfoType.DownloadNow) {
            this.props.exportAssignmentsAsExcel(this.state.query, callback, resourceId);
        } else if (this.state.selectedDownloadType == DefaultDownloadInfoType.DownloadToMyDownloads) {
            bulkOpnQuery != undefined ? this.props.exportToExcelBulk(bulkOpnQuery, callback, resourceId) : this.props.exportToExcelBulk(this.state.bulkOpnQuery, callback, resourceId);
        } else {
            if (callback) {
                callback();
            }
            this.setState({
                selectedDownloadType: DefaultDownloadInfoType.DownloadToMyDownloads,
                DownloadTypeOptionState: true,
                loadSpinner: true,
                selectedDefaultDownloadType: false
            });
        }
    }
    private onDownloadOptionChange(value: any) {
        if (this.state.selectedDownloadType == DefaultDownloadInfoType.DownloadNow) {
            this.setState({ selectedDownloadType: DefaultDownloadInfoType.DownloadToMyDownloads });
        } else {
            this.setState({ selectedDownloadType: DefaultDownloadInfoType.DownloadNow });
        }
    }
    private onDownloadOptionSave(value: any) {
        var settings = this.props.userSettings.settings;
        if (this.state.selectedDefaultDownloadType == true) {
            settings.defaultSettings.download.rememberDefaultDownload = true;
            settings.defaultSettings.download.downloadType = this.state.selectedDownloadType;
            this.props.saveUserSettings(settings, true);
        }

        this.setState({
            DownloadTypeOptionState: false
        });
        let downloadType =
            this.props.userSettings.settings.defaultSettings.download.downloadType || DefaultDownloadInfoType.PromptMeEveryTime;
        this.onExportToExcel(() => {
            this.setState({
                loadSpinner: false,
                selectedDownloadType: downloadType
            });
        });
    }
    private onDownloadTypeCancel() {
        let downloadType =
            this.props.userSettings.settings.defaultSettings.download.downloadType || DefaultDownloadInfoType.PromptMeEveryTime;
        this.setState({
            selectedDownloadType: downloadType,
            DownloadTypeOptionState: false,
            loadSpinner: false
        });
    }
    private useDefaultDownloadType(event: any) {
        if (this.state.selectedDefaultDownloadType == false) {
            this.setState({ selectedDefaultDownloadType: true });
        } else {
            this.setState({ selectedDefaultDownloadType: false });
        }
    }
}
export default CompanyAssignments;
