import React, { useEffect, useRef, useState } from "react";
import { LexicalEditor } from "cp-common-ui-components";

interface LexicalEditorComponentProps {
    messageBody: string;
    changeStateEditorBody: (event: any) => void;
    readOnly?: number;
    canAddLink?: number;
    placeholderText?: string;
}

const LexicalEditorComponent: React.FC<LexicalEditorComponentProps> = React.memo((props) => {
    const { messageBody, changeStateEditorBody, placeholderText, readOnly, canAddLink } = props;
    // const editorRef: any = useRef(null);
    // const [inputText, setInputText] = useState("");

    // useEffect(() => {
    //     setInputText(messageBody);
    // }, [])

    return (
        <>
            {/* COMMENTING TINYMCE CODE TO REPLACE IT WITH LEXICAL EDITOR. COMMENTED CODE WILL BE REMOVED AFTER TESTING LEXICAL IN QA. */}

            {/* <Editor
                tinymceScriptSrc={process.env.PUBLIC_URL + "/tinymce/tinymce.min.js"}
                onInit={(evt, editor) => (editorRef.current = editor)}
                initialValue={htmlencode.htmlDecode(inputText)}
                onEditorChange={changeStateTinymceBody}
                init={{
                    height: 230,
                    branding: false,
                    promotion: false,
                    statusbar: false,
                    menubar: "edit format",
                    menu: {
                        edit: { title: "Edit", items: "undo redo | cut copy paste | selectall" },
                        format: { title: "Format", items: "bold italic underline | blocks align " }
                    },
                    plugins: canAddLink ? "link" : "",
                    toolbar: canAddLink
                        ? "undo redo | bold italic underline | alignleft aligncenter alignright | forecolor backcolor | link image"
                        : "undo redo | bold italic underline | alignleft aligncenter alignright | forecolor backcolor",
                    block_formats: "Paragraph=p;Heading 1=h1;Heading 2=h2;Heading 3=h3;Heading 4=h4;Heading 5=h5;Heading 6=h6",
                    placeholder: placeholderText ?? ""
                }}
                disabled={readOnly ? true : false}
            /> */}
            <LexicalEditor
                placeholderText={placeholderText ?? ""}
                options={
                    canAddLink
                        ? [
                              "undo",
                              "redo",
                              "bold",
                              "italic",
                              "underline",
                              "alignments",
                              "colorPicker",
                              "bgColorPicker",
                              "edit",
                              "format",
                              "linkImage"
                          ]
                        : [
                              "undo",
                              "redo",
                              "bold",
                              "italic",
                              "underline",
                              "alignments",
                              "colorPicker",
                              "bgColorPicker",
                              "edit",
                              "format"
                          ]
                }
                changeStateEditorBody={changeStateEditorBody}
                messageBody={messageBody}
                readOnly={readOnly}
            />
        </>
    );
});

export default LexicalEditorComponent;
