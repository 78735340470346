import React from "react";

interface ISvgProps {
    width?: number;
    height?: number;
    className?: string;
}

const DraftStatusIcon: React.FC<ISvgProps> = (props) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            className={props.className}
            width={props.width || "18"}
            height={props.height || "18"}
            fill="none"
            viewBox="0 0 18 18"
        >
            <path
                fill="#565A5E"
                d="M12.13 2.632a.382.382 0 00-.53 0l-8.535 8.535a.373.373 0 000 .531l3.201 3.201a.359.359 0 00.524 0l8.542-8.534a.381.381 0 000-.531L12.13 2.632zM2.264 12.506a.382.382 0 00-.52-.01.36.36 0 00-.1.16L.02 17.456a.366.366 0 00.09.382.374.374 0 00.404.12l4.802-1.601a.39.39 0 00.247-.27.367.367 0 00-.105-.373l-3.194-3.21zM17.224.755a2.693 2.693 0 00-3.74 0l-.531.531a.375.375 0 000 .531l3.201 3.202a.381.381 0 00.524 0l.546-.524a2.641 2.641 0 000-3.74z"
            ></path>
        </svg>
    );
};

const DraftPenIcon: React.FC<ISvgProps> = (props) => {
    return (
        <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg"
        style={{ marginRight: "2px", marginTop: "-3.5px" }}>
            <path d="M0.903692 7.69572C0.876449 7.66908 0.842513 7.65029 0.805473 7.64134C0.768433 7.63239 0.729664 7.63362 0.693262 7.64488C0.656861 7.65615 0.624179 7.67704 0.59867 7.70535C0.573162 7.73366 0.555775 7.76833 0.548347 7.80571L0.00686896 10.4623C-0.0054672 10.5306 -0.00114184 10.6009 0.0194786 10.6672C0.0400991 10.7335 0.0764063 10.7938 0.125318 10.8431C0.163091 10.8837 0.208951 10.916 0.259949 10.9378C0.310948 10.9597 0.365957 10.9706 0.421438 10.97H0.506044L3.16267 10.437C3.20004 10.4295 3.23472 10.4121 3.26303 10.3866C3.29133 10.3611 3.31223 10.3284 3.32349 10.292C3.33476 10.2556 3.33598 10.2169 3.32704 10.1798C3.31809 10.1428 3.2993 10.1089 3.27266 10.0816L0.903692 7.69572Z" fill="white" />
            <path d="M10.1596 0.470376C9.71179 0.129403 9.15457 -0.0356454 8.59331 0.00644868C8.03206 0.0485428 7.50569 0.29486 7.11377 0.698811L1.5467 6.26588C1.52623 6.28561 1.50994 6.30927 1.49882 6.33543C1.4877 6.3616 1.48197 6.38974 1.48197 6.41817C1.48197 6.4466 1.4877 6.47474 1.49882 6.50091C1.50994 6.52708 1.52623 6.55073 1.5467 6.57046L4.38099 9.40476C4.40073 9.42523 4.42438 9.44151 4.45055 9.45264C4.47671 9.46376 4.50485 9.46949 4.53329 9.46949C4.56172 9.46949 4.58986 9.46376 4.61602 9.45264C4.64219 9.44151 4.66585 9.42523 4.68558 9.40476L10.3372 3.75309C10.5542 3.53253 10.7227 3.26916 10.8322 2.9798C10.9416 2.69043 10.9894 2.38144 10.9727 2.07253C10.956 1.76362 10.8751 1.46159 10.735 1.18573C10.595 0.909868 10.399 0.666231 10.1596 0.470376Z" fill="white" />
        </svg>
    );
};

const ProcessPageDraftIcon: React.FC<{ color?: string }> = ({ color = "#898D91" }) => {
    return (
        <svg width="14.02" height="14" viewBox="0 0 15.02 15" fill="none" xmlns="http://www.w3.org/2000/svg"
            style={{ marginLeft: "5px", marginTop: "-7.5px" }}>
            <path d="M10.1206 2.19671C10.0612 2.13913 9.98177 2.10693 9.89905 2.10693C9.81634 2.10693 9.73687 2.13913 9.67749 2.19671L2.55618 9.31802C2.52693 9.34703 2.50371 9.38154 2.48787 9.41957C2.47202 9.4576 2.46387 9.49839 2.46387 9.53958C2.46387 9.58078 2.47202 9.62157 2.48787 9.6596C2.50371 9.69762 2.52693 9.73214 2.55618 9.76115L5.22745 12.4324C5.25546 12.4623 5.2893 12.4861 5.32687 12.5023C5.36445 12.5186 5.40495 12.527 5.44589 12.527C5.48683 12.527 5.52734 12.5186 5.56491 12.5023C5.60248 12.4861 5.63632 12.4623 5.66434 12.4324L12.7919 5.31111C12.8495 5.25172 12.8817 5.17226 12.8817 5.08954C12.8817 5.00683 12.8495 4.92736 12.7919 4.86798L10.1206 2.19671Z" fill={color} />
            <path d="M1.88918 10.4351C1.85095 10.3982 1.80408 10.3714 1.75284 10.3572C1.7016 10.343 1.64762 10.3419 1.59584 10.354C1.54339 10.3651 1.49487 10.39 1.45535 10.4263C1.41583 10.4625 1.38675 10.5087 1.37116 10.5599L0.0167969 14.5669C-0.00212273 14.6216 -0.00512822 14.6805 0.00812968 14.7369C0.0213876 14.7932 0.0503644 14.8446 0.0916923 14.8852C0.131336 14.9337 0.184693 14.9692 0.244809 14.9871C0.304926 15.0049 0.369014 15.0042 0.428722 14.985L4.43563 13.6494C4.48573 13.6317 4.53072 13.6019 4.56663 13.5627C4.60254 13.5235 4.62828 13.4762 4.64159 13.4247C4.65772 13.3697 4.65808 13.3113 4.64263 13.2561C4.62719 13.201 4.59655 13.1512 4.55421 13.1126L1.88918 10.4351Z" fill={color}/>
            <path d="M14.3719 0.630141C13.9531 0.225907 13.3937 0 12.8116 0C12.2295 0 11.6701 0.225907 11.2513 0.630141L10.8081 1.07327C10.7789 1.10228 10.7557 1.1368 10.7398 1.17483C10.724 1.21285 10.7158 1.25364 10.7158 1.29484C10.7158 1.33603 10.724 1.37682 10.7398 1.41485C10.7557 1.45288 10.7789 1.48739 10.8081 1.5164L13.4794 4.18768C13.5385 4.24342 13.6166 4.27446 13.6978 4.27446C13.7791 4.27446 13.8572 4.24342 13.9163 4.18768L14.3719 3.75079C14.5772 3.54611 14.7401 3.30293 14.8513 3.03518C14.9624 2.76743 15.0196 2.48037 15.0196 2.19046C15.0196 1.90056 14.9624 1.6135 14.8513 1.34575C14.7401 1.078 14.5772 0.834814 14.3719 0.630141Z" fill={color} />
        </svg>
    );
};

export { DraftStatusIcon, DraftPenIcon,ProcessPageDraftIcon };
