import { IUserGroup } from '../User/UserViewModel'

export interface ICompanySettings {
    isDefault: boolean;
    deliverySettingsModel: IDeliverySettingsModel;
    displaySettingsModel: IDisplaySettingsModel;
    notificationSettingsModel: INotificationSettingsModel;
    signatureSettingsModel: ISignatureSettingsModel;
    userPrivilegeSettingsModel: IUserPrivilegeSettingsModel;
    defaultMessageSettings: IDefaultMessage;
    defaultGroupMessageSettings: IDefaultGroupMessage;
    reportingChannelSettings: IReportingChannelSettings[];
    retentionSettingsModel: IRetentionSettings;
    k1Settings: IK1Settings;
    taxpayerPaymentSettings: ITaxpayerPaymentSettings;
    organizerSettings: IOrganizerSettings;
    extensionSettings: IExtensionSettings;
    returnAccessSettings: IReturnAccessSettingsModel;
    smsNotificationSettings:ISMSNotificationSettings;
}

export interface IDefaultMessage {
    initialEmail: number;
    voucherReminders: number;
    downloadTaxDocuments: number;
    downloadSignedForms: number;
    k1Distribution: number;
    welcomeMessage: number;
    k1WelcomeMessage: number;
    downloadInstruction: number;
    paymentInstruction: number;
    savedMessage: number;
    signingReminder: number;
    k1PartnerInfoUpdate: number;
    k1ClientInfoUpdate: number;
    k1PartnerWelcomeMessage: number;
    k1ClientWelcomeMessage: number;
    k1PartnerUpdatePageMessage: number;
    attachmentInstruction: number;
    invoicePaymentSuccessMail: number;
    resendSignedReturn: number;
    voucherRevision: number;
    paperFiledMessage: number;
    initialMailForManualSign: number;
}
export interface IDefaultGroupMessage {
    groupInitialEmail: number;
    groupSigningReminder: number;
    groupVoucherReminders: number;
    groupDownloadTaxDocuments: number;
    groupDownloadSignedForms: number;
    groupDownloadInstruction: number;
    groupPaymentInstruction: number;
    groupResendSignedReturn: number;
    groupVoucherRevision: number;
}
export interface IDeliverySettingsModel {
    transmittalsDownloadOptions: TransmittalDownloadOption;
    vouchersDownloadOptions: VoucherDownloadOption;
    taxpayerAuthentication: TaxpayerAuthenticationType;
    isDirectDeliveryToTaxCaddy: boolean;
    isByPassManualVerification: boolean;
    isFooterEnabled: boolean;
    isPrintToPDFEnabled: boolean;
}
export interface IDisplaySettingsModel {
    onlinePaymentUrl: string;
    isEnableInvoice: boolean;
    voucherDueDateType: DueDateType;
    isEnablePaperFiled: boolean;
    isScreenShareEnabled: boolean;
    isWatermarkEnabled: boolean;
    defaultWatermarkId: number;
}
export interface INotificationSettingsModel {
    paymentVoucherNotificationSettings: IPaymentVoucherNotificationSettings;
    signingReminderNotificationSettings: ISigningReminderNotificationSettings;
}

export interface ISignatureSettingsModel {
    signatureFormSelectionType: SignatureFormSelectionType,
    knowledgeBasedAuthentication: boolean,
    enableDelegateeKbaAuthentication: boolean;
    enableSignerDeligation: boolean,
    useSignatureStamp: boolean,
    enableEsignatureForBusinessReturns: boolean,
    manualAddressId: number,
    isDoNotDisplayDatesEnabled: boolean;
    isDoNotDisplayNameAndTitleEnabled: boolean;
    enableEntityReturnSignerDeligation: boolean;
    enableIndividualReturnSignerDeligation: boolean;
    allowDisablingKBAOnReturnLevel: boolean;
}
export interface IPaymentVoucherNotificationSettings {
    enablePaymentVoucherReminder: boolean,
    noOfDaysForVoucherReminder: number,
    paymentNotification: boolean
}

export interface ISigningReminderNotificationSettings {
    enableSigningReminder: boolean,
    noOfDaysForSigningReminder: number
}

export interface IK1InfoSettings {
    noOfDays: number,
    k1MailCycle: number
}
export enum K1MailCycles {
    None = 0,
    Before = 1,
    After = 2
}
export interface IUserPrivilegeSettingsModel {
    allowAnyUsertoSendDelivary: boolean,
    allowUserToReplaceInvoice: boolean,
    allowUserToSendwithoutInvoice: boolean,
    allowUserToReplaceDistributedInstructionsDocument: boolean,
    enableIPFiltering: boolean,
    allowUserToDistributeK1: boolean
}
export interface IReportingChannelSettings {
    reportingEngine: string;
    enabled: boolean;
    settings: IReportingEngineSettings;
}
export interface IReportingEngineSettings {

}

export interface IOrganizerSettings {
    enabled: boolean;
    enabledSourceDocuments: boolean;
}

export interface IExtensionSettings {
    enabled: boolean;
}

export interface IPrintToPdfSettings {
    enabled: boolean;
}

export interface ITaxCaddySettings extends IReportingEngineSettings {
    isSkipManualVerification: boolean;
    firmIds: string[];
}

export interface ISuiteTaxSoftwareSetting {
    taxSoftware: TaxSoftwareType[];
}

export interface IK1Settings {
    k1ClientInfoSettings: IK1InfoSettings;
    k1PartnerInfoSettings: IK1InfoSettings;
    isSSNMaskingEnabled: boolean;
}
export interface IRetentionSettings {
    retentionPeriod: number;
    attestRetentionPeriod: number;
    allowOverride: boolean
}

export interface IExternalAuthenticationSettings {
    provider: AuthenticationProvider,
    enabled: boolean,
    authorisationType: AuthorisationMode[],
    data: IExternalAuthenticationSettingData[],
    isModified: boolean;
}

export interface IExternalAuthenticationSettingData {
    key: string,
    roles: IUserGroup[],
    authorisationType: AuthorisationMode
}

export interface IPaymentSettingsProxy {
    paymentFlowType: PaymentFlowType,
    productType: ProductType,
    hasCompanyCustomer: boolean,
    hasCompanyPayments: boolean,
    receiptEmail: string
}
export enum TaxCodeType {
    None = 0,
    SSR = 1,
    Signature = 2,
    SSO = 3
}
export interface ITaxCodeData {
    taxCode: string,
    taxCodeType: TaxCodeType,
    itemCode: string
}
export interface ISaleTaxSetings {
    companyCode: string,
    taxCodes: ITaxCodeData[]
}

export interface ITaxpayerPaymentSettings {
    companyConnectedAccountId: string;
    applicationFeesAmount: number;
    //enableStripe: boolean;
    priortoReview: boolean;
    allowUserToSendReturnWithoutPayment: boolean;
    payOption: PaymentOption;
}
export enum PaymentOption {
    None = 0,
    AttachedURL = 1,
    Stripe = 2,
    ExternalSystem = 3
}
export interface IReturnAccessSettingsModel {
    isSetAccessToEveryone: boolean;
    isSetAccessToIndividual: boolean;
    isSetAccessToOtherUsers: boolean;
    isSetAccessToUserGroups: boolean;
}

export interface ISMSNotificationSettings{
    signingReminderNotificationSettings:ISMSSigningReminderNotificationSettings;
}

export interface ISMSSigningReminderNotificationSettings{
    enableSigningReminder:boolean;
    noOfDaysForSigningReminder:number;
    signingReminderFrequency:number;
}

export interface ICompanyPayments {
    paymentId: number
}
export enum PaymentFlowType {
    None = 0,
    PayNow = 1,
    PayasYouGo = 2
}

export enum ReportPaymentFlowType {
    None = 0,
    PayNow = 1,
    PayasYouGo = 2,
    ManuallyPaid = 3,
}

export enum ProductType {
    None = 0,
    SSR = 1,
    Signature = 2,
    Organizers = 3,
    Extensions = 4,
    Exchange = 5,
}

export enum AuthenticationProvider {
    None,
    AzureAD,
    SAML,
    Google
}

export enum AuthorisationMode {
    None,
    AzureAdGroups,
    SSRUserList,
    AzureTenantId
}

export enum TransmittalDownloadOption {
    None = "None",
    IncludeTransmittalInTaxReturnPDF = "IncludeTransmittalInTaxReturnPDF",
    CreateSeparateTransmittalPDF = "CreateSeparateTransmittalPDF"
}
export enum VoucherDownloadOption {
    None = "None",
    SeparateByQuarterlyDueDates = "SeparateByQuarterlyDueDates",
    GroupInSinglePDF = "GroupInSinglePDF"
}
export enum TaxpayerAuthenticationType {
    None = "None",
    Last4ofSSN = "Last4ofSSN",
    First4ofSSN = "First4ofSSN"
}
export enum SignatureFormSelectionType {
    None = "None",
    ESign = "ESign",
    ESignWhenAllIncludedReturnsAllowed = "ESignWhenAllIncludedReturnsAllowed",
    ManualSign = "ManualSign"
}

export enum ReportingEngineType {
    TaxCaddy = "TaxCaddy"
}

export enum TaxSoftwareType {
    None = "None",
    ProSystems = "ProSystems",
    UltraTax = "UltraTax",
    GoSystem = "GoSystem",
    UltraTaxM18 = "UltraTaxM18",
    Lacerte = "Lacerte",
    Drake = "Drake"
}

export enum IconColor {
    Green = "#5CB85C",
    Yellow = "#FFD700"
}

export enum BrandingColors {
    LayoutBackgroundColor = 0,
    LayoutForegroundColor = 1,
    CoverPageBackGroundColor = 2,
    CoverPageForeGroundColor = 3
}
export enum DueDateType {
    None = 0,
    SSRDefault = 1,
    Printed = 2
}

export enum ReportType {
    PaymentHistoryReport = "PaymentHistoryReport",
    PaymentReport = "PaymentReport"
}

export const initialCompanySettings: ICompanySettings = {
    isDefault: true,
    deliverySettingsModel: {
        transmittalsDownloadOptions: TransmittalDownloadOption.None,
        vouchersDownloadOptions: VoucherDownloadOption.None,
        taxpayerAuthentication: TaxpayerAuthenticationType.None,
        isDirectDeliveryToTaxCaddy: false,
        isByPassManualVerification: false,
        isFooterEnabled: false,
        isPrintToPDFEnabled: false
    },
    displaySettingsModel: {
        isEnableInvoice: true,
        onlinePaymentUrl: "",
        voucherDueDateType: DueDateType.SSRDefault,
        isEnablePaperFiled: false,
        isScreenShareEnabled: false,
        isWatermarkEnabled: false,
        defaultWatermarkId: 0
    },
    notificationSettingsModel: {
        paymentVoucherNotificationSettings: {
            enablePaymentVoucherReminder: true,
            noOfDaysForVoucherReminder: 1,
            paymentNotification: true
        },
        signingReminderNotificationSettings: {
            enableSigningReminder: true,
            noOfDaysForSigningReminder: 1
        }
    },
    signatureSettingsModel: {
        signatureFormSelectionType: SignatureFormSelectionType.None,
        knowledgeBasedAuthentication: true,
        enableDelegateeKbaAuthentication: false,
        enableSignerDeligation: true,
        enableEsignatureForBusinessReturns: true,
        useSignatureStamp: false,
        manualAddressId: 0,
        isDoNotDisplayDatesEnabled: true,
        isDoNotDisplayNameAndTitleEnabled: true,
        enableEntityReturnSignerDeligation: true,
        enableIndividualReturnSignerDeligation: false,
        allowDisablingKBAOnReturnLevel: false
    },
    userPrivilegeSettingsModel: {
        allowAnyUsertoSendDelivary: true,
        allowUserToReplaceInvoice: true,
        allowUserToSendwithoutInvoice: true,
        allowUserToReplaceDistributedInstructionsDocument: false,
        enableIPFiltering: false,
        allowUserToDistributeK1: false
    },
    defaultMessageSettings: {
        initialEmail: 0,
        voucherReminders: 0,
        downloadTaxDocuments: 0,
        downloadSignedForms: 0,
        k1Distribution: 0,
        welcomeMessage: 0,
        k1WelcomeMessage: 0,
        downloadInstruction: 0,
        paymentInstruction: 0,
        savedMessage: 0,
        signingReminder: 0,
        k1PartnerInfoUpdate: 0,
        k1ClientInfoUpdate: 0,
        k1PartnerWelcomeMessage: 0,
        k1ClientWelcomeMessage: 0,
        k1PartnerUpdatePageMessage: 0,
        attachmentInstruction: 0,
        invoicePaymentSuccessMail: 0,
        resendSignedReturn: 0,
        voucherRevision: 0,
        paperFiledMessage: 0,
        initialMailForManualSign: 0
    },
    defaultGroupMessageSettings: {
        groupInitialEmail: 0,
        groupSigningReminder: 0,
        groupVoucherReminders: 0,
        groupDownloadTaxDocuments: 0,
        groupDownloadSignedForms: 0,
        groupDownloadInstruction: 0,
        groupPaymentInstruction: 0,
        groupResendSignedReturn: 0,
        groupVoucherRevision: 0
    },
    reportingChannelSettings: [{
        reportingEngine: ReportingEngineType.TaxCaddy,
        enabled: false,
        settings: {
            isSkipManualVerification: false,
            firmIds: []
        }
    }],

    retentionSettingsModel: {
        retentionPeriod: 2520,
        attestRetentionPeriod: 2520,
        allowOverride: false
    },
    k1Settings: {
        k1PartnerInfoSettings:
        {
            noOfDays: 15,
            k1MailCycle: 1
        },
        k1ClientInfoSettings:
        {
            noOfDays: 15,
            k1MailCycle: 1
        }
    },
    taxpayerPaymentSettings: {
        companyConnectedAccountId: "",
        allowUserToSendReturnWithoutPayment: false,
        applicationFeesAmount: 0,
        payOption: PaymentOption.None,
        priortoReview: false
    },
    organizerSettings: {
        enabled: false,
        enabledSourceDocuments: false
    },

    extensionSettings: {
        enabled: false
    },
    returnAccessSettings: {
        isSetAccessToEveryone: true,
        isSetAccessToIndividual: false,
        isSetAccessToOtherUsers: false,
        isSetAccessToUserGroups:false,
    },
    smsNotificationSettings:{
        signingReminderNotificationSettings:{
            enableSigningReminder:true,
            noOfDaysForSigningReminder:1,
            signingReminderFrequency:1,
        }
    }
}


export interface IBulkPurchaseDiscountDetails {
    productType: ProductType,
    minQuantity: number,
    maxQuantity?: number,
    discountPrice?: number
}

export interface ISuiteMFASetting {
    isMfaEnabled: boolean;
    trustedDeviceExpiryDays: number;
    isAllowUsertoRememberTrustedDevice: boolean;
    skipMfaForIP: boolean;
    iSTPMfaEnabled: boolean;
}

export const initialMFASetting: ISuiteMFASetting = {
    isAllowUsertoRememberTrustedDevice: false,
    isMfaEnabled: false,
    iSTPMfaEnabled: false,
    skipMfaForIP: false,
    trustedDeviceExpiryDays: 0
}

export const initialTaxSoftwareSetting: ISuiteTaxSoftwareSetting = {
    taxSoftware: []
}

export interface ISuiteNotificationSetting{
    isSMSNotificationEnabled:boolean;
}

export const initialSuiteNotificationSetting: ISuiteNotificationSetting = {
    isSMSNotificationEnabled: false
}

