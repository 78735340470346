import * as React from "react";
import { RouteComponentProps } from "react-router";
import { ProfileDropdown } from "./profile/ProfileDropdown";
import * as UserProfileStore from "../../store/userManagement/UserProfileStore";
import * as MyAccountStore from "../../store/userManagement/MyAccountStore";
import * as UserSettingsStore from "../../store/userManagement/UserSettingStore";
import * as AllUsersStore from "../../store/userManagement/UserStore";
import * as CompanyStore from "../../store/company/CompanyStore";
import * as InboxStore from "../../store/inbox/InboxStore";
import { IUserProfile, IUserProfileModel } from "../navigation/profile/ProfileObjects";
import { initialUserBaseModel, IUserBaseModel } from "../../Core/ViewModels/User/UserViewModel";
import * as MailingReturnAddressStore from "../../store/common/MailingReturnAddressStore";
import * as UserLoginHistory from "../../store/userManagement/UserLoginHistoryStore";
import { ICompanySettings, ISuiteMFASetting, ISuiteTaxSoftwareSetting } from "../../Core/ViewModels/Company/CompanySettingsViewModel";
import { Popover, Overlay } from "react-bootstrap";
import { UserNotificationPopover } from "../../components/navigation/notification/NotificationPopOver";
import { IInboxMessage, MessageStatus } from "../../Core/ViewModels/User/InboxMessage";
import * as OneTimePasswordStore from "../../store/common/OneTimePasswordStore";
import * as CustomColumnStore from "../../store/common/CustomColumnStore";
import * as ApplicationHelptextStore from "../../store/common/ApplicationHelptextStore";
import { IHelptextModel } from "../../Core/ViewModels/Common/IHelptextModel";
import * as SFUsersStore from "../../SignatureFlow/store/SFUserStore";
import { IsOnlySignatureUser } from "./../../oidcClient/userRoles";
import { IAuthState } from "./../../store/auth/reducer";
import { ICompanyInfo } from "../../Core/ViewModels/Company/CompanyViewModel";
import { VenusNotifier } from "../helper/VenusNotifier";
import { container } from "../../Startup/inversify.config";
import { TYPES } from "../../Startup/types";
import { ISessionLocalStore } from "../../Core/Utilities/SessionStore";
import * as SuiteMFASettingStore from '../../store/company/SuiteMFASettingStore';
import { CommonMessages, userSettingConstants } from "../helper/Constants";
import { IPartnerUserData } from "../../store/userManagement/UserStore";

const SessionStore = container.get<ISessionLocalStore>(TYPES.ISessionStore);

export interface IBasicProfile {
    userId: number;
    firstName: string;
    lastName: string;
    email: string;
}

export interface IDropdownMenuItem {
    caption: string;
    onToggle(prop: string): any;
    target?: string;
};

export interface IHeaderState {
    userData: IUserBaseModel;
    profileMenu: IDropdownMenuItem[];
    helpMenu: IDropdownMenuItem[];
    logoPath: string;
    showNotificationPopover: boolean;
    helptexts: IHelptextModel[];
    isCompanySFSettingEnabled: boolean;
}

export interface IAppHeader {
    showModal: {
        showProfile: boolean;
        showLoginHistory: boolean;
        showMySettings: boolean;
        showMyDownloads: boolean;
        showLogout: boolean;
        showSupport: boolean;
    };
    toggleMenu(prop: string): any;
    closePopoverRef: any;
    showNotificationPopover: boolean;
    toggleNotificationPopover: (prop: boolean) => void;
}

type AppHeaderProps = IAppHeader & {
    auth: IAuthState;
    profile: IUserProfile;
    userDetails: IUserProfileModel;
    userList: AllUsersStore.IUserData;
    ssrUsers: AllUsersStore.ISSRUserListData;
    settings: UserSettingsStore.UserSettings;
    companyData: CompanyStore.ICompanyData;
    mailingReturnAddress: MailingReturnAddressStore.IMailingReturnAddressStoreState;
    userLoginHistory: UserLoginHistory.IUserLoginHistoryState;
    inboxState: InboxStore.IInboxState;
    oneTimePasswordData: OneTimePasswordStore.IOneTimePasswordState;
    customColumnState: CustomColumnStore.ICustomColumnState;
    helptexts: ApplicationHelptextStore.IHelptextState;
    sfUserList: SFUsersStore.ISFUserData;
    mfaSetting: ISuiteMFASetting;
    taxSoftwareSetting: ISuiteTaxSoftwareSetting;
    partnerUsersList: IPartnerUserData;
} & typeof UserProfileStore.actionCreators &
    typeof AllUsersStore.actionCreators &
    typeof UserSettingsStore.actionCreators &
    typeof CompanyStore.actionCreators &
    typeof MailingReturnAddressStore.actionCreators &
    typeof UserLoginHistory.actionCreators &
    typeof InboxStore.actionCreators &
    typeof OneTimePasswordStore.actionCreators &
    typeof CustomColumnStore.actionCreators &
    typeof ApplicationHelptextStore.actionCreators &
    typeof SFUsersStore.actionCreators &
    typeof MyAccountStore.actionCreators &
    typeof SuiteMFASettingStore.actionCreators &
    RouteComponentProps<{}>;

export class AppHeader extends React.Component<AppHeaderProps, IHeaderState> {
    isSignatureOnlyUser = IsOnlySignatureUser(this.props.auth.user);
    constructor(props: AppHeaderProps) {
        super(props);

        this.state = {
            profileMenu: [
                {
                    caption: userSettingConstants.profile,
                    onToggle: () => this.props.toggleMenu(userSettingConstants.profile)
                },
                {
                    caption: userSettingConstants.loginHistory,
                    onToggle: () => this.props.toggleMenu(userSettingConstants.loginHistory)
                },
                {
                    caption: userSettingConstants.mySettings,
                    onToggle: () => this.props.toggleMenu(userSettingConstants.mySettings)
                },
                {
                    caption: userSettingConstants.myDownloads,
                    onToggle: () => this.props.toggleMenu(userSettingConstants.myDownloads)
                },
                {
                    caption: userSettingConstants.logout,
                    onToggle: () => this.props.toggleMenu(userSettingConstants.logout)
                }
            ],
            helpMenu: [
                {
                    caption: userSettingConstants.support,
                    onToggle: () => this.props.toggleMenu(userSettingConstants.support)
                }
            ],
            userData: initialUserBaseModel,
            logoPath: "",
            showNotificationPopover: false,
            helptexts: [],
            isCompanySFSettingEnabled: false
        };
    }

    UNSAFE_componentWillMount() { 
        this.props.requestAllUsers();
        this.props.requestSSRUsers();
        this.props.requestPartnerUser();
        this.props.requestUserDetails();
        this.props.requestCompanyLogo(true);
        this.props.requestUserSettings(true);
        this.props.requestMailingReturnAddressList();
        this.props.requestCompanyProfile(false, this.validateCompanyStatus);
        this.props.requestUserLoginHistory();
        this.props.getInboxMessages();
        this.props.requestMFAOTPLength();
        this.props.requestCompanySettings();
        this.props.requestCustomColumn(true);
        this.props.requestHelptextsForApplication(true);
        this.props.resetUserCookie();
        this.props.getWalkMeScript((script: string) => {
            if (script && script.length > 0) {
                let scriptElement = document.createElement("script");
                scriptElement.type = "text/javascript";
                scriptElement.async = true;
                scriptElement.text = script;
                document.head.appendChild(scriptElement);
                (window as any).loadWalkMe && (window as any).loadWalkMe();
            }
        });
        this.props.requestMFASetting();
    }

    UNSAFE_componentWillReceiveProps(nextProps: AppHeaderProps) {
        this.setState({
            logoPath:
                nextProps.companyData.companyLogoSetting == undefined ? "" : nextProps.companyData.companyLogoSetting.logoPath,
            helptexts: nextProps.helptexts.helpTexts
        });

        this.setInboxTimeOut(nextProps.inboxState.inboxMessages);
    }

    private validateCompanyStatus(companyInfo: ICompanyInfo) {
        if (companyInfo.isDisabled) {
            SessionStore.set("isCompanyDisabled", true);
            VenusNotifier.Error(CommonMessages.Error.CompanyDisabled, null);
        }
    }

    handleNotificationPopOver = (show: boolean) => {
        this.setState({ showNotificationPopover: show });
    };

    private setInboxTimeOut = (inboxMessages: IInboxMessage[] = []) => {
        const currentDate: number = Date.now().valueOf();
        const messages = Array.isArray(inboxMessages)
            ? inboxMessages.filter((m) => new Date(m.startDate).valueOf() > currentDate)
            : [];

        if (messages) {
            const that = this;
            messages.forEach((message) => {
                setTimeout(function () {
                    that.forceUpdate();
                }, new Date(message.startDate).valueOf() - currentDate.valueOf());
            });
        }
    };

    public render() {
        const currentDate: number = Date.now().valueOf();
        const inboxMessages = this.props.inboxState.inboxMessages?.filter(
            (message) => new Date(message.startDate).valueOf() <= currentDate && message.messageStatus != MessageStatus.Deleted
        );
        const unreadMessagesCount = inboxMessages?.filter((m) => m.messageStatus == MessageStatus.Unread).length;

        return (
            <>
                {unreadMessagesCount ? <span className="badge inbox-count support-inbox-count">{unreadMessagesCount}</span> : ""}

                <Overlay
                    rootClose={true}
                    placement="bottom"
                    onHide={() => this.props.toggleNotificationPopover(false)}
                    target={this.props.closePopoverRef}
                    show={this.props.showNotificationPopover}
                >
                    <Popover id="notification-popover" className="notificationPopover">
                        <Popover.Content>
                            <UserNotificationPopover
                                inboxMessages={inboxMessages}
                                notificationSettings={this.props.settings.notificationSettings}
                                updateInboxMessage={this.props.updateInboxMessage}
                                saveUserNotificationSettings={this.props.saveUserNotificationSettings}
                                handlePopOverClose={() => this.props.toggleNotificationPopover(false)}
                                updateClearAll={this.props.updateInboxMessageForClearAll}
                                isLoading={this.props.inboxState.isLoading}
                            />
                        </Popover.Content>
                    </Popover>
                </Overlay>

                <ProfileDropdown
                    auth={this.props.auth}
                    users={this.props.userList.users}
                    ssrUsers={this.props.ssrUsers.userslist}
                    saveUserProfile={this.props.updateMyAccount}
                    updatePassword={this.props.updatePassword }
                    getPasswordPolicySetting={this.props.getPasswordPolicySetting}
                    profile={this.props.profile}
                    userDetails={this.props.userDetails}
                    fullName={this.props.profile.firstName + " " + this.props.profile.lastName}
                    menuItems={this.state.profileMenu}
                    showModal={this.props.showModal}
                    settings={this.props.settings}
                    manualAddresList={this.props.mailingReturnAddress.data}
                    requestUserSettings={this.props.requestUserSettings}
                    updateUserSettings={this.props.updateUserSettings}
                    saveUserSettings={this.props.saveUserSettings}
                    saveSignatureDelegates={this.props.saveSignatureDeligates}
                    requestUserProfile={this.props.requestUserProfile}
                    requestAllUsers={this.props.requestAllUsers}
                    requestSSRUser={this.props.requestSSRUsers}
                    requestUserLoginHistory={this.props.requestUserLoginHistory}
                    userLoginHistory={this.props.userLoginHistory}
                    companySettings={this.props.companyData.companySettings as ICompanySettings}
                    saveOneTimePassword={this.props.saveOneTimePassword}
                    otpData={this.props.oneTimePasswordData.otpState}
                    validateOTP={this.props.validateOTP}
                    verifyLoading={this.props.oneTimePasswordData.isVerifyLoading}
                    getAllMyDownloads={this.props.getMyDownloadsListAsync}
                    downloadReturnFromPopup={this.props.downloadReturnFromPopup}
                    clearAllMyDownloads={this.props.clearAllMyDownloads}
                    deleteMyDownloads={this.props.deleteMyDownloads}
                    myDownloads={this.props.companyData.myDownloads}
                    customColumnData={this.props.customColumnState.customColumn}
                    requestHelptexts={this.props.requestHelptextsForApplication}
                    helptexts={this.state.helptexts}
                    mfaSetting={this.props.mfaSetting}
                    allssrUsers={this.props.ssrUsers.alluserlist}
                    partnerUsersList={this.props.partnerUsersList.partnerUsers}
                />
            </>
        );
    }
}

export default AppHeader;
