import { addTask } from 'domain-task';
import { IGFRSettings} from '../../../Core/ViewModels/Company/Settings/GFRSettingsViewModel';
import { Reducer } from "redux";
import { actionTypes } from "src/types/ActionTypes";
import { API_BASE_URL, SSSUITE_API_BASE_URL } from '../../../utils/constants';
import { handleResponse } from '../../Library';
import { AppThunkAction } from '../..';
import { NotificationAction, StatusType } from '../../common/NotificationStore';
import { gfrUploadResponseType } from 'src/types/GFREnums';
import { HideLoader } from 'src/components/helper/Loader';
import * as Notification from "../../common/NotificationStore";

export interface ReceiveGFRSettingsAction {
    type: actionTypes.RECEIVE_GFR_SETTINGS;
    settings: IGFRSettings;
}

type DispatchAction = 
ReceiveGFRSettingsAction
| NotificationAction;

type KnownAction = DispatchAction;

export const actionCreators = {
    getGFRSettings: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const fetchTask = fetch(SSSUITE_API_BASE_URL + '/api/gfr/feature-status', {
            method: 'GET',
            credentials: 'include'
        })
            .then(handleResponse)
            .then(json => json as Promise<IGFRSettings>)
            .then(data => {
                if (data) {
                    dispatch({ type: actionTypes.RECEIVE_GFR_SETTINGS, settings: data });
                }
            })
            .catch(error => {
                const statusMessage: any = error.statusText?.message ?? error.statusText;
                if (typeof (statusMessage) === "string") {
                    dispatch({ type: actionTypes.NOTIFICATION, statusMessage: statusMessage, statusType: StatusType.Error })
                }
            });
        addTask(fetchTask);
    },
    SendEfileDocumentsToGoFileRoom:
        (id: number, resourceId: string = ""): AppThunkAction<KnownAction> =>
            (dispatch, getState) => {
                const fetchTask = fetch(API_BASE_URL + "api/Download/SendToGoFileRoom/?documentId=" + id, {
                    method: "POST",
                    credentials: "include",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        "X-Resource-Id": resourceId,
                        RequestVerificationToken: (document.getElementById("RequestVerificationToken") as HTMLInputElement).value
                    },
                    body: JSON.stringify(id)
                })
                    .then(handleResponse)
                    .then((response) => response as Promise<number>)
                    .then((uploadStatus) => {
                        var statusMsg = "";
                        switch (uploadStatus) {
                           
                            case gfrUploadResponseType.GFRNotConnected:
                                statusMsg = "GFR Authentication is not completed";
                                break;
                            case gfrUploadResponseType.GFRIndexMapCustomizationIsNull:
                                statusMsg = "GFR Mapping is not completed";
                                break;
                            case gfrUploadResponseType.AuthenticationFailed:
                                statusMsg = "GFR Authentication has failed. Please check your credentials and try again";
                                break;
                            case gfrUploadResponseType.DocumentIsAlreadyUploading:
                                statusMsg = "File uploading is already in-progress";
                                break;
                            case gfrUploadResponseType.UploadJobSent:
                                statusMsg = "GFR Authentication is completed. File uploading in-progress";
                                break;
                            default:
                                break;
                        }
                        HideLoader();
                        
                        dispatch({
                            type: actionTypes.NOTIFICATION,
                            statusMessage: statusMsg,
                            statusType: uploadStatus == gfrUploadResponseType.UploadJobSent ? Notification.StatusType.Success : Notification.StatusType.Warning
                        });   
                    })
                    .catch((error) => {
                        HideLoader();
                        dispatch({
                            type: actionTypes.NOTIFICATION,
                            statusMessage: error,
                            statusType: Notification.StatusType.Error
                        });
                    });
                addTask(fetchTask);
            },
}

const unloadedState: IGFRSettings = {
    isConnected : false,
    username : "",
    isGFREnabled : false

}

export const reducer: Reducer<IGFRSettings> = (state = unloadedState, incomingAction) => {
    const action = incomingAction as DispatchAction;
    switch (action.type) {
        case actionTypes.RECEIVE_GFR_SETTINGS:
            var gfrSettings = { 
                isConnected : action.settings.isConnected,
                username : action.settings.username,
                isGFREnabled: action.settings.isGFREnabled
             };
            return gfrSettings;
    }

    return state;
};