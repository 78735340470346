import { IDocumentAccessSaveModel } from "../../../components/common/TaxReturn";
import { IGroupInfo } from "../../../store/groupedreturns/GroupedReturnProcessState";

export interface SasContainer {
    sasGuid: string;
}

export enum UploadStatus {
    Wait = 0,
    Initiating = 1,
    Uploading = 2,
    Uploaded = 3,
    Error = 4,
    Converting = 5
}

export interface TaxReturnUploadData {
    number?: number;
    clientName?: string;
    name?: string;
    progressBar?: number;
    size?: string;
    status?: UploadStatus;
    clientId?: string;
    partnerId?: number;
    setAccess?: string;
    uuid?: string;
    sasGuid?: string;
    gridRowDisable: boolean;
    documentAccess: IDocumentAccessSaveModel;
    rowIndex?: number;
    file?: any;
    numberOfPages: number;
    storageAccountId: number;
}

export interface TaxReturnUploadData_GR extends TaxReturnUploadData {
    groupInfo: IGroupInfo;
}

export interface CCHReturnUploadData {
    groupedTaxReturnUploadData: TaxReturnUploadData_GR[];
    draftStatus: number;
}

export interface UltraTaxReturnUploadData {
    clientId: string;
    sasGuid: string;
    setAccess: string;
    clientName: string;
    partnerId: number;
    documentAccess: IDocumentAccessSaveModel;
    subDocumentsUploadData: subDocumentUploadData[];
    numberOfPages: number;
    storageAccountId: number;
    draftStatus: number;
}

export interface UltraTaxReturnUploadData_GR extends UltraTaxReturnUploadData {
    groupInfo: IGroupInfo;
}

export interface GoSystemReturnUploadData {
    clientId: string;
    sasGuid: string;
    setAccess: string;
    clientName: string;
    partnerId: number;
    documentAccess: IDocumentAccessSaveModel;
    subDocumentsUploadData: subDocumentUploadData[];
    numberOfPages: number;
    storageAccountId: number;
    draftStatus: number;
}

export interface GoSystemReturnUploadData_GR extends GoSystemReturnUploadData {
    groupInfo: IGroupInfo;
}

export interface LacerteReturnUploadData {
    clientId: string;
    sasGuid: string;
    setAccess: string;
    clientName: string;
    partnerId: number;
    documentAccess: IDocumentAccessSaveModel;
    subDocumentsUploadData: subDocumentUploadData[];
    numberOfPages: number;
    storageAccountId: number;
    draftStatus: number;
}

export interface LacerteReturnUploadData_GR extends LacerteReturnUploadData {
    groupInfo: IGroupInfo;
}

export interface subDocumentUploadData {
    number?: number;
    name?: string;
    progressBar?: number;
    size?: string;
    subDocumentGuid: string;
    status: UploadStatus;
    file?: any;
    numberOfPages: number;
}

interface IDocumentAccess {
    documents: number[];
    users: number[];
    userGroups: number[];
}
