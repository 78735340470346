import * as React from 'react';
import { Button } from 'react-bootstrap';
import { ITaxReturn } from '../common/TaxReturn';
import { IUserDefaultSettingsStates } from '../navigation/profile/MySettings';
import { ICompanySettings, TaxSoftwareType, ISuiteTaxSoftwareSetting } from '../../Core/ViewModels/Company/CompanySettingsViewModel';
import { UploadFabButton } from '../../components/common/UploadFabButton';
import { SendTaxReturnResources } from '../helper/ResourceIdConstants';
import { DeliverGroupConstants, DraftReturnConstants } from '../helper/Constants';
import { DraftStatus } from '../../Core/Common/Common';

interface AssignmentSetActionsProps {
	pageTitle: string;
    taxYear: number;
    selectedRows: number[];
    onAssignOpen: (rowIndex: number, resourceId?: string) => void;
    onDeleteReturnsOpen: (rowIndex: number, resourceId?: string) => void;
    onSetAccessOpen: (rowIndex: number, resourceId?: string) => void;
	onDeliverGroupOpen: (rowIndex: number) => void;
	onUploadReturnsOpen: (rowIndex: number, taxSoftware: TaxSoftwareType) => void;
	selectedDocuments: ITaxReturn[];
	userDefaultSettings: IUserDefaultSettingsStates,
    companySettings: ICompanySettings,
    onGroupConfigurationOpen: (rowIndex: number) => void;
    parentResourceIdentifier: string;
    taxSoftwareSetting: ISuiteTaxSoftwareSetting;
}
const NO_INDEX = -1;

export enum DocumentStatus {
	None = "None",
	UPLOADED = "UPLOADED",
	ERROR = "ERROR",
	READY = "READY",
	PROCESSING = "PROCESSING",
	REVIEW = "REVIEW",
	USERSIGNED = "USERSIGNED",
	DELIVERED = "DELIVERED",
	PREPARINGFORDELIVERY = "PREPARINGFORDELIVERY",
	DELIVERYFAILED = "DELIVERYFAILED",
	NONSUPPORTED = "NONSUPPORTED",
	RECALLED = "RECALLED",
	DELETED = "DELETED",
	APPROVEDFORDELIVERYDESCRIPTION = "APPROVED FOR DELIVERY",
	PREPARINGFORDELIVERYDESCRIPTION = "PREPARING FOR DELIVERY",
	DELIVERYFAILEDDESCRIPTION = "DELIVERY FAILED",
	NONSUPPORTEDDESCRIPTION = "NON SUPPORTED",
	DUPLICATE = "DUPLICATE",
	DOWNLOADPDF = "DOWNLOADPDF",
	DOWNLOADPDFDESCRIPTION = "ALTERNATE DELIVERY",
	DOWNLOADINGFAILED = "DOWNLOADINGFAILED",
	PREPARINGFORPDFDOWNLOAD = "PREPARINGFORPDFDOWNLOAD",
	DOWNLOADINGFAILEDDESCRIPTION = "DOWNLOADING FAILED",
	PREPARINGFORPDFDOWNLOADDESCRIPTION = "PREPARING FOR PDF DOWNLOAD",
	TOBEREPROCESSED = "TOBEREPROCESSED",
	TOBEREPROCESSEDDESCRIPTION = "TO BE REPROCESSED",
	READYFORDELIVERY = "READYFORDELIVERY"
}



export class AssignmentsToolbar extends React.Component<AssignmentSetActionsProps, {}> {

	isValidGroupedReturnSelectedForDelivery = () => {
		let groupId;
		if(this.props.selectedDocuments[0]?.groupId && this.props.selectedDocuments[0]?.groupId > 0)
		{
			groupId = this.props.selectedDocuments[0]?.groupId
		}
		else{
			return false;
		}
		let isValid = true;
		this.props.selectedDocuments?.forEach((taxReturn) => {
		if(taxReturn?.groupId && taxReturn?.groupId > 0)
		{
			if (groupId != taxReturn?.groupId || taxReturn?.documentStatus !== DocumentStatus.READYFORDELIVERY) {
				isValid = false;
				return false;
			}
		}
		else 
		{
			isValid = false;
			return false;
		}
		});
		return isValid ? true : false;
	}

	draftReturnSelection = () => {
		let isDraft = false;
		this.props.selectedDocuments?.forEach((taxReturn) => {
			if (taxReturn?.draftStatus === DraftStatus[DraftStatus.Draft]) {
				isDraft = true;
				return true;
			}
		});
		return isDraft;
	}

	public render() {
		let isNonSupported = false;
		if (this.props.selectedDocuments[0] !== undefined && this.props.selectedDocuments.find(x => x.documentStatus == DocumentStatus.NONSUPPORTED))
			isNonSupported = true;
		else
			isNonSupported = false;
		var btnStyle = {
			minWidth: '105px',
			marginRight: '5px'
        };
		
		return <div>
			<div className="row marB10">
				<div className="col-sm-8">
					<h3>{this.props.pageTitle}</h3>
				</div>
			</div>
			<div className="row">
				<div className="col-sm-8">
                    <Button
                        data-resource-id={this.props.parentResourceIdentifier + SendTaxReturnResources.HeaderAssignButton}
						variant='default'
						className='btn btn-white'
						disabled={this.props.selectedRows.length === 0 || isNonSupported}
                        onClick={() => this.props.onAssignOpen(NO_INDEX, this.props.parentResourceIdentifier + SendTaxReturnResources.HeaderAssignButton)}
						style={btnStyle}
						data-test-auto="E07EA6FC-5CCE-4E3D-99DA-1A184DF5AD74">
						<i className='fas fa-user-check'></i> Assign
                    </Button>
                    <Button
                        data-resource-id={this.props.parentResourceIdentifier + SendTaxReturnResources.HeaderDeleteButton}
						variant='default'
						className='btn btn-white'
						disabled={this.props.selectedRows.length === 0}
                        onClick={() => this.props.onDeleteReturnsOpen(NO_INDEX, this.props.parentResourceIdentifier + SendTaxReturnResources.HeaderDeleteButton)}
						style={btnStyle}
                        data-test-auto="24A80398-C7EF-4676-870F-6F5316950DC4">
						<i className='fas fa-trash-alt'></i> Delete
                    </Button>
                    <Button
                        data-resource-id={this.props.parentResourceIdentifier + SendTaxReturnResources.HeaderSetAccessButton}
						variant='default'
						className='btn btn-white'
						disabled={this.props.selectedRows.length === 0 || isNonSupported}
                        onClick={() => this.props.onSetAccessOpen(NO_INDEX, this.props.parentResourceIdentifier + SendTaxReturnResources.HeaderSetAccessButton)}
						style={btnStyle}
						data-test-auto="A077CA6F-CF65-4136-873E-372B0BCB8F12">
						<i className='fas fa-key'></i> Set Access
                    </Button>
                    <Button
                        data-resource-id={this.props.parentResourceIdentifier + SendTaxReturnResources.HeaderGroupReturnButton}
						variant='default'
                        className='btn btn-white'
                        disabled={this.props.selectedRows.length === 0 || this.draftReturnSelection()}
						onClick={(e) => this.props.onGroupConfigurationOpen(NO_INDEX)}
						style={btnStyle}
                        data-test-auto="A9CAAC18-2E06-4AB2-8653-3AF993D517D0"
						title={this.draftReturnSelection() ? DraftReturnConstants.UnavailableGroupActions : ""}>
                        <i className='fas fa-user-check'></i> Group Actions
                    </Button>
					<Button
                        data-resource-id={this.props.parentResourceIdentifier + SendTaxReturnResources.HeaderDeliverGroupButton}
						variant='default'
                        className='btn btn-white'
                        disabled={this.props.selectedRows.length === 0 || !this.isValidGroupedReturnSelectedForDelivery()}
						onClick={() => this.props.onDeliverGroupOpen(NO_INDEX)}
						title = { DeliverGroupConstants.Title }
						style={btnStyle}
                        data-test-auto="A9CAAC18-2E06-4AB2-8653-3AF993D517D1">
                        <i className='fas fa-paper-plane'></i> Deliver Group
                    </Button>
				</div>
				<div className="col-sm-4 txt-ar">
                    <UploadFabButton
                        parentResourceIdentifier={SendTaxReturnResources.HeaderButtonPrefix}
                        taxSoftwareSetting={this.props.taxSoftwareSetting}
						userDefaultSettings={this.props.userDefaultSettings}
						onUploadReturnsClick={(taxSoftware: TaxSoftwareType) => { this.props.onUploadReturnsOpen(NO_INDEX, taxSoftware)}}
					/>
				</div>
			</div>
		</div>
	}
}
export default AssignmentsToolbar;

