import * as React from "react";
import { RouteComponentProps } from "react-router";
import { EmailMessagePopUp } from "./ClientInstructions/EmailMessage/EmailMessagePopUp";
import { ClientMessagePopUp } from "./ClientInstructions/ClientMessage/ClientMessagePopUp";
import * as bootbox from "bootbox";
import { ISSRSettings, initialSSRSettings, SSRSettings } from "../../Core/ViewModels/Company/Settings/SSRSettingsViewModel";
import {
    IClientMessage,
    IEmailTemplateMessage,
    clientInstructions,
    initialClientMessage,
    initialEmailMessage
} from "../../Core/ViewModels/CompanySettings/ClientInstructionViewModel";
import { VenusNotifier } from "../helper/VenusNotifier";
import * as ClientMessageStore from "../../store/settings/ClientMessageStore";
import * as EmailMessageStore from "../../store/settings/EmailMessageStore";
import * as CompanyStore from "../../store/company/CompanyStore";
import * as SSRSettingsStore from "../../store/company/settings/SSRSettingsStore";
import * as ReminderStore from "../../store/common/ReminderStore";
import * as Constants from "../helper/Constants";
import { EmailMessage } from "./ClientInstructions/EmailMessage/EmailMessage";
import { ClientMessage } from "./ClientInstructions/ClientMessage/ClientMessage";
import * as UserSettingsStore from "../../store/userManagement/UserSettingStore";
import { decodeText } from "../helper/HelperFunctions";
import * as ApplicationHelptextStore from "../../store/common/ApplicationHelptextStore";
import { InitialEmailMessage } from "./ClientInstructions/EmailMessage/InitialEmailMessage";
import { TaxReturnSettingsResources } from "../../components/helper/ResourceIdConstants";

export interface ClientInstructionReturnsState {
    showEmailMessagePopup: boolean;
    showClientMessagePopup: boolean;

    defaultMessageId: number;
    headerTitle: string;
    typeId: number;
    ssrSettings: ISSRSettings;
    buttonText: string;
    title: string;
    isUpdateMessage: boolean;

    selectedInitialWelcomeMessage: number;
    selectedVoucherReminderMessage: number;
    selectedDownloadEsignMessage: number;
    selectedDownloadInstruction: number;
    selectedPaymentInstruction: number;
    selectedSigningReminder: number;
    selectedInvoicePaymentSuccessMessage: number;
    selectedVoucherRevisionMessage: number;
    defaultWelcomeMessage: number;
    defaultInitialWelcomeMessage: number;
    defaultVoucherReminderMessage: number;
    defaultDownloadEsignMessage: number;
    defaultDownloadInstruction: number;
    defaultPaymentInstruction: number;
    defaultSigningReminder: number;
    defaultInvoicePaymentSuccessMessage: number;
    defaultVoucherRevisionMessage: number;
    selectedMessageId: number;
    showLoader: boolean;
    loaderText: string;
    showPopUpLoader: boolean;
    defaultInitialEmailManualSign: number;
    selectedInitialEmailManualSign: number;
    initialMailType: number;
    resourceId?: string;
    defaultRecalledReturnMail: number;
    selectedRecalledReturnMail: number;
}

type ClientInstructionReturnsProps = {
    clientMessageList: ClientMessageStore.IClientMessageStoreState;
    emailMessageList: EmailMessageStore.IEmailMessageStoreState;
    companyData: CompanyStore.ICompanyData;
    helptexts: ApplicationHelptextStore.IHelptextState;
} & typeof ClientMessageStore.ActionCreators &
    typeof EmailMessageStore.ActionCreators &
    typeof CompanyStore.actionCreators &
    typeof UserSettingsStore.actionCreators &
    typeof ApplicationHelptextStore.actionCreators &
    typeof ReminderStore.actionCreators &
    typeof SSRSettingsStore.actionCreators &
    RouteComponentProps<{}>;

export class ClientInstructionReturns extends React.Component<ClientInstructionReturnsProps, ClientInstructionReturnsState> {
    state: ClientInstructionReturnsState = {
        showEmailMessagePopup: false,
        showClientMessagePopup: false,

        defaultMessageId: 0,
        headerTitle: "",
        typeId: 0,
        ssrSettings: initialSSRSettings,
        buttonText: "",
        title: "",
        isUpdateMessage: false,
        selectedInitialWelcomeMessage: 0,
        selectedVoucherReminderMessage: 0,
        selectedDownloadEsignMessage: 0,
        selectedDownloadInstruction: 0,
        selectedPaymentInstruction: 0,
        selectedSigningReminder: 0,
        selectedInvoicePaymentSuccessMessage: 0,
        selectedVoucherRevisionMessage: 0,
        defaultWelcomeMessage: 0,
        defaultInitialWelcomeMessage: 0,
        defaultVoucherReminderMessage: 0,
        defaultDownloadEsignMessage: 0,
        defaultDownloadInstruction: 0,
        defaultPaymentInstruction: 0,
        defaultSigningReminder: 0,
        defaultInvoicePaymentSuccessMessage: 0,
        defaultVoucherRevisionMessage: 0,
        selectedMessageId: 0,
        showLoader: false,
        loaderText: "",
        showPopUpLoader: false,
        defaultInitialEmailManualSign: 0,
        selectedInitialEmailManualSign: 0,
        initialMailType: 1,
        resourceId: "",
        defaultRecalledReturnMail: 0,
        selectedRecalledReturnMail: 0
    };
    componentDidMount() {
        const element = document.getElementById("content-wrapper");
        element && element.scrollTo(0, 0);
    }
    UNSAFE_componentWillMount() {
        this.props.requestAllSSRSettings(true);
        this.props.requestUserSettings(true);
        this.props.requestEmailMessages(true);
        this.props.requestClientMessages(true);
        this.props.requestHelptextsForApplication(true);
        this.setState({ showLoader: true, loaderText: "Loading..." });
    }

    UNSAFE_componentWillReceiveProps(nextProps: ClientInstructionReturnsProps) {
        this.setState({
            selectedInitialWelcomeMessage: nextProps.companyData.ssrSettings
                ? nextProps.companyData.ssrSettings.defaultMessageSettings.initialEmail
                : 0,
            selectedVoucherReminderMessage: nextProps.companyData.ssrSettings
                ? nextProps.companyData.ssrSettings.defaultMessageSettings.voucherReminders
                : 0,
            selectedDownloadEsignMessage: nextProps.companyData.ssrSettings
                ? nextProps.companyData.ssrSettings.defaultMessageSettings.downloadSignedForms
                : 0,
            selectedDownloadInstruction: nextProps.companyData.ssrSettings
                ? nextProps.companyData.ssrSettings.defaultMessageSettings.downloadInstruction
                : 0,
            selectedPaymentInstruction: nextProps.companyData.ssrSettings
                ? nextProps.companyData.ssrSettings.defaultMessageSettings.paymentInstruction
                : 0,
            selectedSigningReminder: nextProps.companyData.ssrSettings
                ? nextProps.companyData.ssrSettings.defaultMessageSettings.signingReminder
                : 0,
            selectedInvoicePaymentSuccessMessage: nextProps.companyData.ssrSettings
                ? nextProps.companyData.ssrSettings.defaultMessageSettings.invoicePaymentSuccessMail
                : 0,
            selectedVoucherRevisionMessage: nextProps.companyData.ssrSettings
                ? nextProps.companyData.ssrSettings.defaultMessageSettings.voucherRevision
                : 0,
            defaultWelcomeMessage: nextProps.companyData.ssrSettings
                ? nextProps.companyData.ssrSettings.defaultMessageSettings.welcomeMessage
                : 0,
            defaultInitialWelcomeMessage: nextProps.companyData.ssrSettings
                ? nextProps.companyData.ssrSettings.defaultMessageSettings.initialEmail
                : 0,
            defaultVoucherReminderMessage: nextProps.companyData.ssrSettings
                ? nextProps.companyData.ssrSettings.defaultMessageSettings.voucherReminders
                : 0,
            defaultDownloadEsignMessage: nextProps.companyData.ssrSettings
                ? nextProps.companyData.ssrSettings.defaultMessageSettings.downloadSignedForms
                : 0,
            defaultDownloadInstruction: nextProps.companyData.ssrSettings
                ? nextProps.companyData.ssrSettings.defaultMessageSettings.downloadInstruction
                : 0,
            defaultPaymentInstruction: nextProps.companyData.ssrSettings
                ? nextProps.companyData.ssrSettings.defaultMessageSettings.paymentInstruction
                : 0,
            defaultSigningReminder: nextProps.companyData.ssrSettings
                ? nextProps.companyData.ssrSettings.defaultMessageSettings.signingReminder
                : 0,
            defaultInvoicePaymentSuccessMessage: nextProps.companyData.ssrSettings
                ? nextProps.companyData.ssrSettings.defaultMessageSettings.invoicePaymentSuccessMail
                : 0,
            defaultVoucherRevisionMessage: nextProps.companyData.ssrSettings
                ? nextProps.companyData.ssrSettings.defaultMessageSettings.voucherRevision
                : 0,
            defaultInitialEmailManualSign: nextProps.companyData.ssrSettings
                ? nextProps.companyData.ssrSettings.defaultMessageSettings.initialMailForManualSign
                : 0,
            selectedInitialEmailManualSign: nextProps.companyData.ssrSettings
                ? nextProps.companyData.ssrSettings.defaultMessageSettings.initialMailForManualSign
                : 0,
            defaultRecalledReturnMail: nextProps.companyData.ssrSettings
                ? nextProps.companyData.ssrSettings.defaultMessageSettings.recallReturnMail
                : 0,
            selectedRecalledReturnMail: nextProps.companyData.ssrSettings
                ? nextProps.companyData.ssrSettings.defaultMessageSettings.recallReturnMail
                : 0
        });

        this.setState(
            {
                showLoader:
                    !nextProps.clientMessageList.isLoading &&
                    !nextProps.emailMessageList.isLoading &&
                    !nextProps.companyData.isLoading
                        ? false
                        : true,
                showPopUpLoader:
                    !nextProps.clientMessageList.isLoading &&
                    !nextProps.emailMessageList.isLoading &&
                    !nextProps.companyData.isLoading
                        ? false
                        : true
            },
            () => {
                if (!this.state.showPopUpLoader)
                    this.setState({
                        showEmailMessagePopup: false,
                        showClientMessagePopup: false
                    });
            }
        );
    }

    private onInitialMessageManualSignListItemClick = (messageId: number) => {
        this.setState({ selectedInitialEmailManualSign: messageId });
    };

    private onInitialMessageListItemClick = (messageId: number) => {
        this.setState({ selectedInitialWelcomeMessage: messageId });
    };

    private onVoucherReminderListItemClick = (messageId: number) => {
        this.setState({ selectedVoucherReminderMessage: messageId });
    };

    private onDownloadEsignListItemClick = (messageId: number) => {
        this.setState({ selectedDownloadEsignMessage: messageId });
    };

    private onRecalledReturnMailListItemClick = (messageId: number) => {
        this.setState({ selectedRecalledReturnMail: messageId });
    };

    private onDownloadInstructionListItemClick = (messageId: number) => {
        this.setState({ selectedDownloadInstruction: messageId });
    };

    private onPaymentInstructionListItemClick = (messageId: number) => {
        this.setState({ selectedPaymentInstruction: messageId });
    };

    private onSigningReminderListItemClick = (messageId: number) => {
        this.setState({ selectedSigningReminder: messageId });
    };

    private onInvoicePaymentSuccessMessageListItemClick = (messageId: number) => {
        this.setState({ selectedInvoicePaymentSuccessMessage: messageId });
    };

    private onVoucherRevisionSuccessMessageListItemClick = (messageId: number) => {
        this.setState({ selectedVoucherRevisionMessage: messageId });
    };

    private onClientInstructionAddButtonClick = (
        defaultMessageId: number,
        selectedMessageId: number,
        typeId: number,
        resourceId?: string
    ) => {
        this.setState({
            defaultMessageId: defaultMessageId,
            selectedMessageId: selectedMessageId,
            buttonText: "Add",
            title: "Add Client Instruction",
            isUpdateMessage: false,
            showClientMessagePopup: true,
            typeId: typeId,
            resourceId: resourceId
        });
    };

    private onClientInstructionEditButtonClick = (
        defaultMessageId: number,
        selectedMessageId: number,
        typeId: number,
        resourceId?: string
    ) => {
        this.setState({
            defaultMessageId: defaultMessageId,
            selectedMessageId: selectedMessageId,
            buttonText: "Save",
            title: "Edit Client Instruction",
            isUpdateMessage: true,
            showClientMessagePopup: true,
            typeId: typeId,
            resourceId: resourceId
        });
    };

    private onEmailInstructionAddButtonClick = (
        defaultMessageId: number,
        selectedMessageId: number,
        typeId: number,
        resourceId?: string
    ) => {
        this.setState({
            defaultMessageId: defaultMessageId,
            selectedMessageId: selectedMessageId,
            showEmailMessagePopup: true,
            buttonText: "Add",
            isUpdateMessage: false,
            title: "Add Client Instruction",
            typeId: typeId,
            resourceId: resourceId
        });
    };

    private onEmailInstructionEditButtonClick = (
        defaultMessageId: number,
        selectedMessageId: number,
        typeId: number,
        resourceId?: string
    ) => {
        this.setState({
            defaultMessageId: defaultMessageId,
            selectedMessageId: selectedMessageId,
            showEmailMessagePopup: true,
            buttonText: "Save",
            isUpdateMessage: true,
            title: "Edit Client Instruction",
            typeId: typeId,
            resourceId: resourceId
        });
    };

    private saveEmailMessage = (message: IEmailTemplateMessage, isSetDefault: boolean, isToggled: boolean) => {
        let companyData: ISSRSettings = this.props.companyData.ssrSettings
            ? this.props.companyData.ssrSettings
            : initialSSRSettings;
        message.type = this.state.typeId;
        message.subject = decodeText(message.subject);
        message.name = decodeText(message.name);
        switch (this.state.typeId) {
            case clientInstructions.voucherReminder:
                this.props.saveEmailMessageDetail(
                    message,
                    companyData,
                    isSetDefault,
                    this.state.typeId,
                    undefined,
                    this.state.resourceId
                );
                break;
            case clientInstructions.signingReminder:
                this.props.saveEmailMessageDetail(
                    message,
                    companyData,
                    isSetDefault,
                    this.state.typeId,
                    undefined,
                    this.state.resourceId
                );
                break;
            default:
                this.props.saveEmailMessageDetail(
                    message,
                    companyData,
                    isSetDefault,
                    this.state.typeId,
                    undefined,
                    this.state.resourceId
                );
        }
        this.setState({
            showPopUpLoader: true
        });
    };

    private isMessageChange = (message: IEmailTemplateMessage) => {
        let selectedMessage = this.props.emailMessageList.data[this.state.selectedMessageId].emailMessage;
        return (selectedMessage.subject !== message.subject || selectedMessage.text !== message.text) &&
            selectedMessage.id === this.state.defaultMessageId
            ? true
            : false;
    };

    private editEmailMessage = (message: IEmailTemplateMessage, isSetDefault: boolean, isToggled: boolean) => {
        message.subject = decodeText(message.subject);
        message.name = decodeText(message.name);
        this.props.updateEmailMessageDetail(message, this.state.resourceId);
        if (isToggled && isSetDefault) {
            switch (this.state.typeId) {
                case clientInstructions.voucherReminder:
                    this.saveDefaultMessage(message.id, undefined, this.state.resourceId);
                    break;
                case clientInstructions.signingReminder:
                    this.saveDefaultMessage(message.id, undefined, this.state.resourceId);
                    break;
                default:
                    this.saveDefaultMessage(message.id, undefined, this.state.resourceId);
            }
        }
        this.setState({
            showPopUpLoader: true
        });
    };

    private deleteEmailMessage = (selectedMessageId: number, defaultMessageId: number, resourceId?: string) => {
        if (this.props.emailMessageList.data[selectedMessageId].emailMessage.id == defaultMessageId) {
            VenusNotifier.Warning(Constants.ClientInstructionConstants.DeleteInstructionWarning, "");
        } else {
            let _currentObject = this;
            bootbox.confirm({
                title: "Delete Instruction",
                message: "Are you sure, You want to delete this Instruction ?",
                buttons: {
                    cancel: {
                        label: '<i class="fas fa-times"></i> Cancel',
                        className: "btn-white btn-default"
                    },
                    confirm: {
                        label: '<i class="fas fa-check"></i> Delete',
                        className: "btn-info"
                    }
                },
                callback: (result: boolean) => {
                    if (result == true) {
                        _currentObject.props.deleteEmailMessageDetail(
                            _currentObject.props.emailMessageList.data[selectedMessageId].emailMessage,
                            resourceId
                        );
                        _currentObject.setState({ showLoader: true });
                    }
                }
            });
        }
    };

    private deleteClientMessage = (selectedMessageId: number, defaultMessageId: number, resourceId?: string) => {
        if (this.props.clientMessageList.data[selectedMessageId].clientMessage.id == defaultMessageId) {
            VenusNotifier.Warning(Constants.ClientInstructionConstants.DeleteInstructionWarning, "");
        } else {
            let _currentObject = this;
            bootbox.confirm({
                title: "Delete Instruction",
                message: "Are you sure, You want to delete this Instruction ?",
                buttons: {
                    cancel: {
                        label: '<i class="fas fa-times"></i> Cancel',
                        className: "btn-white btn-default"
                    },
                    confirm: {
                        label: '<i class="fas fa-check"></i> Delete',
                        className: "btn-info"
                    }
                },
                callback: (result: boolean) => {
                    if (result == true) {
                        _currentObject.props.deleteClientMessageDetail(
                            _currentObject.props.clientMessageList.data[selectedMessageId].clientMessage,
                            resourceId
                        );
                        _currentObject.setState({ showLoader: true });
                    }
                }
            });
        }
    };

    private saveClientMessage = (message: IClientMessage, isSetDefault: boolean, isToggled: boolean) => {
        let companyData: ISSRSettings = this.props.companyData.ssrSettings
            ? this.props.companyData.ssrSettings
            : initialSSRSettings;
        message.type = this.state.typeId;
        message.name = decodeText(message.name);
        this.props.saveClientMessageDetail(message, companyData, isSetDefault, this.state.typeId, this.state.resourceId);
        this.setState({
            showPopUpLoader: true
        });
    };

    private editClientMessage = (message: IClientMessage, isSetDefault: boolean, isToggled: boolean) => {
        message.name = decodeText(message.name);
        this.props.updateClientMessageDetail(message, this.state.resourceId);
        if (isToggled && isSetDefault) {
            this.saveDefaultMessage(message.id, undefined, this.state.resourceId);
        }
        this.setState({
            showPopUpLoader: true
        });
    };

    private onCancelButtonClick = () => {
        this.setState({
            showClientMessagePopup: false,
            showEmailMessagePopup: false
        });
    };

    private saveDefaultMessage = (messageId: number, callback?: () => void, resourceId?: string) => {
        let companyData: any = new SSRSettings(
            this.props.companyData.ssrSettings
                ? this.props.companyData.ssrSettings.defaultMessageSettings
                : initialSSRSettings.defaultMessageSettings
        );

        if (this.state.typeId == clientInstructions.k1WelcomePage)
            companyData.defaultMessageSettings.k1WelcomeMessage = messageId;
        else if (this.state.typeId == clientInstructions.initialEmail)
            companyData.defaultMessageSettings.initialEmail = messageId;
        else if (this.state.typeId == clientInstructions.voucherReminder)
            companyData.defaultMessageSettings.voucherReminders = messageId;
        else if (this.state.typeId == clientInstructions.downloadTaxDocument)
            companyData.defaultMessageSettings.downloadTaxDocuments = messageId;
        else if (this.state.typeId == clientInstructions.downloadEsignedFroms)
            companyData.defaultMessageSettings.downloadSignedForms = messageId;
        else if (this.state.typeId == clientInstructions.k1Distribution)
            companyData.defaultMessageSettings.k1Distribution = messageId;
        else if (this.state.typeId == clientInstructions.downloadInstruction)
            companyData.defaultMessageSettings.downloadInstruction = messageId;
        else if (this.state.typeId == clientInstructions.paymentInstruction)
            companyData.defaultMessageSettings.paymentInstruction = messageId;
        else if (this.state.typeId == clientInstructions.signingReminder)
            companyData.defaultMessageSettings.signingReminder = messageId;
        else if (this.state.typeId == clientInstructions.InvoicePaymentSuccessMail)
            companyData.defaultMessageSettings.invoicePaymentSuccessMail = messageId;
        else if (this.state.typeId == clientInstructions.ResendSignedReturn)
            companyData.defaultMessageSettings.resendSignedReturn = messageId;
        else if (this.state.typeId == clientInstructions.voucherRevision)
            companyData.defaultMessageSettings.voucherRevision = messageId;
        else if (this.state.typeId == clientInstructions.initialMailForManualSign)
            companyData.defaultMessageSettings.initialMailForManualSign = messageId;
        else if (this.state.typeId == clientInstructions.recalledReturnMail)
            companyData.defaultMessageSettings.recallReturnMail = messageId;
        this.props.updateSSRSettings(companyData, callback, resourceId);
    };

    handleInitialMailType = (event: any) => {
        if (event && event.value > 0) {
            let value = event.value;
            this.setState({
                initialMailType: value
            });
        }
    };

    public render() {
        return (
            <div style={{ marginTop: "20px" }} className="font14">
                <InitialEmailMessage
                    heading={"Initial Email"}
                    description={"Initial email sent to clients"}
                    emailMessages={Object.keys(this.props.emailMessageList.data)
                        .filter((k, i) =>
                            this.state.initialMailType === 1
                                ? this.props.emailMessageList.data[parseInt(k)].emailMessage.type ==
                                  clientInstructions.initialEmail
                                : this.props.emailMessageList.data[parseInt(k)].emailMessage.type ==
                                  clientInstructions.initialMailForManualSign
                        )
                        .reduce((obj: any, key: any) => {
                            obj[key] = this.props.emailMessageList.data[key];
                            return obj;
                        }, {})}
                    defaultMessage={
                        this.state.initialMailType === 1
                            ? this.state.defaultInitialWelcomeMessage
                            : this.state.defaultInitialEmailManualSign
                    }
                    selectedMessage={
                        this.state.initialMailType === 1
                            ? this.state.selectedInitialWelcomeMessage
                            : this.state.selectedInitialEmailManualSign
                    }
                    onListItemClick={
                        this.state.initialMailType === 1
                            ? this.onInitialMessageListItemClick
                            : this.onInitialMessageManualSignListItemClick
                    }
                    typeId={
                        this.state.initialMailType === 1
                            ? clientInstructions.initialEmail
                            : clientInstructions.initialMailForManualSign
                    }
                    onAddButtonClick={this.onEmailInstructionAddButtonClick}
                    onEditButtonClick={this.onEmailInstructionEditButtonClick}
                    onDeleteButtonClick={this.deleteEmailMessage}
                    showLoader={this.state.showLoader}
                    loaderText={this.state.loaderText}
                    initialMailType={this.state.initialMailType}
                    handleInitialMailType={this.handleInitialMailType}
                    parentResourceIdentifier={
                        TaxReturnSettingsResources.ClientInstructionPrefix + TaxReturnSettingsResources.InitialEmail
                    }
                />
                <hr></hr>

                <EmailMessage
                    heading={"Review and Sign Reminder Email"}
                    description={"Signing reminder sent to client"}
                    emailMessages={Object.keys(this.props.emailMessageList.data)
                        .filter(
                            (k, i) =>
                                this.props.emailMessageList.data[parseInt(k)].emailMessage.type ==
                                clientInstructions.signingReminder
                        )
                        .reduce((obj: any, key: any) => {
                            obj[key] = this.props.emailMessageList.data[key];
                            return obj;
                        }, {})}
                    defaultMessage={this.state.defaultSigningReminder}
                    selectedMessage={this.state.selectedSigningReminder}
                    onListItemClick={this.onSigningReminderListItemClick}
                    typeId={clientInstructions.signingReminder}
                    onAddButtonClick={this.onEmailInstructionAddButtonClick}
                    onEditButtonClick={this.onEmailInstructionEditButtonClick}
                    onDeleteButtonClick={this.deleteEmailMessage}
                    showLoader={this.state.showLoader}
                    loaderText={this.state.loaderText}
                    parentResourceIdentifier={
                        TaxReturnSettingsResources.ClientInstructionPrefix + TaxReturnSettingsResources.ReviewAndSign
                    }
                />
                <hr></hr>

                <EmailMessage
                    heading={"Voucher Reminders"}
                    description={"Payment and Estimated Payment voucher reminders sent to clients"}
                    emailMessages={Object.keys(this.props.emailMessageList.data)
                        .filter(
                            (k, i) =>
                                this.props.emailMessageList.data[parseInt(k)].emailMessage.type ==
                                clientInstructions.voucherReminder
                        )
                        .reduce((obj: any, key: any) => {
                            obj[key] = this.props.emailMessageList.data[key];
                            return obj;
                        }, {})}
                    defaultMessage={this.state.defaultVoucherReminderMessage}
                    selectedMessage={this.state.selectedVoucherReminderMessage}
                    onListItemClick={this.onVoucherReminderListItemClick}
                    typeId={clientInstructions.voucherReminder}
                    onAddButtonClick={this.onEmailInstructionAddButtonClick}
                    onEditButtonClick={this.onEmailInstructionEditButtonClick}
                    onDeleteButtonClick={this.deleteEmailMessage}
                    showLoader={this.state.showLoader}
                    loaderText={this.state.loaderText}
                    parentResourceIdentifier={
                        TaxReturnSettingsResources.ClientInstructionPrefix + TaxReturnSettingsResources.VoucherReminders
                    }
                />
                <hr></hr>

                <EmailMessage
                    heading={"Download E-Signed Forms"}
                    description={"Once all parties have e-signed all documents"}
                    emailMessages={Object.keys(this.props.emailMessageList.data)
                        .filter(
                            (k, i) =>
                                this.props.emailMessageList.data[parseInt(k)].emailMessage.type ==
                                clientInstructions.downloadEsignedFroms
                        )
                        .reduce((obj: any, key: any) => {
                            obj[key] = this.props.emailMessageList.data[key];
                            return obj;
                        }, {})}
                    defaultMessage={this.state.defaultDownloadEsignMessage}
                    selectedMessage={this.state.selectedDownloadEsignMessage}
                    onListItemClick={this.onDownloadEsignListItemClick}
                    typeId={clientInstructions.downloadEsignedFroms}
                    onAddButtonClick={this.onEmailInstructionAddButtonClick}
                    onEditButtonClick={this.onEmailInstructionEditButtonClick}
                    onDeleteButtonClick={this.deleteEmailMessage}
                    showLoader={this.state.showLoader}
                    loaderText={this.state.loaderText}
                    parentResourceIdentifier={
                        TaxReturnSettingsResources.ClientInstructionPrefix + TaxReturnSettingsResources.InitialEmail
                    }
                />
                <hr></hr>

                <EmailMessage
                    heading={"Recalled Return Instructions"}
                    description={"Recalled Return email sent to clients"}
                    emailMessages={Object.keys(this.props.emailMessageList.data)
                        .filter(
                            (k, i) =>
                                this.props.emailMessageList.data[parseInt(k)].emailMessage.type ==
                                clientInstructions.recalledReturnMail
                        )
                        .reduce((obj: any, key: any) => {
                            obj[key] = this.props.emailMessageList.data[key];
                            return obj;
                        }, {})}
                    defaultMessage={this.state.defaultRecalledReturnMail}
                    selectedMessage={this.state.selectedRecalledReturnMail}
                    onListItemClick={this.onRecalledReturnMailListItemClick}
                    typeId={clientInstructions.recalledReturnMail}
                    onAddButtonClick={this.onEmailInstructionAddButtonClick}
                    onEditButtonClick={this.onEmailInstructionEditButtonClick}
                    onDeleteButtonClick={this.deleteEmailMessage}
                    showLoader={this.state.showLoader}
                    loaderText={this.state.loaderText}
                    parentResourceIdentifier={
                        TaxReturnSettingsResources.ClientInstructionPrefix + TaxReturnSettingsResources.RecallReturn
                    }
                />
                <hr></hr>

                <ClientMessage
                    heading={"Payment Instruction"}
                    description={"Header in payment step client side"}
                    clientMessages={Object.keys(this.props.clientMessageList.data)
                        .filter(
                            (k, i) =>
                                this.props.clientMessageList.data[parseInt(k)].clientMessage.type ==
                                clientInstructions.paymentInstruction
                        )
                        .reduce((obj: any, key: any) => {
                            obj[key] = this.props.clientMessageList.data[key];
                            return obj;
                        }, {})}
                    defaultMessage={this.state.defaultPaymentInstruction}
                    selectedMessage={this.state.selectedPaymentInstruction}
                    onListItemClick={this.onPaymentInstructionListItemClick}
                    typeId={clientInstructions.paymentInstruction}
                    onAddButtonClick={this.onClientInstructionAddButtonClick}
                    onEditButtonClick={this.onClientInstructionEditButtonClick}
                    onDeleteButtonClick={this.deleteClientMessage}
                    showLoader={this.state.showLoader}
                    loaderText={this.state.loaderText}
                    parentResourceIdentifier={
                        TaxReturnSettingsResources.ClientInstructionPrefix + TaxReturnSettingsResources.PaymentInstructions
                    }
                />
                <hr></hr>

                <EmailMessage
                    heading={"Invoice Payment Success Mail Notification"}
                    description={"Once invoice payment is successfully completed by Taxpayer"}
                    emailMessages={Object.keys(this.props.emailMessageList.data)
                        .filter(
                            (k, i) =>
                                this.props.emailMessageList.data[parseInt(k)].emailMessage.type ==
                                clientInstructions.InvoicePaymentSuccessMail
                        )
                        .reduce((obj: any, key: any) => {
                            obj[key] = this.props.emailMessageList.data[key];
                            return obj;
                        }, {})}
                    defaultMessage={this.state.defaultInvoicePaymentSuccessMessage}
                    selectedMessage={this.state.selectedInvoicePaymentSuccessMessage}
                    onListItemClick={this.onInvoicePaymentSuccessMessageListItemClick}
                    typeId={clientInstructions.InvoicePaymentSuccessMail}
                    onAddButtonClick={this.onEmailInstructionAddButtonClick}
                    onEditButtonClick={this.onEmailInstructionEditButtonClick}
                    onDeleteButtonClick={this.deleteEmailMessage}
                    showLoader={this.state.showLoader}
                    loaderText={this.state.loaderText}
                    parentResourceIdentifier={
                        TaxReturnSettingsResources.ClientInstructionPrefix + TaxReturnSettingsResources.InvoicePaymentSuccess
                    }
                />
                <hr></hr>

                <EmailMessage
                    heading={"Voucher Revision Mail Notification"}
                    description={"Once new voucher or transmittal is added in delivered or archive returns"}
                    emailMessages={Object.keys(this.props.emailMessageList.data)
                        .filter(
                            (k, i) =>
                                this.props.emailMessageList.data[parseInt(k)].emailMessage.type ==
                                clientInstructions.voucherRevision
                        )
                        .reduce((obj: any, key: any) => {
                            obj[key] = this.props.emailMessageList.data[key];
                            return obj;
                        }, {})}
                    defaultMessage={this.state.defaultVoucherRevisionMessage}
                    selectedMessage={this.state.selectedVoucherRevisionMessage}
                    onListItemClick={this.onVoucherRevisionSuccessMessageListItemClick}
                    typeId={clientInstructions.voucherRevision}
                    onAddButtonClick={this.onEmailInstructionAddButtonClick}
                    onEditButtonClick={this.onEmailInstructionEditButtonClick}
                    onDeleteButtonClick={this.deleteEmailMessage}
                    showLoader={this.state.showLoader}
                    loaderText={this.state.loaderText}
                    parentResourceIdentifier={
                        TaxReturnSettingsResources.ClientInstructionPrefix +
                        TaxReturnSettingsResources.VoucherRevisionNotification
                    }
                />
                <hr></hr>

                <EmailMessagePopUp
                    showState={this.state.showEmailMessagePopup}
                    onHide={this.onCancelButtonClick}
                    submitButtonClick={this.state.isUpdateMessage ? this.editEmailMessage : this.saveEmailMessage}
                    buttonText={this.state.buttonText}
                    selectedMessageDetail={
                        this.state.isUpdateMessage
                            ? this.props.emailMessageList.data[this.state.selectedMessageId]
                                ? this.props.emailMessageList.data[this.state.selectedMessageId].emailMessage
                                : initialEmailMessage
                            : initialEmailMessage
                    }
                    title={this.state.title}
                    defaultMessageId={this.state.defaultMessageId}
                    isUpdate={this.state.isUpdateMessage}
                    loaderText={"Saving..."}
                    showLoader={this.state.showPopUpLoader}
                    emailMessages={Object.keys(this.props.emailMessageList.data)
                        .filter((k, i) => this.props.emailMessageList.data[parseInt(k)].emailMessage.type == this.state.typeId)
                        .reduce((obj: any, key: any) => {
                            obj[key] = this.props.emailMessageList.data[key];
                            return obj;
                        }, {})}
                />
                <ClientMessagePopUp
                    onHide={this.onCancelButtonClick}
                    showState={this.state.showClientMessagePopup}
                    submitButtonClick={this.state.isUpdateMessage ? this.editClientMessage : this.saveClientMessage}
                    buttonText={this.state.buttonText}
                    selectedMessageDetail={
                        this.state.isUpdateMessage
                            ? this.props.clientMessageList.data[this.state.selectedMessageId]
                                ? this.props.clientMessageList.data[this.state.selectedMessageId].clientMessage
                                : initialClientMessage
                            : initialClientMessage
                    }
                    title={this.state.title}
                    defaultMessageId={this.state.defaultMessageId}
                    isUpdate={this.state.isUpdateMessage}
                    loaderText={"Saving..."}
                    showLoader={this.state.showPopUpLoader}
                    clientMessages={Object.keys(this.props.clientMessageList.data)
                        .filter((k, i) => this.props.clientMessageList.data[parseInt(k)].clientMessage.type == this.state.typeId)
                        .reduce((obj: any, key: any) => {
                            obj[key] = this.props.clientMessageList.data[key];
                            return obj;
                        }, {})}
                />
            </div>
        );
    }
}
