import React from "react";
import { ReactComponent as SSRLogoWithTitle } from "../assets/images/tax-return-woodmark.svg";

const LoadingView: React.FC = () => (
    <div className="login-container flex-column-center-all">
        <SSRLogoWithTitle />
        <div className="loading-text" data-title="dot-spin">
            <div className="dot-spin"></div>
        </div>
    </div>
);

LoadingView.displayName = "LoadingView";

export default LoadingView;
