import * as React from "react";
import { RouteComponentProps } from "react-router";
import { VenusNotifier } from "../../../components/helper/VenusNotifier";
import { ReminderType } from "../../../components/common/Reminder";
import * as VoucherReminderManagementState from "../../../store/reports/VoucherReminderManagement/VoucherReminderManagementState";
import * as VoucherReminderManagementStore from "../../../store/reports/VoucherReminderManagement/VoucherReminderManagementStore";
import { VoucherReminderReportHeader } from "./../VoucherReminderManagement/VoucherReminderReportHeader";
import { VoucherReminderReportTable } from "./../VoucherReminderManagement/VoucherReminderReportTable";
import { ReminderReportUrl, ExportVoucherReminderReportUrl } from "../../../components/helper/Constants";
import { ReminderManagementResource } from "../../../components/helper/ResourceIdConstants";
import ResourceValidator from "@sssuite-component-ui/resource-id-validator";
import { actionCreators, IUserResources } from "../../../store/userManagement/UserStore";
let moment = require("moment");

const pageSize: number = 20;

type VoucherReminderReportProps = {
    voucherReminderReport: VoucherReminderManagementState.VoucherReminderReportState;
    userResources: IUserResources;
} & typeof VoucherReminderManagementStore.actionCreators &
    typeof actionCreators &
    RouteComponentProps<{ page: string }>;

export class VoucherReminderReport extends React.Component<
    VoucherReminderReportProps,
    VoucherReminderManagementState.VoucherReminderReportPageState
> {
    constructor(props: VoucherReminderReportProps) {
        super(props);
        this.state = {
            clientID: "",
            clientName: "",
            dueDate: undefined,
            fromDate: undefined,
            statusUpdatedOn: undefined,
            sortColumn: "ClientId",
            page: 1,
            pageSize: pageSize,
            recepient: "",
            authorityName: "",
            reminderDate: undefined,
            reminderStatus: this.getReminderStausFilter(),
            reminderType: ReminderType.Voucher,
            sortDirection: "asc",
            taxYear: new Date().getFullYear() - 1,
            toDate: undefined,
            showReminderInfo: false,
            officeLocation: ""
        };

        this.onFilterChange = this.onFilterChange.bind(this);
        this.onPageChange = this.onPageChange.bind(this);
        this.onSortChange = this.onSortChange.bind(this);
        this.buildQuery = this.buildQuery.bind(this);
        this.fetchVoucherReminderReport = this.fetchVoucherReminderReport.bind(this);
        this.loadVoucherReminderReport = this.loadVoucherReminderReport.bind(this);
        this.onPageReload = this.onPageReload.bind(this);
        this.handleFilterRecordOnDateRange = this.handleFilterRecordOnDateRange.bind(this);
        this.handleChangedFromDate = this.handleChangedFromDate.bind(this);
        this.handleChangedToDate = this.handleChangedToDate.bind(this);
        this.onExportToExcel = this.onExportToExcel.bind(this);
    }

    componentDidMount() {
        this.props.getArchivedReminderReportEndDate(this.setDatesOnMount);
        const element = document.getElementById("content-wrapper");
        element && element.scrollTo(0, 0);
    }

    private setDatesOnMount = () => {
        const yesterdayDate = this.getYesterdayDate();
        const todayDate = new Date();
        const releaseDate = new Date(this.props.voucherReminderReport?.archivedReminderReportEndDate);
        if (yesterdayDate < releaseDate && todayDate >= releaseDate) {
            const dayBeforeYesterdayDate = this.getDayBeforeYesterdayDate();
            this.handleFilterRecordOnDateRange(dayBeforeYesterdayDate, yesterdayDate);
            this.toggleShowReminderInfo(true);
        } else {
            this.handleFilterRecordOnDateRange(yesterdayDate, todayDate);
        }
    };

    private verifySelectedDates = () => {
        const result: boolean = true;

        if (!this.state.fromDate || !moment(this.state.fromDate).isValid()) {
            VenusNotifier.Warning("From Date cannot be empty.", "");
            return false;
        }

        if (!this.state.toDate || !moment(this.state.toDate).isValid()) {
            VenusNotifier.Warning("To Date cannot be empty.", "");
            return false;
        }

        if (moment(this.state.fromDate) > moment(this.state.toDate)) {
            VenusNotifier.Warning("To Date cannot be less than From Date.", "");
            return false;
        }

        const fromDateObj = new Date(this.state.fromDate);
        const toDateObj = new Date(this.state.toDate);
        const releaseDateObj = new Date(this.props.voucherReminderReport?.archivedReminderReportEndDate);

        if (fromDateObj < releaseDateObj && toDateObj >= releaseDateObj) {
            VenusNotifier.Warning("Invalid dates selected.", "");
            this.toggleShowReminderInfo(true);
            return false;
        }

        return result;
    };

    private onExportToExcel(onExportToExcelComplete: () => void) {
        const reminderReportUrl: string = this.getReminderReportUrl();
        if (reminderReportUrl !== "") {
            const { exportOldVoucherReminderStatus, exportVoucherReminderStatus } = ExportVoucherReminderReportUrl;
            const { getOldReminderReport } = ReminderReportUrl;

            const queryString = this.buildQuery(this.state.page, pageSize);
            const exportVoucherReminderReportUrl =
                reminderReportUrl === getOldReminderReport ? exportOldVoucherReminderStatus : exportVoucherReminderStatus;
            this.props.exportVoucherReminderReportAsExcel(queryString, exportVoucherReminderReportUrl, onExportToExcelComplete);
        }
    }

    private getYesterdayDate() {
        var today = new Date();
        return new Date(today.getFullYear(), today.getMonth(), today.getDate() - 1);
    }

    private getDayBeforeYesterdayDate = () => {
        var today = new Date();
        return new Date(today.getFullYear(), today.getMonth(), today.getDate() - 2);
    };

    private onPageChange(page: number, sizePerPage: number) {
        this.setState(
            {
                page: page
            },
            this.loadVoucherReminderReport
        );
    }

    private onSortChange(sortName: string, sortOrder: string, column: number) {
        let newState: VoucherReminderManagementState.VoucherReminderReportPageState = this.state;
        newState.sortColumn = sortName;
        newState.sortDirection = sortOrder;
        this.setState({ ...newState }, () => {
            this.onPageReload();
        });
    }

    private getReminderStausFilter(reminderStatus: string = "0") {
        // 0 = Selected "All" by default in Reminder Status dropdown
        switch (reminderStatus) {
            case "1":
                return "0";
            case "2":
                return "1,2,3";
            case "3":
                return "4";
            case "4":
                return "9";
            case "5":
                return "5,6,7,8";
            default:
                return "0,1,2,3,4,5,6,7,8,9";
        }
    }
    private onFilterChange(dataField: any) {
        let newState: VoucherReminderManagementState.VoucherReminderReportPageState = {
            page: 1,
            pageSize: pageSize,
            clientID: "",
            clientName: "",
            dueDate: undefined,
            fromDate: this.state.fromDate,
            statusUpdatedOn: undefined,
            sortColumn: "ClientId",
            recepient: "",
            authorityName: "",
            reminderDate: undefined,
            reminderStatus: this.getReminderStausFilter(),
            reminderType: ReminderType.Voucher,
            sortDirection: "asc",
            taxYear: this.state.taxYear,
            toDate: this.state.toDate,
            showReminderInfo: false,
            officeLocation: ""
        };

        let filterTaxYear: any;

        for (let field of Object.keys(dataField)) {
            switch (field) {
                case "clientName":
                    newState.clientName = dataField[field].value ? dataField[field].value : dataField[field];
                    break;
                case "clientId":
                    newState.clientID = dataField[field].value ? dataField[field].value : dataField[field];
                    break;
                case "recipient":
                    newState.recepient = dataField[field].value ? dataField[field].value : dataField[field];
                    break;
                case "reminderData":
                    newState.authorityName = dataField[field].value ? dataField[field].value : dataField[field];
                    break;
                case "taxYear":
                    filterTaxYear = dataField[field].value ? dataField[field].value : dataField[field];
                    filterTaxYear = filterTaxYear == "-1" ? "" : filterTaxYear;
                    this.setState({ taxYear: filterTaxYear });
                    newState.taxYear = dataField[field].value && dataField[field].value !== "-1" ? dataField[field].value : "";
                    break;
                case "locationName":
                    newState.officeLocation = dataField[field].value ? dataField[field].value : dataField[field];
                    newState.officeLocation = newState.officeLocation == "-1" ? "" : newState.officeLocation;
                    break;
                case "dueDate":
                    newState.dueDate = moment(dataField[field].value ? dataField[field].value : dataField[field]).format(
                        "MM/DD/YYYY"
                    );
                    break;
                case "reminderDate":
                    newState.reminderDate = moment(dataField[field].value ? dataField[field].value : dataField[field]).format(
                        "MM/DD/YYYY"
                    );
                    break;
                case "updatedOn":
                    newState.statusUpdatedOn = moment(dataField[field].value ? dataField[field].value : dataField[field]).format(
                        "MM/DD/YYYY"
                    );
                    break;
                case "reminderStatus":
                    newState.reminderStatus = this.getReminderStausFilter(
                        dataField[field].value ? dataField[field].value : dataField[field]
                    );
                    break;
            }
        }

        this.setState({ ...newState }, () => {
            this.onPageReload();
        });
    }

    private onPageReload() {
        if (this.state.fromDate && this.state.toDate) {
            let query = this.buildQuery(this.state.page, this.state.pageSize);
            this.props.requestVoucherReminderReport(query, this.getReminderReportUrl, true);
        }
    }

    buildQuery(page: number, pageSize: number) {
        return (
            "?pageNo=" +
            page +
            "&pageSize=" +
            pageSize +
            "&sortBy=" +
            this.state.sortColumn +
            "&sortOrder=" +
            this.state.sortDirection +
            "&filterClientId=" +
            this.state.clientID +
            "&filterClientName=" +
            this.state.clientName +
            "&filterAuthorityName=" +
            this.state.authorityName +
            "&filterTaxYear=" +
            this.state.taxYear +
            "&filterDueDate=" +
            this.state.dueDate +
            "&filterReminderDate=" +
            this.state.reminderDate +
            "&filterReminderType=" +
            this.state.reminderType +
            "&filterStatusUpdatedOn=" +
            this.state.statusUpdatedOn +
            "&filterRecepientDetails=" +
            this.state.recepient +
            "&filterReminderStatus=" +
            this.state.reminderStatus +
            "&filterFromDate=" +
            (this.state.fromDate ? moment(this.state.fromDate).format("MM/DD/YYYY") : "") +
            "&filterToDate=" +
            (this.state.toDate ? moment(this.state.toDate).format("MM/DD/YYYY") : "") +
            "&filterOfficeLocation=" +
            this.state.officeLocation
        );
    }

    loadVoucherReminderReport() {
        let queryString = this.buildQuery(this.state.page, this.state.pageSize);

        this.props.requestVoucherReminderReport(queryString, this.getReminderReportUrl, true);
    }

    fetchVoucherReminderReport(page: number, sizePerPage: number) {
        let queryString = this.buildQuery(page, sizePerPage);

        this.setState({ page: page, pageSize: sizePerPage }, () => {
            this.props.requestVoucherReminderReport(queryString, this.getReminderReportUrl);
        });
    }

    handleFilterRecordOnDateRange(fromDate: Date, toDate: Date) {
        this.setState({ fromDate: fromDate, toDate: toDate }, () => {
            let queryString = this.buildQuery(this.state.page, this.state.pageSize);
            this.props.requestVoucherReminderReport(queryString, this.getReminderReportUrl, true);
        });
    }

    handleChangedFromDate(fromDate: Date) {
        this.setState({ fromDate: fromDate });
    }

    handleChangedToDate(toDate: Date) {
        this.setState({ toDate: toDate });
    }

    private toggleShowReminderInfo = (bool: boolean) => {
        this.setState({ showReminderInfo: bool });
    };

    private getReminderReportUrl = () => {
        let reminderReportUrl: string = "";
        const fromDateObj = new Date(this.state.fromDate);
        const toDateObj = new Date(this.state.toDate);
        const releaseDateObj = new Date(this.props.voucherReminderReport?.archivedReminderReportEndDate);

        if (fromDateObj < releaseDateObj && toDateObj < releaseDateObj) {
            reminderReportUrl = ReminderReportUrl.getOldReminderReport;
        } else if (fromDateObj >= releaseDateObj && toDateObj >= releaseDateObj) {
            reminderReportUrl = ReminderReportUrl.getReminderReport;
        } else {
            this.toggleShowReminderInfo(true);
            VenusNotifier.Warning("Invalid dates selected.", "");
        }
        return reminderReportUrl;
    };

    public render() {
        return (
            <div className="user-assignment-content">
                <VoucherReminderReportHeader
                    pageTitle="Reminder Management"
                    fromDate={this.state.fromDate}
                    toDate={this.state.toDate}
                    filterRecordOnDateRange={this.handleFilterRecordOnDateRange}
                    setFromDate={this.handleChangedFromDate}
                    setToDate={this.handleChangedToDate}
                    releaseDate={this.props.voucherReminderReport?.archivedReminderReportEndDate}
                    showReminderInfo={this.state.showReminderInfo}
                    toggleShowReminderInfo={this.toggleShowReminderInfo}
                />

                <VoucherReminderReportTable
                    ref="VoucherReminderReportTable"
                    onPageChange={this.onPageChange}
                    onSortChange={this.onSortChange}
                    onFilterChange={this.onFilterChange}
                    pageNo={this.state.page}
                    totalRows={this.props.voucherReminderReport.totalRowCount}
                    loadGrid={this.loadVoucherReminderReport}
                    isLoading={this.props.voucherReminderReport.loading}
                    pageSize={pageSize}
                    voucherReminderReport={this.props.voucherReminderReport}
                    onExportToExcel={this.onExportToExcel}
                    verifySelectedDates={this.verifySelectedDates}
                    parentResourceIdentifier={ReminderManagementResource.Prefix}
                />
            </div>
        );
    }
}
