import * as React from 'react';
import { ProcessReturnConstants } from "../helper/Constants";
import { Button, Modal } from 'react-bootstrap';
import { ITaxReturn } from './TaxReturn';
import CloseIcon from "src/assets/images/CloseIcon";  

interface ActionModalProps {
    showActionPopup: boolean;
    onCancel: () => void;
    taxReturn: ITaxReturn;
    onSubmit: (taxReturn: ITaxReturn) => void;
}

export class ActionModal extends React.Component<ActionModalProps, {}> {
    constructor(props: ActionModalProps) {
        super(props);
    }

    private onSubmit = () => {
        this.props.onSubmit(this.props.taxReturn);
    }

    public render() {
        return <Modal
            className="action-modal"
            show={this.props.showActionPopup}
            onHide={this.props.onCancel}>
            <Modal.Header>
                <div>
                    <Modal.Title>
                        {ProcessReturnConstants.ActionHeader}
                    </Modal.Title>
                </div>
                <div
                    data-test-auto="9f82a56f-d514-40af-a1bc-febd1106a406"
                    className="closeIcon cursor-pointer"
                    onClick={this.onSubmit}
                >
                    <CloseIcon />
                </div>
            </Modal.Header>
            <Modal.Body>
                <div dangerouslySetInnerHTML={{ __html: ProcessReturnConstants.ActionNotAllowedMessage }}>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    className="closeBtn"
                    onClick={this.onSubmit}
                    data-test-auto="06a2b7b2-0429-4dae-b1a5-2a45691c3896"
                >Close</Button>
            </Modal.Footer>
        </Modal>
    }
}
