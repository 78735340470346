import * as React from 'react';

export interface IDatalistComponentProps {
    selectedValue?: any;
    options: any;
    onChange?: (selectedValue: any) => void;
    clearable?: boolean;
    disabled?: boolean;
    customPlaceHolder?: any;
    id: string;
    onFocus?: (event: any) => void;
    className?: any;
}

export class DatalistComponent extends React.Component<IDatalistComponentProps, {}> {
    constructor(props: any) {
        super(props);
    }

    private onChange = (event: any) => {
        const selectedValue = event.target?.value;
        if (this.props.onChange) {
            this.props.onChange(selectedValue);
        }
    }

    public render() {
        const { clearable } = this.props;
        return <div>
            <input type="text" list={this.props.id} onChange={this.onChange} value={this.props.selectedValue} 
             className={this.props.className ? `form-control group-input ${this.props.className}` : "form-control group-input"}
             disabled={this.props.disabled ? this.props.disabled : false}
                placeholder={this.props.customPlaceHolder && this.props.customPlaceHolder.length > 0 ? this.props.customPlaceHolder : "Select..."} />
            <datalist id={this.props.id}>
                {this.props.options.map((value:any, index:any) => {
                    return <option key={value.value} value={value.label} />
                })
                }
            </datalist>
        </div>;
    }
}

export default DatalistComponent;
