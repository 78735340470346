import * as React from 'react';
import { ISSRUserModel } from '../../../../Core/ViewModels/User/UserViewModel';
import { DropdownComponent } from '../../../common/controls/DropdownComponent';
import { DatalistComponent } from '../../../common/controls/DatalistComponent';
import { IGroupInfo } from '../../../../store/groupedreturns/GroupedReturnProcessState';
interface IButtonFormatterProps {
    deleteReturn: any,
    disabled: boolean
}

interface IDropdownItem {

    disableDropdown: boolean;
    defaultERO: number;
    EROs: ISSRUserModel[],
    onEROChange: any;
    id: string
}

interface IDatalistItem {

    disableDropdown: boolean;
    defaultGroup: string;
    Groups: IGroupInfo[];
    onGroupChange: any;
    className?: string
    id: string
}

export class ButtonFormatter extends React.Component<IButtonFormatterProps, {}> {
    render() {
        return (
            <button type="button" className="btn-white" style={{ padding: '4px' }}
                disabled={this.props.disabled} onClick={this.props.deleteReturn}>
                <i className="text-danger fas fa-times"
                    data-test-auto="C166BDDC-ADE5-46F2-8B94-7ABAFFC4BED7">
                </i>
            </button>
        );
    }
}

export class DropDownFormatter extends React.Component<IDropdownItem, {}> {
    render() {
        return (
            <DropdownComponent
                id={this.props.id}
                options={this.props.EROs.map((value, index) => {
                    return {
                        value: value.id,
                        label: value.firstName + ' ' + value.lastName
                    }
                })}
                onChange={this.props.onEROChange}
                selectedValue={this.props.defaultERO === 0 ? null : this.props.defaultERO }
                disabled={this.props.disableDropdown}
            />
        );
    }
}


export class DatalistFormatter extends React.Component<IDatalistItem, {}> {
    render() {
        return (
            <DatalistComponent
                id={this.props.id}
                options={this.props.Groups.map((value, index) => {
                    return {
                        value: value.id,
                        label: value.name
                    }
                })}
                onChange={this.props.onGroupChange}
                selectedValue={this.props.defaultGroup}
                disabled={this.props.disableDropdown}
                className={this.props.className}
            />
        );
    }
}

interface IDownloadDocument {
    downloadAttachment(event: any): void;
    disabled: boolean;
}

export class DownloadButtonFormatter extends React.Component<IDownloadDocument, {}> {
    render() {
        return (
            <button type="button" className="btn-white btn-lg attachmentDownload"
                disabled={this.props.disabled}
                onClick={this.props.downloadAttachment}>
                <i className="fa fa-download" style={{ color: "#333", backgroundColor: "white" }}></i>
            </button>);
    }
}
