import * as React from 'react';
import * as SavedMessageStore from '../../../store/settings/SavedMessageStore';

interface MessageDetailsList {
    messageDetails: SavedMessageStore.ISavedMessageDictionary;
    onListItemClick: (messageId: number) => void;
    selectedMessageId: number;
    defaultMessageId: number;
}

export class MessageList extends React.Component<MessageDetailsList, {}>{
    public render() {
        const list = this.props.messageDetails ? this.props.messageDetails : []
        let messageId: number = this.props.selectedMessageId == 0 ? parseInt(Object.keys(list)[0]) : this.props.selectedMessageId
        return (
            <div className="">
                <ul className="list-group" style={{ overflowY: 'auto', height: 230, border: 'rgb(221, 221, 221) 1px solid' }}>
                    {
                        Object.keys(list).map((key, index) => (
                            this.props.messageDetails[parseInt(key)]?.messageDetails &&
                            <li
                                key={this.props.messageDetails[parseInt(key)].messageDetails.id}
                                className={this.props.messageDetails[parseInt(key)].messageDetails.id == messageId ? "custom-list-group-item-singleline custom-active" : "custom-list-group-item-singleline"}
                                title={this.props.messageDetails[parseInt(key)].messageDetails.name}
                                onClick={() => this.props.onListItemClick(parseInt(key))}
                            >
                                <div className="width100 customFlexProperty">
                                    <div style={{ textOverflow: 'ellipsis' }} className={this.props.messageDetails[parseInt(key)].messageDetails.id == this.props.defaultMessageId ? "nopadding overflowHidden" : "overflowHidden"}>
                                        {this.props.messageDetails[parseInt(key)].messageDetails.name}
                                    </div>
                                {
                                        this.props.messageDetails[parseInt(key)].messageDetails.id == this.props.defaultMessageId ?
                                            <div style={{ padding: '0' }}>
                                                <span className={"badge"} style={{ marginLeft: 10, float: 'initial' }}>Default</span>
                                            </div>
                                            : <span></span>
                                    }
                                </div>
                            </li>
                        ))
                    }
                </ul>
            </div>
        )
    }
}

