import * as React from "react";
import { RouteComponentProps } from "react-router";
import * as Bs from "react-bootstrap";
import * as Cropp from "react-cropper";
import { Modal, Button, Col } from "react-bootstrap";
import "cropperjs/dist/cropper.css";

interface Props {
    file: any;
    show: any;
    onHide(): void;
    getCroppedCanvas(event: any): void;
}

export class SignatureImageCrop extends React.Component<Props, {}> {
    constructor(props: Props) {
        super(props);
        this.getCroppedCanvas = this.getCroppedCanvas.bind(this);
        this.build = this.build.bind(this);
    }

    private getCroppedCanvas() {
        let croppedImage = (this.refs["cropper"] as any).getCroppedCanvas()?.toDataURL();
        this.props.getCroppedCanvas(croppedImage);
    }

    public render() {
        return (
            <Modal show={this.props.show} onHide={this.props.onHide} size="lg">
                <Modal.Header closeButton data-test-auto="E82F9FB9-B652-42E9-B0E3-DBEA5C207776">
                    <Modal.Title>Crop Signature Stamp</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ width: "100%", height: "74vh" }}>
                    <img
                        style={{ maxWidth: "100%" }}
                        id="ImgCropped_Signature"
                        className="userscanneruploadsign_Crop"
                        data-test-auto="08CEB38C-EF5C-426D-9F20-F7F0670D49EB"
                    ></img>
                    <Cropp.default
                        ref="cropper"
                        src={this.props.file}
                        guides={false}
                        aspectRatio={3 / 1}
                        autoCrop={true}
                        viewMode={2}
                        build={this.build}
                        style={{ width: "100%", height: "70vh" }}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        className="btn-white btn btn-default"
                        aria-hidden="true"
                        onClick={this.props.onHide}
                        data-test-auto="F8F734C5-DA36-4D4B-AFDD-7A2FBBE5DEF5"
                    >
                        <i className="fas fa-times"></i>Discard
                    </Button>
                    <Button
                        variant="info"
                        style={{ marginLeft: "16px", marginRight: "10px" }}
                        onClick={this.getCroppedCanvas}
                        data-test-auto="EECA3D32-62A8-4F77-AACF-C809B52B8913"
                    >
                        <i className="fas fa-cut"></i>Crop & Save
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }

    private build() {
        let img = (this.refs["cropper"] as any).getImageData();
        return true;
    }
}

export default SignatureImageCrop;
