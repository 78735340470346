import { connect } from 'react-redux';
import { ApplicationState } from '../../store';
import { AppHeader, IAppHeader } from './AppHeader';
import * as UserProfileStore from '../../store/userManagement/UserProfileStore';
import * as MyAccountStore from "../../store/userManagement/MyAccountStore";
import * as UserSettingsStore from '../../store/userManagement/UserSettingStore';
import * as AllUsersStore from '../../store/userManagement/UserStore';
import * as CompanyStore from '../../store/company/CompanyStore';
import * as MailingReturnAddressStore from '../../store/common/MailingReturnAddressStore';
import * as UserLoginHistory from '../../store/userManagement/UserLoginHistoryStore';
import * as InboxStore from '../../store/inbox/InboxStore';
import * as SuiteMFASettingStore from '../../store/company/SuiteMFASettingStore';
import * as OneTimePasswordStore from '../../store/common/OneTimePasswordStore';
import * as CustomColumnStore from '../../store/common/CustomColumnStore';
import * as SFUsersStore from '../../SignatureFlow/store/SFUserStore';
import * as ApplicationHelptextStore from '../../store/common/ApplicationHelptextStore';

export default connect(
    (state: ApplicationState) => ({
        auth: state.auth,
        userList:state.usersList,
        profile: state.userProfile,
        settings: state.userSettings,
        companyData: state.companyData,
        mailingReturnAddress: state.mailingReturnAddress,
        userLoginHistory: state.userLoginHistory,
        inboxState: state.inboxState,
        oneTimePasswordData: state.oneTimePasswordStore,
        customColumnState: state.customColumn,
        helptexts: state.Helptexts,
        sfUserList: state.sfUserList,
        userDetails: state.userDetails,
        ssrUsers: state.ssrUsers,
        mfaSetting: state.mfaSetting,
        taxSoftwareSetting: state.taxSoftwareSetting,
        partnerUsersList: state.partnerUsersList,
    }),
    {
        ...AllUsersStore.actionCreators,
        ...UserProfileStore.actionCreators,
        ...UserSettingsStore.actionCreators,
        ...CompanyStore.actionCreators,
        ...MailingReturnAddressStore.actionCreators,
        ...UserLoginHistory.actionCreators,
        ...InboxStore.actionCreators,
        ...OneTimePasswordStore.actionCreators,
        ...CustomColumnStore.actionCreators,
        ...ApplicationHelptextStore.actionCreators,
        ...SFUsersStore.actionCreators,
        ...MyAccountStore.actionCreators,
        ...SuiteMFASettingStore.actionCreators
    }
)(AppHeader as any);
