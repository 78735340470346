var htmlencoder = require("htmlencode");
import { IUserProfile } from "../navigation/profile/ProfileObjects";
import { IParterUserModel, ISSRUserModel, IUserBaseModel, IUserModel } from "../../Core/ViewModels/User/UserViewModel";
import { IFilters } from "../../components/reports/Filters";
import {
    ITaxReturn,
    DocumentStatus,
    IFormBase,
    isPartnership,
    isIndividual,
    IK1,
    IMarriedJointTaxReturn,
    isMutual,
    DocumentGroups,
    IGroup,
    ITaxingAuthority,
    ISignatureControl,
    IEFile,
    EngagementType,
    Arrangement,
    SignatureStatus,
    ClientTypes,
    getAllStateImages,
    StateUSA,
    ClientTypesNumber,
    IEfileDocumentControl,
    IVoucher,
    VoucherStatus,
    IClientInfoViewModel,
    IOfficeLocation,
    IDocumentSettings,
    CustomType
} from "../common/TaxReturn";
import { VenusNotifier } from "./VenusNotifier";
import {
    isValidEmailAddress,
    isValidSSN,
    isValidEIN,
    NullandEmptyCheck,
    validatePhoneLength,
    isValidClientId
} from "./Validations";
import * as Constants from "../helper/Constants";
import { ICustomColumn } from "../settings/GeneralSettings";
import { ICountryState, IPrimaryAdmin } from "../../Core/ViewModels/Company/CompanyViewModel";
import { TYPES } from "../../Startup/types";
import { container } from "../../Startup/inversify.config";
import { ISessionLocalStore } from "../../Core/Utilities/SessionStore";
import { IGroupedReturnsModel, IGroupInfo, IGroupSettingsModel } from "../../store/groupedreturns/GroupedReturnProcessState";
import { IControllerInfo } from "../../store/groupedreturns/GroupedReturnProcessState";
import { ISaleTaxItem, ITaxInfo } from "../../Core/ViewModels/Tax/TaxModels";
import { ProductTypes, TaxreturnType } from "../common/ReportProblem/ReportProblemModel";
export const SessionStore = container.get<ISessionLocalStore>(TYPES.ISessionStore);
import { TaxSoftwareType } from "../common/ReportProblem/ReportProblemModel";
import { ITaxpayer } from "../common/TaxClient";
import { ValidationContants } from "../helper/Constants";
import { ICompanySettings, ProductType } from "./../../Core/ViewModels/Company/CompanySettingsViewModel";
import { IHelptextModel } from "../../Core/ViewModels/Common/IHelptextModel";
import { IPaperFileData } from "../common/ProcessReturnModal/PaperFile/PaperFile";
import { API_BASE_URL } from "../../utils/constants";
import { DateRangeOptions, StatusRadioButtonValues } from "../../Core/Common/Common";
import copy from "copy-to-clipboard";
let moment = require("moment");
export const NO_INDEX = -1;

import { PDFJSStatic } from "pdfjs-dist";
import { handleResponse } from "../../store/Library";
import { addTask } from "domain-task";
import { IShareHolder } from "../common/ProcessReturnModal/ProcessReturnModels";
import { createCookie, getDomain } from "@sssuite-component-ui/session-timeout";
import { getPartnerUsers } from "./UserHelperFunctions";
const PDFJS: PDFJSStatic = require("pdfjs-dist");
import { isValidMobileNumber } from "@sssuite-js-packages/mobile-number-validator";
import { CountryData } from "react-phone-input-2";

let REGEX_VALUES = [{ Key: "0", Value: /\d/ }];
const MASK_PLACEHOLDER = "_";

export function iconType(fileName: any) {
    var fileImgIcon = { fileImg: "", fileIcon: "" };
    var fileImg = "";
    if (fileName.indexOf(".pdf") > -1 || fileName.indexOf(".PDF") > -1) {
        fileImgIcon.fileImg = "fas fa-file-pdf";
        fileImgIcon.fileIcon = "text-center text-danger";
    } else if (fileName.indexOf(".jpg") > -1 || fileName.indexOf(".JPG") > -1) {
        fileImgIcon.fileImg += "fas fa-file-image";
        fileImgIcon.fileIcon = "text-center text-danger";
    } else if (fileName.indexOf(".png") > -1 || fileName.indexOf(".PNG") > -1) {
        fileImgIcon.fileImg += "far fa-file-image";
        fileImgIcon.fileIcon = "text-center text-danger";
    } else if (fileName.indexOf(".xlsx") > -1 || fileName.indexOf(".XLSX") > -1) {
        fileImgIcon.fileImg = "fas fa-file-excel";
        fileImgIcon.fileIcon = "text-center text-success";
    } else if (fileName.indexOf(".docx") > -1 || fileName.indexOf(".DOCX") > -1) {
        fileImgIcon.fileImg = "fas fa-file-word";
        fileImgIcon.fileIcon = "text-center text-primary";
    } else if (fileName.indexOf(".html") > -1 || fileName.indexOf(".HTML") > -1) {
        fileImgIcon.fileImg = "fas fa-file-code";
        fileImgIcon.fileIcon = "text-center text-danger";
    } else if (fileName.indexOf(".txt") > -1 || fileName.indexOf(".TXT") > -1) {
        fileImgIcon.fileImg = "fas fa-file-alt btn-dark";
        fileImgIcon.fileIcon = "text-center text-danger";
    } else if (fileName.indexOf(".csv") > -1 || fileName.indexOf(".CSV") > -1) {
        fileImgIcon.fileImg = "fas fa-file-csv";
        fileImgIcon.fileIcon = "text-center text-danger";
    } else if (fileName.indexOf(".xml") > -1 || fileName.indexOf(".XML") > -1) {
        fileImgIcon.fileImg = "fas fa-file-code";
        fileImgIcon.fileIcon = "text-center text-danger";
    } else if (fileName.indexOf(".tiff") > -1 || fileName.indexOf(".TIFF") > -1) {
        fileImgIcon.fileImg = "fas fa-file-image";
        fileImgIcon.fileIcon = "text-center text-danger";
    } else if (fileName.indexOf(".tif") > -1 || fileName.indexOf(".TIF") > -1) {
        fileImgIcon.fileImg = "fas fa-file-image";
        fileImgIcon.fileIcon = "text-center text-danger";
    } else if (fileName.indexOf(".xls") > -1 || fileName.indexOf(".XLS") > -1) {
        fileImgIcon.fileImg = "fas fa-file-excel";
        fileImgIcon.fileIcon = "text-center text-success";
    } else if (fileName.indexOf(".jpeg") > -1 || fileName.indexOf(".JPEG") > -1) {
        fileImgIcon.fileImg += "far fa-file-image";
        fileImgIcon.fileIcon = "text-center text-danger";
    } else if (fileName.indexOf(".zip") > -1 || fileName.indexOf(".ZIP") > -1) {
        fileImgIcon.fileImg += "fas fa-file-archive";
        fileImgIcon.fileIcon = "text-center text-warning";
    } else if (fileName.indexOf(".exe") > -1 || fileName.indexOf(".EXE") > -1) {
        fileImgIcon.fileImg += "fas fa-file";
        fileImgIcon.fileIcon = "text-center text-danger";
    } else if (fileName.indexOf(".rtf") > -1 || fileName.indexOf(".RTF") > -1) {
        fileImgIcon.fileImg += "fas fa-file-word";
        fileImgIcon.fileIcon = "text-center text-primary";
    } else if (fileName.indexOf(".mp3") > -1 || fileName.indexOf(".MP3") > -1) {
        fileImgIcon.fileImg = "fas fa-file-audio";
        fileImgIcon.fileIcon = "text-center text-danger";
    } else if (fileName.indexOf(".mp4") > -1 || fileName.indexOf(".MP4") > -1) {
        fileImgIcon.fileImg = "fas fa-file-video";
        fileImgIcon.fileIcon = "text-center text-danger";
    } else if (fileName.indexOf(".log") > -1 || fileName.indexOf(".LOG") > -1) {
        fileImgIcon.fileImg = "fas fa-file";
        fileImgIcon.fileIcon = "text-center text-danger";
    } else if (fileName.indexOf(".doc") > -1 || fileName.indexOf(".DOC") > -1) {
        fileImgIcon.fileImg = "far fa-file-word";
        fileImgIcon.fileIcon = "text-center text-primary";
    } else if (fileName.indexOf(".giff") > -1 || fileName.indexOf(".GIFF") > -1) {
        fileImgIcon.fileImg = "fas fa-file-image";
        fileImgIcon.fileIcon = "text-center text-danger";
    } else if (fileName.indexOf(".gif") > -1 || fileName.indexOf(".GIF") > -1) {
        fileImgIcon.fileImg = "fas fa-file-image";
        fileImgIcon.fileIcon = "text-center text-danger";
    } else {
        fileImgIcon.fileImg = "fas fa-file";
        fileImgIcon.fileIcon = "text-center text-danger";
    }

    return fileImgIcon;
}

export function getFileSize(fileSizeInBytes: number) {
    let file = parseInt((fileSizeInBytes / 1024).toString(), 10);
    let fileSize = "";
    fileSize = file < 1024 ? (file + " KB").toString() : (parseInt((file / 1024).toString(), 10) + " MB").toString();
    return fileSize;
}

export function phoneNumberDisplay(phoneNumber: string): string {
    return phoneNumber && phoneNumber.length == 10
        ? "(" + phoneNumber.substring(0, 3) + ") " + phoneNumber.substring(3, 6) + "-" + phoneNumber.substring(6, 10)
        : phoneNumber;
}

export function formatSSN(SSN: string): string {
    return isValidSSN(SSN) ? SSN.replace(/(\d{3})(\d{2})(\d{4})/, "$1-$2-$3") : SSN;
}

export function formatEIN(EIN: string): string {
    return isValidEIN(EIN) ? EIN.replace(/(\d{2})(\d{7})/, "$1-$2") : EIN;
}

export function formatSSNWithAsterisks(SSN: string): string {
    return SSN.replace(/(\*{3})(\*{2})(\*{4})/, "$1-$2-$3");
}

export function formatEINWithAsterisks(EIN: string): string {
    return EIN.replace(/(\*{2})(\*{7})/, "$1-$2");
}

export function getUnFormatedUID(value: string): string {
    return value.replace(/[-]/g, "");
}

export function getFileSizeBytes(fileSizeInUnit: string) {
    let fileSize = 0;
    if (fileSizeInUnit.indexOf("KB")) {
        fileSizeInUnit = fileSizeInUnit.replace("KB", "");
        fileSize = parseInt(fileSizeInUnit) * 1024;
    } else if (fileSizeInUnit.indexOf("MB")) {
        fileSizeInUnit = fileSizeInUnit.replace("MB", "");
        fileSize = parseInt(fileSizeInUnit) * 1024 * 1024;
    } else if (fileSizeInUnit.indexOf("GB")) {
        fileSizeInUnit = fileSizeInUnit.replace("GB", "");
        fileSize = parseInt(fileSizeInUnit) * 1024 * 1024 * 1024;
    }
    return fileSize;
}

export function toISO8601Format(date: Date): string {
    return moment(date).format("YYYY-MM-DD");
}

export function myAccountFetch(): Promise<IUserProfile> {
    return fetch(API_BASE_URL + "api/MyAccount/MyAccountDetails", {
        method: "GET",
        credentials: "include"
    })
        .then((response) => response?.json() as Promise<IUserProfile>)
        .then((data) => {
            return data;
        });
}

export function fetchUserOfficeLocation(callback: (officeLocation: IOfficeLocation[]) => void) {
    const fetchTask = fetch(API_BASE_URL + "api/UserOfficeLocation/GetAsync", {
        method: "GET",
        credentials: "include",
        headers: {
            Accept: "application/json, */*",
            "Content-Type": "application/json",
            RequestVerificationToken: (document.getElementById("RequestVerificationToken") as HTMLInputElement).value
        }
    })
        .then(handleResponse)
        .then((response: any) => {
            callback && callback(response);
        })
        .catch((error: any) => {
            console.log(error);
        });
    addTask(fetchTask);
}

export function GetFormatedDateTime(date: Date) {
    return moment.utc(date).local().format("MM/DD/YYYY hh:mm:ss A");
}

export function b64toBlob(b64Data: any, contentType: any, sliceSize: any) {
    contentType = contentType || "";
    sliceSize = sliceSize || 512;

    var byteCharacters = atob(b64Data);
    var byteArrays = [];

    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        var slice = byteCharacters.slice(offset, offset + sliceSize);

        var byteNumbers = new Array(slice.length);
        for (var i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }

        var byteArray = new Uint8Array(byteNumbers);

        byteArrays.push(byteArray);
    }

    var blob = new Blob(byteArrays, { type: contentType });
    return blob;
}

export function getCurrentDate(): string {
    return moment(new Date()).format("MM/DD/YYYY");
}

export function getEncryptedFileName(file: File): string {
    var encryptedFileName = "";
    try {
        encryptedFileName = btoa(file.name);
    } catch (exp) {
        try {
            encryptedFileName = btoa(encodeURIComponent(file.name));
        } catch (exp) {
            encryptedFileName = file.name.replace(/\W/g, "");
            encryptedFileName = btoa(encryptedFileName);
        }
    }
    return encryptedFileName;
}

export function splitName(name: string) {
    let nameList: any;
    let result: string;

    if (name == null) {
        result = "";
    } else {
        nameList = name.split(" ").reverse();
        if (nameList.length > 2) {
            result = nameList[0] + ", " + nameList.slice(1).reverse().join(" ");
        } else {
            result = nameList.join(", ");
        }
    }
    return result;
}

export function fullName(user: IUserBaseModel) {
    if (user.lastName) {
        return user.firstName + " " + user.lastName;
    }
    return user.firstName;
}
export function fullSSRName(user: ISSRUserModel) {
    if (user.lastName) {
        return user.firstName + " " + user.lastName;
    }
    return user.firstName;
}
export function userName(user: IUserModel) {
    if (user.lastName) {
        return user.firstName + " " + user.lastName;
    }
    return user.firstName;
}

export function isClientInfoLoaded(model: ITaxReturn | undefined) {
    if (!model) {
        return false;
    }
    if (isPartnership(model)) {
        return model.partnership && model.partnership.ssn && model.partnership.name;
    }
    if (isMutual(model)) {
        return model.spouse && model.spouse.ssn;
    }
    if (isIndividual(model)) {
        return model.taxpayer && model.taxpayer.ssn;
    }
}

export function isControllerInfoLoaded(model: IGroupInfo) {
    if (!model) {
        return false;
    }
    if (model.controllerInfo) {
        return model.controllerInfo.name && model.controllerInfo.emailAddress
            ? model.controllerInfo.name && model.controllerInfo.emailAddress
            : model.id;
    }
}
export function validateConrollerInfo(groupInfo: IGroupInfo): boolean {
    return validateControllerInfo(groupInfo.controllerInfo);
}

export function isFormGroupsLoaded(model: ITaxReturn) {
    if (!model) {
        return false;
    }
    if (!model.formGroups || model.formGroups.length === 0) {
        return false;
    }

    return true;
}

export function isMarriedJoint(model: ITaxReturn): boolean {
    if (isMutual(model)) {
        return model.spouse !== null;
    }
    return false;
}

export function isProcessable(model: ITaxReturn): boolean {
    return (
        model &&
        (model.documentStatus === DocumentStatus.READY ||
            model.documentStatus === DocumentStatus.PROCESSING ||
            model.documentStatus === DocumentStatus.REVIEW ||
            model.documentStatus === DocumentStatus.APPROVEDFORDELIVERY ||
            model.documentStatus === DocumentStatus.DUPLICATE ||
            model.documentStatus === DocumentStatus.READYFORDELIVERY ||
            model.documentStatus === DocumentStatus.DRAFTAPPROVEDREADYTOFINALIZE)
    );
}

export function isAssignable(status: string): boolean {
    return (
        status &&
        (status === DocumentStatus.READY ||
            status === DocumentStatus.PROCESSING ||
            status === DocumentStatus.REVIEW ||
            status === DocumentStatus.APPROVEDFORDELIVERY ||
            status === DocumentStatus.DUPLICATE ||
            status === DocumentStatus.READYFORDELIVERY ||
            status === DocumentStatus.RECALLED ||
            status === DocumentStatus.DRAFTAPPROVEDREADYTOFINALIZE)
    );
}

export function isLocked(model: ITaxReturn): boolean {
    return (
        model &&
        (model.documentStatus === DocumentStatus.PREPARINGFORDELIVERY ||
            model.documentStatus === DocumentStatus.DELIVERYFAILED ||
            model.documentStatus === DocumentStatus.UPLOADED ||
            model.documentStatus === DocumentStatus.DELIVERED ||
            model.documentStatus === DocumentStatus.NONSUPPORTED ||
            model.documentStatus === DocumentStatus.ERROR ||
            model.documentStatus === DocumentStatus.REPROCESSING ||
            model.documentStatus === DocumentStatus.DELIVEREDTOTAXCADDY ||
            model.documentStatus === DocumentStatus.TAXCADDYDELIVERYFAILED ||
            model.documentStatus === DocumentStatus.PREPARINGFORPDFDOWNLOAD ||
            model.documentStatus === DocumentStatus.DOWNLOADPDF ||
            model.documentStatus === DocumentStatus.DOWNLOADPDFDESCRIPTION)
    );
}

export function isStatusChangeable(documentStatus: string): boolean {
    return (
        documentStatus &&
        (documentStatus === DocumentStatus.READY ||
            documentStatus === DocumentStatus.PROCESSING ||
            documentStatus === DocumentStatus.REVIEW ||
            documentStatus === DocumentStatus.APPROVEDFORDELIVERY ||
            documentStatus === DocumentStatus.DUPLICATE ||
            documentStatus === DocumentStatus.READYFORDELIVERY ||
            documentStatus === DocumentStatus.DRAFTAPPROVEDREADYTOFINALIZE)
    );
}
export function isDeliveredReturnStatusChangeable(signatureStatus: string): boolean {
    let _signatureStatusAwaitingEsign: any = SignatureStatus[SignatureStatus.AwaitingESign];
    let _signatureStatusAwaitingUpload: any = SignatureStatus[SignatureStatus.AwaitingUpload];
    let _signatureStatusManuallySigned: any = SignatureStatus[SignatureStatus.ManuallySigned];
    let _signatureStatusPartiallySigned: any = SignatureStatus[SignatureStatus.PartiallySigned];

    return (
        signatureStatus &&
        (signatureStatus === _signatureStatusAwaitingEsign ||
            signatureStatus === _signatureStatusAwaitingUpload ||
            signatureStatus === _signatureStatusManuallySigned ||
            signatureStatus === _signatureStatusPartiallySigned)
    );
}
export function validateFilter(filter: IFilters): boolean {
    let isValid: boolean = false;

    //Checking for empty filters
    for (let fieldKey of Object.keys(filter.fields)) {
        if (
            fieldKey != "selectedTaxYear" &&
            filter.fields[fieldKey] &&
            filter.fields[fieldKey].toString().length > 0 &&
            filter.fields[fieldKey].toString() != "-1"
        ) {
            isValid = true;
        }
    }

    if (filter.searchText.length > 0) {
        isValid = true;
    }

    return isValid;
}
export function validateInvoice(model: ITaxReturn, companySettings: ICompanySettings): boolean {
    if (companySettings.userPrivilegeSettingsModel.allowUserToSendwithoutInvoice) return true;
    else {
        let invoice = model.formGroups.find((x) => x.group == DocumentGroups.Invoice);
        return invoice != undefined && invoice != null && invoice.forms != null && invoice.forms.length > 0;
    }
}

export function validateClientId(taxReturn: ITaxReturn) {
    let isValid: boolean = true;
    if (!NullandEmptyCheck(taxReturn.clientId)) {
        VenusNotifier.Warning(Constants.CompanySettingsConstants.Validate.ClientIdWarning, null);
        isValid = false;
    }
    if (NullandEmptyCheck(taxReturn.clientId)) {
        if (!isValidClientId(taxReturn.clientId)) {
            VenusNotifier.Warning(ValidationContants.ClientIdWarning, "");
            isValid = false;
        }
    }
    return isValid;
}
export function validateDeliveryOption(taxReturn: ITaxReturn, compnaySettings: ICompanySettings): boolean {
    let isValid: boolean = true;
    if (
        taxReturn?.documentSettings?.deliverySettings?.contactPerson === null ||
        taxReturn?.documentSettings?.deliverySettings?.contactPerson === 0
    ) {
        VenusNotifier.Warning(Constants.DeliveryOptionsValidation.InCorrectContactPerson, null);
        isValid = false;
    }
    //if (taxReturn.documentSettings.deliverySettings.preparerMessage.id === 0) {
    //    VenusNotifier.Warning(Constants.DeliveryOptionsValidation.InValidMessageFromPreparer, null);
    //    isValid = false;
    //}
    if (taxReturn?.documentSettings?.deliverySettings.sender.senderId === null) {
        VenusNotifier.Warning(Constants.DeliveryOptionsValidation.InValidSenderName, null);
        isValid = false;
    }
    if (
        taxReturn?.documentSettings?.deliverySettings.notifyUser === null ||
        taxReturn.documentSettings.deliverySettings.notifyUser === 0
    ) {
        VenusNotifier.Warning(Constants.DeliveryOptionsValidation.InValidNotifyAboutSigningsEvents, null);
        isValid = false;
    }
    if (
        taxReturn?.documentSettings?.documentNotificationSetting.paymentVoucherNotificationSettingsModel
            .enablePaymentVoucherReminder &&
        (taxReturn.documentSettings.documentNotificationSetting.paymentVoucherNotificationSettingsModel
            .noOfDaysForVoucherReminder == null ||
            taxReturn.documentSettings.documentNotificationSetting.paymentVoucherNotificationSettingsModel
                .noOfDaysForVoucherReminder === 0)
    ) {
        VenusNotifier.Warning(Constants.DeliveryOptionsValidation.InvalidNumberofDaysVoucherReminder, null);
        isValid = false;
    }
    if (
        taxReturn?.documentSettings?.documentNotificationSetting.signingReminderNotificationSettingsModel.enableSigningReminder &&
        (taxReturn.documentSettings.documentNotificationSetting.signingReminderNotificationSettingsModel
            .noOfDaysForSigningReminder == null ||
            taxReturn.documentSettings.documentNotificationSetting.signingReminderNotificationSettingsModel
                .noOfDaysForSigningReminder === 0)
    ) {
        VenusNotifier.Warning(Constants.DeliveryOptionsValidation.InvalidNumberofDaysSigningReminder, null);
        isValid = false;
    }
    if (
        (taxReturn?.documentSettings?.deliverySettings.voucherPaymentNotifyUser === null ||
            taxReturn?.documentSettings?.deliverySettings.voucherPaymentNotifyUser === 0) &&
        compnaySettings.notificationSettingsModel.paymentVoucherNotificationSettings.paymentNotification
    ) {
        VenusNotifier.Warning(Constants.DeliveryOptionsValidation.InvalidNotifyAboutPaymentEvents, null);
        isValid = false;
    }
    return isValid;
}

export function validateControllerInfo(controllerInfo: IControllerInfo): boolean {
    let isValid = true;
    if (!NullandEmptyCheck(controllerInfo.name)) {
        VenusNotifier.Warning(Constants.ControllerInfoValidation.nameIsMandatory, null);
        isValid = false;
    }
    if (controllerInfo.ssn && !isValidSSN(controllerInfo.ssn)) {
        VenusNotifier.Warning(Constants.ControllerInfoValidation.inValidSSN, null);
        isValid = false;
    }
    if (!controllerInfo.emailAddress) {
        VenusNotifier.Warning(Constants.ControllerInfoValidation.emailIsMandatory, null);
        isValid = false;
    } else if (!isValidEmailAddress(controllerInfo.emailAddress)) {
        VenusNotifier.Warning(Constants.ControllerInfoValidation.inCorrectControllerEmail, null);
        isValid = false;
    }
    if (
        controllerInfo.mobileNumber != null &&
        controllerInfo.mobileNumber != "" &&
        !validateMobileNumber(controllerInfo.mobileNumber, controllerInfo.countryCode)
    ) {
        isValid = false;
    }

    if (
        !validateCountryCode(
            controllerInfo.mobileNumber,
            controllerInfo.countryCode,
            Constants.ValidationContants.CountryCodeWarning
        )
    ) {
        isValid = false;
    }
    return isValid;
}

export function validateGroupDeliveryOption(deliveryOptions: IGroupSettingsModel, compnaySettings: ICompanySettings): boolean {
    let isValid: boolean = true;
    if (
        deliveryOptions.groupDeliverySettings.contactPerson === null ||
        deliveryOptions.groupDeliverySettings.contactPerson === 0
    ) {
        VenusNotifier.Warning(Constants.DeliveryOptionsValidation.InCorrectContactPerson, null);
        isValid = false;
    }

    if (deliveryOptions.groupDeliverySettings.sender.senderId === null) {
        VenusNotifier.Warning(Constants.DeliveryOptionsValidation.InValidSenderName, null);
        isValid = false;
    }
    if (deliveryOptions.groupDeliverySettings.notifyUser === null || deliveryOptions.groupDeliverySettings.notifyUser === 0) {
        VenusNotifier.Warning(Constants.DeliveryOptionsValidation.InValidNotifyAboutSigningsEvents, null);
        isValid = false;
    }
    if (
        deliveryOptions.groupNotificationSettings.paymentVoucherNotificationSettingsModel.enablePaymentVoucherReminder &&
        deliveryOptions.groupNotificationSettings.paymentVoucherNotificationSettingsModel.noOfDaysForVoucherReminder == null
    ) {
        VenusNotifier.Warning(Constants.DeliveryOptionsValidation.InvalidNumberofDaysVoucherReminder, null);
        isValid = false;
    }
    if (
        deliveryOptions.groupNotificationSettings.signingReminderNotificationSettingsModel.enableSigningReminder &&
        deliveryOptions.groupNotificationSettings.signingReminderNotificationSettingsModel.noOfDaysForSigningReminder == null
    ) {
        VenusNotifier.Warning(Constants.DeliveryOptionsValidation.InvalidNumberofDaysSigningReminder, null);
        isValid = false;
    }
    if (
        (deliveryOptions.groupDeliverySettings.voucherPaymentNotifyUser === null ||
            deliveryOptions.groupDeliverySettings.voucherPaymentNotifyUser === 0) &&
        compnaySettings.notificationSettingsModel.paymentVoucherNotificationSettings.paymentNotification
    ) {
        VenusNotifier.Warning(Constants.DeliveryOptionsValidation.InvalidNotifyAboutPaymentEvents, null);
        isValid = false;
    }
    return isValid;
}

export function validateDocumentLocation(taxReturn: ITaxReturn): boolean {
    if (!taxReturn.locationId || (taxReturn.locationId && taxReturn.locationId == 0)) {
        VenusNotifier.Warning(Constants.ClientInfoValidation.inCorrectLocation, null);
        return false;
    }
    return true;
}

export function validateStateForVoucher(taxReturn: ITaxReturn): boolean {
    if (
        (isMutual(taxReturn) || isIndividual(taxReturn)) &&
        taxReturn.taxpayer.state == "" &&
        voucherExist(taxReturn, CustomType.OnlinePaymentRequired)
    ) {
        VenusNotifier.Warning(Constants.ClientInfoValidation.invalidState, null);
        return false;
    }
    return true;
}

export function validatePartner(taxReturn: ITaxReturn): boolean {
    const partnerUsers = getPartnerUsers();
    if (
        !taxReturn.partner ||
        taxReturn.partner?.userId <= 0 ||
        !partnerUsers.some((partner) => partner.id === taxReturn.partner.userId)
    ) {
        VenusNotifier.Warning(Constants.ClientInfoValidation.inCorrectPartner, null);
        return false;
    }
    return true;
}
export function validateClientInfo(taxReturn: ITaxReturn, skipEroValidation?: boolean): boolean {
    let isValid: boolean = true;
    const partnerUsers = getPartnerUsers();
    if (
        !skipEroValidation &&
        (taxReturn.partner?.userId <= 0 || !partnerUsers.some((partner) => partner.id === taxReturn.partner.userId))
    ) {
        VenusNotifier.Warning(Constants.ClientInfoValidation.inCorrectPartner, null);
        return false;
    }

    if (NullandEmptyCheck(taxReturn.clientId)) {
        if (!isValidClientId(taxReturn.clientId)) {
            VenusNotifier.Warning(ValidationContants.ClientIdWarning, "");
            return false;
        }
    }

    if (isMutual(taxReturn)) {
        if (taxReturn.taxpayer.ssn != null && !isValidSSN(taxReturn.taxpayer.ssn)) {
            VenusNotifier.Warning(Constants.ClientInfoValidation.inValidTaxpayerSSN, null);
            isValid = false;
        } else if (taxReturn.spouse.ssn != null && !isValidSSN(taxReturn.spouse.ssn)) {
            VenusNotifier.Warning(Constants.ClientInfoValidation.inValidSpouseSSN, null);
            isValid = false;
        } else if (
            taxReturn?.documentSettings?.deliverySettings.contactPerson === null ||
            taxReturn?.documentSettings?.deliverySettings.contactPerson === 0
        ) {
            VenusNotifier.Warning(Constants.DeliveryOptionsValidation.InCorrectContactPerson, null);
            isValid = false;
        } else if (
            !validateMobileNumber(
                taxReturn.taxpayer.mobileNumber,
                taxReturn.taxpayer.countryCode,
                Constants.ClientInfoValidation.invalidMobileNumberTaxpayer,
                Constants.ClientInfoValidation.mobileNumberLengthWarningTaxpayer
            )
        ) {
            isValid = false;
        } else if (
            !validateCountryCode(
                taxReturn.taxpayer.mobileNumber,
                taxReturn.taxpayer.countryCode,
                Constants.ClientInfoValidation.invalidCountryCodeTaxpayer
            )
        ) {
            isValid = false;
        } else if (
            !validateMobileNumber(
                taxReturn.spouse.mobileNumber,
                taxReturn.spouse.countryCode,
                Constants.ClientInfoValidation.invalidMobileNumberSpouse,
                Constants.ClientInfoValidation.mobileNumberLengthWarningSpouse
            )
        ) {
            isValid = false;
        } else if (
            !validateCountryCode(
                taxReturn.spouse.mobileNumber,
                taxReturn.spouse.countryCode,
                Constants.ClientInfoValidation.invalidCountryCodeSpouse
            )
        ) {
            isValid = false;
        } else if (!NullandEmptyCheck(taxReturn.clientId)) {
            VenusNotifier.Warning(Constants.CompanySettingsConstants.Validate.ClientIdWarning, null);
            isValid = false;
        } else if (!validateIndividualInfo(taxReturn.taxpayer, ClientTypesNumber.Taxpayer)) {
            isValid = false;
        } else if (!validateIndividualInfo(taxReturn.spouse, ClientTypesNumber.Spouse)) {
            isValid = false;
        } else if (taxReturn.taxpayer.ssn == taxReturn.spouse.ssn) {
            VenusNotifier.Warning(Constants.ClientInfoValidation.ssnDuplicateWarningMessage, null);
            isValid = false;
        }

        return isValid;
    }
    if (isPartnership(taxReturn)) {
        if (!NullandEmptyCheck(taxReturn.partnership.name)) {
            VenusNotifier.Warning(Constants.CompanySettingsConstants.Validate.CompanyNameWarning, null);
            isValid = false;
        } else if (!isValidEIN(taxReturn.partnership.ssn)) {
            VenusNotifier.Warning(Constants.ValidationContants.EINWarning, null);
            isValid = false;
        } else if (
            taxReturn?.documentSettings?.deliverySettings.contactPerson === null ||
            taxReturn?.documentSettings?.deliverySettings.contactPerson === 0
        ) {
            VenusNotifier.Warning(Constants.DeliveryOptionsValidation.InCorrectContactPerson, null);
            isValid = false;
        } else if (
            !validateMobileNumber(
                taxReturn.partnership.mobileNumber,
                taxReturn.partnership.countryCode,
                Constants.ClientInfoValidation.invalidMobileNumberCompany,
                Constants.ClientInfoValidation.mobileNumberLengthWarningCompany
            )
        ) {
            isValid = false;
        } else if (
            !validateCountryCode(
                taxReturn.partnership.mobileNumber,
                taxReturn.partnership.countryCode,
                Constants.ClientInfoValidation.invalidCountryCodeCompany
            )
        ) {
            isValid = false;
        } else if (!NullandEmptyCheck(taxReturn.clientId)) {
            VenusNotifier.Warning(Constants.CompanySettingsConstants.Validate.ClientIdWarning, null);
            isValid = false;
        }
        return isValid;
    }
    if (isIndividual(taxReturn)) {
        if (taxReturn.taxpayer.ssn != null && !isValidSSN(taxReturn.taxpayer.ssn)) {
            VenusNotifier.Warning(Constants.ClientInfoValidation.inValidTaxpayerSSN, null);
            isValid = false;
        } else if (
            taxReturn?.documentSettings?.deliverySettings.contactPerson === null ||
            taxReturn?.documentSettings?.deliverySettings.contactPerson === 0
        ) {
            VenusNotifier.Warning(Constants.DeliveryOptionsValidation.InCorrectContactPerson, null);
            isValid = false;
        } else if (
            !validateMobileNumber(
                taxReturn.taxpayer.mobileNumber,
                taxReturn.taxpayer.countryCode,
                Constants.ClientInfoValidation.invalidMobileNumberTaxpayer,
                Constants.ClientInfoValidation.mobileNumberLengthWarningTaxpayer
            )
        ) {
            isValid = false;
        } else if (
            !validateCountryCode(
                taxReturn.taxpayer.mobileNumber,
                taxReturn.taxpayer.countryCode,
                Constants.ClientInfoValidation.invalidCountryCodeTaxpayer
            )
        ) {
            isValid = false;
        } else if (!NullandEmptyCheck(taxReturn.clientId)) {
            VenusNotifier.Warning(Constants.CompanySettingsConstants.Validate.ClientIdWarning, null);
            isValid = false;
        } else if (!validateIndividualInfo(taxReturn.taxpayer, ClientTypesNumber.Taxpayer)) {
            isValid = false;
        }
        return isValid;
    }

    return false;
}

export function validateEmailAddressFormat(taxReturn: ITaxReturn) {
    let isValid: boolean = true;
    if (isPartnership(taxReturn)) {
        if (taxReturn.partnership.email && !isValidEmailAddress(taxReturn.partnership.email)) {
            VenusNotifier.Warning(Constants.ClientInfoValidation.inCorrectCompanyEmail, null);
            isValid = false;
        }
    } else if (isMutual(taxReturn)) {
        if (taxReturn.spouse.email && !isValidEmailAddress(taxReturn.spouse.email)) {
            VenusNotifier.Warning(Constants.ClientInfoValidation.inCorrectSpouseEmail, null);
            isValid = false;
        }
        if (taxReturn.taxpayer.email && !isValidEmailAddress(taxReturn.taxpayer.email)) {
            VenusNotifier.Warning(Constants.ClientInfoValidation.inCorrectTaxpayerEmail, null);
            isValid = false;
        }
        return isValid;
    } else if (isIndividual(taxReturn)) {
        if (taxReturn.taxpayer.email && !isValidEmailAddress(taxReturn.taxpayer.email)) {
            VenusNotifier.Warning(Constants.ClientInfoValidation.inCorrectTaxpayerEmail, null);
            isValid = false;
        }
    }
    return isValid;
}

export function handleInvalidCountryCodeEntered(fullValue: string, countryObject: CountryData) {
    if (fullValue && fullValue.length >= 4 && fullValue.substring(0, countryObject.dialCode.length) !== countryObject.dialCode) {
        return `${countryObject.dialCode}${fullValue}`;
    }
    return fullValue;
}

export function validateTaxReturnMobileNumber(taxReturn: ITaxReturn) {
    if (isPartnership(taxReturn)) {
        return validateMobileNumber(taxReturn.partnership.mobileNumber, taxReturn.partnership.countryCode);
    } else if (isMutual(taxReturn)) {
        let isValid: boolean = true;
        isValid = validateMobileNumber(taxReturn.taxpayer.mobileNumber, taxReturn.taxpayer.countryCode);
        if (!isValid) return isValid;
        isValid = validateMobileNumber(taxReturn.spouse.mobileNumber, taxReturn.spouse.countryCode);
        return isValid;
    } else if (isIndividual(taxReturn)) {
        return validateMobileNumber(taxReturn.taxpayer.mobileNumber, taxReturn.taxpayer.countryCode);
    }
    return true;
}

export function validateIndividualInfo(individual: ITaxpayer, clientType: ClientTypesNumber): boolean {
    let isValid: boolean = true;
    if (!NullandEmptyCheck(individual.name)) {
        VenusNotifier.Warning(
            clientType == ClientTypesNumber.Taxpayer
                ? Constants.ClientInfoValidation.inValidTaxPayerName
                : Constants.ClientInfoValidation.inValidSpouseName,
            null
        );
        isValid = false;
    }
    if (individual.ssn != null && !isValidSSN(individual.ssn)) {
        VenusNotifier.Warning(
            clientType == ClientTypesNumber.Taxpayer
                ? Constants.ClientInfoValidation.inValidTaxpayerSSN
                : Constants.ClientInfoValidation.inValidSpouseSSN,
            null
        );
        isValid = false;
    }
    if (individual.dob) {
        if (moment(individual.dob) > new Date()) {
            VenusNotifier.Warning(Constants.ClientInfoTab.AddedRefund.DOBWarning, null);
            isValid = false;
        }
        if (moment(individual.dob) < new Date("01/01/1753")) {
            VenusNotifier.Warning(Constants.ClientInfoTab.AddedRefund.DOBWarning, null);
            isValid = false;
        }
    }
    return isValid;
}

export function validateMobileNumber(
    mobileNumber: string,
    countryCode: string,
    warningMessage?: string,
    lengthWarning?: string
): boolean {
    let isValid: boolean = true;

    //country code is entered, but mobile number field is empty
    if (mobileNumber && mobileNumber.trim()) {
        if (!countryCode || !countryCode.trim()) {
            isValid = false;
            VenusNotifier.Warning(warningMessage ? warningMessage : ValidationContants.MobileNumberWarning, "");
        } else if (!isValidMobileNumber(countryCode, mobileNumber)) {
            isValid = false;
            VenusNotifier.Warning(lengthWarning ? lengthWarning : ValidationContants.MobileNumberLengthWarning, "");
        }
    }
    return isValid;
}

export function validateCountryCode(mobileNumber: string, countryCode: string, warningMessage: string): boolean {
    let isValid: boolean = true;
    //mobile number is entered, but country code field is empty
    if (mobileNumber !== undefined && mobileNumber !== null && mobileNumber.trim() !== "") {
        if (countryCode === undefined || (countryCode !== undefined && countryCode.trim() === "")) {
            isValid = false;
            VenusNotifier.Warning(warningMessage, "");
        }
    }
    /*  if ((individual.mobileNumber != null && individual.mobileNumber != "" && !validatePhoneLength(individual.mobileNumber))) {
          VenusNotifier.Warning(Constants.ValidationContants.PhoneNumberLengthWarning, null);
          isValid = false;
      }*/
    return isValid;
}

export function validateClientEmailInfo(taxReturn: ITaxReturn): boolean {
    let isValid: boolean = true;
    if (isMutual(taxReturn)) {
        if (taxReturn.documentSettings.deliverySettings.deliverTo === ClientTypes.Taxpayer) {
            if (!taxReturn.taxpayer.email || !isValidEmailAddress(taxReturn.taxpayer.email)) {
                VenusNotifier.Warning(Constants.ClientInfoValidation.inCorrectTaxpayerEmail, null);
                isValid = false;
            }
            if (taxReturn.spouse.email && !isValidEmailAddress(taxReturn.spouse.email)) {
                VenusNotifier.Warning(Constants.ClientInfoValidation.inCorrectSpouseEmail, null);
                isValid = false;
            }
        } else if (taxReturn.documentSettings.deliverySettings.deliverTo === ClientTypes.Spouse) {
            if (!taxReturn.spouse.email || !isValidEmailAddress(taxReturn.spouse.email)) {
                VenusNotifier.Warning(Constants.ClientInfoValidation.inCorrectSpouseEmail, null);
                isValid = false;
            }
            if (taxReturn.taxpayer.email && !isValidEmailAddress(taxReturn.taxpayer.email)) {
                VenusNotifier.Warning(Constants.ClientInfoValidation.inCorrectTaxpayerEmail, null);
                isValid = false;
            }
        }
        return isValid;
    }
    if (isPartnership(taxReturn)) {
        if (!taxReturn.partnership.email || !isValidEmailAddress(taxReturn.partnership.email)) {
            VenusNotifier.Warning(Constants.ClientInfoValidation.inCorrectCompanyEmail, null);
            isValid = false;
        }
        taxReturn.formGroups
            .filter((x) => x.group == DocumentGroups.K1)
            .forEach((forms: any) => {
                if (forms.shareHolder.emailAddress && !isValidEmailAddress(forms.shareHolder.emailAddress.trim())) {
                    VenusNotifier.Warning(Constants.ClientInfoValidation.inCorrectK1Email, null);
                    isValid = false;
                }
            });
        return isValid;
    }
    if (isIndividual(taxReturn)) {
        if (!taxReturn.taxpayer.email || !isValidEmailAddress(taxReturn.taxpayer.email)) {
            VenusNotifier.Warning(Constants.ClientInfoValidation.inCorrectTaxpayerEmail, null);
            isValid = false;
        }
        return isValid;
    }
    return false;
}

function validateDeliveredReturnClientInfoEmails(taxreturn: any, checkDeceased: boolean = false) {
    let isValid: boolean = true;

    let isTaxPayerSigned: boolean =
        taxreturn.signedDetails.filter((x: any) => x.signerType === ClientTypesNumber.Taxpayer)[0] != undefined
            ? taxreturn.signedDetails.filter((x: any) => x.signerType === ClientTypesNumber.Taxpayer)[0].isSigned
            : false;

    let isSpouseSigned: boolean =
        taxreturn.signedDetails.filter((x: any) => x.signerType === ClientTypesNumber.Spouse)[0] != undefined
            ? taxreturn.signedDetails.filter((x: any) => x.signerType === ClientTypesNumber.Spouse)[0].isSigned
            : false;

    let isTaxPayerEmailAvailable: boolean = false;
    isTaxPayerEmailAvailable =
        taxreturn.taxpayer.email != null && taxreturn.taxpayer.email != "" && taxreturn.taxpayer.email != undefined;

    let isSpouseEmailAvailable: boolean = false;
    isSpouseEmailAvailable =
        taxreturn.spouse.email != null && taxreturn.spouse.email != "" && taxreturn.spouse.email != undefined;

    if (checkDeceased) {
        if (isSpouseEmailAvailable && !isValidEmailAddress(taxreturn.spouse.email)) {
            VenusNotifier.Warning(Constants.ClientInfoValidation.inCorrectSpouseEmail, null);
            return false;
        }

        if (isTaxPayerEmailAvailable && !isValidEmailAddress(taxreturn.taxpayer.email)) {
            VenusNotifier.Warning(Constants.ClientInfoValidation.inCorrectTaxpayerEmail, null);
            return false;
        }

        if (!taxreturn.spouse.isDeceased && !isSpouseEmailAvailable) {
            VenusNotifier.Warning(Constants.ClientInfoValidation.inCorrectSpouseEmail, null);
            return false;
        }

        if (!taxreturn.taxpayer.isDeceased && !isTaxPayerEmailAvailable) {
            VenusNotifier.Warning(Constants.ClientInfoValidation.inCorrectTaxpayerEmail, null);
            return false;
        }

        return isValid;
    }

    if (!isSpouseEmailAvailable || !isValidEmailAddress(taxreturn.spouse.email)) {
        VenusNotifier.Warning(Constants.ClientInfoValidation.inCorrectSpouseEmail, null);
        return false;
    } else if (!isTaxPayerEmailAvailable || !isValidEmailAddress(taxreturn.taxpayer.email)) {
        VenusNotifier.Warning(Constants.ClientInfoValidation.inCorrectTaxpayerEmail, null);
        return false;
    }

    return isValid;
}

function isFirstSignerEmailValid(taxreturn: any, isTaxPayerEmailAvailable: boolean, isSpouseEmailAvailable: boolean) {
    let firstSigner: ClientTypes = taxreturn.documentSettings.deliverySettings.deliverTo;
    let isValid: boolean = true;

    switch (firstSigner) {
        case ClientTypes.Taxpayer:
            if (!isTaxPayerEmailAvailable || !isValidEmailAddress(taxreturn.taxpayer.email)) {
                VenusNotifier.Warning(Constants.ClientInfoValidation.inCorrectTaxpayerEmail, null);
                isValid = false;
            }
            if (isSpouseEmailAvailable && !isValidEmailAddress(taxreturn.spouse.email)) {
                VenusNotifier.Warning(Constants.ClientInfoValidation.inCorrectSpouseEmail, null);
                isValid = false;
            }
            return isValid;
        case ClientTypes.Spouse:
            if (!isSpouseEmailAvailable || !isValidEmailAddress(taxreturn.spouse.email)) {
                VenusNotifier.Warning(Constants.ClientInfoValidation.inCorrectSpouseEmail, null);
                isValid = false;
            }
            if (isTaxPayerEmailAvailable && !isValidEmailAddress(taxreturn.taxpayer.email)) {
                VenusNotifier.Warning(Constants.ClientInfoValidation.inCorrectTaxpayerEmail, null);
                isValid = false;
            }
            return isValid;

        default:
            return isTaxPayerEmailAvailable && isValidEmailAddress(taxreturn.taxpayer.email);
    }
}

//Validate if only email exists while saving for edit client info pop-up
export function validateEditClientInfoEmails(taxReturn: any, checkDeceased: boolean = false) {
    let isValid: boolean = true;
    let isPrintForPaperDelivery: boolean = false;

    isPrintForPaperDelivery =
        taxReturn.documentStatus != null &&
        taxReturn.documentStatus != undefined &&
        taxReturn.documentStatus.toUpperCase().trim() === DocumentStatus[DocumentStatus.DOWNLOADPDF] &&
        taxReturn.signatureStatus == SignatureStatus[SignatureStatus.AlternateDelivery];

    if (isPrintForPaperDelivery) {
        return true;
    } else if (isMutual(taxReturn)) {
        let isDeliveredReturn: boolean = false;
        isDeliveredReturn =
            taxReturn.documentStatus != null &&
            taxReturn.documentStatus != undefined &&
            taxReturn.documentStatus.toUpperCase().trim() === DocumentStatus[DocumentStatus.DELIVERED];

        if (isDeliveredReturn) {
            return validateDeliveredReturnClientInfoEmails(taxReturn, true);
        } else if (
            !taxReturn.taxpayer.isDeceased &&
            (!taxReturn.taxpayer.email || !isValidEmailAddress(taxReturn.taxpayer.email))
        ) {
            VenusNotifier.Warning(Constants.ClientInfoValidation.inCorrectTaxpayerEmail, null);
            isValid = false;
        } else if (taxReturn.taxpayer.isDeceased) {
            if (
                taxReturn.taxpayer.email != null &&
                taxReturn.taxpayer.email != "" &&
                taxReturn.taxpayer.email != undefined &&
                !isValidEmailAddress(taxReturn.taxpayer.email)
            ) {
                VenusNotifier.Warning(Constants.ClientInfoValidation.inCorrectTaxpayerEmail, null);
                isValid = false;
            }
        } else if (!taxReturn.spouse.isDeceased && (!taxReturn.spouse.email || !isValidEmailAddress(taxReturn.spouse.email))) {
            VenusNotifier.Warning(Constants.ClientInfoValidation.inCorrectSpouseEmail, null);
            isValid = false;
        } else if (taxReturn.spouse.isDeceased) {
            if (
                taxReturn.spouse.email != null &&
                taxReturn.spouse.email != "" &&
                taxReturn.spouse.email != undefined &&
                !isValidEmailAddress(taxReturn.spouse.email)
            ) {
                VenusNotifier.Warning(Constants.ClientInfoValidation.inCorrectSpouseEmail, null);
                isValid = false;
            }
        }
    } else if (isPartnership(taxReturn)) {
        if (
            (taxReturn.partnership.email && taxReturn.partnership.email.trim() == "") ||
            taxReturn.partnership.email == undefined ||
            taxReturn.partnership.email == null ||
            !isValidEmailAddress(taxReturn.partnership.email)
        ) {
            VenusNotifier.Warning(Constants.ClientInfoValidation.inCorrectCompanyEmail, null);
            isValid = false;
        }
    } else if (isIndividual(taxReturn)) {
        if (
            (taxReturn.taxpayer.email && taxReturn.taxpayer.email.trim() == "") ||
            taxReturn.taxpayer.email == null ||
            taxReturn.taxpayer.email == undefined ||
            !isValidEmailAddress(taxReturn.taxpayer.email)
        ) {
            VenusNotifier.Warning(Constants.ClientInfoValidation.inCorrectTaxpayerEmail, null);
            isValid = false;
        }
    }
    return isValid;
}

export function validateTaxPayerEmail(taxReturn: any): taxReturn is IMarriedJointTaxReturn {
    let isValid: boolean = true;
    if (!taxReturn.taxpayer.email || !isValidEmailAddress(taxReturn.taxpayer.email)) {
        VenusNotifier.Warning(Constants.ClientInfoValidation.inCorrectTaxpayerEmail, null);
        isValid = false;
    }
    return isValid;
}

export function validateSpouseEmail(taxReturn: any): taxReturn is IMarriedJointTaxReturn {
    let isValid: boolean = true;
    if (!taxReturn.spouse.email || !isValidEmailAddress(taxReturn.spouse.email)) {
        VenusNotifier.Warning(Constants.ClientInfoValidation.inCorrectSpouseEmail, null);
        isValid = false;
    }
    return isValid;
}

export function validateControllerEmail(groupInfo: IGroupInfo) {
    let isValid: boolean = true;
    if (!groupInfo.controllerInfo.emailAddress || !isValidEmailAddress(groupInfo.controllerInfo.emailAddress)) {
        VenusNotifier.Warning(Constants.ClientInfoValidation.inCorrectControllerEmail, null);
        isValid = false;
    }
    return isValid;
}

export function validateMailingAddress(taxReturn: ITaxReturn): boolean {
    if (
        taxReturn.documentSettings.deliverySettings.manualAddressId == null ||
        taxReturn.documentSettings.deliverySettings.manualAddressId == undefined ||
        taxReturn.documentSettings.deliverySettings.manualAddressId == 0
    ) {
        return false;
    }
    return true;
}

export function validateMailingAddressForGroup(groupReturnModel: IGroupedReturnsModel): boolean {
    if (
        groupReturnModel.groupInfo.groupSettings.groupDeliverySettings.manualAddressId == 0 ||
        groupReturnModel.groupInfo.groupSettings.groupDeliverySettings.manualAddressId == undefined ||
        groupReturnModel.groupInfo.groupSettings.groupDeliverySettings.manualAddressId == null
    ) {
        return false;
    }
    return true;
}

export function selectUsers(users: IUserModel[], ids: number[]): IUserModel[] {
    let selected: IUserModel[] = [];
    users.map((user, i) => {
        let found = ids.find((id) => id === user.userId);
        if (found) {
            selected.push(user);
        }
    });
    return selected;
}
export function selectSSRUsers(users: ISSRUserModel[], ids: number[]): ISSRUserModel[] {
    let selected: ISSRUserModel[] = [];
    users.map((user, i) => {
        let found = ids.find((id) => id === user.id);
        if (found) {
            selected.push(user);
        }
    });
    return selected;
}

export function handleSelect(
    selected: IUserModel,
    event: any,
    stateSelect: IUserModel[],
    stateCollection: IUserModel[],
    lastSelect: number
) {
    let select = [...stateSelect];
    if (event.ctrlKey) {
        //Ctrl Click: add with existing items
        if (!removeItem(selected, select)) {
            select.push(selected);
        }
    } else if (event.shiftKey) {
        //Shift Click: select all from the previous item, discard previous items
        let prevIndex = stateCollection.findIndex((s) => s.userId === lastSelect);
        let currIndex = stateCollection.findIndex((s) => s.userId === selected?.userId);
        if (prevIndex > currIndex) {
            let temp = currIndex;
            currIndex = prevIndex;
            prevIndex = temp;
        }
        select = [];
        for (var i = prevIndex; i <= currIndex; i++) {
            select.push(stateCollection[i]);
        }
    } else {
        //Normal Click: Select only the clicked item
        select = [selected];
    }
    return select;
}

export function handleSSRSelect(
    selected: ISSRUserModel,
    event: any,
    stateSelect: ISSRUserModel[],
    stateCollection: ISSRUserModel[],
    lastSelect: number
) {
    let select = [...stateSelect];
    if (event.ctrlKey) {
        //Ctrl Click: add with existing items
        if (!removeSSRItem(selected, select)) {
            select.push(selected);
        }
    } else if (event.shiftKey) {
        //Shift Click: select all from the previous item, discard previous items
        let prevIndex = stateCollection.findIndex((s) => s.id === lastSelect);
        let currIndex = stateCollection.findIndex((s) => s.id === selected?.id);
        if (prevIndex > currIndex) {
            let temp = currIndex;
            currIndex = prevIndex;
            prevIndex = temp;
        }
        select = [];
        for (var i = prevIndex; i <= currIndex; i++) {
            select.push(stateCollection[i]);
        }
    } else {
        //Normal Click: Select only the clicked item
        select = [selected];
    }
    return select;
}

export function removeItem(item: IUserModel, list: IUserModel[]) {
    let pos = list.findIndex((u) => u.userId === item?.userId);
    if (pos !== -1) {
        list.splice(pos, 1);
        return true;
    }
    return false;
}
export function removeSSRItem(item: ISSRUserModel, list: ISSRUserModel[]) {
    let pos = list.findIndex((u) => u.id === item?.id);
    if (pos !== -1) {
        list.splice(pos, 1);
        return true;
    }
    return false;
}

export function removeItemById(itemId: number, list: IUserModel[]): IUserModel[] {
    let listExceptItem: IUserModel[] = [...list];
    let pos = listExceptItem.findIndex((u) => u.userId === itemId);
    if (pos !== -1) {
        listExceptItem.splice(pos, 1);
    }
    return listExceptItem;
}

export function intersect(a: any[], b: any[]) {
    var t;
    if (a && b && b.length > a.length) (t = b), (b = a), (a = t); // indexOf to loop over shorter
    return a.filter(function (e) {
        return b && b.indexOf(e) > -1;
    });
}

export function intersection(arrays: any[]): any[] {
    if (arrays && arrays.length < 2) {
        return arrays[0];
    }
    let ret = arrays[0] as any[];
    for (var i = 1; i < arrays.length; i++) {
        ret = intersect(ret, arrays[i]);
    }
    return ret;
}

export function isDocumentAccessLoaded(taxReturns: ITaxReturn[]) {
    let ret = false;
    for (var i in taxReturns) {
        if (!taxReturns[i].documentAccess) {
            return false;
        }
        ret = true;
    }
    return ret;
}

export function taxReturnTitle(model: ITaxReturn) {
    {
        if (!model) {
            return "No TaxReturn Selected!";
        }
        if (isPartnership(model)) {
            return model.partnership.name + " - " + model.clientId;
        }
        if (isMutual(model) || isIndividual(model)) {
            let Name = model.taxpayer.name.split(" ");

            return (Name.length > 1 ? Name[1] + ", " : "") + Name[0] + " - " + model.clientId;
        }

        return model.clientId;
    }
}

//export const TAX_FORMS: DocumentGroups[] = [
//    DocumentGroups.Transmittals,
//    DocumentGroups.TaxReturns,
//    DocumentGroups.EFile,
//    DocumentGroups.Vouchers,
//    DocumentGroups.Invoice,
//    DocumentGroups.K1,
//    DocumentGroups.Deleted
//];

//export function orderFormGroups(model: ITaxReturn): ITaxReturn {

//    if (model && model.formGroups && model.formGroups.length > 0) {
//        let formGroups: IGroup[] = [];
//        TAX_FORMS.map((groupType, i) => {
//            let groups = model.formGroups.filter((g) => g.group === groupType);
//            if (groups.length > 0) {
//                let group: IGroup = { group: groupType, forms: [] };
//                groups.map((g, j) => {
//                    //TODO type caste to appropriate form group form
//                    group.forms.push(...g.forms.sort((first: IFormBase, second: IFormBase) => {
//                        return (first.pageNo[0] - second.pageNo[0]);
//                    }));
//                });
//                formGroups[groupType] = group;
//            }
//        });
//        if (!formGroups[DocumentGroups.Deleted]) {
//            formGroups[DocumentGroups.Deleted] = {
//                group: DocumentGroups.Deleted,
//                forms:[]
//            };
//        }
//        model.formGroups = formGroups;
//    }
//    return model;
//}

export function removeForms(model: ITaxReturn, pages: number[], source?: DocumentGroups): IFormBase[] {
    let forms: IFormBase[] = [];
    if (model && model.formGroups) {
        model.formGroups.map((group, j) => {
            for (let i = 0; i < group.forms.length; i++) {
                let form = group.forms[i];
                let found = NO_INDEX;
                if (group.group == source || !source) {
                    form.pageNo.map((page, k) => {
                        found = pages.findIndex((n) => n === page);
                    });
                    if (found !== NO_INDEX) {
                        //Remove the form and add to the return
                        forms = forms.concat(group.forms.splice(i, 1));
                        i--;
                    }
                }
            }
        });
    }
    return forms;
}

export function removeSignatureControl(model: ITaxReturn, control: ISignatureControl, pageNo: number): void {
    if (model && model.formGroups) {
        let index = model.formGroups.findIndex((x) => x.group == DocumentGroups.EFile);
        if (model.formGroups[index]) {
            model.formGroups[index].forms.map((f: IFormBase) => {
                let form: IEFile = f as IEFile;
                let found = NO_INDEX;
                found = form.pageNo.findIndex((n) => n === pageNo);

                if (found != NO_INDEX) {
                    form.signatureControls = form.signatureControls.filter((x) => x.controlGuid != control.controlGuid);
                }
            });
        }
    }
}

export function addSignatureControl(model: ITaxReturn, control: IEfileDocumentControl, pageNo: number): void {
    if (model && model.formGroups) {
        let index = model.formGroups.findIndex((x) => x.group == DocumentGroups.EFile);
        if (model.formGroups[index]) {
            model.formGroups[index].forms.map((f: IFormBase) => {
                let form: IEFile = f as IEFile;
                let found = NO_INDEX;
                found = form.pageNo.findIndex((n) => n === pageNo);
                if (found != NO_INDEX) {
                    form.signatureControls.push(control);
                }
            });
        }
    }
}

export function voucherExist(taxReturn: ITaxReturn, customType: CustomType): boolean {
    let isExist: boolean = false;
    let formIndex = taxReturn.formGroups.findIndex((x) => x.group == DocumentGroups.Vouchers);
    if (formIndex > -1 && taxReturn.formGroups[formIndex].forms.length > 0) {
        let voucherForms = taxReturn.formGroups[formIndex].forms as IVoucher[];
        for (let i = 0; i < voucherForms.length; i++) {
            if (voucherForms[i].customType === customType) {
                isExist = true;
                break;
            }
        }
    }
    return isExist;
}

export function replaceSignatureControl(
    model: ITaxReturn,
    oldControl: IEfileDocumentControl,
    newControl: IEfileDocumentControl,
    pageNo: number
): void {
    if (model && model.formGroups) {
        let index = model.formGroups.findIndex((x) => x.group == DocumentGroups.EFile);
        if (model.formGroups[index]) {
            model.formGroups[index].forms.map((f: IFormBase) => {
                let form: IEFile = f as IEFile;
                let found = NO_INDEX;
                found = form.pageNo.findIndex((n) => n === pageNo);

                if (found != NO_INDEX) {
                    for (let i = 0; i < form.signatureControls.length; i++) {
                        let signControl = form.signatureControls[i];

                        if (signControl.controlGuid === oldControl.controlGuid) {
                            signControl.left = newControl.left;
                            signControl.top = newControl.top;
                            signControl.signatureControlRole = newControl.signatureControlRole;
                            signControl.type = newControl.type;
                            signControl.required = newControl.required;
                            signControl.tooltip = newControl.tooltip;

                            break;
                        }
                    }
                }
            });
        }
    }
}

export function isK1ShareHolder(engagementType: EngagementType, arrangement: Arrangement): boolean {
    const llc: any = Arrangement[Arrangement.SingleMemberLLC];
    const e1065: any = EngagementType[EngagementType.E1065];
    const e1120s: any = EngagementType[EngagementType.E1120S];
    const e1041: any = EngagementType[EngagementType.E1041];
    return arrangement != llc && (engagementType === e1065 || engagementType === e1120s || engagementType === e1041);
}

export function isIndividualEnagagementType(engagementType: EngagementType): boolean {
    const parameterType = typeof engagementType;
    if (parameterType == "number") {
        const e1040: any = EngagementType.E1040;
        const e1040NR: any = EngagementType.E1040NR;
        return engagementType === e1040 || engagementType === e1040NR;
    } else {
        const e1040: any = EngagementType[EngagementType.E1040];
        const e1040NR: any = EngagementType[EngagementType.E1040NR];
        return engagementType === e1040 || engagementType === e1040NR;
    }
}

export function isInvoiceAllowed(arrangement: Arrangement) {
    const llc: any = Arrangement[Arrangement.SingleMemberLLC];
    return arrangement != llc;
}

export function cloneTaxReturn(taxReturn: ITaxReturn): ITaxReturn {
    //TODO: complete deepCopy
    let ret = Object.assign({}, taxReturn);
    let formGroup: IGroup[] = [];
    taxReturn.formGroups.map((group, i) => {
        let forms: IFormBase[] = [];
        if (group.forms) {
            group.forms.map((form, j) => {
                let f = { ...form };
                f.pageNo = [...form.pageNo];
                forms.push(f);
            });

            if (group.group === DocumentGroups.K1) {
                let k1: IK1 = {} as IK1;
                k1.forms = forms;
                k1.group = group.group;
                k1.shareHolder = { ...(group as IK1).shareHolder };
                formGroup.push(k1);
            } else {
                let g: IGroup = {} as IGroup;
                g.forms = forms;
                g.group = group.group;
                formGroup.push(g);
            }
        }
    });
    ret.formGroups = formGroup;
    ret.assignedUser = Object.assign({}, taxReturn.assignedUser);
    ret.attachments = taxReturn.attachments ? [...taxReturn.attachments] : [];
    ret.clientTracking = taxReturn.clientTracking ? [...taxReturn.clientTracking] : [];
    ret.documentAccess = Object.assign({}, taxReturn.documentAccess);
    ret.documentSettings = Object.assign({}, taxReturn.documentSettings);
    ret.partner = Object.assign({}, taxReturn.partner);
    ret.refundInfo = taxReturn.refundInfo ? [...taxReturn.refundInfo] : [];
    if (isMutual(ret)) {
        ret.taxpayer = Object.assign({}, ret.taxpayer);
        ret.spouse = Object.assign({}, ret.spouse);
    }
    if (isIndividual(ret)) {
        ret.taxpayer = Object.assign({}, ret.taxpayer);
    }
    if (isPartnership(ret)) {
        ret.partnership = Object.assign({}, ret.partnership);
    }
    return ret;
}

export function removeLastComma(str: string) {
    return str.replace(/,(\s+)?$/, "");
}

export function printTextOnCanvas(text: string) {
    let canvas: any = null;
    canvas = document.querySelector("canvas");
    var ctx = canvas.getContext("2d");
    let stack: any = [];
    ctx.font = 'normal 100 200px "Journal",Georgia,Times,serif';
    ctx.fillStyle = "#145394";

    let state: any = [];
    for (let property in ctx) {
        if (property == "canvas") continue;
        if (typeof ctx[property] == "function") continue;
        state[property] = ctx[property];
    }
    stack.push(state);

    let width = 0;
    if (text.length <= 6) {
        width = ctx.measureText(text).width / 2.5;
    } else if (text.length > 6 && text.length <= 15) {
        width = ctx.measureText(text).width / 3;
    } else {
        width = ctx.measureText(text).width / 3.5;
    }

    ctx.canvas.width = width ? width : canvas.width;
    ctx.canvas.height = 100 || canvas.height;

    let newState = stack.pop() || {};
    for (let property in newState) {
        ctx[property] = newState[property];
    }

    ctx.font = 'normal 100 50px "Journal",Georgia,Times,serif';
    ctx.fillStyle = "#145394";
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    ctx.fillText(text, 14, 59);
    return canvas?.toDataURL("image/png");
}

export function formatCurency(amount: number) {
    return parseFloat((Math.round(amount * 100) / 100).toString()).toFixed(2);
}

export function GetDocumentStatus<T>(enumName: any, value: T): T {
    if (enumName[(value as unknown as String).toString() + Constants.Assignment.Description] == undefined) {
        return value;
    } else {
        return enumName[(value as unknown as String).toString() + Constants.Assignment.Description];
    }
}

export function PrepareTaxYear(minTaxYear?: number) {
    if (minTaxYear === undefined) {
        minTaxYear = 2015;
    }
    var years: any = [];
    for (let i = new Date().getFullYear() - 1; i >= minTaxYear; i--) {
        years.push({
            value: i,
            label: i.toString()
        });
    }
    return years;
}

export function AllTaxYearSelectedString(minTaxYear?: number) {
    if (minTaxYear === undefined) {
        minTaxYear = 2015;
    }
    var years = "0";
    for (let i = new Date().getFullYear() - 1; i >= minTaxYear; i--) {
        years += "," + i;
    }
    return years;
}

export function PrepareOrganizerTaxYear(minTaxYear?: number) {
    if (minTaxYear === undefined) {
        minTaxYear = 2020;
    }
    let maxTaxYear = new Date().getFullYear() - 1;
    const month = new Date().getMonth();
    if (month == 11) {
        // If Current month is Dec then add current year in tax year list
        maxTaxYear++;
    }
    var years = [];
    for (let i = maxTaxYear; i >= minTaxYear; i--) {
        years.push({
            value: i,
            label: i.toString()
        });
    }
    return years;
}

export function PrepareSFTaxYear(minTaxYear?: number) {
    if (minTaxYear === undefined) {
        minTaxYear = 2015;
    }
    var years = [];
    for (let i = new Date().getFullYear(); i >= minTaxYear; i--) {
        years.push({
            value: i,
            label: i.toString()
        });
    }
    return years;
}

export function prepareCustomColumnValues(customColumn: ICustomColumn) {
    var columnValues: any = [];
    columnValues.push({
        value: 0,
        label: "Select"
    });
    customColumn.customColumnValues.map((column: any) =>
        columnValues.push({
            value: column.id,
            label: column.value
        })
    );

    return columnValues;
}

export function prepareStateDropdown(states: ICountryState[]) {
    var columnValues: any = [];
    states.map((state: ICountryState) => {
        columnValues.push({
            value: state.name,
            label: state.name
        });
    });
    return columnValues;
}

export function prepareAuthorityDropdown(states: ITaxingAuthority[]) {
    var columnValues: any = [
        {
            value: 0,
            label: "Select"
        }
    ];
    states.map((state: any) =>
        columnValues.push({
            value: state.Id,
            label: state.AuthorityName
        })
    );

    return columnValues;
}

export function prepareCountryStateDropdown(states: ICountryState[]) {
    var columnValues: any = [];
    states.map((state: any) =>
        columnValues.push({
            value: state.id,
            label: state.name
        })
    );

    return columnValues;
}

export function prepareAdminUserDropdown(users: IPrimaryAdmin[]) {
    var columnValues: any = [];
    users.map((user: any) =>
        columnValues.push({
            value: user.id,
            label: user.firstName
        })
    );

    return columnValues;
}

export function prepareAuthorityStateDropdown(states: ITaxingAuthority[]) {
    var columnValues: any = [];
    states.map((state: any) =>
        columnValues.push({
            value: state.Id,
            label: state.Abbreviation
        })
    );

    return columnValues;
}

export function insertWhiteSpace(name: string) {
    return name.replace(/([A-Z][a-z]|[A-Z][0-9])/g, " $1");
}

export function enumToNumbers(enums: any) {
    const keys = Object.keys(enums).filter((k) => typeof enums[k] === "number");
    return keys.map((k) => enums[k]);
}

export function isBusinessReturn(taxReturn: ITaxReturn) {
    let e1065: any = EngagementType[EngagementType.E1065];
    let e1120: any = EngagementType[EngagementType.E1120];
    let e1120s: any = EngagementType[EngagementType.E1120S];
    let e1041: any = EngagementType[EngagementType.E1041];

    return (
        taxReturn.engagementType === e1065 ||
        taxReturn.engagementType === e1041 ||
        taxReturn.engagementType === e1120 ||
        taxReturn.engagementType === e1120s
    );
}
export function numberFormatting(number: string) {
    return Number(number)
        .toFixed(2)
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        .toString();
}

export function getStatus(value: number): string {
    switch (value) {
        case SignatureStatus.ESigned:
            return "E Signed";
        case SignatureStatus.ESignedInOffice:
            return "E-Signed – In Office";
        case SignatureStatus.ManuallySigned:
            return " Manually Signed";
        case SignatureStatus.Uploaded:
            return "Uploaded";
        case SignatureStatus.AwaitingESign:
            return "Awaiting E-Sign";
        case SignatureStatus.AwaitingUpload:
            return "Awaiting Upload";
        case SignatureStatus.Locked:
            return "Locked";
        case SignatureStatus.MailOrFax:
            return "Mail Or Fax";
        case SignatureStatus.SignedAndESigned:
            return "Signed And E-Signed";
        case SignatureStatus.AutoParsed:
            return "Auto Parsed";
        case SignatureStatus.Processing:
            return "Processing";
        case SignatureStatus.Delivering:
            return "Delivering";
        case SignatureStatus.Delivered:
            return "Delivered";
        case SignatureStatus.DeliveryFailed:
            return "Delivery Failed";
        case SignatureStatus.UploadInProgress:
            return "Upload In Progress";
        case SignatureStatus.DeliveredToTaxCaddy:
            return "Delivered To TaxCaddy";
        case SignatureStatus.TaxCaddyDeliveryFailed:
            return "TaxCaddy Delivery Failed";
        case SignatureStatus.PartiallySigned:
            return "Partially Signed";
    }
    return "";
}

export function getSignatureStatusAsString(signatureStatus: SignatureStatus): string {
    return typeof signatureStatus == "number" ? SignatureStatus[signatureStatus] : signatureStatus;
}

export function filterSignatureByRole(signatureControls: any, prop: string, role: number) {
    var filtered = [];
    for (var i = 0; i < signatureControls.length; i++) {
        var obj = signatureControls[i];
        for (var key in obj) {
            if (typeof (obj[key] == "object")) {
                var item = obj[key];
                if (item[prop] == role) {
                    filtered.push(item);
                }
            }
        }
    }
    return filtered;
}

export function capitaliseString(string: string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export function capitaliseOnlyFirstLetter(string: string) {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
}

export function CheckIfValueExist(data: any) {
    if (data !== null && data !== undefined) {
        return true;
    }
    return false;
}

export function CheckIfStringHasValue(data: string) {
    if (CheckIfValueExist(data) && data !== "") {
        return true;
    }
    return false;
}

function GetPreviousDate(value: number) {
    const d = new Date();
    d.setDate(d.getDate() - value);
    return moment(d.toString()).format("MM/DD/YYYY");
}

export function GetNumberOfDays(value: any) {
    switch (value) {
        case "One_Day":
            return GetPreviousDate(1);

        case "Two_Days":
            return GetPreviousDate(2);

        case "Three_Days":
            return GetPreviousDate(3);

        case "Five_Days":
            return GetPreviousDate(5);

        case "Seven_Days":
            return GetPreviousDate(7);

        case "Fifteen_Days":
            return GetPreviousDate(15);

        case "Thirty_Days":
            return GetPreviousDate(30);
        default:
            return GetPreviousDate(1);
    }
}

export function FilterSSN(ssn: string): string {
    if (ssn && ssn.startsWith("SSN")) {
        return "";
    } else {
        return ssn;
    }
}

export function GetPagesToMatch(matchedForms: IFormBase[], pages: number[]): any {
    return pages.filter((x) => matchedForms.some((y) => y.pageNo[0] != x));
}

export function GetFileMagicNumber(file: any): Promise<any> {
    const reader = new FileReader();
    reader.readAsArrayBuffer(file.slice(0, 4));
    return new Promise((resolve) => {
        reader.onload = function () {
            if (this.readyState == reader.DONE) {
                const arrayBuffer: any = this.result;
                const uint = new Uint8Array(arrayBuffer).subarray(0, 4);
                let bytes: any = [];
                uint.forEach((byte) => {
                    bytes.push(byte.toString(16));
                });
                return resolve(bytes.join("").toUpperCase());
            }
        };
    });
}
export function ReplaceHelperVariableForEditor(message: string) {
    message = message.replace(/<COMPANYNAME>/g, "&amp;lt;COMPANYNAME&amp;gt;");
    message = message.replace(/<TAXPAYERNAME>/g, "&amp;lt;TAXPAYERNAME&amp;gt;");
    message = message.replace(/<SPOUSENAME>/g, "&amp;lt;SPOUSENAME&amp;gt;");
    message = message.replace(/<PREPARERNAME>/g, "&amp;lt;PREPARERNAME&amp;gt;");
    message = message.replace(/<DUEDATE>/g, "&amp;lt;DUEDATE&amp;gt;");
    message = message.replace(/<TAXYEAR>/g, "&amp;lt;TAXYEAR&amp;gt;");
    message = message.replace(/<NUMBEROFDAYS>/g, "&amp;lt;NUMBEROFDAYS&amp;gt;");
    message = message.replace(/<RECIPIENT>/g, "&amp;lt;RECIPIENT&amp;gt;");
    message = message.replace(/<HELPCONTACT>/g, "&amp;lt;HELPCONTACT&amp;gt;");
    message = message.replace(/<HELPPHONE>/g, "&amp;lt;HELPPHONE&amp;gt;");
    message = message.replace(/<HELPEMAIL>/g, "&amp;lt;HELPEMAIL&amp;gt;");
    message = message.replace(/<SENDERNAME>/g, "&amp;lt;SENDERNAME&amp;gt;");
    message = message.replace(/<ERONAME>/g, "&amp;lt;ERONAME&amp;gt;");
    message = message.replace(/<GROUPNAME>/g, "&amp;lt;GROUPNAME&amp;gt;");
    message = message.replace(/<CONTROLLERNAME>/g, "&amp;lt;CONTROLLERNAME&amp;gt;");

    return message;
}

export function ReplaceHelperVariable(message: string) {
    message = message.replace(/<COMPANYNAME>/g, "&lt;COMPANYNAME&gt;");
    message = message.replace(/<TAXPAYERNAME>/g, "&lt;TAXPAYERNAME&gt;");
    message = message.replace(/<SPOUSENAME>/g, "&lt;SPOUSENAME&gt;");
    message = message.replace(/<PREPARERNAME>/g, "&lt;PREPARERNAME&gt;");
    message = message.replace(/<DUEDATE>/g, "&lt;DUEDATE&gt;");
    message = message.replace(/<TAXYEAR>/g, "&lt;TAXYEAR&gt;");
    message = message.replace(/<NUMBEROFDAYS>/g, "&lt;NUMBEROFDAYS&gt;");
    message = message.replace(/<RECIPIENT>/g, "&lt;RECIPIENT&gt;");
    message = message.replace(/<HELPCONTACT>/g, "&lt;HELPCONTACT&gt;");
    message = message.replace(/<HELPPHONE>/g, "&lt;HELPPHONE&gt;");
    message = message.replace(/<HELPEMAIL>/g, "&lt;HELPEMAIL&gt;");
    message = message.replace(/<SENDERNAME>/g, "&lt;SENDERNAME&gt;");
    message = message.replace(/<ERONAME>/g, "&lt;ERONAME&gt;");
    message = message.replace(/<GROUPNAME>/g, "&lt;GROUPNAME&gt;");
    message = message.replace(/<CONTROLLERNAME>/g, "&lt;CONTROLLERNAME&gt;");
    return message;
}

export function padDigits(value: any, digits: number) {
    return Array(Math.max(digits - String(value).length + 1, 0)).join("0") + value;
}

export function getAuthorityData(authorityId: number, authorities: ITaxingAuthority[]): { name: string; image: string } {
    let voucherAuthority: ITaxingAuthority = authorities[authorities.findIndex((x) => x.Id === authorityId)];
    let stateImage: { imageName: string; stateUSA: StateUSA } | undefined;
    let stateAbbreviation: any = voucherAuthority?.Abbreviation;
    stateImage = getAllStateImages().find((img) => img.stateUSA === StateUSA[stateAbbreviation as keyof typeof StateUSA]);
    return {
        name: voucherAuthority.AuthorityName,
        image: stateImage !== undefined ? stateImage.imageName : "no-image.png"
    };
}

export function getMaskedValue(value: string, mask: string, isMaskingEnabled?: boolean): string {
    if (value) {
        let maskedValue = "";
        if (isMaskingEnabled) {
            REGEX_VALUES = [{ Key: "0", Value: /[0-9*]/ }];
        }
        const computedMask: any[] = mask.split("").map((m) => {
            const index = REGEX_VALUES.findIndex((a) => a.Key == m);
            if (index > -1) {
                return REGEX_VALUES[index].Value;
            } else {
                return m;
            }
        });

        let processedIndex = 0;
        const currentValue = getUnmaskedValue(value, mask);

        for (let index = 0; index < computedMask.length; index++) {
            if (computedMask[index] instanceof RegExp) {
                if (processedIndex < currentValue.length) {
                    let valueUpdated = false;

                    for (let i = processedIndex; i < currentValue.length; i++) {
                        if (computedMask[index].test(currentValue[processedIndex]) || currentValue[processedIndex] == "*") {
                            maskedValue += currentValue[processedIndex];
                            processedIndex += 1;
                            valueUpdated = true;
                            break;
                        } else {
                            processedIndex += 1;
                        }
                    }

                    if (!valueUpdated) {
                        maskedValue += MASK_PLACEHOLDER;
                    }
                } else {
                    maskedValue += MASK_PLACEHOLDER;
                }
            } else {
                maskedValue += computedMask[index];
            }
        }

        if (getUnmaskedValue(maskedValue, mask) != "") {
            return maskedValue;
        }
    }

    return "";
}

export function getUnmaskedValue(value: string, mask: string): string {
    if (value) {
        const separators = getSeparators(mask);

        return value
            .split("")
            .filter((v) => {
                return separators.every((separator) => separator != v) && v != MASK_PLACEHOLDER;
            })
            .join("");
    } else {
        return "";
    }
}

export function getSeparators(mask: string): string[] {
    return mask.split("").filter((m, index, self) => {
        return REGEX_VALUES.findIndex((regex) => regex.Key == m) == -1 && self.indexOf(m) == index;
    });
}

export function maskNumbers(input: string): string {
    const maskedString = input.replace(/\d/g, "*");
    return maskedString;
}

export function decodeText(text: string) {
    return text.replace(/\&nbsp;/g, " ");
}

export function openWindowWithPostRequest(
    url: string,
    data: string,
    fieldName: string,
    scope?: string,
    controllerGuid?: string,
    cpaUserId?: number
) {
    var winName = "_blank";
    var winURL = url;
    var form = document.createElement("form");
    form.setAttribute("method", "post");
    form.setAttribute("action", winURL);
    form.setAttribute("target", winName);
    form.setAttribute("enctype", "application/json");
    var input = document.createElement("input");
    input.type = "hidden";
    input.name = fieldName;
    input.value = JSON.stringify(data);
    form.appendChild(input);
    if (scope) {
        var iscope = document.createElement("input");
        iscope.type = "hidden";
        iscope.name = "scope";
        iscope.value = scope;
        form.appendChild(iscope);
        document.body.appendChild(form);
    }

    if (controllerGuid) {
        var isControllerGuid = document.createElement("input");
        isControllerGuid.type = "hidden";
        isControllerGuid.name = "controllerGuid";
        isControllerGuid.value = controllerGuid;
        form.appendChild(isControllerGuid);
        document.body.appendChild(form);
    }

    if (cpaUserId) {
        var cpaUserIdInput = document.createElement("input");
        cpaUserIdInput.type = "hidden";
        cpaUserIdInput.name = "cpaUserId";
        cpaUserIdInput.value = cpaUserId.toString();
        form.appendChild(cpaUserIdInput);
        document.body.appendChild(form);
    }

    form.target = winName;
    form.submit();
    document.body.removeChild(form);
}

export function GetCountryCode() {
    var CountryCodeOption = [];
    CountryCodeOption.push({ value: "+1", label: "USA/Canada (+1)" });
    CountryCodeOption.push({ value: "+213", label: "Algeria (+213)" });
    CountryCodeOption.push({ value: "+376", label: "Andorra (+376)" });
    CountryCodeOption.push({ value: "+244", label: "Angola (+244)" });
    CountryCodeOption.push({ value: "+1264", label: "Anguilla (+1264)" });
    CountryCodeOption.push({ value: "+1268", label: "Antigua Barbuda (+1268)" });
    CountryCodeOption.push({ value: "+54", label: "Argentina (+54)" });
    CountryCodeOption.push({ value: "+374", label: "Armenia (+374)" });
    CountryCodeOption.push({ value: "+297", label: "Aruba (+297)" });
    CountryCodeOption.push({ value: "+61", label: "Australia (+61)" });
    CountryCodeOption.push({ value: "+43", label: "Austria (+43)" });
    CountryCodeOption.push({ value: "+994", label: "Azerbaijan (+994)" });
    CountryCodeOption.push({ value: "+1242", label: "Bahamas (+1242)" });
    CountryCodeOption.push({ value: "+973", label: "Bahrain (+973)" });
    CountryCodeOption.push({ value: "+880", label: "Bangladesh (+880)" });
    CountryCodeOption.push({ value: "+1246", label: "Barbados (+1246)" });
    CountryCodeOption.push({ value: "+375", label: "Belarus (+375)" });
    CountryCodeOption.push({ value: "+32", label: "Belgium (+32)" });
    CountryCodeOption.push({ value: "+501", label: "Belize (+501)" });
    CountryCodeOption.push({ value: "+229", label: "Benin (+229)" });
    CountryCodeOption.push({ value: "+1441", label: "Bermuda (+1441)" });
    CountryCodeOption.push({ value: "+975", label: "Bhutan (+975)" });
    CountryCodeOption.push({ value: "+591", label: "Bolivia (+591)" });
    CountryCodeOption.push({ value: "+387", label: "Bosnia Herzegovina (+387)" });
    CountryCodeOption.push({ value: "+267", label: "Botswana (+267)" });
    CountryCodeOption.push({ value: "+55", label: "Brazil (+55)" });
    CountryCodeOption.push({ value: "+673", label: "Brunei (+673)" });
    CountryCodeOption.push({ value: "+359", label: "Bulgaria (+359)" });
    CountryCodeOption.push({ value: "+226", label: "Burkina Faso (+226)" });
    CountryCodeOption.push({ value: "+257", label: "Burundi (+257)" });
    CountryCodeOption.push({ value: "+855", label: "Cambodia (+855)" });
    CountryCodeOption.push({ value: "+237", label: "Cameroon (+237)" });
    CountryCodeOption.push({ value: "+238", label: "Cape Verde Islands (+238)" });
    CountryCodeOption.push({ value: "+1345", label: "Cayman Islands (+1345)" });
    CountryCodeOption.push({ value: "+236", label: "Central African Republic (+236)" });
    CountryCodeOption.push({ value: "+56", label: "Chile (+56)" });
    CountryCodeOption.push({ value: "+86", label: "China (+86)" });
    CountryCodeOption.push({ value: "+57", label: "Colombia (+57)" });
    CountryCodeOption.push({ value: "+242", label: "Congo (+242)" });
    CountryCodeOption.push({ value: "+682", label: "Cook Islands (+682)" });
    CountryCodeOption.push({ value: "+506", label: "Costa Rica (+506)" });
    CountryCodeOption.push({ value: "+385", label: "Croatia (+385)" });
    CountryCodeOption.push({ value: "+53", label: "Cuba (+53)" });
    CountryCodeOption.push({ value: "+90392", label: "Cyprus North (+90392)" });
    CountryCodeOption.push({ value: "+357", label: "Cyprus South (+357)" });
    CountryCodeOption.push({ value: "+42", label: "Czech Republic (+42)" });
    CountryCodeOption.push({ value: "+45", label: "Denmark (+45)" });
    CountryCodeOption.push({ value: "+253", label: "Djibouti (+253)" });
    CountryCodeOption.push({ value: "+1809", label: "Dominican Republic (+1809)" });
    CountryCodeOption.push({ value: "+593", label: "Ecuador (+593)" });
    CountryCodeOption.push({ value: "+20", label: "Egypt (+20)" });
    CountryCodeOption.push({ value: "+503", label: "El Salvador (+503)" });
    CountryCodeOption.push({ value: "+240", label: "Equatorial Guinea (+240)" });
    CountryCodeOption.push({ value: "+291", label: "Eritrea (+291)" });
    CountryCodeOption.push({ value: "+372", label: "Estonia (+372)" });
    CountryCodeOption.push({ value: "+251", label: "Ethiopia (+251)" });
    CountryCodeOption.push({ value: "+500", label: "Falkland Islands (+500)" });
    CountryCodeOption.push({ value: "+298", label: "Faroe Islands (+298)" });
    CountryCodeOption.push({ value: "+679", label: "Fiji (+679)" });
    CountryCodeOption.push({ value: "+358", label: "Finland (+358)" });
    CountryCodeOption.push({ value: "+33", label: "France (+33)" });
    CountryCodeOption.push({ value: "+594", label: "French Guiana (+594)" });
    CountryCodeOption.push({ value: "+689", label: "French Polynesia (+689)" });
    CountryCodeOption.push({ value: "+241", label: "Gabon (+241)" });
    CountryCodeOption.push({ value: "+220", label: "Gambia (+220)" });
    CountryCodeOption.push({ value: "+7880", label: "Georgia (+7880)" });
    CountryCodeOption.push({ value: "+49", label: "Germany (+49)" });
    CountryCodeOption.push({ value: "+233", label: "Ghana (+233)" });
    CountryCodeOption.push({ value: "+350", label: "Gibraltar (+350)" });
    CountryCodeOption.push({ value: "+30", label: "Greece (+30)" });
    CountryCodeOption.push({ value: "+299", label: "Greenland (+299)" });
    CountryCodeOption.push({ value: "+1473", label: "Grenada (+1473)" });
    CountryCodeOption.push({ value: "+590", label: "Guadeloupe (+590)" });
    CountryCodeOption.push({ value: "+671", label: "Guam (+671)" });
    CountryCodeOption.push({ value: "+502", label: "Guatemala (+502)" });
    CountryCodeOption.push({ value: "+224", label: "Guinea (+224)" });
    CountryCodeOption.push({ value: "+245", label: "Guinea - Bissau (+245)" });
    CountryCodeOption.push({ value: "+592", label: "Guyana (+592)" });
    CountryCodeOption.push({ value: "+509", label: "Haiti (+509)" });
    CountryCodeOption.push({ value: "+504", label: "Honduras (+504)" });
    CountryCodeOption.push({ value: "+852", label: "Hong Kong (+852)" });
    CountryCodeOption.push({ value: "+36", label: "Hungary (+36)" });
    CountryCodeOption.push({ value: "+354", label: "Iceland (+354)" });
    CountryCodeOption.push({ value: "+91", label: "India (+91)" });
    CountryCodeOption.push({ value: "+62", label: "Indonesia (+62)" });
    CountryCodeOption.push({ value: "+98", label: "Iran (+98)" });
    CountryCodeOption.push({ value: "+964", label: "Iraq (+964)" });
    CountryCodeOption.push({ value: "+353", label: "Ireland (+353)" });
    CountryCodeOption.push({ value: "+972", label: "Israel (+972)" });
    CountryCodeOption.push({ value: "+39", label: "Italy (+39)" });
    CountryCodeOption.push({ value: "+1876", label: "Jamaica (+1876)" });
    CountryCodeOption.push({ value: "+81", label: "Japan (+81)" });
    CountryCodeOption.push({ value: "+962", label: "Jordan (+962)" });
    CountryCodeOption.push({ value: "+254", label: "Kenya (+254)" });
    CountryCodeOption.push({ value: "+686", label: "Kiribati (+686)" });
    CountryCodeOption.push({ value: "+850", label: "Korea North (+850)" });
    CountryCodeOption.push({ value: "+82", label: "Korea South (+82)" });
    CountryCodeOption.push({ value: "+965", label: "Kuwait (+965)" });
    CountryCodeOption.push({ value: "+996", label: "Kyrgyzstan (+996)" });
    CountryCodeOption.push({ value: "+856", label: "Laos (+856)" });
    CountryCodeOption.push({ value: "+371", label: "Latvia (+371)" });
    CountryCodeOption.push({ value: "+961", label: "Lebanon (+961)" });
    CountryCodeOption.push({ value: "+266", label: "Lesotho (+266)" });
    CountryCodeOption.push({ value: "+231", label: "Liberia (+231)" });
    CountryCodeOption.push({ value: "+218", label: "Libya (+218)" });
    CountryCodeOption.push({ value: "+417", label: "Liechtenstein (+417)" });
    CountryCodeOption.push({ value: "+370", label: "Lithuania (+370)" });
    CountryCodeOption.push({ value: "+352", label: "Luxembourg (+352)" });
    CountryCodeOption.push({ value: "+853", label: "Macao (+853)" });
    CountryCodeOption.push({ value: "+389", label: "Macedonia (+389)" });
    CountryCodeOption.push({ value: "+261", label: "Madagascar (+261)" });
    CountryCodeOption.push({ value: "+265", label: "Malawi (+265)" });
    CountryCodeOption.push({ value: "+60", label: "Malaysia (+60)" });
    CountryCodeOption.push({ value: "+960", label: "Maldives (+960)" });
    CountryCodeOption.push({ value: "+223", label: "Mali (+223)" });
    CountryCodeOption.push({ value: "+356", label: "Malta (+356)" });
    CountryCodeOption.push({ value: "+692", label: "Marshall Islands (+692)" });
    CountryCodeOption.push({ value: "+596", label: "Martinique (+596)" });
    CountryCodeOption.push({ value: "+222", label: "Mauritania (+222)" });
    CountryCodeOption.push({ value: "+269", label: "Mayotte (+269)" });
    CountryCodeOption.push({ value: "+52", label: "Mexico (+52)" });
    CountryCodeOption.push({ value: "+691", label: "Micronesia (+691)" });
    CountryCodeOption.push({ value: "+373", label: "Moldova (+373)" });
    CountryCodeOption.push({ value: "+377", label: "Monaco (+377)" });
    CountryCodeOption.push({ value: "+976", label: "Mongolia (+976)" });
    CountryCodeOption.push({ value: "+1664", label: "Montserrat (+1664)" });
    CountryCodeOption.push({ value: "+212", label: "Morocco (+212)" });
    CountryCodeOption.push({ value: "+258", label: "Mozambique (+258)" });
    CountryCodeOption.push({ value: "+95", label: "Myanmar (+95)" });
    CountryCodeOption.push({ value: "+264", label: "Namibia (+264)" });
    CountryCodeOption.push({ value: "+674", label: "Nauru (+674)" });
    CountryCodeOption.push({ value: "+977", label: "Nepal (+977)" });
    CountryCodeOption.push({ value: "+31", label: "Netherlands (+31)" });
    CountryCodeOption.push({ value: "+687", label: "New Caledonia (+687)" });
    CountryCodeOption.push({ value: "+64", label: "New Zealand (+64)" });
    CountryCodeOption.push({ value: "+505", label: "Nicaragua (+505)" });
    CountryCodeOption.push({ value: "+227", label: "Niger (+227)" });
    CountryCodeOption.push({ value: "+234", label: "Nigeria (+234)" });
    CountryCodeOption.push({ value: "+683", label: "Niue (+683)" });
    CountryCodeOption.push({ value: "+672", label: "Norfolk Islands (+672)" });
    CountryCodeOption.push({ value: "+670", label: "Northern Marianas (+670)" });
    CountryCodeOption.push({ value: "+47", label: "Norway (+47)" });
    CountryCodeOption.push({ value: "+968", label: "Oman (+968)" });
    CountryCodeOption.push({ value: "+680", label: "Palau (+680)" });
    CountryCodeOption.push({ value: "+507", label: "Panama (+507)" });
    CountryCodeOption.push({ value: "+675", label: "Papua New Guinea (+675)" });
    CountryCodeOption.push({ value: "+595", label: "Paraguay (+595)" });
    CountryCodeOption.push({ value: "+51", label: "Peru (+51)" });
    CountryCodeOption.push({ value: "+63", label: "Philippines (+63)" });
    CountryCodeOption.push({ value: "+48", label: "Poland (+48)" });
    CountryCodeOption.push({ value: "+351", label: "Portugal (+351)" });
    CountryCodeOption.push({ value: "+1787", label: "Puerto Rico (+1787)" });
    CountryCodeOption.push({ value: "+974", label: "Qatar (+974)" });
    CountryCodeOption.push({ value: "+262", label: "Reunion (+262)" });
    CountryCodeOption.push({ value: "+40", label: "Romania (+40)" });
    CountryCodeOption.push({ value: "+7", label: "Russia (+7)" });
    CountryCodeOption.push({ value: "+250", label: "Rwanda (+250)" });
    CountryCodeOption.push({ value: "+378", label: "San Marino (+378)" });
    CountryCodeOption.push({ value: "+239", label: "Sao Tome Principe (+239)" });
    CountryCodeOption.push({ value: "+966", label: "Saudi Arabia (+966)" });
    CountryCodeOption.push({ value: "+221", label: "Senegal (+221)" });
    CountryCodeOption.push({ value: "+381", label: "Serbia (+381)" });
    CountryCodeOption.push({ value: "+248", label: "Seychelles (+248)" });
    CountryCodeOption.push({ value: "+232", label: "Sierra Leone (+232)" });
    CountryCodeOption.push({ value: "+65", label: "Singapore (+65)" });
    CountryCodeOption.push({ value: "+421", label: "Slovak Republic (+421)" });
    CountryCodeOption.push({ value: "+386", label: "Slovenia (+386)" });
    CountryCodeOption.push({ value: "+677", label: "Solomon Islands (+677)" });
    CountryCodeOption.push({ value: "+252", label: "Somalia (+252)" });
    CountryCodeOption.push({ value: "+27", label: "South Africa (+27)" });
    CountryCodeOption.push({ value: "+34", label: "Spain (+34)" });
    CountryCodeOption.push({ value: "+94", label: "Sri Lanka (+94)" });
    CountryCodeOption.push({ value: "+290", label: "St. Helena (+290)" });
    CountryCodeOption.push({ value: "+1869", label: "St. Kitts (+1869)" });
    CountryCodeOption.push({ value: "+1758", label: "St. Lucia (+1758)" });
    CountryCodeOption.push({ value: "+249", label: "Sudan (+249)" });
    CountryCodeOption.push({ value: "+597", label: "Suriname (+597)" });
    CountryCodeOption.push({ value: "+268", label: "Swaziland (+268)" });
    CountryCodeOption.push({ value: "+46", label: "Sweden (+46)" });
    CountryCodeOption.push({ value: "+41", label: "Switzerland (+41)" });
    CountryCodeOption.push({ value: "+963", label: "Syria (+963)" });
    CountryCodeOption.push({ value: "+886", label: "Taiwan (+886)" });
    CountryCodeOption.push({ value: "+66", label: "Thailand (+66)" });
    CountryCodeOption.push({ value: "+228", label: "Togo (+228)" });
    CountryCodeOption.push({ value: "+676", label: "Tonga (+676)" });
    CountryCodeOption.push({ value: "+1868", label: "Trinidad Tobago (+1868)" });
    CountryCodeOption.push({ value: "+216", label: "Tunisia (+216)" });
    CountryCodeOption.push({ value: "+90", label: "Turkey (+90)" });
    CountryCodeOption.push({ value: "+993", label: "Turkmenistan (+993)" });
    CountryCodeOption.push({ value: "+1649", label: "Turks Caicos Islands (+1649)" });
    CountryCodeOption.push({ value: "+688", label: "Tuvalu (+688)" });
    CountryCodeOption.push({ value: "+256", label: "Uganda (+256)" });
    CountryCodeOption.push({ value: "+44", label: "UK (+44)" });
    CountryCodeOption.push({ value: "+380", label: "Ukraine (+380)" });
    CountryCodeOption.push({ value: "+971", label: "United Arab Emirates (+971)" });
    CountryCodeOption.push({ value: "+598", label: "Uruguay (+598)" });
    CountryCodeOption.push({ value: "+678", label: "Vanuatu (+678)" });
    CountryCodeOption.push({ value: "+379", label: "Vatican City (+379)" });
    CountryCodeOption.push({ value: "+58", label: "Venezuela (+58)" });
    CountryCodeOption.push({ value: "+84", label: "Virgin Islands" });
    CountryCodeOption.push({ value: "+681", label: "Wallis Futuna (+681)" });
    CountryCodeOption.push({ value: "+969", label: "Yemen North (+969)" });
    CountryCodeOption.push({ value: "+967", label: "Yemen South (+967)" });
    CountryCodeOption.push({ value: "+260", label: "Zambia (+260)" });
    CountryCodeOption.push({ value: "+263", label: "Zimbabwe (+263)" });
    return CountryCodeOption;
}

export function GetCountryCodeWithoutCountryName() {
    var CountryCodeOption = [];

    CountryCodeOption.push({ value: "+1", label: "+1" });
    CountryCodeOption.push({ value: "+213", label: "+213" });
    CountryCodeOption.push({ value: "+376", label: "+376" });
    CountryCodeOption.push({ value: "+244", label: "+244" });
    CountryCodeOption.push({ value: "+1264", label: "+1264" });
    CountryCodeOption.push({ value: "+1268", label: "+1268" });
    CountryCodeOption.push({ value: "+54", label: "+54" });
    CountryCodeOption.push({ value: "+374", label: "+374" });
    CountryCodeOption.push({ value: "+297", label: "+297" });
    CountryCodeOption.push({ value: "+61", label: "+61" });
    CountryCodeOption.push({ value: "+43", label: "+43" });
    CountryCodeOption.push({ value: "+994", label: "+994" });
    CountryCodeOption.push({ value: "+1242", label: "+1242" });
    CountryCodeOption.push({ value: "+973", label: "+973" });
    CountryCodeOption.push({ value: "+880", label: "+880" });
    CountryCodeOption.push({ value: "+1246", label: "+1246" });
    CountryCodeOption.push({ value: "+375", label: "+375" });
    CountryCodeOption.push({ value: "+32", label: "+32" });
    CountryCodeOption.push({ value: "+501", label: "+501" });
    CountryCodeOption.push({ value: "+229", label: "+229" });
    CountryCodeOption.push({ value: "+1441", label: "+1441" });
    CountryCodeOption.push({ value: "+975", label: "+975" });
    CountryCodeOption.push({ value: "+591", label: "+591" });
    CountryCodeOption.push({ value: "+387", label: "+387" });
    CountryCodeOption.push({ value: "+267", label: "+267" });
    CountryCodeOption.push({ value: "+55", label: "+55" });
    CountryCodeOption.push({ value: "+673", label: "+673" });
    CountryCodeOption.push({ value: "+359", label: "+359" });
    CountryCodeOption.push({ value: "+226", label: "+226" });
    CountryCodeOption.push({ value: "+257", label: "+257" });
    CountryCodeOption.push({ value: "+855", label: "+855" });
    CountryCodeOption.push({ value: "+237", label: "+237" });
    CountryCodeOption.push({ value: "+238", label: "+238" });
    CountryCodeOption.push({ value: "+1345", label: "+1345" });
    CountryCodeOption.push({ value: "+236", label: "+236" });
    CountryCodeOption.push({ value: "+56", label: "+56" });
    CountryCodeOption.push({ value: "+86", label: "+86" });
    CountryCodeOption.push({ value: "+57", label: "+57" });
    CountryCodeOption.push({ value: "+242", label: "+242" });
    CountryCodeOption.push({ value: "+682", label: "+682" });
    CountryCodeOption.push({ value: "+506", label: "+506" });
    CountryCodeOption.push({ value: "+385", label: "+385" });
    CountryCodeOption.push({ value: "+53", label: "+53" });
    CountryCodeOption.push({ value: "+90392", label: "+90392" });
    CountryCodeOption.push({ value: "+357", label: "+357" });
    CountryCodeOption.push({ value: "+42", label: "+42" });
    CountryCodeOption.push({ value: "+45", label: "+45" });
    CountryCodeOption.push({ value: "+253", label: "+253" });
    CountryCodeOption.push({ value: "+1809", label: "+1809" });
    CountryCodeOption.push({ value: "+593", label: "+593" });
    CountryCodeOption.push({ value: "+20", label: "+20" });
    CountryCodeOption.push({ value: "+503", label: "+503" });
    CountryCodeOption.push({ value: "+240", label: "+240" });
    CountryCodeOption.push({ value: "+291", label: "+291" });
    CountryCodeOption.push({ value: "+372", label: "+372" });
    CountryCodeOption.push({ value: "+251", label: "+251" });
    CountryCodeOption.push({ value: "+500", label: "+500" });
    CountryCodeOption.push({ value: "+298", label: "+298" });
    CountryCodeOption.push({ value: "+679", label: "+679" });
    CountryCodeOption.push({ value: "+358", label: "+358" });
    CountryCodeOption.push({ value: "+33", label: "+33" });
    CountryCodeOption.push({ value: "+594", label: "+594" });
    CountryCodeOption.push({ value: "+689", label: "+689" });
    CountryCodeOption.push({ value: "+241", label: "+241" });
    CountryCodeOption.push({ value: "+220", label: "+220" });
    CountryCodeOption.push({ value: "+7880", label: "+7880" });
    CountryCodeOption.push({ value: "+49", label: "+49" });
    CountryCodeOption.push({ value: "+233", label: "+233" });
    CountryCodeOption.push({ value: "+350", label: "+350" });
    CountryCodeOption.push({ value: "+30", label: "+30" });
    CountryCodeOption.push({ value: "+299", label: "+299" });
    CountryCodeOption.push({ value: "+1473", label: "+1473" });
    CountryCodeOption.push({ value: "+590", label: "+590" });
    CountryCodeOption.push({ value: "+671", label: "+671" });
    CountryCodeOption.push({ value: "+502", label: "+502" });
    CountryCodeOption.push({ value: "+224", label: "+224" });
    CountryCodeOption.push({ value: "+245", label: "+245" });
    CountryCodeOption.push({ value: "+592", label: "+592" });
    CountryCodeOption.push({ value: "+509", label: "+509" });
    CountryCodeOption.push({ value: "+504", label: "+504" });
    CountryCodeOption.push({ value: "+852", label: "+852" });
    CountryCodeOption.push({ value: "+36", label: "+36" });
    CountryCodeOption.push({ value: "+354", label: "+354" });
    CountryCodeOption.push({ value: "+91", label: "+91" });
    CountryCodeOption.push({ value: "+62", label: "+62" });
    CountryCodeOption.push({ value: "+98", label: "+98" });
    CountryCodeOption.push({ value: "+964", label: "+964" });
    CountryCodeOption.push({ value: "+353", label: "+353" });
    CountryCodeOption.push({ value: "+972", label: "+972" });
    CountryCodeOption.push({ value: "+39", label: "+39" });
    CountryCodeOption.push({ value: "+1876", label: "+1876" });
    CountryCodeOption.push({ value: "+81", label: "+81" });
    CountryCodeOption.push({ value: "+962", label: "+962" });
    CountryCodeOption.push({ value: "+254", label: "+254" });
    CountryCodeOption.push({ value: "+686", label: "+686" });
    CountryCodeOption.push({ value: "+850", label: "+850" });
    CountryCodeOption.push({ value: "+82", label: "+82" });
    CountryCodeOption.push({ value: "+965", label: "+965" });
    CountryCodeOption.push({ value: "+996", label: "+996" });
    CountryCodeOption.push({ value: "+856", label: "+856" });
    CountryCodeOption.push({ value: "+371", label: "+371" });
    CountryCodeOption.push({ value: "+961", label: "+961" });
    CountryCodeOption.push({ value: "+266", label: "+266" });
    CountryCodeOption.push({ value: "+231", label: "+231" });
    CountryCodeOption.push({ value: "+218", label: "+218" });
    CountryCodeOption.push({ value: "+417", label: "+417" });
    CountryCodeOption.push({ value: "+370", label: "+370" });
    CountryCodeOption.push({ value: "+352", label: "+352" });
    CountryCodeOption.push({ value: "+853", label: "+853" });
    CountryCodeOption.push({ value: "+389", label: "+389" });
    CountryCodeOption.push({ value: "+261", label: "+261" });
    CountryCodeOption.push({ value: "+265", label: "+265" });
    CountryCodeOption.push({ value: "+60", label: "+60" });
    CountryCodeOption.push({ value: "+960", label: "+960" });
    CountryCodeOption.push({ value: "+223", label: "+223" });
    CountryCodeOption.push({ value: "+356", label: "+356" });
    CountryCodeOption.push({ value: "+692", label: "+692" });
    CountryCodeOption.push({ value: "+596", label: "+596" });
    CountryCodeOption.push({ value: "+222", label: "+222" });
    CountryCodeOption.push({ value: "+269", label: "+269" });
    CountryCodeOption.push({ value: "+52", label: "+52" });
    CountryCodeOption.push({ value: "+691", label: "+691" });
    CountryCodeOption.push({ value: "+373", label: "+373" });
    CountryCodeOption.push({ value: "+377", label: "+377" });
    CountryCodeOption.push({ value: "+976", label: "+976" });
    CountryCodeOption.push({ value: "+1664", label: "+1664" });
    CountryCodeOption.push({ value: "+212", label: "+212" });
    CountryCodeOption.push({ value: "+258", label: "+258" });
    CountryCodeOption.push({ value: "+95", label: "+95" });
    CountryCodeOption.push({ value: "+264", label: "+264" });
    CountryCodeOption.push({ value: "+674", label: "+674" });
    CountryCodeOption.push({ value: "+977", label: "+977" });
    CountryCodeOption.push({ value: "+31", label: "+31" });
    CountryCodeOption.push({ value: "+687", label: "+687" });
    CountryCodeOption.push({ value: "+64", label: "+64" });
    CountryCodeOption.push({ value: "+505", label: "+505" });
    CountryCodeOption.push({ value: "+227", label: "+227" });
    CountryCodeOption.push({ value: "+234", label: "+234" });
    CountryCodeOption.push({ value: "+683", label: "+683" });
    CountryCodeOption.push({ value: "+672", label: "+672" });
    CountryCodeOption.push({ value: "+670", label: "+670" });
    CountryCodeOption.push({ value: "+47", label: "+47" });
    CountryCodeOption.push({ value: "+968", label: "+968" });
    CountryCodeOption.push({ value: "+680", label: "+680" });
    CountryCodeOption.push({ value: "+507", label: "+507" });
    CountryCodeOption.push({ value: "+675", label: "+675" });
    CountryCodeOption.push({ value: "+595", label: "+595" });
    CountryCodeOption.push({ value: "+51", label: "+51" });
    CountryCodeOption.push({ value: "+63", label: "+63" });
    CountryCodeOption.push({ value: "+48", label: "+48" });
    CountryCodeOption.push({ value: "+351", label: "+351" });
    CountryCodeOption.push({ value: "+1787", label: "+1787" });
    CountryCodeOption.push({ value: "+974", label: "+974" });
    CountryCodeOption.push({ value: "+262", label: "+262" });
    CountryCodeOption.push({ value: "+40", label: "+40" });
    CountryCodeOption.push({ value: "+7", label: "+7" });
    CountryCodeOption.push({ value: "+250", label: "+250" });
    CountryCodeOption.push({ value: "+378", label: "+378" });
    CountryCodeOption.push({ value: "+239", label: "+239" });
    CountryCodeOption.push({ value: "+966", label: "+966" });
    CountryCodeOption.push({ value: "+221", label: "+221" });
    CountryCodeOption.push({ value: "+381", label: "+381" });
    CountryCodeOption.push({ value: "+248", label: "+248" });
    CountryCodeOption.push({ value: "+232", label: "+232" });
    CountryCodeOption.push({ value: "+65", label: "+65" });
    CountryCodeOption.push({ value: "+421", label: "+421" });
    CountryCodeOption.push({ value: "+386", label: "+386" });
    CountryCodeOption.push({ value: "+677", label: "+677" });
    CountryCodeOption.push({ value: "+252", label: "+252" });
    CountryCodeOption.push({ value: "+27", label: "+27" });
    CountryCodeOption.push({ value: "+34", label: "+34" });
    CountryCodeOption.push({ value: "+94", label: "+94" });
    CountryCodeOption.push({ value: "+290", label: "+290" });
    CountryCodeOption.push({ value: "+1869", label: "+1869" });
    CountryCodeOption.push({ value: "+1758", label: "+1758" });
    CountryCodeOption.push({ value: "+249", label: "+249" });
    CountryCodeOption.push({ value: "+597", label: "+597" });
    CountryCodeOption.push({ value: "+268", label: "+268" });
    CountryCodeOption.push({ value: "+46", label: "+46" });
    CountryCodeOption.push({ value: "+41", label: "+41" });
    CountryCodeOption.push({ value: "+963", label: "+963" });
    CountryCodeOption.push({ value: "+886", label: "+886" });
    CountryCodeOption.push({ value: "+66", label: "+66" });
    CountryCodeOption.push({ value: "+228", label: "+228" });
    CountryCodeOption.push({ value: "+676", label: "+676" });
    CountryCodeOption.push({ value: "+1868", label: "+1868" });
    CountryCodeOption.push({ value: "+216", label: "+216" });
    CountryCodeOption.push({ value: "+90", label: "+90" });
    CountryCodeOption.push({ value: "+993", label: "+993" });
    CountryCodeOption.push({ value: "+1649", label: "+1649" });
    CountryCodeOption.push({ value: "+688", label: "+688" });
    CountryCodeOption.push({ value: "+256", label: "+256" });
    CountryCodeOption.push({ value: "+44", label: "+44" });
    CountryCodeOption.push({ value: "+380", label: "+380" });
    CountryCodeOption.push({ value: "+971", label: "+971" });
    CountryCodeOption.push({ value: "+598", label: "+598" });
    CountryCodeOption.push({ value: "+678", label: "+678" });
    CountryCodeOption.push({ value: "+379", label: "+379" });
    CountryCodeOption.push({ value: "+58", label: "+58" });
    CountryCodeOption.push({ value: "+84", label: "+84" });
    CountryCodeOption.push({ value: "+681", label: "+681" });
    CountryCodeOption.push({ value: "+969", label: "+969" });
    CountryCodeOption.push({ value: "+967", label: "+967" });
    CountryCodeOption.push({ value: "+260", label: "+260" });
    CountryCodeOption.push({ value: "+263", label: "+263" });
    return CountryCodeOption;
}

export function countryCodeDisplay(countryCode: string): string {
    return countryCode ? "(" + countryCode + ") " : "";
}

export function convertIPAddresstoNumber(ipAddress: any): number {
    return Number(
        ipAddress
            .split(".")
            .map((d: any) => ("000" + d).substr(-3))
            .join("")
    );
}

export function getIndividualOrPartnerClientGuid(taxReturn: ITaxReturn) {
    if (isPartnership(taxReturn)) {
        return taxReturn.partnership.clientGuid;
    }
    if (isMutual(taxReturn)) {
        return taxReturn.taxpayer.clientGuid;
    }
    if (isIndividual(taxReturn)) {
        return taxReturn.taxpayer.clientGuid;
    }
    return "";
}

export function getIndividualOrPartnerClientType(taxReturn: ITaxReturn) {
    if (isPartnership(taxReturn)) {
        return ClientTypes.PartnerShip;
    }
    if (isMutual(taxReturn)) {
        return ClientTypes.Taxpayer;
    }
    if (isIndividual(taxReturn)) {
        return ClientTypes.Taxpayer;
    }
    return ClientTypes.Undefied;
}

export function getDocumentEntityorIndividualName(taxReturn: any) {
    if (isPartnership(taxReturn)) {
        return taxReturn.partnership.name;
    }
    if (isMutual(taxReturn) || isIndividual(taxReturn)) {
        return taxReturn.taxpayer.name;
    }
    return "";
}

export function displayFileName(fileName: string, maxLength: number, stringEnd: number) {
    if (fileName.length > maxLength) {
        let truncatedFileName = fileName.substring(0, stringEnd);
        truncatedFileName = truncatedFileName + "...";
        return truncatedFileName;
    } else {
        return fileName;
    }
}

export function getTaxYearsForSignReportsFilter(numOfYears?: number) {
    if (numOfYears === undefined) {
        numOfYears = 3;
    }
    var years = [];
    let year = new Date().getFullYear();
    for (let i = numOfYears; i > 0; i--) {
        years.push({
            value: year,
            label: year.toString()
        });
        year--;
    }
    return years;
}

export function addSignedToDocName(fileName: string, isSigned: boolean) {
    return (
        (fileName.indexOf(".") > -1 ? fileName.substring(0, fileName.lastIndexOf(".")) : fileName) +
        (isSigned ? " Signed" : "") +
        ".pdf"
    );
}

export function getZipFileNameForMultipleDload() {
    let date = moment(new Date()).format("MM_DD_YYYY");
    return "Signed_" + date + ".zip";
}

export function getZipFileNameForSingleDload(subject: string, isSigned: boolean) {
    return subject + (isSigned ? " Signed" : "") + ".zip";
}

export function getTaxSoftwares() {
    let taxSoftwareTypes = [];
    for (let taxSoftware in TaxSoftwareType) {
        if (taxSoftware != TaxSoftwareType.None) {
            taxSoftwareTypes.push({
                value: taxSoftware.toString(),
                label: TaxSoftwareType[taxSoftware.toString() as keyof typeof TaxSoftwareType]
            });
        }
    }
    return taxSoftwareTypes;
}

export function getOrganizerTaxSoftwares() {
    let taxSoftwareTypes = [];
    for (let taxSoftware in TaxSoftwareType) {
        if (
            taxSoftware != TaxSoftwareType.None &&
            taxSoftware != TaxSoftwareType.UltraTaxM18 &&
            taxSoftware != TaxSoftwareType.Drake
        ) {
            taxSoftwareTypes.push({
                value: taxSoftware.toString(),
                label: TaxSoftwareType[taxSoftware.toString() as keyof typeof TaxSoftwareType]
            });
        }
    }
    return taxSoftwareTypes;
}

export function getOrganizerEngTypes() {
    let organizerEngType = [];
    organizerEngType.push({ value: "E1040", label: "1040" });
    return organizerEngType;
}

export function getProductTypes() {
    let productTypes = [];
    for (let type in ProductTypes) {
        if (isNaN(Number(type))) {
            productTypes.push({
                value: type.toString(),
                label: type.toString()
            });
        }
    }
    return productTypes;
}

export function getTextLengthInByte(text: any) {
    var textLength = text.length;
    for (var i = text.length - 1; i >= 0; i--) {
        var code = text.charCodeAt(i);
        if (code > 0x7f && code <= 0x7ff) {
            textLength++;
        } else if (code > 0x7ff && code <= 0xffff) {
            textLength += 2;
        }
        if (code >= 0xdc00 && code <= 0xdfff) {
            i--;
        }
    }
    return textLength;
}

export function fetchHelpText(helptexts: IHelptextModel[] = [], helptextKey: string) {
    if (helptexts && helptexts.length > 0) {
        var result = helptexts.filter((t: IHelptextModel) => t.helptextKey.toLowerCase() === helptextKey.toLowerCase());
        if (result && result.length > 0) {
            return result[0].helptextValue;
        }
    }
    return "";
}

export function validatePaperFile(paperFiles: IPaperFileData[], taxingAuthority: ITaxingAuthority[]): boolean {
    let isValid = true;
    paperFiles.map((item, index) => {
        if (!NullandEmptyCheck(item.formName)) {
            VenusNotifier.Warning(Constants.PaperFile.formNameRequired, null);
            isValid = false;
            return isValid;
        }
        if (item.authorityId === 0 || item.authorityId === null) {
            VenusNotifier.Warning(Constants.PaperFile.authorityRequired, null);
            isValid = false;
            return isValid;
        }
        if (!NullandEmptyCheck(item.mailingAddress)) {
            const authority = taxingAuthority.find((x) => x.Id == item.authorityId);
            const authorityName = authority == undefined || authority == null ? "" : authority.AuthorityName;
            let warningMessage = Constants.PaperFile.mailingAddressRequired;
            warningMessage = warningMessage.replace("<authorityName>", authorityName).replace("<formName>", item.formName);
            VenusNotifier.Warning(warningMessage, null);
            isValid = false;
            return isValid;
        }
    });
    return isValid;
}

export function validateK1(taxReturn: ITaxReturn): boolean {
    let isValid = true;
    taxReturn.formGroups
        .filter((x) => x.group == DocumentGroups.K1)
        .map((x: any) => {
            if (x.shareHolder.entityType === 0 || x.shareHolder.entityType === null || x.shareHolder.entityType === "") {
                VenusNotifier.Warning(Constants.K1TabConstants.partnerTypeWarning, null);
                isValid = false;
                return isValid;
            }
            if (!isValidSSN(x.shareHolder.ssn)) {
                VenusNotifier.Warning(Constants.K1TabConstants.ssnWarning, null);
                isValid = false;
                return isValid;
            }
        });
    return isValid;
}

export function getProductTypeName(productType: ProductType) {
    switch (productType) {
        case ProductType.Organizers:
            return "Organizers ";
        case ProductType.Extensions:
            return "Extensions ";
        case ProductType.Signature:
            return "Signatures ";
        case ProductType.Exchange:
            return "Exchange ";
        case ProductType.SSR:
        default:
            return "";
    }
}

export function getVoucherStatus(oldVoucher: IVoucher, updatedVoucher: IVoucher) {
    if (oldVoucher.voucherStatus == VoucherStatus.New || oldVoucher.voucherStatus == VoucherStatus.ExistingUpdated) {
        return oldVoucher.voucherStatus;
    }
}

export function getPreviousMonthDate() {
    var today = new Date();
    return new Date(today.getFullYear(), today.getMonth() - 1, today.getDate());
}

export function updateDateRange(selectedValue: DateRangeOptions) {
    let today = new Date();
    let fromDate = new Date();
    let toDate = new Date();
    switch (selectedValue) {
        case DateRangeOptions.Last7Days:
            fromDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 7);
            toDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 1);
            break;
        case DateRangeOptions.LastMonth:
            fromDate =
                today.getMonth() === 1
                    ? new Date(today.getFullYear() - 1, 12, 1)
                    : new Date(today.getFullYear(), today.getMonth() - 1, 1);
            let tempDate = new Date(); // current date
            tempDate.setDate(1); // going to 1st of the month
            tempDate.setHours(-1);
            toDate =
                today.getMonth() === 0
                    ? new Date(today.getFullYear() - 1, 11, tempDate.getDate())
                    : new Date(today.getFullYear(), tempDate.getMonth(), tempDate.getDate());
            break;
        case DateRangeOptions.LastYear:
            fromDate = new Date(today.getFullYear() - 1, 0, 1);
            toDate = new Date(today.getFullYear() - 1, 11, 31);
            break;
        case DateRangeOptions.MonthToDate:
            fromDate = new Date(today.getFullYear(), today.getMonth(), 1);
            toDate = new Date();
            break;
        case DateRangeOptions.YearToDate:
            fromDate = new Date(today.getFullYear(), 0, 1);
            toDate = new Date();
            break;
        case DateRangeOptions.Custom:
            fromDate = getPreviousMonthDate();
            toDate = new Date();
    }
    return { fromDate: fromDate, toDate: toDate };
}

export function getPdfFilePageCount(pdfFile: File): Promise<number> {
    var fileReader = new FileReader();
    fileReader.readAsArrayBuffer(pdfFile as File);
    return new Promise((resolve) => {
        fileReader.onload = function () {
            var typedarray = new Uint8Array(fileReader.result as ArrayBuffer);

            const loadingTask = PDFJS.getDocument(typedarray);
            loadingTask
                .then((pdf) => {
                    var numPages = pdf.numPages;
                    console.log("# Document Loaded");
                    console.log("Number of Pages: " + numPages);
                    return resolve(numPages);
                })
                .then(null, function (error) {
                    return resolve(0);
                });
        };
    });
}

export const copyToClipboard = (variableItem: string) => {
    if (navigator && navigator.userAgent.indexOf("Firefox") != -1) {
        navigator.clipboard.writeText("<" + variableItem + ">");
    } else {
        copy("<" + variableItem + ">");
    }
    return;
};

export const checkIfValueExists = (value: any): boolean => {
    if (typeof value == "string") {
        if (value && value != null && value != "") {
            return true;
        }
    } else if (typeof value == "number") {
        if (value && value != 0 && value != null) {
            return true;
        }
    }
    return false;
};

export const getPartnerUserName = (
    partnerId: string,
    partnerUsersList: IParterUserModel[],
    reportType: string
): string => {
    if (reportType !== "Delivered" && reportType !== "Archived") {
        return partnerId;
    }   
    const user = partnerUsersList && partnerUsersList.find((user) => user.id === Number(partnerId));
    return user ? `${user.firstName} ${user.lastName}` : partnerId;
};  

export const getSentByUserName = (
    SentById: string,
    allssrUsers: ISSRUserModel[],
    reportType: string
): string => {
    if (reportType !== "Delivered" && reportType !== "Archived") {
        return SentById;
    }   
    const user = allssrUsers && allssrUsers.find((user) => user.id === Number(SentById));
    return user ? `${user.firstName} ${user.lastName}` : SentById;
}; 

export function getcookie(cookiename: string) {
    var cookiestring = document.cookie;
    var cookiearray = cookiestring.split(";");
    for (var i = 0; i < cookiearray.length; ++i) {
        if (cookiearray[i].trim().match("^" + cookiename + "=")) {
            return cookiearray[i].replace(`${cookiename}=`, "").trim();
        }
    }
    return null;
}

export function resetCookie(cookiename: string, value: string) {
    var cookiestring = document.cookie;
    var cookiearray = cookiestring.split(";");
    for (var i = 0; i < cookiearray.length; ++i) {
        if (cookiearray[i].trim().match("^" + cookiename + "=")) {
            if (cookiearray[i]) {
                cookiearray[i] = `${cookiename}=${value}`.trim();
            }
        }
    }
}

export const limitMaxCharacters = (fieldName: string, fieldValue: string, maxChar: number, callback: (prop: string) => void) => {
    if (fieldValue.length > maxChar) {
        callback(`${fieldName} must not exceed ${maxChar} characters`);
        fieldValue = fieldValue.substring(0, maxChar);
    }
    return fieldValue;
};

export function validateError(error: any) {
    if (typeof error === "string") return error;
    if (typeof error === "undefined") return "";
    if (error.message !== undefined) return error.message;
    if (error.statusText !== undefined) return error.statusText;
    return "";
}

export function getClientInfoByClientId(
    clientId: string,
    uid: string = "",
    callback: (clientInfoViewModel: IClientInfoViewModel) => void
) {
    const fetchTask = fetch(API_BASE_URL + `/api/ClientManagement/GetClientInfoByClientIdAsync?clientId=${encodeURIComponent(clientId)}&uid=${uid}`, {
        method: "GET",
        credentials: "include",
        headers: {
            Accept: "application/json, */*",
            "Content-Type": "application/json",
            RequestVerificationToken: (document.getElementById("RequestVerificationToken") as HTMLInputElement).value
        }
    })
        .then(handleResponse)
        .then((response: any) => {
            callback && callback(response);
        })
        .catch((error: any) => {
            console.log(error);
        });
    addTask(fetchTask);
}

export const taxReturnType = (taxReturn: ITaxReturn) => {
    if (isIndividual(taxReturn)) {
        return TaxreturnType.IndividualTaxReturn;
    } else if (isMutual(taxReturn)) {
        return TaxreturnType.MarriedJointTaxReturn;
    } else if (isPartnership(taxReturn)) {
        return TaxreturnType.CorporateTaxReturn;
    }
    return TaxreturnType.None;
};

export const encodeTaxDocumentHtmlTags = (taxReturn: ITaxReturn) => {
    if (taxReturn) {
        encodeDocumentSettingsHtmlTags(taxReturn.documentSettings);
        if (taxReturn.attachments) {
            taxReturn.attachments.forEach((attachment, i) => {
                attachment.instruction = htmlencoder.htmlEncode(attachment.instruction);
            });
        }
    }
};

export const decodeTaxDocumentHtmlTags = (taxReturn: ITaxReturn) => {
    if (taxReturn) {
        decodeDocumentSettingsHtmlTags(taxReturn.documentSettings);
        if (taxReturn.attachments) {
            taxReturn.attachments.forEach((attachment, i) => {
                attachment.instruction = htmlencoder.htmlDecode(attachment.instruction);
            });
        }
    }
};

export const encodeDocumentSettingsHtmlTags = (documentSettings: IDocumentSettings) => {
    if (documentSettings) {
        if (
            documentSettings?.deliverySettings?.preparerMessage?.body != null &&
            documentSettings?.deliverySettings?.preparerMessage?.body != undefined
        ) {
            documentSettings.deliverySettings.preparerMessage.body = htmlencoder.htmlEncode(
                documentSettings.deliverySettings.preparerMessage.body
            );
        }

        if (
            documentSettings?.deliverySettings?.preparerMessage?.name != null &&
            documentSettings?.deliverySettings?.preparerMessage?.name != undefined
        ) {
            documentSettings.deliverySettings.preparerMessage.name = htmlencoder.htmlEncode(
                documentSettings.deliverySettings.preparerMessage.name
            );
        }

        if (
            documentSettings?.deliverySettings?.paperFilePreparerMessage?.body != null &&
            documentSettings?.deliverySettings?.paperFilePreparerMessage?.body != undefined
        ) {
            documentSettings.deliverySettings.paperFilePreparerMessage.body = htmlencoder.htmlEncode(
                documentSettings.deliverySettings.paperFilePreparerMessage.body
            );
        }

        if (
            documentSettings?.deliverySettings?.paperFilePreparerMessage?.name != null &&
            documentSettings?.deliverySettings?.paperFilePreparerMessage?.name != undefined
        ) {
            documentSettings.deliverySettings.paperFilePreparerMessage.name = htmlencoder.htmlEncode(
                documentSettings.deliverySettings.paperFilePreparerMessage.name
            );
        }
    }
};

export const decodeDocumentSettingsHtmlTags = (documentSettings: IDocumentSettings) => {
    if (documentSettings) {
        if (
            documentSettings?.deliverySettings?.preparerMessage?.body != null &&
            documentSettings?.deliverySettings?.preparerMessage?.body != undefined
        ) {
            documentSettings.deliverySettings.preparerMessage.body = htmlencoder.htmlDecode(
                documentSettings.deliverySettings.preparerMessage.body
            );
        }

        if (
            documentSettings?.deliverySettings?.preparerMessage?.name != null &&
            documentSettings?.deliverySettings?.preparerMessage?.name != undefined
        ) {
            documentSettings.deliverySettings.preparerMessage.name = htmlencoder.htmlDecode(
                documentSettings.deliverySettings.preparerMessage.name
            );
        }

        if (
            documentSettings?.deliverySettings?.paperFilePreparerMessage?.body != null &&
            documentSettings?.deliverySettings?.paperFilePreparerMessage?.body != undefined
        ) {
            documentSettings.deliverySettings.paperFilePreparerMessage.body = htmlencoder.htmlDecode(
                documentSettings.deliverySettings.paperFilePreparerMessage.body
            );
        }

        if (
            documentSettings?.deliverySettings?.paperFilePreparerMessage?.name != null &&
            documentSettings?.deliverySettings?.paperFilePreparerMessage?.name != undefined
        ) {
            documentSettings.deliverySettings.paperFilePreparerMessage.name = htmlencoder.htmlDecode(
                documentSettings.deliverySettings.paperFilePreparerMessage.name
            );
        }
    }
};

export const encodeGroupInfoDataHtmlTags = (groupInfo: IGroupInfo) => {
    if (groupInfo && groupInfo.groupSettings) {
        const groupDeliverySettings = groupInfo.groupSettings?.groupDeliverySettings;
        if (groupDeliverySettings?.preparerMessage?.body != null && groupDeliverySettings?.preparerMessage?.body != undefined) {
            groupDeliverySettings.preparerMessage.body = htmlencoder.htmlEncode(groupDeliverySettings.preparerMessage.body);
        }

        if (groupDeliverySettings?.preparerMessage?.name != null && groupDeliverySettings?.preparerMessage?.name != undefined) {
            groupDeliverySettings.preparerMessage.name = htmlencoder.htmlEncode(groupDeliverySettings.preparerMessage.name);
        }

        if (
            groupDeliverySettings?.paperFilePreparerMessage?.body != null &&
            groupDeliverySettings?.paperFilePreparerMessage?.body != undefined
        ) {
            groupDeliverySettings.paperFilePreparerMessage.body = htmlencoder.htmlEncode(
                groupDeliverySettings.paperFilePreparerMessage.body
            );
        }

        if (
            groupDeliverySettings?.paperFilePreparerMessage?.name != null &&
            groupDeliverySettings?.paperFilePreparerMessage?.name != undefined
        ) {
            groupDeliverySettings.paperFilePreparerMessage.name = htmlencoder.htmlEncode(
                groupDeliverySettings.paperFilePreparerMessage.name
            );
        }
    }
};

export const decodeGroupInfoDataHtmlTags = (groupInfo: IGroupInfo) => {
    if (groupInfo && groupInfo.groupSettings) {
        const groupDeliverySettings = groupInfo.groupSettings?.groupDeliverySettings;
        if (groupDeliverySettings?.preparerMessage?.body != null && groupDeliverySettings?.preparerMessage?.body != undefined) {
            groupDeliverySettings.preparerMessage.body = htmlencoder.htmlDecode(groupDeliverySettings.preparerMessage.body);
        }

        if (groupDeliverySettings?.preparerMessage?.name != null && groupDeliverySettings?.preparerMessage?.name != undefined) {
            groupDeliverySettings.preparerMessage.name = htmlencoder.htmlDecode(groupDeliverySettings.preparerMessage.name);
        }

        if (
            groupDeliverySettings?.paperFilePreparerMessage?.body != null &&
            groupDeliverySettings?.paperFilePreparerMessage?.body != undefined
        ) {
            groupDeliverySettings.paperFilePreparerMessage.body = htmlencoder.htmlDecode(
                groupDeliverySettings.paperFilePreparerMessage.body
            );
        }

        if (
            groupDeliverySettings?.paperFilePreparerMessage?.name != null &&
            groupDeliverySettings?.paperFilePreparerMessage?.name != undefined
        ) {
            groupDeliverySettings.paperFilePreparerMessage.name = htmlencoder.htmlDecode(
                groupDeliverySettings.paperFilePreparerMessage.name
            );
        }
    }
};

export const encodeGroupReturnDataHtmlTags = (groupedReturns: IGroupedReturnsModel) => {
    if (groupedReturns && groupedReturns.groupInfo) {
        encodeGroupInfoDataHtmlTags(groupedReturns.groupInfo);
    }

    if (groupedReturns.taxReturns) {
        groupedReturns.taxReturns.map((item) => {
            encodeTaxDocumentHtmlTags(item as ITaxReturn);
        });
    }
};

export const decodeGroupReturnDataHtmlTags = (groupedReturns: IGroupedReturnsModel) => {
    if (groupedReturns && groupedReturns.groupInfo) {
        decodeGroupInfoDataHtmlTags(groupedReturns.groupInfo);
    }
    if (groupedReturns.taxReturns) {
        groupedReturns.taxReturns.map((item) => {
            decodeTaxDocumentHtmlTags(item as ITaxReturn);
        });
    }
};

export const verifyIfShareHolderInfoUpdated = (shareHolders: IShareHolder[], taxReturn: ITaxReturn): boolean => {
    let updatedShareHolder = taxReturn.formGroups
        .filter((x) => x.group == DocumentGroups.K1)
        .map((i: any) => {
            return i?.shareHolder;
        });
    return !arrayEquals(shareHolders, updatedShareHolder);
};

export const arrayEquals = (a, b) => {
    return Array.isArray(a) && Array.isArray(b) && a.length === b.length && a.every((val, index) => val === b[index]);
};

export const getUpdatedShareHolders = (shareHolders: IShareHolder[], taxReturn: ITaxReturn): IShareHolder[] => {
    let updatedShareHolder = taxReturn.formGroups
        .filter((x) => x.group == DocumentGroups.K1)
        .map((i: any) => {
            return i?.shareHolder;
        });
    return getUpdatedValues(shareHolders, updatedShareHolder);
};

export const getUpdatedValues = (oldArray: IShareHolder[], newArray: IShareHolder[]) => {
    const updatedValues: IShareHolder[] = [];

    newArray.forEach((newObj) => {
        const oldObj = oldArray.find((obj) => obj.id === newObj.id);

        if (oldObj && !isEqual(oldObj, newObj)) {
            updatedValues.push(newObj);
        }
    });

    return updatedValues;
};

export const isEqual = (obj1: IShareHolder, obj2: IShareHolder) => {
    return obj1.ssn === obj2.ssn && obj1.entityType === obj2.entityType;
};

export const extractClientId = (fileName: string, fileExtension?: string): string => {
    let regexPattern = /^\d{4}US [I|P|C|S|F|Y|K|X](.*) Clnt .*$/i;
    let tempFileName = fileExtension != undefined && fileExtension != null ? fileName.replace("." + fileExtension, "") : fileName;
    if (regexPattern.test(tempFileName)) {
        let matchName = tempFileName.match(regexPattern);
        if (matchName && matchName[1]) {
            return matchName[1];
        }
    }
    return tempFileName;
};

export const createForethoughtCookie = (key: string, value: string, expiryMiliSeconds?: number) => {
    const domain = getDomain(window.location.href);
    createCookie(key, value, expiryMiliSeconds ?? 24 * 60 * 60 * 1000, `.${domain}`);
};

export const getGroupName = (filterGroupType: number, groupInfoList: IGroupInfo[]): string => {
    if (filterGroupType == -1) {
        return "";
    } else if (filterGroupType == -2) {
        return "Standard Returns";
    } else if (filterGroupType == -3) {
        return "Grouped Returns";
    } else {
        return groupInfoList.find((info) => info.id == filterGroupType)?.name || "";
    }
};

export const isUserHasAccessToLocation = (locationId: number, loggedInUserLocations: number[]) => {
    const index = loggedInUserLocations.findIndex((x) => x == locationId);
    return index != NO_INDEX;
};

export const isInValidSignatureStatus = (signatureStatus) => {
    return (
        signatureStatus === SignatureStatus[SignatureStatus.DeliveredToTaxCaddy] ||
        signatureStatus === SignatureStatus[SignatureStatus.Uploaded] ||
        signatureStatus === SignatureStatus[SignatureStatus.SignedAndESigned] ||
        signatureStatus === SignatureStatus[SignatureStatus.AlternateDelivery] ||
        signatureStatus === SignatureStatus[SignatureStatus.AlternateAndUploaded] ||
        signatureStatus === SignatureStatus[SignatureStatus.ManuallySigned] ||
        signatureStatus === SignatureStatus[SignatureStatus.ESigned] ||
        signatureStatus === SignatureStatus[SignatureStatus.SignatureStampingFailed] ||
        signatureStatus === SignatureStatus[SignatureStatus.AwaitingDraftReview] ||
        signatureStatus === SignatureStatus[SignatureStatus.DraftApproved] ||
        signatureStatus === SignatureStatus[SignatureStatus.DraftRejected] ||
        signatureStatus === SignatureStatus[SignatureStatus.ESignedInOffice] ||
        signatureStatus === SignatureStatus[SignatureStatus.FinalReturnCopyCreated]
    );
};

export const isInValidSignaturStatusForReminder = (signatureStatus) => {
    return (
        signatureStatus === SignatureStatus[SignatureStatus.DeliveredToTaxCaddy] ||
        signatureStatus === SignatureStatus[SignatureStatus.SignatureStampingFailed] ||
        signatureStatus === SignatureStatus[SignatureStatus.AwaitingDraftReview] ||
        signatureStatus === SignatureStatus[SignatureStatus.DraftApproved] ||
        signatureStatus === SignatureStatus[SignatureStatus.DraftRejected] ||
        signatureStatus === SignatureStatus[SignatureStatus.FinalReturnCopyCreated]
    );
};

export const separatePositiveAndNegativeValues = (data: string) => {
    // expected data format --> "-1, 2" or "-1"
    const values = data.split(",").map((value) => parseInt(value.trim()));
    let positiveValues: number = 0;
    let negativeValues: number = -1;
    if (values.length === 1) {
        negativeValues = values[0];
    } else if (values.length === 2) {
        if (values[0] < 0) {
            negativeValues = values[0];
            positiveValues = values[1];
        } else {
            negativeValues = values[1];
            positiveValues = values[0];
        }
    }
    return { positiveValues, negativeValues };
};

export const separateDraftStatusValues = (data: string) => {
    const values = data.split(",").map((value) => parseInt(value.trim()));
    let positiveValues: number[] = [];
    let negativeValue: number = StatusRadioButtonValues.All;

    values.forEach((value) => {
        if (value < 0) {
            negativeValue = value;
        } else if (value > 0) {
            positiveValues.push(value);
        }
    });

    const positiveValuesString = positiveValues.join(",");

    return { positiveValues: positiveValuesString, negativeValue };
};
