import { FC, useEffect, useState } from "react";
import React from "react";
import { CheckBoxComponent } from "../CheckBoxComponent";
import { IDeliveredTaxReturns } from "../DeliveredTaxReturns";
import { SignatureStatus } from "../TaxReturn";

interface ISendGroupReminderNowProps {
    signingReminder: boolean;
    setSigningReminder: React.Dispatch<React.SetStateAction<boolean>>;
    model: IDeliveredTaxReturns;
    isAutomatedSigningReminderEnabled: boolean;
}

const SendGroupReminderNow: FC<ISendGroupReminderNowProps> = ({
    signingReminder,
    setSigningReminder,
    model,
    isAutomatedSigningReminderEnabled
}) => {
    const [signingReminderDisabled, setSigningReminderDisabled] = useState<boolean>(false);

    useEffect(() => {
        let taxReturn = model;
        setSigningReminderDisabled(
            taxReturn.signatureStatus?.toString() === SignatureStatus[SignatureStatus.Uploaded] ||
                taxReturn.signatureStatus?.toString() === SignatureStatus[SignatureStatus.ESigned] ||
                taxReturn.signatureStatus?.toString() === SignatureStatus[SignatureStatus.ManuallySigned] ||
                taxReturn.signatureStatus?.toString() === SignatureStatus[SignatureStatus.DeliveredToTaxCaddy] ||
                taxReturn.signatureStatus?.toString() === SignatureStatus[SignatureStatus.AlternateDelivery] ||
                taxReturn.signatureStatus?.toString() === SignatureStatus[SignatureStatus.AlternateAndUploaded] ||
                taxReturn.signatureStatus?.toString() === SignatureStatus[SignatureStatus.SignatureStampingFailed] ||
                taxReturn.signatureStatus?.toString() === SignatureStatus[SignatureStatus.SignedAndESigned] ||
                taxReturn.signatureStatus?.toString() === SignatureStatus[SignatureStatus.ESignedInOffice]
        );
    }, []);

    return (
        <div className="py-2">
            <div className="mt-2 px-3">
                <div>
                    <CheckBoxComponent
                        id="signing_reminder"
                        text="Send Signing Reminder"
                        checked={signingReminder}
                        onChange={() => setSigningReminder((prev) => !prev)}
                        disabled={signingReminderDisabled}
                    />
                </div>
                {isAutomatedSigningReminderEnabled && !signingReminderDisabled && (
                    <div className="text-danger mt-2" style={{ paddingLeft: "5px" }}>
                        Automatic reminders are enabled for the selected Group!
                    </div>
                )}
            </div>
        </div>
    );
};

export default SendGroupReminderNow;
