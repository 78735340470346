import { addTask } from 'domain-task';
import { Action, Reducer } from 'redux';
import { AppThunkAction } from '../';
import { actionTypes } from '../../types/ActionTypes';
import { NotificationAction } from '../common/NotificationStore';
import { ITaxReturn } from '../../components/common/TaxReturn';
import { handleResponse } from '../Library';
import { API_BASE_URL } from '../../utils/constants';
import * as CRSHelper from '../../components/helper/CRSHelper';
import { encodeTaxDocumentHtmlTags } from '../../../src/components/helper/HelperFunctions';

interface SetVoucherDueDateReminderAction {
    type: actionTypes.SET_VOUCHER_DUE_DATE_REMINDER;
}

export interface ErrorSetReminderAction {
    type: actionTypes.ERROR_SET_REMINDER;
    message: string;
}

export interface IReminderData {
    id: number,
    isLoading: boolean,
    error: boolean,
    message: string
}

type KnownAction = SetVoucherDueDateReminderAction | ErrorSetReminderAction | NotificationAction;

export const actionCreators = {
    setVoucherDueDateReminder: (taxReturn: ITaxReturn, callback?: (reminderData: IReminderData) => void, resourceId:string =""): AppThunkAction<KnownAction> => (dispatch, getState) => {
        
        encodeTaxDocumentHtmlTags(taxReturn);

        const formData = new FormData();
        let taxReturnData = JSON.stringify(taxReturn);
        formData.append('taxDocument', taxReturnData);
        let options: any = {
            method: 'POST',
            credentials: 'include',
            headers: {
                'X-Resource-Id':resourceId,
                'Accept': 'application/json',
                'RequestVerificationToken': (document.getElementById('RequestVerificationToken') as HTMLInputElement).value,
                'X-Resource-Id': resourceId 
            },
            body: formData
        };
        const fetchTask = fetch(API_BASE_URL + 'api/Reminder/SetVoucherDueDateReminder', options)
            .then(handleResponse)
            .then(() => {
                let reminderData: IReminderData = { id: taxReturn.id, isLoading: false, error: false, message: '' };
                callback && callback(reminderData);
            })
            .catch(error => {
                let reminderData: IReminderData = { id: taxReturn.id, isLoading: false, error: false, message: error };
                callback && callback(reminderData);
            });
        addTask(fetchTask);

    } 
};

const unloadedState: IReminderData = {} as IReminderData;

export const reducer: Reducer<IReminderData> = (state = unloadedState, incomingAction) => {

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case actionTypes.SET_VOUCHER_DUE_DATE_REMINDER:
            let reminderState: IReminderData = { ...state };
            reminderState.isLoading = false;
            reminderState.error = false;
            reminderState.message = '';
            break;
        case actionTypes.ERROR_SET_REMINDER:
            let errorState: IReminderData = { ...state };
            errorState.isLoading = false;
            errorState.error = true;
            errorState.message = action.message;
            break;
        default:
            break;
    }
    return state;
};

