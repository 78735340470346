export const EngagementList: any = {
    1: '1040',
    2: '1065',
    3: '1120',
    4: '1120S',
    5: '1041',
    6: '1040NR',
    7: "990/T",
    8: "990PF",
    9: "990EZ"
}

export enum ArrangementList {
    FullReturn = -2,
    All = -1,
    StateOnly = 1,
}

// These enum values are only for rendering in react-select dropdown. Pls refer ArrangementList for actual values.
export enum TypeDropdownValues {
    All = -2,
    StateOnly = -3,
    FullReturn = -4,
}

export const RenderOtherOptions = [
    { value: -4, label: 'Full Return' },
    { value: -3, label: 'State Only' },
    { value: -2, label: 'All' },
];

export const RenderEngagementOptions = [
    { value: 1, label: '1040' },
    { value: 2, label: '1065' },
    { value: 3, label: '1120' },
    { value: 4, label: '1120S' },
    { value: 5, label: '1041' },
    { value: 6, label: '1040NR' },
    { value: 7, label: '990/T' },
    { value: 8, label: '990PF' },
    { value: 9, label: '990EZ' },
];

export const RenderK1EngagementOptions = RenderEngagementOptions.filter(option => [2, 4, 5].includes(option.value));

export const RenderTypeFilterOptions = [...RenderOtherOptions, ...RenderEngagementOptions].sort((a, b) => a.value - b.value);

export const RenderK1TypeFilterOptions = [...RenderOtherOptions, ...RenderK1EngagementOptions].sort((a, b) => a.value - b.value);

export const RenderRadioButtons = [
    { value: -3, label: 'All' },
    { value: -2, label: 'Draft Return' },
    { value: -1, label: 'Final Return' },
];

export const RenderDraftReturnOptions = [
    { label: "Uploaded", value: 1 },
    { label: "Error", value: 2 },
    { label: "Ready", value: 3 },
    { label: "Processing", value: 4 },
    { label: "Review", value: 5 },
    { label: "Approved for Delivery", value: 8 },
    { label: "Preparing for Delivery", value: 9 },
    { label: "Delivery Failed", value: 10 },
    { label: "Duplicate", value: 17 },
    { label: "Ready for Delivery", value: 22 }
];

export const RenderDraftReturnOptionsForInUseReturns = [
    { label: "Uploaded", value: 1 },
    { label: "Error", value: 2 },
    { label: "Processing", value: 4 },
    { label: "Review", value: 5 },
    { label: "Approved for Delivery", value: 8 },
    { label: "Preparing for Delivery", value: 9 },
    { label: "Delivery Failed", value: 10 },
];

const RenderStatusOtherOptions = [
    { label: "Non Supported", value: 11 },
    { label: "Recalled", value: 12 },
    { label: "Reprocessing", value: 14 },
    { label: "Taxcaddy Delivery Failed", value: 16 },
    { label: "Downloading Failed", value: 19 },
    { label: "Preparing for PDF Download", value: 20 },
    { label: "To be Reprocessed", value: 21 },
    { label: "Draft Approved - Ready to Finalize", value: 25 }
];

const RenderStatusOtherOptionsForInUseReturns = [
    { label: "Non Supported", value: 11 },
    { label: "Recalled", value: 12 },
];

export const RenderAllOptions = [...RenderRadioButtons, ...RenderDraftReturnOptions, ...RenderStatusOtherOptions].sort((a, b) => a.value - b.value);
export const RenderStatusOptionsForInUseReturns = [...RenderRadioButtons, ...RenderDraftReturnOptionsForInUseReturns, ...RenderStatusOtherOptionsForInUseReturns].sort((a, b) => a.value - b.value);

export const RenderDraftFilterOptions = [...RenderRadioButtons, ...RenderDraftReturnOptions].sort((a, b) => a.value - b.value);
export const RenderDraftFilterOptionsForInUseReturns = [...RenderRadioButtons, ...RenderDraftReturnOptionsForInUseReturns].sort((a, b) => a.value - b.value);

export enum DraftStatus {
    None = 0,
    Draft = 1,
    DraftFinalized = 2,
}

// These enum values are only for rendering in react-select dropdown. Pls refer DraftStatus for actual values.
export enum StatusRadioButtonValues {
    All = -3,
    Draft = -2,
    Final = -1
}

// These enum values are only for rendering in react-select dropdown. Pls refer DraftStatus for actual values.
export enum DraftFilter {
    All = 0,
    DraftReturn = 1,
    FinalReturn = 2,
}

export const SignatureStatusList: any = {
    1: 'E-SIGNED',
    2: 'MANUALLY SIGNED',
    3: 'UPLOADED',
    4: 'AWAITING E-SIGN',
    5: 'AWAITING UPLOAD',
    8: 'SIGNED AND E-SIGNED',
    10:'DELIVERED TO TAXCADDY',
    16:'TAXCADDY DELIVERY FAILED',
    17: 'ALTERNATE DELIVERY',
    19:'ALTERNATE AND UPLOADED',
    20: 'PARTIALLY SIGNED',
    21:'SIGNATURE STAMPING FAILED',
    22: 'E-SIGNED – IN OFFICE',
    23: 'AWAITING DRAFT REVIEW',
    24: 'DRAFT APPROVED',
    25: 'DRAFT NOT APPROVED',
    26: 'FINAL RETURN COPY CREATED'
}

export const SignatureStatusOptionsList = [
    { label: 'E-SIGNED', value: 1 },
    { label: 'MANUALLY SIGNED', value: 2 },
    { label: 'UPLOADED', value: 3 },
    { label: 'AWAITING E-SIGN', value: 4 },
    { label: 'AWAITING UPLOAD', value: 5 },
    { label: 'SIGNED AND E-SIGNED', value: 8 },
    { label: "DELIVERED TO TAXCADDY", value: 10 },
    { label: "TAXCADDY DELIVERY FAILED", value: 16 },
    { label: 'ALTERNATE DELIVERY', value: 17 },
    { label: "ALTERNATE AND UPLOADED", value: 19 },
    { label: 'PARTIALLY SIGNED', value: 20 },
    { label: "SIGNATURE STAMPING FAILED", value: 21 },
    { label: 'E-SIGNED – IN OFFICE', value: 22 },
    { label: "AWAITING DRAFT REVIEW", value: 23 },
    { label: "DRAFT APPROVED", value: 24 },
    { label: "DRAFT NOT APPROVED", value: 25},
    { label: "FINAL RETURN COPY CREATED", value: 26 },
];


export enum DateRangeOptions {
    None = 0,
    Last7Days = 1,
    LastMonth = 2,
    LastYear = 3,
    MonthToDate = 4,
    YearToDate = 5,
    Custom = 6
}

export interface SelectOptions {
    value: number,
    label: string,
}