import * as React from 'react';
import { IK1DeliveredStatusState, IK1DeliveredTaxDocument } from './../../store/reports/K1DeliveredStatusReport/K1DeliveredStatusState'
import * as K1DeliveredStatusStore from './../../store/reports/K1DeliveredStatusReport/K1DeliveredStatusStore'
import * as FilterStore from '../../store/reports/FilterStore';
import { RouteComponentProps } from 'react-router';
import K1DeliveredStatusTable from './K1DeliveredStatus/K1DeliveredStatusTable'
import K1DeliveredStatusHeader from './K1DeliveredStatus/K1DeliveredStatusHeader'
import { IFilters, SortDirections, ReportFilterType } from './Filters';
import { VenusNotifier } from '../helper/VenusNotifier';
import { EngagementType, ClientTypes, IOfficeLocation } from '../common/TaxReturn';
import * as Helper from '../helper/HelperFunctions';
import { DeliveredReturnsConstants, ReportFilterConstants } from '../helper/Constants'
import * as CompanyStore from '../../store/company/CompanyStore';
import * as UploadTaxReturnStore from '../../store/common/uploadTaxReturn';
import * as UserStore from '../../store/userManagement/UserStore';
import * as UserSettingStore from '../../store/userManagement/UserSettingStore';
import * as TaxDocumentStore from './../../store/common/TaxDocumentStore';
import K1PartnersModal from './K1DeliveredStatus/K1PartnersModal';
import { ShowLoader } from '../helper/Loader';
let moment = require('moment');
import { initialCompanySettings } from '../../Core/ViewModels/Company/CompanySettingsViewModel';
import * as GroupedReturnStore from '../../store/groupedreturns/GroupedReturnStore';
import * as BulkOperationsStore from '../../store/common/BulkOperationsStore';
import { DownloadTypeOption } from '../reports/DeliveredReturns/DownloadTypeOption';
import { BulkOperationQuery } from '../../Core/ViewModels/Common/BulkOperationQuery';
import { DefaultDownloadInfoType } from '../navigation/profile/MySettings';
import { IUserProfile } from '../navigation/profile/ProfileObjects';
import { SetAccessType } from '../../store/reports/GroupedReturns/SendGroupedReturnsState';
import { CommonResources, K1DeliveredStatusResources } from '../helper/ResourceIdConstants';
import { ArrangementList, TypeDropdownValues } from '../../Core/Common/Common';


type K1DeliveredStatusProps = {
    k1DeliveredStatusState: IK1DeliveredStatusState,
    reportFilters: FilterStore.IFilterState,
    company: CompanyStore.ICompanyData,
    uploadTaxReturnStore: UploadTaxReturnStore.IUploadTaxReturnState,
    userStore: UserStore.ISSRUserData,
    userSettings: UserSettingStore.UserSettings,
    groupedReturnStore: GroupedReturnStore.IGroupInfoState,
    userProfle: IUserProfile,
}
    & typeof K1DeliveredStatusStore.actionCreator
    & typeof FilterStore.actionCreators
    & typeof CompanyStore.actionCreators
    & typeof UploadTaxReturnStore.actionCreators
    & typeof UserStore.actionCreators
    & typeof UserSettingStore.actionCreators
    & typeof TaxDocumentStore.actionCreators
    & typeof GroupedReturnStore.actionCreators
    & typeof BulkOperationsStore.actionCreators
    & RouteComponentProps<any>;

interface K1DeliveredStatusState {
    page: number;
    selectedRows: number[];
    filter: IFilters;
    sortName: string;
    sortOrder: string;
    searchString: string;
    filterTaxYear: any;
    filterName: string;
    filterClientId: string;
    filterPartner: string;
    filterDeliveredOn: Date | undefined;
    filterEngagementType: EngagementType;
    filterLocation: string;
    filterArrangementType: number;
    selectedDocument: number;
    openPartnerModal: boolean;
    saveFilterShow: boolean;
    refreshDelay?: boolean;
    defaultGroup: string;
    selectedDefaultDownloadType: boolean;
    DownloadTypeOptionState: boolean;
    loadSpinner: boolean;
    selectedDownloadType: DefaultDownloadInfoType;
    officeLocationList: any[];
}

const pageSize: number = 20;

export class K1DeliveredStatus extends React.Component<K1DeliveredStatusProps, K1DeliveredStatusState>
{
    private proxyFilter = ReportFilterType[ReportFilterType.K1Delivery].toUpperCase() + ReportFilterConstants.DefaultFilterBuilder;;
    private reportFilterType = ReportFilterType.K1Delivery;
    state: K1DeliveredStatusState = {
        page: 1,
        selectedRows: [],
        filter: {
            name: '',
            searchText: '',
            sort: {
                column: "",
                direction: SortDirections.None
            },
            fields: {},
            isDefaultFilter: false,
            filterType: ReportFilterType.K1Delivery,
            isMasterFilter: false
        },
        sortName: "delivereddate",
        sortOrder: "desc",
        searchString: "",
        filterTaxYear: new Date().getFullYear() - 1,
        filterName: "",
        filterClientId: "",
        filterPartner: "",
        filterDeliveredOn: undefined,
        filterEngagementType: EngagementType.None,
        filterLocation: "",
        filterArrangementType: -1,
        selectedDocument: 0,
        openPartnerModal: false,
        saveFilterShow: false,
        refreshDelay: false,
        defaultGroup: '',
        selectedDefaultDownloadType: false,
        DownloadTypeOptionState: false,
        loadSpinner: false,
        selectedDownloadType: DefaultDownloadInfoType.PromptMeEveryTime,
        officeLocationList: []
    }

    componentDidMount() {
        const element = document.getElementById('content-wrapper');
        element && element.scrollTo(0, 0);
        Helper.fetchUserOfficeLocation(this.setOfficeLocation);
    }

    UNSAFE_componentWillMount() {
        this.props.requestK1DeliveredStatusDocuments(this.buildQuery(1, pageSize));
        this.props.requestAllSSRUsers(true);
        this.props.requestUserSettings(true, this.onRequestUserSettingsSuccess);
        this.props.getAllReportFilter(true, this.reportFilterType);
        this.props.requestCompanySettings(false);
        this.props.requestGroupAccess(true);
    }

    UNSAFE_componentWillReceiveProps() {
        this.props.userSettings.settings && this.props.userSettings.settings.defaultSettings &&
            this.props.userSettings.settings.defaultSettings.download &&
            this.setState({
                selectedDownloadType: this.props.userSettings.settings.defaultSettings.download.downloadType
            });
    }

    private setOfficeLocation = (officeLocationList: IOfficeLocation[]) => {
        let list;
        if (officeLocationList?.length) {
            list = officeLocationList.map(({ locationName: label, locationId: value }) => ({ label, value }));
        }
        else {
            list = [];
        }  
        this.setState({ officeLocationList: list });
    }
    
    private onRequestUserSettingsSuccess = () => {
        let defaultReportFilter = this.props.userSettings.settings.defaultSettings.reportFilter;
        if (!defaultReportFilter || defaultReportFilter == '') {
            this.loadDeliveredReturns();
        }
        this.props.userSettings.settings && this.props.userSettings.settings.defaultSettings &&
            this.props.userSettings.settings.defaultSettings.download &&
            this.setState({
                selectedDownloadType: this.props.userSettings.settings.defaultSettings.download.downloadType
            });
    }

    private buildQuery = (page: number, pageSize: number) => {
        return '?pageNo=' + page
            + '&pageSize=' + pageSize
            + '&sortBy=' + this.state.sortName
            + '&sortOrder=' + this.state.sortOrder
            + '&selectedTaxYear=' + this.state.filterTaxYear
            + '&filterName=' + encodeURIComponent(this.state.filterName)
            + '&filterClientId=' + encodeURIComponent(this.state.filterClientId)
            + '&filterPartner=' + encodeURIComponent(this.state.filterPartner)
            + '&filterDeliveredOn=' + this.state.filterDeliveredOn
            + '&filterEngagementType=' + this.state.filterEngagementType
            + '&filterLocation=' + this.state.filterLocation
            + '&filterArrangementType=' + this.state.filterArrangementType
    }
    private getBulkOperationQuery = (): BulkOperationQuery => {
        let query: BulkOperationQuery = {
            sortBy: this.state.sortName,
            sortOrder: this.state.sortOrder,
            searchText: this.state.searchString,
            clientId: this.state.filterClientId,
            customColumn: "",
            downloadedBy: "",
            name: this.state.filterName,
            partner: this.state.filterPartner,
            officeLocation: this.state.filterLocation,
            deliveredOn: this.state.filterDeliveredOn,
            engagementType: this.state.filterEngagementType,
            sentBy: "",
            status: "",
            isArchived: false,
            taxYear: this.state.filterTaxYear,
            unSelectedIds: [],
            assignedTo: "",
            reportType: ReportFilterType.K1Delivery,
            documentFilingType: 0,
            isPaperFileEnabled: false,
            quickReportStatus:'',
			groupName: '',
            taxSoftware: '',
            createdBy: '',
            globalSearch:'',
            setAccess: SetAccessType.None,
            arrangement: this.state.filterArrangementType
        }

        return query;
    }

    private onExportToExcel = (onExportToExcelComplete: () => void, resourceId: string) => {
        if (this.state.selectedDownloadType == DefaultDownloadInfoType.DownloadNow) {
            let queryString = this.buildQuery(this.state.page, pageSize);
            this.props.exportK1DeliveredReturnsAsExcel(queryString, onExportToExcelComplete, resourceId);
        }
        else if (this.state.selectedDownloadType == DefaultDownloadInfoType.DownloadToMyDownloads) {
            this.props.exportToExcelBulk(this.getBulkOperationQuery(), onExportToExcelComplete, resourceId);
        }
        else {
            onExportToExcelComplete();
            this.setState({
                selectedDownloadType: DefaultDownloadInfoType.DownloadToMyDownloads,
                DownloadTypeOptionState: true,
                loadSpinner: true,
                selectedDefaultDownloadType: false
            });
        }

    }
    private onDownloadOptionChange = (value: any) => {
        if (this.state.selectedDownloadType == DefaultDownloadInfoType.DownloadNow) {
            this.setState({ selectedDownloadType: DefaultDownloadInfoType.DownloadToMyDownloads });
        }
        else {
            this.setState({ selectedDownloadType: DefaultDownloadInfoType.DownloadNow });
        }

    }
    private onDownloadOptionSave = (value: any) => {
        var settings = this.props.userSettings.settings;
        if (this.state.selectedDefaultDownloadType == true) {

            settings.defaultSettings.download.rememberDefaultDownload = true;
            settings.defaultSettings.download.downloadType = this.state.selectedDownloadType;
            this.props.saveUserSettings(settings, true);
        }

        this.setState({
            DownloadTypeOptionState: false
        });
        let downloadType = this.props.userSettings.settings.defaultSettings.download.downloadType || DefaultDownloadInfoType.PromptMeEveryTime;
        this.onExportToExcel(() => {
            this.setState({
                loadSpinner: false,
                selectedDownloadType: downloadType
            });
        }, K1DeliveredStatusResources.Prefix + CommonResources.ExportToExcel);
    }
    private onDownloadTypeCancel = () => {
        let downloadType = this.props.userSettings.settings.defaultSettings.download.downloadType || DefaultDownloadInfoType.PromptMeEveryTime;
        this.setState({
            selectedDownloadType: downloadType,
            DownloadTypeOptionState: false,
            loadSpinner: false
        });
    }
    private useDefaultDownloadType = (event: any) => {
        if (this.state.selectedDefaultDownloadType == false) {
            this.setState({ selectedDefaultDownloadType: true });
        }
        else {
            this.setState({ selectedDefaultDownloadType: false });
        }
    }

    private onRowSelect = (row: any, isSelected: any, e: React.BaseSyntheticEvent) => {
        let newList = [...this.state.selectedRows];
        if (e.target.tagName !== 'BUTTON' && e.target.tagName !== 'I'
            && e.target.tagName !== 'SPAN' && e.target.tagName !== 'A') {
            if (isSelected) {
                newList.push(row.rowIndex);
            } else {
                var index = newList.indexOf(row.rowIndex);
                if (index > -1) {
                    newList.splice(index, 1);
                }
            }
            this.setState({ selectedRows: newList });
        }
        this.forceUpdate();
    }

    private onSelectAll = (isSelected: any, rows: any, e: any) => {
        let selectedRows: number[] = [];
        if (isSelected) {
            let count = rows && rows.length ? rows.length : 0;
            Array.from(Array(count).keys());
            selectedRows = Array.from(Array(count).keys());
        }
        this.setState({ selectedRows: selectedRows });
    }

    private onPageChange = (page: number, sizePerPage: number) => {
        this.setState({
            page: page,
            selectedRows: []
        }, this.loadDeliveredReturns);
    }

    private onSortChange = (sortName: string, sortOrder: string, column: number) => {
        let temp: IFilters = { ...this.state.filter };
        temp.sort.column = sortName;
        temp.sort.direction = sortOrder == "asc" ? SortDirections.Ascending : SortDirections.Descending;
        Helper.SessionStore.set(this.proxyFilter, JSON.stringify(temp));
        this.setState({
            filter: temp,
            sortName: sortName,
            sortOrder: sortOrder == "asc" ? "asc" : "desc"
        }, () => this.fetchDeliveredReturns(1, pageSize));
    }

        
    private onFilterNameChange = (event: any) => {
        let temp: IFilters = { ...this.state.filter };
        temp.name = event.target.value;
        this.setState({
            filter: temp
        })
    }


    private onPageReload = () => {
        this.setState(
            { refreshDelay: true },
            () => {
                let query = this.buildQuery(this.state.page, pageSize);
                this.props.requestK1DeliveredStatusDocuments(query, () => {
                    this.setState({
                        refreshDelay: false,
                        selectedRows: []
                    });
                });
            });
    }

    private onFilterChange = (dataField: any, filterType: ReportFilterType) => {
        let newState: any = {
            filterTaxYear: "",
            filterName: "",
            filterClientId: "",
            filterPartner: "",
            filterDeliveredOn: undefined,
            filterLocation: "",
            filterEngagementType: EngagementType.None,
            filterArrangementType: -1
        };
        let filterTaxYear: any;
        let isClearFilter = true;
        var dict: { [columnName: string]: string; } = {};

        for (let field of Object.keys(dataField)) {
            var data = dataField[field.valueOf()].value ? dataField[field.valueOf()].value : dataField[field.valueOf()];
            if (isClearFilter && data !== "" && data !== "-1") {
                isClearFilter = false;
            }
            dict[field.valueOf().toString()] = data;

            switch (field) {
                case 'taxDocumentName':
                    newState.filterName = dataField[field].value ? dataField[field].value : dataField[field];
                    break;
                case 'clientId':
                    newState.filterClientId = dataField[field].value ? dataField[field].value : dataField[field];
                    break;
                case 'partner':
                    newState.filterPartner = dataField[field].value ? dataField[field].value : dataField[field];
                    break;
                case 'deliveredDate':
                    newState.filterDeliveredOn = Helper.GetNumberOfDays(dataField[field].value ? dataField[field].value : dataField[field]);
                    break;
                case 'engagementType':
                    newState.filterEngagementType = dataField[field].value ? dataField[field].value : dataField[field];
                    let filterEngagementType = dataField[field].value ? dataField[field].value : dataField[field];
                    let filterArrangementType = -1;
                    const { positiveValues, negativeValues } = Helper.separatePositiveAndNegativeValues(filterEngagementType);
                    filterEngagementType = positiveValues;
                    filterArrangementType =
                        negativeValues == TypeDropdownValues.FullReturn
                            ? ArrangementList.FullReturn
                            : negativeValues == TypeDropdownValues.StateOnly
                            ? ArrangementList.StateOnly
                            : ArrangementList.All;
                    newState.filterEngagementType = filterEngagementType;
                    newState.filterArrangementType = filterArrangementType;
                    break;
                case 'taxYear':
                    filterTaxYear = dataField[field].value ? dataField[field].value : dataField[field];
                    filterTaxYear = (filterTaxYear == "-1") ? "" : filterTaxYear;
                    this.setState({ filterTaxYear: filterTaxYear });
                    newState.filterTaxYear = filterTaxYear;
                    break;
                case 'locationName':
                    newState.filterLocation = dataField[field].value ? dataField[field].value : dataField[field];
                    newState.filterLocation = newState.filterLocation == '-1' ? '' : newState.filterLocation;
                    break;
            }
        }
        let tempfilter: IFilters = { ...this.state.filter };
        tempfilter.fields = dict;
        tempfilter.filterType = filterType;
        if (isClearFilter) {
            Helper.SessionStore.remove(this.proxyFilter);
        } else {
            Helper.SessionStore.set(this.proxyFilter, JSON.stringify(tempfilter));
        }
        if (this.isFilterChanged(newState, filterTaxYear)) {
            this.setState({ ...newState, filter: tempfilter, page: 1 },
                () => {
                    let K1DeliveredStatusTable: any = this.refs.K1DeliveredStatusTable;
                    if (!K1DeliveredStatusTable.isAppliedFilter)
                        this.props.requestK1DeliveredStatusDocuments(this.buildQuery(this.state.page, pageSize))
                })
        }
    }

    private isFilterChanged = (newState: any, filterTaxYear: any): boolean => {
        return (
            newState.filterName !== this.state.filterName ||
            newState.filterClientId !== this.state.filterClientId ||
            newState.filterPartner !== this.state.filterPartner ||
            newState.filterDeliveredOn !== this.state.filterDeliveredOn ||
            newState.filterEngagementType !== this.state.filterEngagementType ||
            newState.filterArrangementType !== this.state.filterArrangementType ||
            filterTaxYear !== this.state.filterTaxYear ||
            newState.filterLocation !== this.state.filterLocation);
    }
    loadDeliveredReturns = () => {
        let queryString = this.buildQuery(this.state.page, pageSize);
        this.props.requestK1DeliveredStatusDocuments(queryString);
    }

    fetchDeliveredReturns = (page: number, sizePerPage: number) => {
        let queryString = this.buildQuery(page, sizePerPage);
        this.setState({ page: page }, () => { this.props.requestK1DeliveredStatusDocuments(queryString) });
    }

    onOpenPartnerModal = (rowIndex: number) => {
        var documentId: number = this.props.k1DeliveredStatusState.K1DeliveredStatus.documents[rowIndex].id;
        this.setState({
            selectedDocument: documentId,
            openPartnerModal: true
        });
    }
    onClosePartnerModal = () => {
        this.setState({
            selectedDocument: 0,
            openPartnerModal: false
        });
    }

    onFilterSave = (onApplyFilter: (filter: IFilters) => void) => {
        if (this.state.filter.name.trim() != '') {
            let filter = this.props.reportFilters.filters.find(x => x.name == this.state.filter.name);
            if (filter) {
                VenusNotifier.Warning(DeliveredReturnsConstants.OtherMessage.FilterNameAlreadyExists, null);
            }
            else {
                this.props.addReportFilter(this.state.filter.name, this.state.filter, () => {
                    onApplyFilter(this.state.filter);
                    this.onSaveFilterHide();
                });

            }
        }
        else {
            VenusNotifier.Warning(DeliveredReturnsConstants.OtherMessage.PleaseEnterFilterName, null);
        }
    }

    onFilterUpdate = (name: string) => {
        let filter = this.props.reportFilters.filters.find(x => x.name == name);
        if (!filter) {
            VenusNotifier.Warning(DeliveredReturnsConstants.OtherMessage.FilterDoesNotExists, null);
        }
        else {
            filter.fields = this.state.filter.fields;
            filter.name = name;
            filter.searchText = this.state.filter.searchText;
            filter.sort = this.state.filter.sort;
            this.props.updateReportFilter(name, filter);
            this.onSaveFilterHide();
        }
    }

    onSetDefaultFilterSuccess = () => {
        VenusNotifier.Success(DeliveredReturnsConstants.StatusMessage.SetDefaultFilterSuccess, null);
    }

    onSetDefaultFilter = (name: string, filterType: ReportFilterType) => {
        this.props.addDefaultFilter(name, filterType, () => { this.onSetDefaultFilterSuccess(); });
    }

    onRemoveDefaultFilterSuccess = () => {
        VenusNotifier.Success(DeliveredReturnsConstants.StatusMessage.RemoveDefaultFilterSuccess, null);
    }

    onRemoveDefaultFilter = (name: string, filterType: ReportFilterType) => {
        this.props.removeDefaultFilter(name, filterType, () => { this.onRemoveDefaultFilterSuccess(); });
        let K1DeliveredStatusTable: any = this.refs.K1DeliveredStatusTable;
        K1DeliveredStatusTable.refs.Filters?.setState({ activeFilter: '', appliedFilters: [] });
        this.onPageReload;
    }

    onFilterDelete = (filterName: string, filterType: ReportFilterType) => {
        this.props.deleteReportFilter(filterName, filterType);
    }

    onSaveFilterHide = () => {
        this.setState({
            saveFilterShow: false
        });
    }

    onSaveFilterShow = () => {
        this.setState({
            saveFilterShow: true
        });
    }

    onTaxpayerView = (rowIndex: number) => {
        var documentId = this.props.k1DeliveredStatusState.K1DeliveredStatus.documents[rowIndex].id;
        ShowLoader("Loading....");
        this.props.requestTaxDocument(documentId, true, true, ClientTypes.PartnerShip, undefined, undefined, K1DeliveredStatusResources.Prefix + K1DeliveredStatusResources.ClientView);
    }
    private getDefaultFilter = (filters: IFilters[]): string | undefined => {
        let existsMasterFilter = filters.findIndex(x => x.isMasterFilter) > -1 ? true : false;
        let existsDefaultFilter = filters.findIndex(x => x.isDefaultFilter) > -1 ? true : false;
        if (existsMasterFilter) {
            let mName = filters.find(x => x.isMasterFilter);
            return mName ? mName.name : undefined;
        }
        else if (existsDefaultFilter) {
            let dName = filters.find(x => x.isDefaultFilter);
            return dName ? dName.name : undefined;
        }
        return undefined;
    }

    render() {
        const { k1DeliveredStatusState: { K1DeliveredStatusLoading, K1PartnersLoading, K1DeliveredStatus, K1Partners }, requestK1Partners } = this.props;
        return (
            <div className="user-assignment-content">
                <K1DeliveredStatusHeader
                    pageTitle={"K1 Delivered Status"}
                />
                <br />
                <K1DeliveredStatusTable
                    ref='K1DeliveredStatusTable'
                    onPageChange={this.onPageChange}
                    onSortChange={this.onSortChange}
                    onFilterChange={this.onFilterChange}
                    pageNo={this.state.page}
                    onExportToExcel={this.onExportToExcel}
                    onFilterNameChange={this.onFilterNameChange}
                    onFilterSave={this.onFilterSave}
                    onFilterUpdate={this.onFilterUpdate}
                    onFilterDelete={this.onFilterDelete}
                    filterList={this.props.reportFilters.filters}
                    currentFilter={this.state.filter}
                    onSetDefaultFilter={this.onSetDefaultFilter}
                    onRemoveDefaultFilter={this.onRemoveDefaultFilter}
                    saveFilterShow={this.state.saveFilterShow}
                    onSaveFilterHide={this.onSaveFilterHide}
                    onSaveFilterShow={this.onSaveFilterShow}
                    K1DeliveredStatus={K1DeliveredStatus}
                    isLoading={K1DeliveredStatusLoading}
                    onTaxpayerView={this.onTaxpayerView}
                    pageSize={pageSize}
                    onRowSelect={this.onRowSelect}
                    onSelectAll={this.onSelectAll}
                    selectedRows={this.state.selectedRows}
                    defaultFilter={this.getDefaultFilter(this.props.reportFilters.filters)}
                    loadGrid={this.loadDeliveredReturns}
                    onOpenPartnerModal={this.onOpenPartnerModal}
                    proxyFilter={this.proxyFilter}
                    filterType={this.reportFilterType}
                    onPageReload={this.onPageReload}
                    refreshDelay={this.state.refreshDelay}
                    loadSpinner={this.state.loadSpinner}
                    officeLocationList = {this.state.officeLocationList}
                    parentResourceIdentifier={K1DeliveredStatusResources.Prefix}
                />
                <K1PartnersModal
                    k1Partners={K1Partners}
                    isLoading={K1PartnersLoading}
                    requestK1Partners={requestK1Partners}
                    openPartnerModal={this.state.openPartnerModal}
                    currentDocument={this.state.selectedDocument}
                    onCloseModal={this.onClosePartnerModal}
                    onExportToExcel={this.props.exportK1PartnersAsExcel}
                    clearK1Partner={this.props.clearK1Partner}
                    sendReminderToPartner={this.props.sendReminderToPartner}
                    parentResourceIdentifier={K1DeliveredStatusResources.Prefix}
                />
                <DownloadTypeOption
                    show={this.state.DownloadTypeOptionState}
                    onCancel={this.onDownloadTypeCancel}
                    selectedDownloadType={this.state.selectedDownloadType}
                    onDownloadOptionChange={this.onDownloadOptionChange}
                    onDownloadOptionSave={this.onDownloadOptionSave}
                    useDefaultDownloadType={this.useDefaultDownloadType}
                    selectedDefaultDownloadType={this.state.selectedDefaultDownloadType}
                />
            </div>
        )
    }
}
