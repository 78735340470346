import * as React from "react";
import moment from "moment";
import { ButtonToolbar, DropdownButton } from "react-bootstrap";
import { Link } from "react-router-dom";
import { DocumentStatus, ITaxReturn } from "../common/TaxReturn";
import * as Helper from "../helper/HelperFunctions";
import * as UserSettingStore from "../../store/userManagement/UserSettingStore";
import { IAssignmentsState } from "../../store/assignments/AssignmentsState";
import { SendTaxReturnResources } from "../helper/ResourceIdConstants";

export interface dropdownItem {
    title: any;
    taxReturn: ITaxReturn;
    rowIndex: number;
    onProcessReturnOpen: (rowIndex: number, resourceId?: string, editable?: boolean) => void;
    onEditClientInfoOpen: (rowIndex: number) => void;
    onSetAccessOpen: (rowIndex: number, resourceId?: string) => void;
    onChangeStatusOpen: (rowIndex: number) => void;
    onReportProblemOpen: (id: number) => void;
    onDeleteTaxReturnOpen: (rowIndex: number, resourceId?: string) => void;
    onRecallReturnOpen: (rowIndex: number) => void;
    onClientTrackingOpen: (rowIndex: number, resourceId?: string) => void;
    onReprocessReturnOpen: (rowIndex: number, resourceId?: string) => void;
    userSettings: UserSettingStore.UserSettings;
    currentPageAssignment: IAssignmentsState;
    checkAssignmentProperty?: (documentId: Number, currentAssign: boolean, rowIndex: any, callBack: any) => void;
    checkAssignmentPropertyAction?: (documentId: Number, currentAssign: boolean, callBack: any, rowIndex?: any) => void;
    OnEditGroupInfoOpen: (groupId: number) => void;
    parentResourceIdentifier: string;
    onGroupedReportProblemOpen: (rowIndex: number) => void;
}

export class AssignmentAction extends React.Component<dropdownItem, {}> {
    private createDeleteAction = () => {
        let elements: any[] = [];
        if (this.props.taxReturn.documentStatus === DocumentStatus.ERROR &&
            this.props.taxReturn.recallReturn) {
            elements.push(
                <div key="RecalledAction">
                    <button
                        title={"Upload Corrected Return"}
                        type="button"
                        className="btn-white"
                        style={{ paddingLeft: "2px", paddingRight: "2px" }}
                        onClick={(e) => this.props.onRecallReturnOpen(this.props.rowIndex)}
                    >
                        <i className="fas fa-sync-alt text-grey"></i>
                    </button>
                    <button
                        data-resource-id={this.props.parentResourceIdentifier + SendTaxReturnResources.GridClientTrackingButton}
                        title={"Client Tracking"}
                        type="button"
                        className="btn-white"
                        onClick={(e) =>
                            this.props.onClientTrackingOpen(
                                this.props.rowIndex,
                                this.props.parentResourceIdentifier + SendTaxReturnResources.GridClientTrackingButton
                            )
                        }
                    >
                        <i className="fas fa-history text-warning"></i>
                    </button>
                    <button
                        data-resource-id={this.props.parentResourceIdentifier + SendTaxReturnResources.GridDeleteButton}
                        title={"Delete Return"}
                        type="button"
                        className="btn-white"
                        onClick={(e) =>
                            this.props.onDeleteTaxReturnOpen(
                                this.props.rowIndex,
                                this.props.parentResourceIdentifier + SendTaxReturnResources.GridDeleteButton
                            )
                        }
                    >
                        <i className="text-danger glyphicon glyphicon-remove" />
                    </button>
                </div>
            );
        }
        else {
            elements.push( 
                    <button
                        key="deleteReturn"
                        data-resource-id={this.props.parentResourceIdentifier + SendTaxReturnResources.GridDeleteButton}
                        title={"Delete Return"}
                        type="button"
                        className="btn-white"
                        onClick={(e) =>
                            this.props.onDeleteTaxReturnOpen(
                                this.props.rowIndex,
                                this.props.parentResourceIdentifier + SendTaxReturnResources.GridDeleteButton
                            )
                        }
                    >
                        <i className="text-danger glyphicon glyphicon-remove" />
                    </button> 
            );
        }
        return elements;
    };

    private showGRReportAProblem = false;
    private createPrimaryActions = () => {
        const {
            taxReturn: { assignedUser }
        } = this.props;

        let elements: any[] = [];
        if (this.props.taxReturn.documentStatus == DocumentStatus.REPROCESSING) {
            elements.push(
                <div key="ReprocessingActions">
                    <button
                        title={"Reprocess Return"}
                        type="button"
                        className="btn-white"
                        style={{ paddingLeft: "2px", paddingRight: "2px" }}
                    >
                        <span className="fa-stack fas fa-spinner fa-spin text-white bg-transparent">
                            <i className="fas fa-circle fa-stack-2x text-violet" style={{ padding: "0px" }}></i>
                            <i
                                className="fas fa-redo-alt fa-inverse fa-stack-1x"
                                style={{ padding: "0px" }}
                                data-fa-transform="shrink-4"
                            ></i>
                        </span>
                    </button>
                </div>
            );
        } else if (
            this.props.taxReturn.documentStatus === DocumentStatus.RECALLED ||
            this.props.taxReturn.documentStatus === DocumentStatus.TOBEREPROCESSED
        ) {
            elements.push(
                <div key="RecalledActions">
                    <button
                        data-resource-id={this.props.parentResourceIdentifier + SendTaxReturnResources.GridReprocessButton}
                        title={"Reprocess Return"}
                        type="button"
                        className="btn-white"
                        style={{ paddingLeft: "2px", paddingRight: "2px" }}
                        onClick={(e) => {
                            e.currentTarget.disabled = true;
                            this.props.onReprocessReturnOpen(
                                this.props.rowIndex,
                                this.props.parentResourceIdentifier + SendTaxReturnResources.GridReprocessButton
                            );
                        }}
                    >
                        <span className="fa-stack">
                            <i className="fas fa-circle fa-stack-2x text-violet" style={{ padding: "0px" }}></i>
                            <i
                                className="fas fa-redo-alt fa-inverse fa-stack-1x"
                                style={{ padding: "0px" }}
                                data-fa-transform="shrink-4"
                            ></i>
                        </span>
                    </button> 
                    <button 
                        title={"Upload Corrected Return"}
                        type="button"
                        className="btn-white"
                        onClick={(e) => this.props.onRecallReturnOpen(this.props.rowIndex)}
                    >
                        <i className="fas fa-sync-alt text-grey"></i>
                    </button>
                    <button
                        data-resource-id={this.props.parentResourceIdentifier + SendTaxReturnResources.GridClientTrackingButton}
                        title={"Client Tracking"}
                        type="button"
                        className="btn-white"
                        onClick={(e) =>
                            this.props.onClientTrackingOpen(
                                this.props.rowIndex,
                                this.props.parentResourceIdentifier + SendTaxReturnResources.GridClientTrackingButton
                            )
                        }
                    >
                        <i className="fas fa-history text-warning"></i>
                    </button>
                    <button
                        data-resource-id={this.props.parentResourceIdentifier + SendTaxReturnResources.GridDeleteButton}
                        title={"Delete Return"}
                        type="button"
                        className="btn-white"
                        style={{ verticalAlign: "middle" }}
                        onClick={(e) =>
                            this.props.onDeleteTaxReturnOpen(
                                this.props.rowIndex,
                                this.props.parentResourceIdentifier + SendTaxReturnResources.GridDeleteButton
                            )
                        }
                    >
                        <i
                            className="text-danger glyphicon glyphicon-remove"
                            style={{ marginBottom: "1px", marginTop: "-1px" }}
                        />
                    </button>
                </div>
            );
        }  else {
            elements.push(
                <div key="AssignmentsAction">
                    <button
                        data-resource-id={this.props.parentResourceIdentifier + SendTaxReturnResources.GridProcessButton}
                        title={"Process Return"}
                        type="button"
                        className="btn-white"
                        data-test-auto="8192e630-37a2-4e13-90f7-b5ea7befc103"
                        onClick={(e) => {
                            this.props.onProcessReturnOpen(
                                this.props.rowIndex,
                                this.props.parentResourceIdentifier + SendTaxReturnResources.GridProcessButton
                            );
                        }}
                    >
                        <i className="fa fa-rocket text-violet"></i>
                    </button>
                    <button
                        data-resource-id={this.props.parentResourceIdentifier + SendTaxReturnResources.GridEditClientButton}
                        title={this.props.taxReturn.groupId > 0 ? "Edit Info" : "Edit Client Info"}
                        type="button"
                        className="btn-white"
                        onClick={(e) => this.props.onEditClientInfoOpen(this.props.rowIndex)}
                    >
                        <i className="fas fa-address-card text-success"></i>
                    </button>
                    <button
                        data-resource-id={this.props.parentResourceIdentifier + SendTaxReturnResources.GridDeleteButton}
                        title={"Delete Return"}
                        type="button"
                        className="btn-white"
                        onClick={(e) =>
                            this.props.onDeleteTaxReturnOpen(
                                this.props.rowIndex,
                                this.props.parentResourceIdentifier + SendTaxReturnResources.GridDeleteButton
                            )
                        }
                    >
                        <i className="text-danger glyphicon glyphicon-remove" />
                    </button>{" "}
                    {this.createSecondaryActions()}{" "}
                </div>
            );
        }

        return elements;
    };

    private showGroupedReturnReportAProblemSubMenu = () => {
        this.showGRReportAProblem = true;
    };

    private createSecondaryActions = () => {
        let reportAProblemSubMenu = (
            <div>
                <li title="Report Group">
                    <Link
                        to={"#"}
                        onClick={() => this.props.onGroupedReportProblemOpen(this.props.taxReturn?.id)}
                        data-test-auto="CBAADAF2-9C74-48B0-8D7E-CBC55BA61766"
                    >
                        <span className="marL05 marR05">Report Group</span>
                    </Link>
                </li>
                <li title="Report Return">
                    <Link
                        to={"#"}
                        onClick={() => this.props.onReportProblemOpen(this.props.taxReturn?.id)}
                        data-test-auto="42ACE989-6786-41EB-B494-D0EBD738E0DB"
                    >
                        <span className="marL05 marR05">Report Return</span>
                    </Link>
                </li>
            </div>
        );

        return (
            <ButtonToolbar className="btn-group-vertical" title={"More.."}>
                <DropdownButton
                    size="sm"
                    title={<span className="fa fa-ellipsis-h" style={{ verticalAlign: "inherit" }}></span>}
                    id="process-return-dropdown"
                    alignRight
                    data-test-auto="2d0d51d7-5f0f-43b2-96bb-13f1fd93d09d"
                >
                    {this.props.taxReturn?.groupId > 0 && (
                        <li key="EditGroup" title="Edit Group Name">
                            <Link
                                data-resource-id={this.props.parentResourceIdentifier + SendTaxReturnResources.EditGroupName}
                                to={"#"}
                                onClick={(e: any) => this.props.OnEditGroupInfoOpen(this.props.taxReturn?.groupId)}
                                data-test-auto="6BE84819-B396-4C68-9556-124BC688CFF2"
                            >
                                <i className="fas fa-edit ddl-icon"></i>Edit Group Name
                            </Link>
                        </li>
                    )}
                    <li
                        key="ChangeStatus"
                        title="Change Status"
                        data-resource-id={this.props.parentResourceIdentifier + SendTaxReturnResources.GridChangeStatus}
                    >
                        <Link
                            to={"#"}
                            onClick={(e: any) => {
                                this.props.onChangeStatusOpen(this.props.rowIndex);
                            }}
                            data-test-auto="A5B3F389-F367-4E0D-9BC5-23BCD15EF3F8"
                        >
                            <i className="fas fa-random ddl-icon"></i>Change Status
                        </Link>
                    </li>
                    <li
                        data-resource-id={this.props.parentResourceIdentifier + SendTaxReturnResources.GridSetAccessButton}
                        key="SetAccess"
                        title="Set Access"
                    >
                        <Link
                            to={"#"}
                            onClick={(e: any) => {
                                this.props.onSetAccessOpen(
                                    this.props.rowIndex,
                                    this.props.parentResourceIdentifier + SendTaxReturnResources.GridSetAccessButton
                                );
                            }}
                            data-test-auto="F3D71FAB-5614-45F9-A62B-DE14330EA301"
                        >
                            <i className="fas fa-key ddl-icon"></i>Set Access
                        </Link>
                    </li>
                    {this.props.taxReturn.groupId == 0 || this.props.taxReturn.groupId == null ? (
                        <li
                            data-resource-id={
                                this.props.parentResourceIdentifier + SendTaxReturnResources.GridReportAProblemButton
                            }
                            key="ReportProblem"
                            title="Report a Problem"
                        >
                            <Link
                                to={"#"}
                                onClick={(e: any) => this.props.onReportProblemOpen(this.props.taxReturn?.id)}
                                data-test-auto="5E72448C-C121-4B50-83C1-878BBC0C41D4"
                            >
                                <i className="fas fa-bullhorn ddl-icon"></i>Report a Problem
                            </Link>
                        </li>
                    ) : (
                        <li
                            className="dropdown-submenu dropdown-menu-right dropdown-submenu-right"
                            title="Report a Problem"
                            onMouseEnter={() => {
                                this.showGroupedReturnReportAProblemSubMenu();
                            }}
                            data-resource-id={`${
                                this.props.parentResourceIdentifier + SendTaxReturnResources.GridReportAProblemButton
                            }`}
                        >
                            <Link
                                to={"#"}
                                onClick={() => this.showGroupedReturnReportAProblemSubMenu()}
                                data-test-auto="5E72448C-C121-4B50-83C1-878BBC0C41D5"
                            >
                                <i className="fas fa-desktop ddl-icon"></i>Report a Problem
                            </Link>
                            {this.showGRReportAProblem && (
                                <ul className="dropdown-menu dropdown-menu-ul">{reportAProblemSubMenu}</ul>
                            )}
                        </li>
                    )}
                    {this.props.taxReturn?.recallReturn == true && (
                        <li key="UploadCorrectedReturn" title="Upload Corrected Return">
                            <Link
                                data-resource-id={this.props.parentResourceIdentifier + SendTaxReturnResources.EditGroupName}
                                to={"#"}
                                onClick={(e: any) => this.props.onRecallReturnOpen(this.props.rowIndex)}
                                data-test-auto="6BE84819-B396-4C68-9556-124BC688CFF2"
                            >
                                <i className="fas fa-sync-alt ddl-icon"></i>Upload Corrected Return
                            </Link>
                        </li>
                    )}
                    {this.props.taxReturn?.recallReturn == true && (
                         <li key="ClientTracking" title="Client Tracking">
                        <Link
                            data-resource-id={this.props.parentResourceIdentifier + SendTaxReturnResources.GridClientTrackingButton}
                            to={"#"}
                            onClick={(e: any) =>  this.props.onClientTrackingOpen(
                            this.props.rowIndex,
                            this.props.parentResourceIdentifier + SendTaxReturnResources.GridClientTrackingButton
                                )}
                            data-test-auto="6BE84819-B396-4C68-9556-124BC688CFF2"
                        >
                                <i className="fas fa-history ddl-icon"></i>Client Tracking
                        </Link>
                        </li>
                    )}
                </DropdownButton>
            </ButtonToolbar>
        );
    };

    private isOneDayOldAndDeletable = (): boolean => {
        let deletableDocumentStatus = [
            DocumentStatus.PREPARINGFORDELIVERY,
            DocumentStatus.PREPARINGFORDELIVERYDESCRIPTION,
            DocumentStatus.UPLOADED,
            DocumentStatus.DELIVERYFAILEDDESCRIPTION,
            DocumentStatus.PREPARINGFORPDFDOWNLOAD,
            DocumentStatus.PREPARINGFORPDFDOWNLOADDESCRIPTION,
            DocumentStatus.DOWNLOADPDF,
            DocumentStatus.TAXCADDYDELIVERYFAILED,
            DocumentStatus.REPROCESSING
        ];
        let hours: number;
        hours = moment
            .duration(moment(new Date(), "YYYY/MM/DD HH:mm").diff(moment(this.props.taxReturn.uploadedOn, "YYYY/MM/DD HH:mm")))
            .asHours();
        return deletableDocumentStatus.indexOf(this.props.taxReturn.documentStatus) > -1 && hours > 24;
    };

    public render() {
        let disabled = Helper.isLocked(this.props.taxReturn);

        const {
            taxReturn: { documentStatus, assignedUser }
        } = this.props;

        return (
            <div className="button-group">
                {documentStatus === DocumentStatus.NONSUPPORTED ||
                documentStatus === DocumentStatus.ERROR ||
                documentStatus === DocumentStatus.NONSUPPORTEDDESCRIPTION ||
                documentStatus === DocumentStatus.DELIVERYFAILED ||
                this.isOneDayOldAndDeletable()
                    ? this.createDeleteAction()
                    : documentStatus === DocumentStatus.REPROCESSING
                    ? this.createPrimaryActions()
                    : !disabled && this.createPrimaryActions()}
            </div>
        );
    }
}
export default AssignmentAction;
