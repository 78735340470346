import React, { useEffect } from "react";
import { Modal } from "react-bootstrap";
let FileViewer = require("react-file-viewer");

interface FilePreviewProps {
    url: string;
    showPDFPreview: boolean;
    onHidePDFPreview: () => void;
    fileType: string | undefined;
    fileName: string;
}

const FilePreview: React.FC<FilePreviewProps> = (props) => {
    const getModalBodyClass = (): string => {
        let modalClass = "filePreviewModalBody";
        if (props.fileType && props.fileType.toLowerCase() === "pdf") {
            modalClass += " nopadding";
        }
        return modalClass;
    };

    useEffect(() => {}, [props.fileType, props.url]);

    return (
        <div>
            <Modal className="file-preview-modal" show={props.showPDFPreview} onHide={props.onHidePDFPreview}>
                <div style={{ height: 800 }}>
                    <Modal.Header closeButton>
                        <Modal.Title>{props.fileName}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className={getModalBodyClass()}>
                        {props.fileType && props.fileType.toLowerCase() === "pdf" ? (
                            <object
                                name={props.fileName}
                                data={props.url + "#toolbar=0"}
                                type="application/pdf"
                                height="90%"
                                width="100%"
                                title={props.fileName}
                            >
                                alt: <a href={props.url}>{props.fileName}</a>
                            </object>
                        ) : (
                            <div className="file-image-preview">
                                <FileViewer fileType={props.fileType != undefined ? props.fileType : ""} filePath={props.url} />
                            </div>
                        )}
                    </Modal.Body>
                </div>
            </Modal>
        </div>
    );
};

export default FilePreview;
