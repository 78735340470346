import { addTask } from 'domain-task';
import { Reducer } from 'redux';
import { AppThunkAction } from '../';
import { actionTypes } from '../../types/ActionTypes';
import { ICompanySettings, initialCompanySettings, IOrganizerSettings, IExtensionSettings, ProductType, IBulkPurchaseDiscountDetails, PaymentOption, IGFRSettings } from '../../Core/ViewModels/Company/CompanySettingsViewModel';
import { ITaxingAuthority} from '../../components/common/TaxReturn';
import {
    ICompanyLogoSetting, IAdmin, ICompany, ICompanyInfo, ICompanyWhiteLogoSetting,
    initialCompanyLogoSettings, initialAdminModel, initialCompanyModel, initialCompanyWhiteLogoSettings, initalMyDownloadsList, ProductStatus, ProductType as CompanyProductType, IPrimaryAdmin
} from '../../Core/ViewModels/Company/CompanyViewModel';
import { handleResponse, handleBlob } from '../Library';
import { NotificationAction, StatusType } from '../common/NotificationStore';
import * as Constants from '../../components/helper/Constants';
const isEqual = require("react-fast-compare");
import { validateCompanySettings } from '../../components/helper/Validations';
import { ICompanyLedgerModel } from '../../Core/ViewModels/Billing/CompanyLedgerModel';
import { DisplayDownloadFile } from '../../components/common/DisplayDownloadFile';
import { RequestExpireDocumentsCount, ReceiveExpireDocumentsCount, ErrorExpireTaxDocumentCountAction } from "../reports/KnownTypes";
import { IDownloadedZipFilesModel, StatusZipDownload } from '../../components/navigation/profile/MyDownload';
import { IPaymentHistoryModel } from '../../Core/ViewModels/Billing/PaymentHistoryModel';
import { IBillingDetailModel } from '../../Core/ViewModels/Billing/BillingDetailModel';
import { PaymentGateway, PaymentType } from '../../Core/ViewModels/Purchase/PurchaseViewModels';
import * as Helper from '../../components/helper/HelperFunctions';
import { API_BASE_URL, SSSUITE_API_BASE_URL } from '../../utils/constants';
import { initialSSRSettings, ISSRSettings } from '../../Core/ViewModels/Company/Settings/SSRSettingsViewModel';
import { ReceiveSSRSettingsAction, RequestAllSSRSettings } from './settings/SSRSettingsStore';

export interface IAuthorityDictionary {
    [index: number]: ITaxingAuthority;
}

export interface ICompanyData { //TODO: should be a dictionary so that we can pick a user's details with 'userId' from this.
    //DataPart
    ssrSettings: ISSRSettings | any;
    companySettings: ICompanySettings | any;
    taxingAuthorities: ITaxingAuthority[];
    companyProfile: ICompany;
    admins: IAdmin;
    companyLogoSetting: ICompanyLogoSetting;
    signatureUploadLink: string;
    logoUploadLink: string;
    authorityLookup: IAuthorityDictionary;
    ledgerData: ICompanyLedgerModel[];
    groupIDs: string[];
    myDownloads: IDownloadedZipFilesModel[];
    paymentHistoryData: IPaymentHistoryModel[];
    billingDetail: IBillingDetailModel[];

    companyWhiteLogoSetting: ICompanyWhiteLogoSetting;
    whiteLogoUploadLink: string;

    minQuantityForDiscount: IBulkPurchaseDiscountDetails[];
    //Meta infomation
    isLoading: boolean;
    error: boolean;
    message: string;

    //extra information
    documentToExpireCount: number;
    isDocumentStoreDirty: boolean;
}

export interface ReceiveGFRSettingsAction {
    type: actionTypes.RECEIVE_GFR_SETTINGS;
    settings: IGFRSettings;
}

export interface RequestCompanySettingsAction {
    type: actionTypes.REQUEST_COMPANY_SETTINGS;
    message: string;
}

export interface GetMyDownloadsList {
    type: actionTypes.RECEIVE_MY_DOWNLOADS_LIST;
    myDownloadsList: any[];
}

export interface ReceiveCompanySettingsAction {
    type: actionTypes.RECEIVE_COMPANY_SETTINGS;
    settings: ICompanySettings;
}
export interface UpdateCompanySettingsAction {
    type: actionTypes.UPDATE_COMPANY_SETTINGS;
    settings: ICompanySettings;
}

export interface UpdateCompanySettingsLocalAction {
    type: actionTypes.UPDATE_COMPANY_SETTINGS_LOCAL;
    isEnableIPFiltering: boolean;
}

export interface ErrorCompanySettingsAction {
    type: actionTypes.ERROR_COMPANY_SETTINGS;
    reason: string;
}

export interface RequestTaxAuthoritiesAction {
    type: actionTypes.REQUEST_TAX_AUTHORITIES;
    message: string;
}

export interface ReceiveTaxAuthoritiesAction {
    type: actionTypes.RECEIVE_TAX_AUTHORITIES;
    taxingAuthorities: ITaxingAuthority[];
}

export interface ReceiveCompanyLogoAction {
    type: actionTypes.RECEIVE_COMPANY_LOGO;
    logoPath: string;
    isSsrLogo: boolean;
}

export interface ReceiveCompanyProfileAction {
    type: actionTypes.RECEIVE_COMPANY_PROFILE;
    companyProfile: ICompany;
}

export interface UpdateCompanyProfileAction {
    type: actionTypes.UPDATE_COMPANY_PROFILE;
    companyInfo: ICompanyInfo;
}

export interface RequestCompanySignatureUploadLinkAction {
    type: actionTypes.UPDATE_COMPANY_SIGNATURE;
    sasUrl: string;
}

export interface ReceiveCompanyWhiteLogoAction {
    type: actionTypes.RECEIVE_COMPANY_WHITE_LOGO;
    whiteLogoPath: string;
}

export interface ValidateAzureADGroupAction {
    type: actionTypes.VALIDATE_AD_AZURE_GROUP;
    groupIDs: string[];
}

export interface RefreshTaxDocumentStore {
    type: actionTypes.REFRESH_TAX_DOCUMENT_STORE;
    isDirty: boolean;
}

interface ReceiveCompanyLedger {
    type: actionTypes.RECEIVE_COMPANY_LEDGER;
    ledgerData: ICompanyLedgerModel[];
}

interface RequestCompanyLedger {
    type: actionTypes.REQUEST_COMPANY_LEDGER;
}
interface UpdateOrganizerSettings {
    type: actionTypes.UPDATE_COMPANY_SETTINGS_ORGANIZER,
    settings: IOrganizerSettings
}

interface UpdateExtensionSettings {
    type: actionTypes.UPDATE_COMPANY_SETTINGS_EXTENSION,
    settings: IExtensionSettings
}
type DispatchAction = RequestCompanySettingsAction |
    ReceiveCompanySettingsAction |
    ErrorCompanySettingsAction |
    RequestTaxAuthoritiesAction |
    ReceiveTaxAuthoritiesAction |
    UpdateCompanySettingsLocalAction |
    ReceiveCompanyLogoAction |
    ReceiveCompanyWhiteLogoAction |
    ReceiveCompanyProfileAction |
    UpdateCompanyProfileAction |
    RequestCompanySignatureUploadLinkAction |
    NotificationAction |
    ReceiveCompanyLedger |
    RequestExpireDocumentsCount |
    ReceiveExpireDocumentsCount |
    ErrorExpireTaxDocumentCountAction |
    ValidateAzureADGroupAction |
    RefreshTaxDocumentStore |
    GetMyDownloadsList |
    UpdateOrganizerSettings |
    UpdateExtensionSettings |
    RequestCompanyLedger |
    ReceiveSSRSettingsAction |
    RequestAllSSRSettings |
    ReceiveGFRSettingsAction;

type KnownAction =
    DispatchAction |
    UpdateCompanySettingsAction |
    RequestExpireDocumentsCount |
    ReceiveExpireDocumentsCount |
    ErrorExpireTaxDocumentCountAction;

export const actionCreators = {
    requestCompanySettings: (reload: boolean = false): AppThunkAction<KnownAction> => (dispatch, getState) => {
        let state = getState().companyData;
        if (reload || !state.companySettings || isEqual(state.companySettings, initialCompanySettings)) {
            const fetchTask = fetch(API_BASE_URL + 'api/Company/CompanySettings/GetCompanySettings', {
                method: 'GET',
                credentials: 'include'
            })
                .then(handleResponse)
                .then(json => json as Promise<ICompanySettings>)
                .then(data => {
                    if (data) {
                        dispatch({ type: actionTypes.RECEIVE_COMPANY_SETTINGS, settings: data });
                    }
                })
                .catch(error => {
                    dispatch({
                        type: actionTypes.NOTIFICATION,
                        statusMessage: Constants.CompanySettingsConstants.StatusMessage.CompanySettingsError,
                        statusType: StatusType.Error
                    })
                    dispatch({
                        type: actionTypes.ERROR_COMPANY_SETTINGS,
                        reason: error.message,
                    });

                });
            addTask(fetchTask);
            dispatch({
                type: actionTypes.REQUEST_COMPANY_SETTINGS,
                message: Constants.CompanySettingsConstants.OverlayMessage.ApplicationLoading
            });
        }
    },

    updateCompanySettingsLocal: (isEnableIPFiltering: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: actionTypes.UPDATE_COMPANY_SETTINGS_LOCAL, isEnableIPFiltering: isEnableIPFiltering });
    },

    onSMSSigningReminderToggelChange: (enable: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
        let options: any = {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Accept': 'application/json',
                'RequestVerificationToken': (document.getElementById('RequestVerificationToken') as HTMLInputElement).value
            },
        };
        const fetchTask = fetch(API_BASE_URL + 'api/Reminder/SMSSingingReminderSettingChangeAsync?enable=' + enable, options)
            .then(handleResponse)
            .catch(error => {

            });
        addTask(fetchTask);
    },

    documentSMSSettingChangeAsync: (documentId: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        let options: any = {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Accept': 'application/json',
                'RequestVerificationToken': (document.getElementById('RequestVerificationToken') as HTMLInputElement).value
            },
        };
        const fetchTask = fetch(API_BASE_URL + 'api/Reminder/DocumentSMSSettingChangeAsync?documentId=' + documentId, options)
            .then(handleResponse)
            .catch(error => {

            });
        addTask(fetchTask);
    },


    updateCompanySettings: (companySettings: ICompanySettings, callback?: () => void, resourceId: string = ""): AppThunkAction<KnownAction> => (dispatch, getState) => {
        if (!validateCompanySettings(companySettings)) {
            dispatch({
                type: actionTypes.NOTIFICATION,
                statusMessage: Constants.CompanySettingsConstants.StatusMessage.UpdateCompanySettingsError,
                statusType: StatusType.Error
            });
        } else {

            const fetchTask = fetch(API_BASE_URL + 'api/Company/CompanySettings/', {
                method: 'PUT',
                credentials: 'include',
                headers: {
                    'Accept': 'application/json, text/plain, *',
                    'Content-Type': 'application/json; charset=utf-8',
                    'X-Resource-Id': resourceId,
                    'RequestVerificationToken': (document.getElementById('RequestVerificationToken') as HTMLInputElement).value
                },
                body: JSON.stringify(companySettings)
            })
                .then(handleResponse)
                .then((response) => {

                    if (callback) {
                        callback();
                    }

                    dispatch({ type: actionTypes.RECEIVE_COMPANY_SETTINGS, settings: companySettings });

                    dispatch({
                        type: actionTypes.NOTIFICATION,
                        statusMessage: Constants.CompanySettingsConstants.StatusMessage.UpdateCompanySettingsSuccess,
                        statusType: StatusType.Success
                    })
                })
                .catch((error) => {
                    dispatch({
                        type: actionTypes.NOTIFICATION,
                        statusMessage: Constants.CompanySettingsConstants.StatusMessage.UpdateCompanySettingsError,
                        statusType: StatusType.Error
                    });
                    dispatch({
                        type: actionTypes.ERROR_COMPANY_SETTINGS,
                        reason: error.message,
                    });
                })

            addTask(fetchTask);
            dispatch({
                type: actionTypes.REQUEST_COMPANY_SETTINGS,
                message: Constants.CompanySettingsConstants.OverlayMessage.UpdatingCompanySettings
            });
        }
    },

    requestTaxingAuthorities: (forceReload?: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
        let state = getState().companyData;
        if (!state.taxingAuthorities || forceReload) {
            //TODO: read from server

            const fetchTask = fetch(API_BASE_URL + 'api/Common/GetTaxingAuthorityAsync', { credentials: 'include' })
                .then(handleResponse)
                .then(json => json as Promise<ITaxingAuthority[]>)
                .then(data => {
                    if (data) {
                        dispatch({ type: actionTypes.RECEIVE_TAX_AUTHORITIES, taxingAuthorities: data });
                    }
                })
                .catch(error => {
                    dispatch({
                        type: actionTypes.NOTIFICATION,
                        statusMessage: Constants.CompanySettingsConstants.StatusMessage.CompanySettingsError,
                        statusType: StatusType.Error
                    })
                    dispatch({
                        type: actionTypes.ERROR_COMPANY_SETTINGS,
                        reason: error.message,
                    });

                });
            addTask(fetchTask);
            if (!forceReload) {
                dispatch({ type: actionTypes.REQUEST_TAX_AUTHORITIES, message: Constants.CompanySettingsConstants.OverlayMessage.ApplicationLoading });
            }
        }
    },

    requestCompanyLogo: (reload: boolean = false, isLogoUpdate: boolean = false): AppThunkAction<KnownAction> => (dispatch, getState) => {
        if (reload) {
            const fetchTask = fetch(SSSUITE_API_BASE_URL + '/api/logo/company-logo', {
                method: 'GET',
                credentials: 'include'
            })
                .then(handleResponse)
                .then(json => json)
                .then(data => {
                    if (data) {
                        var isSsrLogo = data.isSsrlogo == "True" ? true : false;
                        dispatch({ type: actionTypes.RECEIVE_COMPANY_LOGO, logoPath: data.logoPath, isSsrLogo: isSsrLogo });
                    }
                })
                .catch(error => {
                    dispatch({
                        type: actionTypes.NOTIFICATION,
                        statusMessage: Constants.CompanySettingsConstants.StatusMessage.CompanyLogoError,
                        statusType: StatusType.Error
                    })
                });
            addTask(fetchTask);
            dispatch({ type: actionTypes.REQUEST_COMPANY_SETTINGS, message: Constants.CompanySettingsConstants.OverlayMessage.ApplicationLoading });
        }
    },

    requestCompanyProfile: (reload: boolean = false, callback?: (companyInfo: ICompanyInfo) => void): AppThunkAction<KnownAction> => (dispatch, getState) => {
        let state = getState().companyData;
        if (reload || !state.companyProfile || state.companyProfile.companyInfo.companyName.length === 0) {
            const fetchTask = fetch(SSSUITE_API_BASE_URL + 'api/company/company-profile', {
                method: 'GET',
                credentials: 'include'
            })
                .then(handleResponse)
                .then(json => json)
                .then(data => {
                    if (data) {
                        dispatch({ type: actionTypes.RECEIVE_COMPANY_PROFILE, companyProfile: data.value.company });
                        if (callback) {
                            callback(data.value.company.companyInfo);
                        }
                    }
                })
                .catch(error => {
                    dispatch({
                        type: actionTypes.NOTIFICATION,
                        statusMessage: Constants.CompanySettingsConstants.StatusMessage.CompanyProfileError,
                        statusType: StatusType.Error
                    })
                });
            addTask(fetchTask);
            dispatch({ type: actionTypes.REQUEST_COMPANY_SETTINGS, message: Constants.CompanySettingsConstants.OverlayMessage.ApplicationLoading });
        }
    },

    requestCompanySignatureUploadLink: (callback?: () => void): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const fetchTask = fetch(SSSUITE_API_BASE_URL + '/api/Upload/company-signature-upload-link', {
            method: 'GET',
            credentials: 'include'
        })
            .then(handleResponse)
            .then(json => json)
            .then(data => {
                if (data) {
                    dispatch({ type: actionTypes.UPDATE_COMPANY_SIGNATURE, sasUrl: data.sas });
                }
                if (callback) {
                    callback();
                }
            })
            .catch(error => {
                dispatch({ type: actionTypes.ERROR_COMPANY_SETTINGS, reason: error.message });

                dispatch({
                    type: actionTypes.NOTIFICATION, statusType: StatusType.Error,
                    statusMessage: Constants.CompanySettingsConstants.StatusMessage.CompanyLogoError
                });
            });
        addTask(fetchTask);
        dispatch({
            type: actionTypes.REQUEST_COMPANY_SETTINGS, message: Constants.CompanySettingsConstants.OverlayMessage.ApplicationLoading
        });
    },

    updateReminders: (resourceId: string = "", callback?: () => void): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const fetchTask = fetch(API_BASE_URL + 'api/Company/UpdateRemindersAsync', {
            method: 'POST',
            credentials: 'include',
            headers: {
                'RequestVerificationToken': (document.getElementById('RequestVerificationToken') as HTMLInputElement).value,
                'X-Resource-Id': resourceId
            },
        }).then(handleResponse)
            .then(() => {
                if (callback) {
                    callback();
                }
            })
            .catch(error => {
                dispatch({ type: actionTypes.ERROR_COMPANY_SETTINGS, reason: error.message });

            })
        addTask(fetchTask);
    },

    requestDocumentsToexpire: (retentionPeriod: number, attestRetentionPeriod: number, callback: (count: number) => void): AppThunkAction<KnownAction> => (dispatch, getState) => {

        const fetchTask = fetch(API_BASE_URL + 'api/TaxDocument/GetDocumentsToExpire/' + retentionPeriod + '/' + attestRetentionPeriod, {
            method: 'GET',
            credentials: 'include'
        })
            .then(handleResponse)
            .then(response => response as Promise<number>)
            .then(data => {
                callback(data);

            })
            .catch(error => {
                dispatch({ type: actionTypes.ERROR_EXPIRE_TAX_DOCUMENT_COUNT, message: error });
            });
        addTask(fetchTask);
    },

    updateRetentionForAllDocuments: (defaultDuration: number, attestDuration: number): AppThunkAction<KnownAction> => (dispatch, getState) => {

        const fetchTask = fetch(API_BASE_URL + 'api/TaxDocument/UpdateRetentionPeriod/', {
            method: 'PUT',
            credentials: 'include',
            headers: {
                'Accept': 'application/json, text/plain, *',
                'Content-Type': 'application/json; charset=utf-8',
                'RequestVerificationToken': (document.getElementById('RequestVerificationToken') as HTMLInputElement).value
            },
            body: JSON.stringify({ "defaultRetention": defaultDuration, "attestRetention": attestDuration })
        })
            .then(handleResponse)
            .then(() => {
                //dispatch({
                //    type: actionTypes.NOTIFICATION, statusMessage: Constants.CompanySettingsConstants.StatusMessage.UpdateRetentionForAllDocuments,
                //    statusType: StatusType.Success
                //});
            })
            .catch(error => {
                //dispatch({ type: actionTypes.ERROR_COMPANY_SETTINGS, reason: error.message });

                dispatch({
                    type: actionTypes.NOTIFICATION, statusType: StatusType.Error,
                    statusMessage: Constants.CompanySettingsConstants.StatusMessage.UpdateRetentionPeriodError
                });
            });
        addTask(fetchTask);
    },

    forceRefreshTaxDcoumentStore: (forceReload: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: actionTypes.REFRESH_TAX_DOCUMENT_STORE, isDirty: forceReload });
    },

    validateAzureADGroup: (key: string[], callback: (groupIDs: string[]) => void, resourceId: string = ""):
        AppThunkAction<KnownAction> => (dispatch, getstate) => {
            const fetchTask = fetch(API_BASE_URL + 'api/Company/CompanySettings/ValidateAzureADGroup', {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Accept': 'application/json, text/plain, *',
                    'Content-Type': 'application/json; charset=utf-8',
                    'X-Resource-Id': resourceId,
                    'RequestVerificationToken': (document.getElementById('RequestVerificationToken') as HTMLInputElement).value
                },
                body: JSON.stringify(key)
            })
                .then(handleResponse)
                .then((data) => {
                    callback(data);
                }).catch(error => {
                    dispatch({ type: actionTypes.ERROR_COMPANY_SETTINGS, reason: error.message });

                    dispatch({
                        type: actionTypes.NOTIFICATION, statusType: StatusType.Error,
                        statusMessage: Constants.CompanySettingsConstants.StatusMessage.ADAzureExist
                    });
                });
            addTask(fetchTask);
        },

    requestCompanyWhiteLogo: (reload: boolean = false): AppThunkAction<KnownAction> => (dispatch, getState) => {

        if (reload || !getState().companyData.companyWhiteLogoSetting.isWhiteLogoDelted) {
            const fetchTask = fetch(SSSUITE_API_BASE_URL + '/api/logo/company-whitelogo', {
                method: 'GET',
                credentials: 'include'
            })
                .then(handleResponse)
                .then((data) => {

                    dispatch({ type: actionTypes.RECEIVE_COMPANY_WHITE_LOGO, whiteLogoPath: data });

                })
                .catch(error => {
                    dispatch({
                        type: actionTypes.NOTIFICATION,
                        statusMessage: Constants.BrandingConstants.StatusMessage.UpdateCompanyWhiteLogoError,
                        statusType: StatusType.Error
                    })
                });
            addTask(fetchTask);
            dispatch({ type: actionTypes.REQUEST_COMPANY_SETTINGS, message: Constants.BrandingConstants.OverlayMessage.ApplicationLoading });
        }
    },

    getMyDownloadsListAsync: (callback?: (result: any[]) => void): AppThunkAction<KnownAction> => (dispatch, getState) => {
        let options: any = {
            method: 'GET',
            credentials: 'include'
        };
        var fetchTask = fetch(API_BASE_URL + 'api/Download/GetMyDownloadsListAsync', options)
            .then(handleResponse)
            .then(response => response as Promise<any[]>)
            .then(data => {
                if (data.length > 0 && Helper.SessionStore.isExists("DownloadNowIds")) {
                    let downloadNowIds = Helper.SessionStore.get("DownloadNowIds");
                    var ids = JSON.parse(downloadNowIds) as string[];
                    let updatedDownloadNowIds: string[] = [];
                    ids.forEach((id, i) => {
                        var downoadFile = data.find(i => i.id == id);
                        if (downoadFile != undefined) {
                            if (downoadFile.statusZipDownload != StatusZipDownload.InProgress && downoadFile.statusZipDownload != StatusZipDownload.Error && downoadFile.statusZipDownload != StatusZipDownload.None) {
                                dispatch({
                                    type: actionTypes.NOTIFICATION,
                                    statusMessage: "File is ready for download",
                                    statusType: StatusType.Success
                                });
                                let downoadAction: any = actionCreators.downloadReturnFromPopup(downoadFile.jobId, downoadFile.fileName, downoadFile.storageAccountId, true, getDownloadSuccessDetails);
                                dispatch(downoadAction);
                            }
                            else {
                                updatedDownloadNowIds.push(id);
                            }
                        }
                    });

                    Helper.SessionStore.set("DownloadNowIds", JSON.stringify(updatedDownloadNowIds));
                }
                if (callback) {
                    callback(data);
                }
                dispatch({ type: actionTypes.RECEIVE_MY_DOWNLOADS_LIST, myDownloadsList: data })
            })
            .catch(error => {
                dispatch({ type: actionTypes.ERROR_COMPANY_SETTINGS, reason: error.message, });
            });
        addTask(fetchTask);
    },
    downloadReturnFromPopup: (jobId: string, fileName: string, storageAccountId: number, isDownloadNow?: boolean, callback?: (url: string) => void): AppThunkAction<KnownAction> => (dispatch, getState) => {
        let fetchTask = fetch(API_BASE_URL + '/api/Download/DownloadReturnFromPopupAsync', {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'RequestVerificationToken': (document.getElementById('RequestVerificationToken') as HTMLInputElement).value
            },
            body: JSON.stringify({
                jobId: jobId,
                fileName: fileName,
                storageAccountId: storageAccountId
            })
        })
            .then(handleResponse)
            .then(json => json)
            .then(data => {
                let displayDownloadFile = new DisplayDownloadFile();
                displayDownloadFile.directDownload(data.sas, fileName);
                fetch(API_BASE_URL + '/api/Download/UpdateStatusBulkDownLoadAsync', {
                    method: 'POST',
                    credentials: 'include',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'RequestVerificationToken': (document.getElementById('RequestVerificationToken') as HTMLInputElement).value
                    },
                    body: JSON.stringify({
                        jobId: jobId,
                        fileName: fileName
                    })
                }).then((data) => {
                    if (callback) {
                        if (isDownloadNow != null && isDownloadNow) {
                            //wait for 3 seconds for dload to fetch
                            setTimeout(function () {
                                let deleteaction: any = actionCreators.deleteMyDownloads(jobId, isDownloadNow);
                                dispatch(deleteaction);
                            }, 3000);

                        }
                    }
                })

            })
            .catch(function (error: any) {
                dispatch({
                    type: actionTypes.NOTIFICATION, statusMessage: error.response?.statusText ?? "Failed to Download the Selected File",
                    statusType: StatusType.Error
                });
            });
        addTask(fetchTask);
    },

    deleteMyDownloads: (jobId: string, isDownloadNow?: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
        let state = getState();
        let fetchTask = fetch(API_BASE_URL + 'api/Download/DeleteMyDownloadAsync?downloadId=' + jobId, {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'RequestVerificationToken': (document.getElementById('RequestVerificationToken') as HTMLInputElement).value
            }
        }).then(handleResponse)
            .then((response) => {
                let data: any = state.companyData.myDownloads;
                data.splice(data.findIndex((m: any) => m.jobId == jobId), 1);

                dispatch({ type: actionTypes.RECEIVE_MY_DOWNLOADS_LIST, myDownloadsList: data });
                if (isDownloadNow == undefined || !isDownloadNow) {
                    dispatch({
                        type: actionTypes.NOTIFICATION, statusMessage: Constants.MyDownloadsConstants.DeleteMyDownload,
                        statusType: StatusType.Success
                    });
                }
            })
            .catch(function (error: any) {
                dispatch({
                    type: actionTypes.NOTIFICATION, statusMessage: error.response?.statusText ?? "Failed to Delete the Selected File",
                    statusType: StatusType.Error
                });
                dispatch({ type: actionTypes.ERROR_COMPANY_SETTINGS, reason: error.message, });
            });
        addTask(fetchTask);
    },

    requestPaymentRegisterUrl: (paymentGateway: PaymentGateway, standardRegister: boolean, callback?: (data: any) => void): AppThunkAction<KnownAction> => (dispatch, getState) => {
        let state = getState().companyData;
        if (paymentGateway || !state.companySettings || isEqual(state.companySettings, initialCompanySettings)) {
            let fetchTask = fetch(`${API_BASE_URL}api/Company/CompanySettings/GetPaymentRegisterUrl/?paymentGateway=${paymentGateway}&standardRegister=${standardRegister}`, {
                method: 'GET',
                credentials: 'include'
            })
                .then(handleResponse)
                .then(json => json as Promise<string>)
                .then(data => {
                    if (callback && data) {
                        callback(data);
                    }
                })
                .catch(error => {
                    dispatch({
                        type: actionTypes.NOTIFICATION,
                        statusMessage: Constants.CompanySettingsConstants.StatusMessage.CompanySettingsError,
                        statusType: StatusType.Error
                    })
                    dispatch({
                        type: actionTypes.ERROR_COMPANY_SETTINGS,
                        reason: error.message,
                    });

                });
            addTask(fetchTask);
            dispatch({
                type: actionTypes.REQUEST_COMPANY_SETTINGS,
                message: Constants.CompanySettingsConstants.OverlayMessage.ApplicationLoading
            });
        }
    },
    handleDisconnect: (): AppThunkAction<KnownAction> => async (dispatch) => {
        const response = await fetch(`${API_BASE_URL}api/Company/CompanySettings/DisconnectStripe`, {
            method: 'Post',
            credentials: 'include'
        })
            .then((response) => {
                dispatch({
                    type: actionTypes.UPDATE_COMPANY_SETTINGS,
                    settings: initialCompanySettings
                })
            })
            .catch(error => {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: Constants.CompanySettingsConstants.StatusMessage.DisconnectStripeAccountError,
                    statusType: StatusType.Error
                })
            });

    },
    requestStripeEditURL: (callback?: (data: any) => void): AppThunkAction<KnownAction> => (dispatch, getState) => {
        let state = getState().companyData;

        let fetchTask = fetch(`${API_BASE_URL}api/Company/CompanySettings/GetStripeEditURL`, {
            method: 'GET',
            credentials: 'include'
        })
            .then(handleResponse)
            .then(json => json as Promise<string>)
            .then(data => {
                if (callback) {
                    callback(data);
                }
            })
            .catch(error => {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: Constants.CompanySettingsConstants.StatusMessage.StripeEditURLError,
                    statusType: StatusType.Error
                })
            });
        addTask(fetchTask);
    },
    clearAllMyDownloads: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        let state = getState();
        let fetchTask = fetch(API_BASE_URL + 'api/Download/ClearAllDownloadsAsync', {
            method: 'DELETE',
            credentials: 'include',
            headers: {
                'RequestVerificationToken': (document.getElementById('RequestVerificationToken') as HTMLInputElement).value
            }
        }).then(handleResponse)
            .then((response) => {

                let data = state.companyData.myDownloads;
                data = data.filter(function (download: any) {
                    return download.status == StatusZipDownload[StatusZipDownload.InProgress];
                });

                dispatch({ type: actionTypes.RECEIVE_MY_DOWNLOADS_LIST, myDownloadsList: data });

                dispatch({
                    type: actionTypes.NOTIFICATION, statusMessage: Constants.MyDownloadsConstants.ClearAllMyDownloads,
                    statusType: StatusType.Success
                });

            })
            .catch(function (error: any) {
                dispatch({
                    type: actionTypes.NOTIFICATION, statusMessage: error.response?.statusText ?? "Failed to Clear your Downloads",
                    statusType: StatusType.Error
                });
                dispatch({ type: actionTypes.ERROR_COMPANY_SETTINGS, reason: error.message, });
            });
        addTask(fetchTask);

    },
    updateOrganizerSettings: (settings: IOrganizerSettings, callback: () => void): AppThunkAction<KnownAction> => (dispatch, getState) => {
        let fetchTask = fetch(`${API_BASE_URL}api/Company/CompanySettings/UpdateOrganizerSettings/`, {
            method: 'PUT',
            credentials: 'include',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'RequestVerificationToken': (document.getElementById('RequestVerificationToken') as HTMLInputElement).value
            },
            body: JSON.stringify(settings)

        })
            .then(handleResponse)
            .then((response) => {
                dispatch({ type: actionTypes.UPDATE_COMPANY_SETTINGS_ORGANIZER, settings: settings });

                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: Constants.CompanySettingsConstants.StatusMessage.UpdateCompanySettingsSuccess,
                    statusType: StatusType.Success
                });

                callback();
            })
            .catch((error) => {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: Constants.CompanySettingsConstants.StatusMessage.UpdateCompanySettingsError,
                    statusType: StatusType.Error
                });
                dispatch({
                    type: actionTypes.ERROR_COMPANY_SETTINGS,
                    reason: error.message,
                });
                callback();
            });
        addTask(fetchTask);
        dispatch({
            type: actionTypes.REQUEST_COMPANY_SETTINGS,
            message: Constants.CompanySettingsConstants.OverlayMessage.UpdatingCompanySettings
        });
    },

    updateExtensionSettings: (settings: IExtensionSettings, callback: () => void): AppThunkAction<KnownAction> => (dispatch, getState) => {
        let fetchTask = fetch(`${API_BASE_URL}api/Company/CompanySettings/UpdateExtensionSettings/`, {
            method: 'PUT',
            credentials: 'include',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(settings)

        })
            .then(handleResponse)
            .then((response) => {
                dispatch({ type: actionTypes.UPDATE_COMPANY_SETTINGS_EXTENSION, settings: settings });

                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: Constants.CompanySettingsConstants.StatusMessage.UpdateCompanySettingsSuccess,
                    statusType: StatusType.Success
                });

                callback();
            })
            .catch((error) => {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: Constants.CompanySettingsConstants.StatusMessage.UpdateCompanySettingsError,
                    statusType: StatusType.Error
                });
                dispatch({
                    type: actionTypes.ERROR_COMPANY_SETTINGS,
                    reason: error.message,
                });
                callback();
            });
        addTask(fetchTask);
        dispatch({
            type: actionTypes.REQUEST_COMPANY_SETTINGS,
            message: Constants.CompanySettingsConstants.OverlayMessage.UpdatingCompanySettings
        });
    },

    requestEulaStatus: (updateStatus: (val: boolean) => void, handleLogout: () => void): AppThunkAction<KnownAction> => (dispatch, getState) => {

        const fetchTask = fetch(API_BASE_URL + '/api/Company/GetEulaStatus', {
            method: 'GET',
            credentials: 'include'
        })
            .then(handleResponse)
            .then(json => json)
            .then(data => {
                updateStatus(data);
            })
            .catch(error => {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: Constants.EulaConstant.eulaStatusfetchError,
                    statusType: StatusType.Error
                })
                handleLogout()

            });
        addTask(fetchTask);

    },
    updateEula: (eulaCurrentState: number, updatedDate: string, updateStatus: (val: boolean) => void): AppThunkAction<KnownAction> => (dispatch, getState) => {

        let updationData = {
            eulaState: eulaCurrentState,
            updatedOn: updatedDate,
            userId: 0
        };

        const fetchTask = fetch(API_BASE_URL + '/api/Company/SetEulaStatus', {
            method: 'POST',
            credentials: 'include',
            body: JSON.stringify(updationData),
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json; charset=utf-8',
                'RequestVerificationToken': (document.getElementById('RequestVerificationToken') as HTMLInputElement).value
            }
        })
            .then(handleResponse)
            .then(json => json)
            .then(data => {
                updateStatus(data);
            })
            .catch(error => {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: Constants.EulaConstant.eulaStatusUpdationFailed,
                    statusType: StatusType.Error
                })
            });
        addTask(fetchTask);

    }
    , requestEulaPdf: (updateLink: (link: string) => void): AppThunkAction<KnownAction> => (dispatch, getState) => {

        const fetchTask = fetch(API_BASE_URL + '/api/Company/GetEulaPdfLink', {
            method: 'GET',
            credentials: 'include'
        })
            .then(handleResponse)
            .then(json => json)
            .then(data => {
                updateLink(data);
            })
            .catch(error => {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: Constants.EulaConstant.eulaPdfLinkfailed,
                    statusType: StatusType.Error
                })
            });
        addTask(fetchTask);

    },
    getWalkMeScript: (callback: (script: string) => void): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const fetchTask = fetch(API_BASE_URL + '/api/Common/GetWalkMeScriptAsync', {
            method: 'GET',
            credentials: 'include'
        })
            .then(handleResponse)
            .then(json => json)
            .then(data => {
                callback(data);
            })
            .catch(error => {
            });
        addTask(fetchTask);
    },
    requestDashboardLink: (callback: (dashboardLink: string) => void): AppThunkAction<KnownAction> => (dispatch, getState) => {

        const fetchTask = fetch(API_BASE_URL + '/api/Common/GetDashboardLink', {
            method: 'GET',
            credentials: 'include'
        })
            .then(handleResponse)
            .then(data => {
                callback(data);
            })
            .catch(error => {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: Constants.CommonMessages.Error.DashboardLinkFetchFailed,
                    statusType: StatusType.Error
                })

            });
        addTask(fetchTask);

    },

    clearAccessibleProductsCache: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const fetchTask = fetch(SSSUITE_API_BASE_URL + '/api/menu/clear-acessible-products-cache', {
            method: 'DELETE',
            credentials: 'include'
        })
            .then(handleResponse)
            .then(data => {
            })
            .catch(error => {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: Constants.CommonMessages.Error.ClearingCacheFailed,
                    statusType: StatusType.Error
                })

            });
        addTask(fetchTask);
    },
};

const unloadedState: ICompanyData = {
    companySettings: initialCompanySettings,
    myDownloads: initalMyDownloadsList,
    companyLogoSetting: initialCompanyLogoSettings,
    admins: initialAdminModel,
    companyProfile: initialCompanyModel,
    signatureUploadLink: '',
    logoUploadLink: '',
    documentToExpireCount: 0,
    isDocumentStoreDirty: false,
    companyWhiteLogoSetting: initialCompanyWhiteLogoSettings,
    ssrSettings: initialSSRSettings
} as ICompanyData;

export const arrayToHash = (array: any[], id: string = 'userId') =>
    array.reduce((obj, item) => (obj[item[id]] = item, obj), {})

export const reducer: Reducer<ICompanyData> = (state = unloadedState, incomingAction) => {
    const action = incomingAction as DispatchAction;
    switch (action.type) {
        case actionTypes.REQUEST_COMPANY_SETTINGS:
            var received = { ...state };
            var unLoadedState = { ...unloadedState } as ICompanyData;
            received.taxingAuthorities = state.taxingAuthorities ? [...state.taxingAuthorities] : state.taxingAuthorities;
            received.authorityLookup = state.authorityLookup ? { ...state.authorityLookup } : state.authorityLookup,
                received.companySettings = state.companySettings ? { ...state.companySettings } : state.companySettings,
                received.isLoading = true;
            received.error = false;
            received.message = action.message;
            received.companyLogoSetting = state.companyLogoSetting;
            received.companyWhiteLogoSetting = unLoadedState.companyWhiteLogoSetting;
            return received;

        case actionTypes.RECEIVE_COMPANY_SETTINGS:
            var received = { ...state };
            received.companySettings = action.settings;
            received.companySettings.isDefault = false;
            received.isLoading = false;
            received.error = false;
            received.message = Date();
            return received;
        case actionTypes.UPDATE_COMPANY_SETTINGS_LOCAL:
            let companyData: ICompanyData = { ...state };
            let companySetting = companyData.companySettings as ICompanySettings;
            companySetting.userPrivilegeSettingsModel.enableIPFiltering = action.isEnableIPFiltering
            return companyData;
        case actionTypes.UPDATE_COMPANY_SETTINGS:
            let company: ICompanyData = { ...state };
            let companySettings = company.companySettings as ICompanySettings;
            companySettings.taxpayerPaymentSettings.companyConnectedAccountId = '';
            companySettings.taxpayerPaymentSettings.payOption = PaymentOption.ExternalSystem;
            return company;
        case actionTypes.ERROR_COMPANY_SETTINGS:
            return {
                companySettings: Object.assign({}, state.companySettings),//{...state.companySettings}
                taxingAuthorities: state.taxingAuthorities ? [...state.taxingAuthorities] : state.taxingAuthorities,
                companyLogoSetting: state.companyLogoSetting,
                admins: state.admins,
                companyProfile: state.companyProfile,
                signatureUploadLink: state.signatureUploadLink,
                logoUploadLink: state.logoUploadLink,
                authorityLookup: state.authorityLookup ? { ...state.authorityLookup } : state.authorityLookup,
                isLoading: false,
                error: true,
                message: action.reason,
                myDownloads: state.myDownloads,
                ssrSettings: Object.assign({}, state.ssrSettings)
            } as ICompanyData;

        case actionTypes.REQUEST_TAX_AUTHORITIES:
            return {
                companySettings: Object.assign({}, state.companySettings),
                companyLogoSetting: state.companyLogoSetting,
                admins: state.admins,
                companyProfile: state.companyProfile,
                signatureUploadLink: state.signatureUploadLink,
                logoUploadLink: state.logoUploadLink,
                taxingAuthorities: [],
                authorityLookup: {} as IAuthorityDictionary,
                isLoading: true,
                error: false,
                message: action.message,
                ledgerData: [],
                groupIDs: [],
                documentToExpireCount: 0,
                isAttested: false,
                isDocumentStoreDirty: false,
                companyWhiteLogoSetting: state.companyWhiteLogoSetting,
                whiteLogoUploadLink: state.whiteLogoUploadLink,
                myDownloads: state.myDownloads,
                billingDetail: [],
                paymentHistoryData: [],
                minQuantityForDiscount: [],
                ssrSettings: Object.assign({}, state.ssrSettings)
            } as ICompanyData;

        case actionTypes.RECEIVE_TAX_AUTHORITIES:
            return {
                companySettings: Object.assign({}, state.companySettings),
                companyLogoSetting: state.companyLogoSetting,
                admins: state.admins,
                companyProfile: state.companyProfile,
                signatureUploadLink: state.signatureUploadLink,
                logoUploadLink: state.logoUploadLink,
                taxingAuthorities: action.taxingAuthorities,
                authorityLookup: buildAuthorityDictionary(action.taxingAuthorities),
                isLoading: false,
                error: false,
                message: Date(),
                myDownloads: state.myDownloads,
                ssrSettings: Object.assign({}, state.ssrSettings)
            } as ICompanyData;

        case actionTypes.RECEIVE_COMPANY_LOGO:
            var received = { ...state };
            received.companyLogoSetting.logoPath = action.logoPath;
            received.companyLogoSetting.isSsrLogo = action.isSsrLogo;
            received.isLoading = false;
            received.error = false;
            received.message = Date();
            return received;

        case actionTypes.RECEIVE_COMPANY_PROFILE:
            var received = { ...state };
            received.companyProfile = action.companyProfile;
            received.admins.adminId = action.companyProfile.companyInfo.adminUser;
            received.isLoading = false;
            received.error = false;
            received.message = Date();
            return received;

        case actionTypes.UPDATE_COMPANY_SIGNATURE:
            var received = { ...state };
            received.signatureUploadLink = action.sasUrl;
            received.isLoading = false;
            received.error = false;
            received.message = Date();
            return received;

        case actionTypes.REQUEST_COMPANY_LEDGER:
            var received = { ...state };
            received.ledgerData = [];
            return received;

        case actionTypes.RECEIVE_COMPANY_LEDGER:
            var received = { ...state };
            received.ledgerData = action.ledgerData;
            return received;

        case actionTypes.VALIDATE_AD_AZURE_GROUP:
            var received = { ...state };
            received.groupIDs = action.groupIDs;
            return received;

        case actionTypes.REFRESH_TAX_DOCUMENT_STORE:
            let companyState: ICompanyData = { ...state };
            companyState.isDocumentStoreDirty = action.isDirty;
            return companyState;

        case actionTypes.ERROR_EXPIRE_TAX_DOCUMENT_COUNT:
            let expireErrorState: ICompanyData = { ...state };
            expireErrorState.documentToExpireCount = 0;
            expireErrorState.error = true;
            expireErrorState.message = action.message;
            return expireErrorState;

        case actionTypes.RECEIVE_COMPANY_WHITE_LOGO:
            var received: ICompanyData = { ...state };
            received.companyWhiteLogoSetting.whiteLogoPath = action.whiteLogoPath;
            received.isLoading = false;
            received.error = false;
            received.message = Date();
            return received;
        case actionTypes.UPDATE_COMPANY_SETTINGS_ORGANIZER:
            var received: ICompanyData = { ...state };
            var receivedCompanySetting = received.companySettings as ICompanySettings;
            receivedCompanySetting.organizerSettings = action.settings;
            received.isLoading = false;
            return received;

        case actionTypes.UPDATE_COMPANY_SETTINGS_EXTENSION:
            var received: ICompanyData = { ...state };
            var receivedCompanySetting = received.companySettings as ICompanySettings;
            receivedCompanySetting.extensionSettings = action.settings;
            received.isLoading = false;
            return received;

        case actionTypes.RECEIVE_MY_DOWNLOADS_LIST:
            var received = { ...state };
            received.myDownloads = action.myDownloadsList
            return received;
        case actionTypes.REQUEST_SSR_SETTINGS:
            var received = { ...state };
            received.error = false;
            received.message = action.message;
            return received;

        case actionTypes.RECEIVE_SSR_SETTINGS:
            var received = { ...state };
            received.ssrSettings = action.settings;
            received.ssrSettings.isDefault = false;
            received.isLoading = false;
            received.error = false;
            received.message = Date();
            return received;
    }

    return state;
};


function buildAuthorityDictionary(authorities: ITaxingAuthority[]): IAuthorityDictionary {
    let ret: IAuthorityDictionary = {} as IAuthorityDictionary;
    if (authorities.length > 0) {
        authorities.map((authority, i) => {
            ret[authority.Id] = authority;
        });
    }

    return ret;
}
function getDownloadSuccessDetails(data: any) {

}
