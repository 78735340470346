import * as React from "react";
import { IClientHubClientTrackingModel, ITaxReturn } from "../../common/TaxReturn";
import { FC, useState } from "react";
import { Modal } from "react-bootstrap";
import { Tabs } from "react-bootstrap";
import { Tab } from "react-bootstrap";
import { BaseClientTrackingReport } from "./BaseClientTrackingReport";
import { InternalUserTracking } from "./InternalUserTracking";
import { DeliveredReturnReportResourceId } from "../../helper/ResourceIdConstants";
import { IUserResources } from "../../../store/userManagement/UserStore";
import { ControllerTracking } from "./ControllerTracking";

interface IEventTrackingModalProps {
    show: boolean;
    onCancel: () => void;
    resourceId?: string;
    taxReturn: ITaxReturn;
    parentResourceGridIdentifier: string;
    userResources: IUserResources;
    clientHubEvents: IClientHubClientTrackingModel[];
}

const EventTrackingModal: FC<IEventTrackingModalProps> = (props) => {
    const { show, onCancel, taxReturn, parentResourceGridIdentifier, userResources, clientHubEvents } = props;

    const [key, setKey] = useState<string | null>("client_tracking");
    const [rbacResourceId, setRbacResourceId] = useState<string>(
        parentResourceGridIdentifier + DeliveredReturnReportResourceId.TabInternalTracking
    );

    React.useEffect(() => {
        if (taxReturn && taxReturn.groupId > 0) {
            setKey("controller_tracking");
        }
    }, [taxReturn, taxReturn?.groupId]);

    const handleTabSelect = (key: string | null) => {
        setKey(key);
    };

    const handleModalClose = () => {
        setKey("client_tracking");
        onCancel();
    };

    return (
        <Modal show={show} className="user_tracking_modal" onHide={handleModalClose}>
            <Modal.Header closeButton>
                <Modal.Title>User Tracking History</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Tabs className="custom-tab" fill id="user_tracking" activeKey={key} onSelect={(key) => handleTabSelect(key)}>
                    {taxReturn && taxReturn.groupId > 0 && (
                        <Tab
                            eventKey="controller_tracking"
                            title={
                                <div>
                                    <i className="fas fa-user-clock"></i>
                                    <span className="ml-2">Controller Tracking</span>
                                </div>
                            }
                            style={{ overflowX: "hidden" }}
                        >
                            <div style={{ marginTop: "12px" }}>
                                <ControllerTracking taxReturn={taxReturn} show={show} />
                            </div>
                        </Tab>
                    )}
                    <Tab
                        eventKey="client_tracking"
                        title={
                            <div>
                                <i className="fas fa-user-clock"></i>
                                <span className="ml-2">Client Tracking</span>
                            </div>
                        }
                        style={{ overflowX: "hidden" }}
                    >
                        <div style={{ marginTop: "12px" }}>
                            <BaseClientTrackingReport taxReturn={taxReturn} clientHubEvents={clientHubEvents} show={show} />
                        </div>
                    </Tab>
                    {userResources.resourceIds.includes(rbacResourceId) && taxReturn && (
                        <Tab
                            eventKey="internal_tracking"
                            title={
                                <div>
                                    <i className="fas fa-house-user"></i>
                                    <span className="ml-2">Internal Tracking</span>
                                </div>
                            }
                            style={{ overflowX: "hidden" }}
                        >
                            <div style={{ marginTop: "12px" }}>
                                <InternalUserTracking taxReturn={taxReturn} show={show} />
                            </div>
                        </Tab>
                    )}
                </Tabs>
            </Modal.Body>
        </Modal>
    );
};

export default EventTrackingModal;
