import * as React from 'react';
import { Button } from 'react-bootstrap';
import { Modal } from 'react-bootstrap';
import { VariableList } from '../../../settings/ClientInstructions/VariableList';
import { CommonMessages } from '../../../../components/helper/Constants';
import LexicalEditorComponent from '../../../settings/SavedMessage/LexicalEditor';

interface PaperFileMessageProps {
    showState: boolean;
    onHide: () => void;
    changeStateEditorBody: (event: any) => void;
    messageBody: string;
    isAllowEdit: boolean;
    updateButtonClick: (event: any) => void;
    saveButtonClick: (event: any) => void;
    buttonText: string;
    isGroupReturn?: boolean;
}

export class PaperFileMessage extends React.Component<PaperFileMessageProps, {}>{
    public render() {
        return (
            <Modal className="preparer-message" show={this.props.showState} onHide={this.props.onHide}>
                <Modal.Header closeButton>
                    <Modal.Title>Paper File Instructions</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="padLR-2">
                        {/* <TinyMCEComponent
                            changeStateTinymceBody={this.props.changeStateTinymceBody}
                            messageBody={this.props.messageBody}
                            readOnly={this.props.isAllowEdit == true ? 0 : 1}
                            placeholderText={CommonMessages.PlaceholderText}
                        ></TinyMCEComponent> */}
                        <LexicalEditorComponent
                            readOnly={this.props.isAllowEdit == true ? 0 : 1}
                            changeStateEditorBody={this.props.changeStateEditorBody}
                            messageBody={this.props.messageBody}
                            placeholderText={CommonMessages.PlaceholderText}
                        />
                    </div>
                    {
                        this.props.isAllowEdit && <div className="marT10">
                            <VariableList isGroupReturn={this.props.isGroupReturn} />
                        </div>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <span className="modal-footer-left float-left padT07">
                        <span className="fas fa-asterisk req_star_txt"></span>
                        <span> Required</span>
                    </span>
                    <Button
                        className="btn-white"
                        variant='default'
                        data-test-auto="A17E2D70-9AA8-4269-9E8A-DBC2D1331D46"
                        onClick={this.props.onHide} >
                        <i className="fas fa-times"></i>
                    Cancel
                  </Button>
                    {this.props.buttonText == "Save" ?
                        <Button
                            variant='info'
                            data-test-auto="C433FD34-6519-4765-9430-E7C8438B4071"
                            onClick={this.props.saveButtonClick}
                            className={this.props.isAllowEdit == true ? "" : "display-button"}
                        >
                            <i className='fas fa-save'></i>{this.props.buttonText}
                        </Button>
                        :
                        <Button
                            variant='info'
                            data-test-auto="4B3D6A22-BE01-4329-B206-DE0EA1DDFB31"
                            onClick={this.props.updateButtonClick}
                            className={this.props.isAllowEdit == true ? "" : "display-button"}
                        >
                            <i className='fas fa-save'></i>{this.props.buttonText}
                        </Button>
                    }

                </Modal.Footer>
            </Modal>
        )
    }
}
