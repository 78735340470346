import * as React from 'react';
import { ProcessReturnConstants } from "../helper/Constants";
import { Button, Modal } from 'react-bootstrap';
import { ITaxReturn } from './TaxReturn';

interface ImportantModalProps {
    showImportantPopup: boolean;
    onCancel: () => void;
    taxReturn: ITaxReturn;
    onSubmit: (taxReturn: ITaxReturn) => void;
}

export class ImportantModal extends React.Component<ImportantModalProps, {}> {
    constructor(props: ImportantModalProps) {
        super(props);
    }

    private onSubmit = () => {
        this.props.onSubmit(this.props.taxReturn);
    }

    public render() {
        return <Modal
            className="action-modal"
            show={this.props.showImportantPopup}
            onHide={this.props.onCancel}>
            <Modal.Header closeButton>
                <Modal.Title>
                    Important!
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div dangerouslySetInnerHTML={{ __html: ProcessReturnConstants.ImportantReturnMessage }}> 
                </div> 
            </Modal.Body>
            <Modal.Footer>
                <Button
                    className="noBtn"
                    onClick={this.props.onCancel}
                >No</Button>
                <Button
                    className="yesBtn"
                    onClick={this.onSubmit}
                >Yes</Button> 
            </Modal.Footer>
        </Modal>
    }
}
