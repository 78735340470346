var htmlencode = require('htmlencode');
const isEqual = require("react-fast-compare");
import * as bootbox from 'bootbox';
import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { MessageDetails, initialMessageDetails } from '../../Core/ViewModels/CompanySettings/SavedMessageViewModel';
import { MessagePopUp } from './SavedMessage/MessagePopUp';
import { MessageList } from './SavedMessage/MessageList';
import * as SavedMessageStore from '../../store/settings/SavedMessageStore';
import * as CompanyStore from '../../store/company/CompanyStore';
import * as Constants from '../helper/Constants';
import { Button } from 'react-bootstrap';
import { MessageView } from '../settings/SavedMessage/MessageView'
import { OverlayLoader } from '../helper/OverlayLoader';
import { VenusNotifier } from '../helper/VenusNotifier';
import * as UserSettingsStore from '../../store/userManagement/UserSettingStore';
import { AttachmentInstruction } from './SavedMessage/AttachmentInstruction/AttachmentInstruction';
import * as AttachmentInstructionStore from '../../store/settings/AttachmentInstructionStore';
import { initialCompanySettings } from '../../Core/ViewModels/Company/CompanySettingsViewModel';
import * as ApplicationHelptextStore from '../../store/common/ApplicationHelptextStore';
import * as PaperFiledMessageStore from '../../store/settings/PaperFiledMessageStore';
import { PaperFiled } from './SavedMessage/PaperFiled/PaperFiled';
import { initialSSRSettings, SSRSettings } from '../../Core/ViewModels/Company/Settings/SSRSettingsViewModel';
import * as SSRSettingsStore from '../../store/company/settings/SSRSettingsStore';
import { TaxReturnSettingsResources, InstructionsActionButtonResources } from '../../components/helper/ResourceIdConstants';

export interface SavedMessageState {
    showAddMessagePopUp: boolean;
    selectedMessageId: number;
    defaultMessageId: number;
    showLoader: boolean;
    loaderText: string;
    buttonText: string;
    isUpdateMessage: boolean;
    title: string;
    showLoaderPopUp: boolean;
    showPaperFiled: boolean;
    resourceId?: string
}

type SavedMessageProps = {
    savedMessageList: SavedMessageStore.SavedMessageStoreState,
    companyData: CompanyStore.ICompanyData,
    attachmentInstructionList: AttachmentInstructionStore.IAttachmentInstructionState,
    helptexts: ApplicationHelptextStore.IHelptextState,
    paperFiledMessageList: PaperFiledMessageStore.PaperFiledMessageStoreState,
}
    & typeof SavedMessageStore.actionCreators
    & typeof CompanyStore.actionCreators
    & typeof UserSettingsStore.actionCreators
    & typeof AttachmentInstructionStore.actionCreators
    & typeof ApplicationHelptextStore.actionCreators
    & typeof PaperFiledMessageStore.actionCreators
    & typeof SSRSettingsStore.actionCreators
    & RouteComponentProps<{ page: string }>;

export class SavedMessages extends React.Component<SavedMessageProps, SavedMessageState> {
    constructor(props: SavedMessageProps) {
        super(props);
        this.state = {
            showAddMessagePopUp: false,
            selectedMessageId: 0,
            defaultMessageId: 0,
            showLoader: false,
            loaderText: "",
            buttonText: "",
            isUpdateMessage: false,
            title: "",
            showLoaderPopUp: false,
            showPaperFiled: false,
        }
        this.closeAddPopUp = this.closeAddPopUp.bind(this);

        this.onListItemClick = this.onListItemClick.bind(this);

        this.addButtonClick = this.addButtonClick.bind(this);
        this.updateButtonClick = this.updateButtonClick.bind(this);
        this.deleteButtonClick = this.deleteButtonClick.bind(this);

        this.openAddMessagePopUp = this.openAddMessagePopUp.bind(this);
        this.openEditMessagePopUp = this.openEditMessagePopUp.bind(this);
    }

    componentDidMount() {
        this.props.requestSavedMessages(false);
        this.props.requestCompanySettings(false);
        this.props.requestAllSSRSettings(false);
        this.props.requestUserSettings(true);
        this.props.requestAttachmentInstructions();
        this.props.requestPaperFiledMessages(false);
        this.setState({ showLoader: true, loaderText: "Loading..." })

        //If Redux already has these then componentWillReceiveProps won't get called on load
        if (this.props.companyData.companySettings) {
            this.setState({
                selectedMessageId: this.props.companyData.ssrSettings.defaultMessageSettings.savedMessage,
                defaultMessageId: this.props.companyData.ssrSettings.defaultMessageSettings.savedMessage,
                showPaperFiled: this.props.companyData.companySettings.displaySettingsModel.isEnablePaperFiled,
            })
        }
        //Loader Hide if no backend call needed, componentWillReceiveProps won't get called first time
        if (this.props.companyData.companySettings && this.props.savedMessageList) {
            this.setState({ showLoader: false })
        }
        this.props.requestHelptextsForApplication(true);

        const element = document.getElementById('content-wrapper');
        element && element.scrollTo(0, 0);
    }

    UNSAFE_componentWillReceiveProps(nextProps: SavedMessageProps) {
        if (!isEqual(this.props, nextProps)) {
            this.setState({
                selectedMessageId: nextProps.companyData.companySettings ? nextProps.companyData.ssrSettings.defaultMessageSettings.savedMessage : 0,
                defaultMessageId: nextProps.companyData.companySettings ? nextProps.companyData.ssrSettings.defaultMessageSettings.savedMessage : 0,
                showPaperFiled: nextProps.companyData.companySettings ? nextProps.companyData.companySettings.displaySettingsModel.isEnablePaperFiled : false,
            })
        }
        //If No change in Data, but clicked save/update. No change in props
        this.setState({
            showLoader: !nextProps.savedMessageList.isLoading && !nextProps.companyData.isLoading ? false : true,
            showLoaderPopUp: !nextProps.savedMessageList.isLoading && !nextProps.companyData.isLoading ? false : true,

        }, () => { if (!this.state.showLoaderPopUp) this.setState({ showAddMessagePopUp: false }) })
    }

    //===============List Action Button Start===========

    private addButtonClick(messageDetail: MessageDetails, isSetDefault: boolean, isToggled: boolean) {
        let companySettings = this.props.companyData.companySettings;
        if (companySettings) {
            messageDetail.body = htmlencode.htmlEncode(messageDetail.body)
            this.props.saveMessageDetail(messageDetail, companySettings, isSetDefault, TaxReturnSettingsResources.SavedMessagesPrefix + TaxReturnSettingsResources.SavedMessages + InstructionsActionButtonResources.ButtonAdd);
            this.setState({ selectedMessageId: this.state.defaultMessageId, showLoaderPopUp: true })
        } else {
            VenusNotifier.Error(Constants.SavedMessageConstants.SaveMessageError, null);
        }
    }

    private updateButtonClick(messageDetail: MessageDetails, isSetDefault: boolean, isToggled: boolean) {
        let ssrSettings = new SSRSettings(this.props.companyData.ssrSettings.defaultMessageSettings);
        if (ssrSettings) {
            if (isToggled) {
                if (isSetDefault && ssrSettings.defaultMessageSettings) {
                    ssrSettings.defaultMessageSettings.savedMessage = messageDetail.id;
                    this.props.updateSSRSettings(ssrSettings, undefined, TaxReturnSettingsResources.SavedMessagesPrefix + TaxReturnSettingsResources.SavedMessages + InstructionsActionButtonResources.ButtonEdit);
                } else {
                    //Default message shouldnot be turned off
                    VenusNotifier.Warning(Constants.SavedMessageConstants.SelectDefaultMessageWarning, null);
                    return false;
                }
            }
            messageDetail.body = htmlencode.htmlEncode(messageDetail.body)
            this.props.updateMessageDetail(messageDetail, TaxReturnSettingsResources.SavedMessagesPrefix + TaxReturnSettingsResources.SavedMessages + InstructionsActionButtonResources.ButtonEdit);
            this.setState({
                isUpdateMessage: false,
                showLoaderPopUp: true
            })
        } else {
            VenusNotifier.Error(Constants.SavedMessageConstants.UpdateMessageError, null);
        }
    }

    private deleteButtonClick() {
        if (this.state.selectedMessageId == this.state.defaultMessageId) {
            bootbox.alert({
                message: Constants.SavedMessageConstants.DeleteMessageWarning,
                size: 'small',
                buttons: {
                    ok: {
                        label: '<i class="fas fa-check"></i> OK',
                        className: 'btn btn-success'
                    }
                }
            });
        }
        else {
            let _currentObject = this
            bootbox.confirm({
                title: 'Delete Message',
                message: 'Are you sure you want to delete this message?',
                buttons: {
                    cancel: {
                        label: '<i class="fas fa-times"></i> Cancel',
                        className: 'btn-white btn-default'
                    },
                    confirm: {
                        label: '<i class="fas fa-check"></i> Delete',
                        className: 'btn-info'
                    }
                },
                callback: (result: boolean) => {
                    if (result == true) {
                        _currentObject.props.deleteMessageDetail(_currentObject.props.savedMessageList.data[_currentObject.state.selectedMessageId].messageDetails,
                            TaxReturnSettingsResources.SavedMessagesPrefix + TaxReturnSettingsResources.SavedMessages + InstructionsActionButtonResources.ButtonDelete);
                        _currentObject.setState({ selectedMessageId: _currentObject.state.defaultMessageId, showLoader: true, loaderText: "Deleting message" })
                    }
                }
            });
        }
    }

    private openAddMessagePopUp() {
        this.setState({
            showAddMessagePopUp: true,
            buttonText: "Add",
            isUpdateMessage: false,
            title: "Add Saved Message",
            loaderText: "Saving message",            
        });
    }

    private closeAddPopUp() {
        this.setState({ showAddMessagePopUp: false, isUpdateMessage: false });
    }

    private openEditMessagePopUp() {
        this.setState({
            showAddMessagePopUp: true,
            buttonText: "Save",
            isUpdateMessage: true,
            title: "Edit Saved Message",
            loaderText: "Saving message"
        });
    }

    private onListItemClick(messageId: number) {
        this.setState({
            selectedMessageId: messageId
        });
    }

    public render() {
        return <div className="font14" style={{ position: 'initial', marginTop: '20px' }} >
            <OverlayLoader show={this.state.showLoader} text={this.state.loaderText} />
            <h3>Saved Messages</h3>
            <hr />

            <div className="row">
                <div className="col-lg-4 col-sm-6" style={{ marginLeft: 17 }}>
                    <div className="row dispFR">
                        <div className="float-right">
                            <Button
                                variant='info'
                                data-resource-id = { TaxReturnSettingsResources.SavedMessagesPrefix + TaxReturnSettingsResources.SavedMessages + InstructionsActionButtonResources.ButtonAdd}
                                size='lg'
                                style={{ padding: 12, minWidth: 40 }}
                                title="Add Message"
                                onClick={this.openAddMessagePopUp}>
                                <span className="fas fa-plus"/>
                            </Button>
                        </div>
                    </div>

                    <div className="row" style={{ marginTop: 10 }}>
                        <div className="width100">
                            <MessageList
                                messageDetails={this.props.savedMessageList.data ? this.props.savedMessageList.data : []}
                                onListItemClick={this.onListItemClick}
                                selectedMessageId={this.props.savedMessageList.data && this.props.savedMessageList.data[this.state.selectedMessageId] ?
                                    this.props.savedMessageList.data[this.state.selectedMessageId].messageDetails?.id : 0}
                                defaultMessageId={this.state.defaultMessageId}
                            />
                        </div>
                    </div>
                </div>

                <div className="col-lg-5 col-sm-6" style={{ marginLeft: 32 }}>
                    <div className="row dispFR">
                        <div className="float-right">
                            <Button
                                variant="info"
                                data-resource-id = { TaxReturnSettingsResources.SavedMessagesPrefix + TaxReturnSettingsResources.SavedMessages + InstructionsActionButtonResources.ButtonEdit}
                                bsPrefix="btn btn-icon-default btn-lg fas fa-edit"
                                style={{ padding: 12, minWidth: 40 }}
                                title="Edit Message"
                                onClick={this.openEditMessagePopUp}>
                            </Button>
                            <Button
                                variant="info"
                                data-resource-id = { TaxReturnSettingsResources.SavedMessagesPrefix + TaxReturnSettingsResources.SavedMessages + InstructionsActionButtonResources.ButtonDelete}
                                bsPrefix="btn btn-icon-default btn-lg fas fa-trash-alt"
                                title="Delete Message"
                                style={{ marginLeft: 5, padding: 12, minWidth: 40 }}
                                onClick={this.deleteButtonClick}>
                            </Button>
                        </div>
                    </div>

                    <div className="row" style={{ marginTop: 10 }}>
                        <div className="width100">
                            <MessageView
                                selectedMessageDetails={this.props.savedMessageList.data == undefined ||
                                    this.props.savedMessageList.data[this.state.selectedMessageId == 0 ? parseInt(Object.keys(this.props.savedMessageList.data)[0]) : this.state.selectedMessageId] == undefined ?
                                    initialMessageDetails : this.props.savedMessageList.data[this.state.selectedMessageId == 0 ? parseInt(Object.keys(this.props.savedMessageList.data)[0]) : this.state.selectedMessageId]?.messageDetails}
                                defaultMessageId={this.state.defaultMessageId}
                            />
                        </div>
                    </div>
                </div>
            </div>

            <MessagePopUp
                onShow={this.state.showAddMessagePopUp}
                onHide={this.closeAddPopUp}
                SubmitButtonClick={this.state.isUpdateMessage ? this.updateButtonClick : this.addButtonClick}
                buttonText={this.state.buttonText}
                selectedMessageDetail={this.state.isUpdateMessage ? this.props.savedMessageList.data == undefined || this.props.savedMessageList.data[this.state.selectedMessageId == 0 ?
                    parseInt(Object.keys(this.props.savedMessageList.data)[0]) : this.state.selectedMessageId] == undefined ?
                    initialMessageDetails : this.props.savedMessageList.data[this.state.selectedMessageId == 0 ?
                        parseInt(Object.keys(this.props.savedMessageList.data)[0]) : this.state.selectedMessageId]?.messageDetails : initialMessageDetails}
                title={this.state.title}
                defaultMessageId={this.state.defaultMessageId}
                isUpdate={this.state.isUpdateMessage}
                messageList={this.props.savedMessageList}
                loadertext={"Saving message"}
                showLoader={this.state.showLoaderPopUp}
                helptexts={this.props.helptexts.helpTexts}
            />

            <AttachmentInstruction
                attachmentInstructions={this.props.attachmentInstructionList ? this.props.attachmentInstructionList.attachmentInstructions : []}
                companySettings={this.props.companyData.companySettings ? this.props.companyData.companySettings : initialCompanySettings}
                saveAttachmentInstruction={this.props.saveAttachmentInstruction}
                updateAttachmentInstruction={this.props.updateAttachmentInstruction}
                deleteAttachmentInstruction={this.props.deleteAttachmentInstruction}
                updateCompanySettings={this.props.updateCompanySettings}
                helptexts={this.props.helptexts.helpTexts}
                updateSSRSettings={ this.props.updateSSRSettings }
                ssrSettings={this.props.companyData ? this.props.companyData.ssrSettings : initialSSRSettings }
                parentResourceIdentifier = { TaxReturnSettingsResources.SavedMessagesPrefix + TaxReturnSettingsResources.AttachmentInstructions }
            />
            {this.state.showPaperFiled &&
                <PaperFiled
                    paperFiledMessages={this.props.paperFiledMessageList ? this.props.paperFiledMessageList.data : []}
                    companySettings={this.props.companyData.companySettings ? this.props.companyData.companySettings : initialCompanySettings}
                    saveMessage={this.props.savePaperFiledMessageDetail}
                    updateMessage={this.props.updatePaperFiledMessageDetail}
                    deleteMessage={this.props.deletePaperFiledMessageDetail}
                    updateCompanySettings={this.props.updateCompanySettings}
                    helptexts={this.props.helptexts.helpTexts}
                    updateSSRSettings={this.props.updateSSRSettings}
                    ssrSettings={this.props.companyData ? this.props.companyData.ssrSettings : initialSSRSettings}
                    parentResourceIdentifier = { TaxReturnSettingsResources.SavedMessagesPrefix + TaxReturnSettingsResources.PaperFileInstructions }
                />
            }

        </div >
    }
}

