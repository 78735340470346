import * as React from "react";
import { Card } from "react-bootstrap";
import Select from "react-select";
import { prepareStateDropdown, FilterSSN, handleInvalidCountryCodeEntered } from "../helper/HelperFunctions";
import { ClientInfoProperty } from "./ClientInfoEnum";
import { CustomDatePicker } from "./controls/CustomDatePicker";
import { ITaxpayer } from "./TaxClient";
import { SignatureStatus, ITaxReturn, ClientTypesNumber, DocumentStatus } from "./TaxReturn";
import { ICountryState } from "../../Core/ViewModels/Company/CompanyViewModel";
import { TaxIdentificationNumber } from "../common/controls/TaxIdentificationNumber";
import { VenusNotifier } from "../helper/VenusNotifier";
import { ClientInfoTab } from "../helper/Constants";
import ToggleSwitch from "../common/ToggleSwitch";
import { ISMSNotificationDetails } from "../../store/common/SMSNotificationDetailsStore";
import { Link } from "react-router-dom";
import "@sssuite-component-ui/react-phone-input-2/lib/style.css";
import PhoneInput, { CountryData } from "@sssuite-component-ui/react-phone-input-2";

let dateMoment = require("moment");

export interface ISpouseProp {
    model: ITaxpayer;
    handleValueChange(item: ClientInfoProperty, value: string): void;
    states: ICountryState[];
    taxReturn: ITaxReturn;
    isMandatory: boolean;
    showSMSTextNotifications: boolean;
    isSpouseOpted: boolean;
    smsNotificationDetails: ISMSNotificationDetails;
    updateSpouseOpted: (spouseOpted: boolean) => void;
}

export interface ISpouseState {
    isDisabled: boolean;
    isDeliveredToTaxCaddy: boolean;
    mobileNumberFullValue?: string;
}

export class Spouse extends React.Component<ISpouseProp, ISpouseState> {
    state = {
        isDisabled: false,
        isDeliveredToTaxCaddy: false,
        mobileNumberFullValue: undefined
    };
    UNSAFE_componentWillReceiveProps(nextprops: ISpouseProp) {
        if (nextprops.taxReturn.signedDetails != undefined) {
            const taxReturnStatus = parseInt(SignatureStatus[this.props.taxReturn.signatureStatus]);
            let isDisabled: boolean =
                (taxReturnStatus === SignatureStatus.PartiallySigned &&
                this.props.taxReturn.signedDetails != undefined &&
                this.props.taxReturn.signedDetails.filter((x) => x.signerType === ClientTypesNumber.Spouse)[0] != undefined
                    ? this.props.taxReturn.signedDetails.filter((x) => x.signerType === ClientTypesNumber.Spouse)[0].isSigned
                    : false) ||
                taxReturnStatus === SignatureStatus.ESigned ||
                taxReturnStatus === SignatureStatus.ESignedInOffice;

            let isDeliveredToTaxCaddy: boolean = nextprops.taxReturn.documentStatus === DocumentStatus.DELIVEREDTOTAXCADDY;
            this.setState({ isDisabled: isDisabled, isDeliveredToTaxCaddy: isDeliveredToTaxCaddy });
        }
    }

    private handleChangeDoB = (event: any) => {
        let now = new Date();
        if (dateMoment(event) > now) {
            VenusNotifier.Warning(ClientInfoTab.AddedRefund.DOBWarning, null);
        } else this.props.handleValueChange(ClientInfoProperty.spouseDateOfBirth, event);
    };

    private handleSpouseToggleSwitchChange = (event: any) => {
        this.props.updateSpouseOpted(event.target.checked);
    };

    onMobileChange = (fullValue: string, countryObject: CountryData): void => {
        fullValue = handleInvalidCountryCodeEntered(fullValue, countryObject);
        this.props.handleValueChange(ClientInfoProperty.spouseCountryCode, fullValue ? `+${countryObject.dialCode}` : "");
        this.props.handleValueChange(ClientInfoProperty.spouseMobileNumber, fullValue.slice(countryObject.dialCode.length));
        this.setState({ mobileNumberFullValue: fullValue });
    };

    public render() {
        if (!this.props.model) {
            return <div className="col-12"></div>;
        }
        return (
            <div className="col-12">
                <Card style={{ border: "none" }}>
                    <Card.Header style={{ border: "1px solid rgba(0,0,0,.125)" }}>
                        <b>Spouse</b>
                    </Card.Header>
                    <Card.Body>
                        <div className="row marL0">
                            <div className="col-sm-12 col-md-12 col-xl-12">
                                <div className="row">
                                    <div className="col-sm-2 col-md-2 col-xl-2 padT07 tpLabelFont">
                                        Name<span className="fas fa-asterisk compulsory-star"></span>
                                    </div>
                                    <div className="col-sm-10 col-md-10 col-xl-10 marB10 padR25">
                                        <input
                                            type="text"
                                            className="form-control"
                                            value={this.props.model.name}
                                            onChange={(event: any) => {
                                                this.props.handleValueChange(ClientInfoProperty.spouseName, event.target?.value);
                                            }}
                                            placeholder="First Name"
                                            data-test-auto="AC0D9968-4790-45C4-96D8-36A91CDC8205"
                                            disabled={this.state.isDeliveredToTaxCaddy}
                                        ></input>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row marL0">
                            <div className="col-sm-12 col-md-12 col-xl-12">
                                <div className="row">
                                    <div className="col-sm-2 col-md-2 col-xl-2 padT07 tpLabelFont">
                                        Email
                                        <span className={this.props.isMandatory ? "fas fa-asterisk compulsory-star" : ""}></span>
                                    </div>
                                    <div className="col-sm-10 col-md-10 col-xl-10 marB10 padR25">
                                        <input
                                            type="text"
                                            className="form-control"
                                            value={this.props.model.email || ""}
                                            onChange={(event: any) => {
                                                this.props.handleValueChange(ClientInfoProperty.spouseEmail, event.target?.value);
                                            }}
                                            placeholder="Email"
                                            data-test-auto="DEC0751D-2D77-48A8-A958-5124E7D7D6C4"
                                            disabled={this.state.isDeliveredToTaxCaddy}
                                        ></input>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row marL0">
                            <div className="col-sm-12 col-md-12 col-xl-12">
                                <div className="row">
                                    <div className="col-sm-2 col-md-2 col-xl-2 padT07 tpLabelFont">Address</div>
                                    <div className="col-sm-10 col-md-10 col-xl-10 marB10 padR25">
                                        <input
                                            type="text"
                                            className="form-control"
                                            value={this.props.model.address}
                                            onChange={(event: any) => {
                                                this.props.handleValueChange(
                                                    ClientInfoProperty.spouseAddress,
                                                    event.target?.value
                                                );
                                            }}
                                            placeholder="Address"
                                            data-test-auto="7E173A8F-EF54-4959-8828-CA8767884A5B"
                                            disabled={this.state.isDeliveredToTaxCaddy}
                                        ></input>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row marL0">
                            <div className="col-sm-6 col-md-6 col-xl-6">
                                <div className="row">
                                    <div className="col-sm-4 col-md-4 col-xl-4 padT07 tpLabelFont">City</div>
                                    <div className="col-sm-8 col-md-8 col-xl-8 marB10">
                                        <input
                                            type="text"
                                            className="form-control"
                                            value={this.props.model.city}
                                            onChange={(event: any) => {
                                                this.props.handleValueChange(ClientInfoProperty.spouseCity, event.target?.value);
                                            }}
                                            placeholder="City"
                                            data-test-auto="490B543F-D8FA-4FA4-A3D1-6755D41C0500"
                                            disabled={this.state.isDeliveredToTaxCaddy}
                                        ></input>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6 col-md-6 col-xl-6">
                                <div className="row">
                                    <div className="col-sm-4 col-md-4 col-xl-4 padT07 tpLabelFont">State</div>
                                    <div className="col-sm-8 col-md-8 col-xl-8 marB10 padR25">
                                        <Select
                                            name="form-field-edit-info-state-spouse"
                                            className="editClientInfo_dropdown"
                                            value={this.props.model.state}
                                            onChange={(event: any) => {
                                                this.props.handleValueChange(ClientInfoProperty.spouseState, event?.value);
                                            }}
                                            options={prepareStateDropdown(this.props.states)}
                                            data-test-auto="1F29729F-075F-422F-92F9-EC51033A44F5"
                                            clearable={false}
                                            disabled={this.state.isDeliveredToTaxCaddy}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row marL0">
                            <div className="col-sm-6 col-md-6 col-xl-6">
                                <div className="row">
                                    <div className="col-sm-4 col-md-4 col-xl-4 padT07 tpLabelFont">Zip</div>
                                    <div className="col-sm-8 col-md-8 col-xl-8 marB10">
                                        <input
                                            type="text"
                                            className="form-control"
                                            value={this.props.model.zip}
                                            onChange={(event: any) => {
                                                this.props.handleValueChange(ClientInfoProperty.spouseZip, event.target?.value);
                                            }}
                                            maxLength={5}
                                            placeholder="Zip"
                                            data-test-auto="F8EC44D1-A576-4B71-BE4B-C1A844D44E62"
                                            disabled={this.state.isDeliveredToTaxCaddy}
                                        ></input>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6 col-md-6 col-xl-6">
                                <div className="row">
                                    <div className="col-sm-4 col-md-4 col-xl-4 padT07 tpLabelFont">DOB</div>
                                    <div className="col-sm-8 col-md-8 col-xl-8 marB10 padR25">
                                        <CustomDatePicker
                                            value={this.props.model.dob}
                                            onChange={this.handleChangeDoB}
                                            disabled={this.state.isDeliveredToTaxCaddy}
                                            maxDate={new Date()}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row marL0">
                            <div className="col-sm-12 col-md-12 col-xl-12">
                                <div className="row">
                                    <div className="col-sm-2 col-md-2 col-xl-2 padT07 tpLabelFont">Mobile</div>
                                    <div className="col-sm-10 col-md-10 col-xl-10 marB10 padR25">
                                        <PhoneInput
                                            specialLabel=""
                                            country={"us"}
                                            autoFormat={true}
                                            value={
                                                this.state.mobileNumberFullValue ??
                                                `${this.props.model.countryCode}${this.props.model.mobileNumber}`
                                            }
                                            onChange={this.onMobileChange}
                                            disabled={this.state.isDeliveredToTaxCaddy}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row marL0">
                            <div className="col-sm-6 col-md-6 col-xl-6">
                                <div className="row">
                                    <div className="col-sm-4 col-md-4 col-xl-4 padT07 tpLabelFont">SSN</div>
                                    <div className="col-sm-8 col-md-8 col-xl-8 marB10">
                                        <TaxIdentificationNumber
                                            value={this.props.model ? FilterSSN(this.props.model.ssn) : ""}
                                            onChange={(value: string) => {
                                                this.props.handleValueChange(ClientInfoProperty.spouseSSN, value);
                                            }}
                                            placeholder="SSN"
                                            mask="000-00-0000"
                                            disabled={this.state.isDeliveredToTaxCaddy}
                                        />
                                    </div>
                                </div>
                            </div>
                            {this.props.showSMSTextNotifications && (
                                <div className="col-sm-6 col-md-6 col-xl-6">
                                    <div className="row">
                                        <ToggleSwitch
                                            switched={this.props.isSpouseOpted}
                                            handleChange={this.handleSpouseToggleSwitchChange}
                                            disabled={
                                                !this.props.smsNotificationDetails.isSuiteSMSNotificationSetting ||
                                                !this.props.smsNotificationDetails.isTwilioSpouseSMSOptedIn
                                            }
                                            className={
                                                !this.props.smsNotificationDetails.isSuiteSMSNotificationSetting ||
                                                !this.props.smsNotificationDetails.isTwilioSpouseSMSOptedIn
                                                    ? "disable"
                                                    : ""
                                            }
                                            dataTestChecked={this.props.isSpouseOpted}
                                            dataTestAuto="f0a259f6-49a3-4a19-a2d2-29bc7cd5336b"
                                        />
                                        <div
                                            className="col-sm-4 col-md-4 col-xl-7 padT07 tpLabelFont"
                                            style={{ padding: "6px 0px 0px 14px" }}
                                        >
                                            SMS Text Notification
                                        </div>
                                        {(!this.props.smsNotificationDetails.isSuiteSMSNotificationSetting ||
                                            !this.props.smsNotificationDetails.isTwilioSpouseSMSOptedIn) && (
                                            <Link
                                                to={"#"}
                                                style={{ marginLeft: "-27px", marginTop: "3px" }}
                                                className="help-icon marL05 col-sm-1"
                                                data-placement="right"
                                                data-toggle="tooltip"
                                                title={
                                                    !this.props.smsNotificationDetails.isSuiteSMSNotificationSetting
                                                        ? "To change, enable SMS notification at the Suite level."
                                                        : !this.props.smsNotificationDetails.isTwilioSpouseSMSOptedIn
                                                        ? "Client can opt-in again by replying START."
                                                        : ""
                                                }
                                            >
                                                <i className="fas fa-question-circle"></i>
                                            </Link>
                                        )}
                                    </div>
                                </div>
                            )}
                        </div>
                    </Card.Body>
                </Card>
            </div>
        );
    }
}
export default Spouse;
