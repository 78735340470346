import * as React from "react";
import { Button, Col } from "react-bootstrap";
import SignatureImageCrop from "./SignatureImageCrop";
import { ISignatureSettings } from "./MySettings";
import Checkbox from "../../common/Checkbox";
import { IHelptextModel } from "../../../Core/ViewModels/Common/IHelptextModel";
import { fetchHelpText } from "../../helper/HelperFunctions";
import { IsOnlySignatureUser } from "./../../../oidcClient/userRoles";
import { IAuthState } from "./../../../store/auth/reducer";

interface State {
    file: string;
    showCropPanel: boolean;
    checkBoxAllowOtherUsersLabel: string;
    checkBoxAutoPlaceLabel: string;
    helptextUpdate: string;
}

interface Props {
    auth: IAuthState;
    SignatureSettings: ISignatureSettings;
    getCroppedCanvas(event: any): void;
    onChangeAutoApplySignature(event: any): void;
    onChangeAllowDelegatestoApply(event: any): void;
    enableSignerDeligation: boolean;
    signatureCrophandleShow: any;
    signatureCrophandleShowValue: boolean;
    signatureUploadedStatus: boolean;
    onClickSelectUsers(event: any): void;
    userFullName: string;
    helptexts?: IHelptextModel[];
}

export class MySignatureStamp extends React.Component<Props, State> {
    isSignatureOnlyUser = IsOnlySignatureUser(this.props.auth.user);
    constructor(props: Props) {
        super(props);

        this.onChecked = this.onChecked.bind(this);
        this.onStampUpload = this.onStampUpload.bind(this);
        this.onAllowDelegates = this.onAllowDelegates.bind(this);
        this.state = {
            file: "",
            showCropPanel: false,
            checkBoxAllowOtherUsersLabel: "",
            checkBoxAutoPlaceLabel: "",
            helptextUpdate: ""
        };
    }

    UNSAFE_componentWillMount() {}
    UNSAFE_componentWillReceiveProps(nextProps: Props) {
        this.setState({
            checkBoxAllowOtherUsersLabel: "Allow other users to apply " + nextProps.userFullName + " signature",
            checkBoxAutoPlaceLabel: "Automatically place " + nextProps.userFullName + " signature stamp on e- file forms",
            helptextUpdate: fetchHelpText(nextProps.helptexts, "B68634EF-3777-48D4-AABE-09E955116787")
        });
    }
    private onChecked() {
        //TODO: Implement handling
    }

    private onStampUpload(e: React.ChangeEvent<HTMLInputElement>) {
        e?.preventDefault();
        this.setState({
            file: URL.createObjectURL(e.target.files[0]),
            showCropPanel: true
        }); //, () => this.props.signatureCrophandleShow());
    }

    private onAllowDelegates() {
        //TODO: Implement handling
    }

    private getCroppedCanvas(croppedImage: any) {
        this.props.getCroppedCanvas(croppedImage);
        this.setState({
            file: "",
            showCropPanel: false
        });
    }

    private cancelCrop = () => {
        this.setState({
            file: "",
            showCropPanel: false
        });
    };

    public render() {
        return (
            <div>
                <div className="col-sm-8 col-md-8 col-xl-8" style={{ display: "inline", float: "left" }}>
                    <div className="row">
                        <div className="marB10">
                            <div>
                                <label data-test-auto="19499053-BA13-4239-B0FC-75BB483F3AAC">Preview</label>
                            </div>
                            <div>
                                <img
                                    className="userscanneruploadsign"
                                    alt=""
                                    src={this.props.SignatureSettings.signaturePath}
                                    data-test-auto="A6296DBF-243D-420C-941B-4BABB92565BB"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className={`${!this.props.signatureUploadedStatus || this.isSignatureOnlyUser ? "disabled" : ""}`}>
                            <Checkbox
                                id={"AutomaticPlacement"}
                                checkedCheckbox={
                                    this.props.signatureUploadedStatus ? this.props.SignatureSettings.isAutoPlaceSignature : false
                                }
                                onChangeCheckbox={this.props.onChangeAutoApplySignature.bind(this)}
                                textCheckbox={this.state.checkBoxAutoPlaceLabel}
                                dataTestAuto="FE98A10E-CA39-43A5-8878-5806551A5E9A"
                                disabledCheckbox={!this.props.signatureUploadedStatus || this.isSignatureOnlyUser}
                            />
                        </div>
                    </div>
                    {this.props.enableSignerDeligation == true ? (
                        <div className="row" style={{ alignItems: "baseline" }}>
                            <div
                                className={`marB10 ${
                                    !this.props.signatureUploadedStatus || this.isSignatureOnlyUser ? "disabled" : ""
                                }`}
                                style={{ paddingTop: "8px", float: "left" }}
                            >
                                <Checkbox
                                    id={"AllowMySignature"}
                                    checkedCheckbox={
                                        this.props.signatureUploadedStatus
                                            ? this.props.SignatureSettings.isEnableSignatureDelegation
                                            : false
                                    }
                                    onChangeCheckbox={this.props.onChangeAllowDelegatestoApply}
                                    textCheckbox={this.state.checkBoxAllowOtherUsersLabel}
                                    dataTestAuto="DB1E586C-25BE-4DA1-B4ED-8C628C74105C"
                                    disabledCheckbox={!this.props.signatureUploadedStatus || this.isSignatureOnlyUser}
                                />
                            </div>
                            <div
                                className={`marB10 ${
                                    !this.props.signatureUploadedStatus || this.isSignatureOnlyUser ? "disabled" : ""
                                }`}
                            >
                                <Button
                                    variant="info"
                                    data-test-auto="9EE4524A-5D3C-4FD8-BD24-0CCDA4CCF4E3"
                                    onClick={this.props.onClickSelectUsers}
                                    disabled={
                                        !this.props.SignatureSettings.isEnableSignatureDelegation || this.isSignatureOnlyUser
                                    }
                                    style={{ marginLeft: "8px" }}
                                >
                                    <i className="fas fa-plus"></i>
                                    Select Users
                                </Button>
                            </div>
                        </div>
                    ) : (
                        ""
                    )}
                </div>
                <div className="col-sm-4 col-md-4 col-xl-4" style={{ display: "inline", float: "right" }}>
                    <div className="row" style={{ float: "right" }}>
                        <Col className={`${this.isSignatureOnlyUser ? "disabled" : ""}`} style={{ float: "right" }}>
                            <a className={`btn btn-default ${this.isSignatureOnlyUser ? "disabled" : ""}`} href="#">
                                <i className="far fa-image"></i>Upload Signature
                                <input
                                    type="file"
                                    value=""
                                    accept="image/*"
                                    onChange={this.onStampUpload}
                                    className="btnuploadsettings"
                                    data-test-auto="AE822E79-1E27-4EF4-8C9F-ADFC1F842576"
                                    disabled={this.isSignatureOnlyUser}
                                />
                            </a>
                            <a
                                href="#"
                                className="help-icon upload-help"
                                data-toggle="tooltip"
                                data-placement="right"
                                title={this.state.helptextUpdate}
                            >
                                <i className="fas fa-question-circle" />
                            </a>
                        </Col>
                    </div>
                    <SignatureImageCrop
                        file={this.state.file}
                        show={this.state.showCropPanel}
                        onHide={this.cancelCrop}
                        getCroppedCanvas={this.getCroppedCanvas.bind(this)}
                    />
                </div>
            </div>
        );
    }
}
export default MySignatureStamp;
