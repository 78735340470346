import * as React from 'react';
import { SignatureFormSelectionType } from '../../../../Core/ViewModels/Company/CompanySettingsViewModel';
import * as TaxDocument from '../../../common/TaxReturn';
import * as CompanyStore from '../../../../store/company/CompanyStore';
import * as UserSettingStore from '../../../../store/userManagement/UserSettingStore';
import {
	IBookmarkSection,
	IEfileSignatureControlsDictionary,
	IFormGroupViewProps, IFormGroupViewState,
	PdfPageSignatureControls, ProcessReturnTabs, getAllEroSigners,
} from '../ProcessReturnModels';
import { EfileSignatureControlCard, ISigner } from '../../../common/ProcessReturnModal/RightSidebar/EfileSignatureControlPanel';
import { BookmarksPane } from '../BookmarksPane';
import { EROSignatureCard } from '../RightSidebar/EROSignaturePanel';
import { IEfileSignControlBoxItem } from '../RightSidebar/EfileSignControl';
import * as UserSignatureStore from '../../../../store/common/UserSignatureStore';

import { INVALID_PAGE } from '../CustomPagination/CustomPagination';
import {
	EngagementType, SignatureGroupType, DocumentGroups,
	SignatureControlRole, SignatureControlTypes, ITaxingAuthority, IEFile, initialEfile
} from '../../../common/TaxReturn';
import { IUserProfile } from '../../../navigation/profile/ProfileObjects';
import * as Helper from '../../../helper/HelperFunctions';
import { EsignHelpTextConstant, EfileTabConstats, TabEFileConstants } from './../../../helper/Constants';
import { dragStartSignatureControl, getTopAdjustmentSignatureControlValue } from './../SignatureControls/SignatureHelper';
import { Guid } from '../../../../Core/Utilities/Guid';
import { cloneTaxReturn, isIndividualEnagagementType } from '../../../helper/HelperFunctions';
import { VenusNotifier } from '../../../helper/VenusNotifier';
import { ILogger } from '../../../../Logger/Logger';
import { LoggerFactory } from '../../../../Logger/LoggerFactory';
import { SignerTooltipPopup } from '../../../../components/common/Generic/SignerTooltipPopup';
import { PdfProperties } from '../../../../Core/ViewModels/Common/PdfHelper';
import { PageMoveTo } from './../PageMoveTo';
import { IPdfDocumentFacade } from '../../../../Core/Utilities/PdfDocumentFacade';
import { IAuthState } from '../../../../store/auth/reducer';
import { LoadingOverlay, Loader } from 'react-overlay-loader';
import * as PdfStore from "../../../../store/pdf/PdfStore";
import { SignatureControlConstants } from './../../../helper/Constants';
import { ChoosableControlData, EsignControlHelper, IAdditionalESignDocumentControl, ICheckBoxControl, IRadioButtonControl } from '../AdditionalEsignDocuments/AdditionalEsignDocument';
import Draggabilly from 'draggabilly';
import { AwesomePdfViewerUrl } from '../AwesomePdfViewerUrl';
import ControlBase from 'awesome-pdf-viewer/dist/Controls/ControlBase';

export interface IRecognizedAdditionalESignProps extends IFormGroupViewProps {
    auth: IAuthState;
    isLoading: boolean;
    bookmarks: IBookmarkSection[];
    pages: number[];
    docId: number;
    pdfDocuments: PdfStore.IPdfDocumentDictionary;
    taxReturn: TaxDocument.ITaxReturn;
    isAssignedToLoggedinUser: boolean;
    renderTrigger: string;
    userSettings: UserSettingStore.UserSettings;
    isEsign: boolean;
    enableEsignatureForBusinessReturns: boolean;
    company: CompanyStore.ICompanyData;
    userProfile: IUserProfile;
    signatureControls: IEfileSignatureControlsDictionary;
    userSignatures: UserSignatureStore.IUserSignatures;
    signatureFormSelectionType: SignatureFormSelectionType;
    getAllTaxingAuthorities: ITaxingAuthority[];
    isEnableInvoice: boolean;
    isDoNotDisplayDatesEnabled: boolean;
    leftPanelPage?: number;
    updateTaxDocument: (taxDocument: TaxDocument.ITaxReturn) => void;
    reGroupPreview: (
        pages: number[],
        destinationGroup: TaxDocument.DocumentGroups,
        sourceGroup?: TaxDocument.DocumentGroups,
        callback?: () => void
    ) => void;
    deletePages: (pages: number[]) => void;
    removeSignatureControl(control: TaxDocument.ISignatureControl, pageNo: number): void;
    addSignatureControl(control: TaxDocument.ISignatureControl, pageNo: number): void;
    updateEROSignatureStamp(userId: number): void;
    replaceSignatureControl(
        oldControl: TaxDocument.ISignatureControl,
        newControl: TaxDocument.ISignatureControl,
        pageNo: number
    ): void;
    createLeftPanel?(eEfile?: TaxDocument.IEFile): void;
}

export interface IState extends IFormGroupViewState {
	eroSigners: IEROSigner[];
	selectedEROSigner: IEROSigner;
	signer: ISigner[];
	selectedSigner: ISigner;
	focusedGroup: SignatureGroupType;
	efile: IEFile;
	currentPageIxdex: number;
	logger: ILogger;
	toolTipControl: TaxDocument.IEfileDocumentControl;
	showToolTipPopup: boolean;
	scale: number;
	pdfLoaded :	boolean;
}

export interface IEROSigner {
	value: number;
	label: string;
	isEnableSignatureDelegation: boolean;
	eroImage: string;
	disabled?: boolean;
}

const NO_INDEX = -1;
const INVALID_INDEX: number = -1;
const isEqual = require("react-fast-compare");

export class RecognizedAdditionalESign extends React.Component<IRecognizedAdditionalESignProps, IState>{
	private _pdfViewer;
	constructor(props: IRecognizedAdditionalESignProps) {
		super(props);
		this.state = {
			currentPage: INVALID_PAGE,
			currentPageIxdex: 0,
			zoomEnabled: false,
			actionCardShow: false,
			signer: [],
			pdfLoaded : false,
			selectedSigner: {
				value: 0,
				label: "",
				signatureRole: TaxDocument.SignatureControlRole.None
			},

			eroSigners: [],
			selectedEROSigner: {
				value: -1,
				label: "",
				isEnableSignatureDelegation: true,
				eroImage: ""
			},
			focusedGroup: SignatureGroupType.None,
			efile: initialEfile,
			toolTipControl: TaxDocument.initialControl,
			showToolTipPopup: false,
			logger: new LoggerFactory().getTelemetryLogger(),
			scale: PdfProperties.DefaultScale
		};
	}
	public temptaxreturn;
	componentDidMount() {
		this.temptaxreturn = cloneTaxReturn(this.props.taxReturn);
		if (this.props && this.props.pages) {
			this.setState({
				currentPage: this.props.pages[0],
				pdfLoaded : false
			});
		}
	}

	shouldComponentUpdate(nextProps: IRecognizedAdditionalESignProps, nextState: IState) {
		return ((nextProps.pages !== this.props.pages) ||
			(this.state.currentPage === INVALID_PAGE) ||
			(!isEqual(this.state, nextState)));
	}

	UNSAFE_componentWillMount() {
		this.setDropdownValues();
	}

	UNSAFE_componentWillReceiveProps(nextProps: IRecognizedAdditionalESignProps) {
		this.setEroSignerDropdownValues(nextProps);

		if (nextProps.pages && nextProps.pages.length != this.props.pages.length) {
			this.onPageSelect(nextProps.pages[this.state.currentPageIxdex]);
		}
		if (nextProps.pages && nextProps.pages.length == this.state.currentPageIxdex) {
			this.onPageSelect(nextProps.pages[this.state.currentPageIxdex - 1]);
		}
		if (nextProps.leftPanelPage && nextProps.leftPanelPage !== this.state.currentPage) {
			this.onPageSelect(nextProps.leftPanelPage);
		}
	}

	public checkIfDateControlsExist(taxReturn: TaxDocument.ITaxReturn): boolean {
		if (taxReturn && taxReturn.formGroups) {
			const index = taxReturn.formGroups.findIndex(x => x.group === DocumentGroups.AdditionalEsignDocument);
			if (index !== -1) {
				const forms = taxReturn.formGroups[index].forms;
				const dateControls = forms.reduce((controls: any[], f: TaxDocument.IFormBase) => {
					const form: TaxDocument.IEFile = f as TaxDocument.IEFile;
					const filteredControls = form.signatureControls.filter(x => x.type === SignatureControlTypes.Date);
					return controls.concat(filteredControls);
				}, []);
				return dateControls.length > 0;
			}
		}
		return false;
	}

	public checkIfEROControlsExist(taxReturn: TaxDocument.ITaxReturn): boolean {
		if (taxReturn && taxReturn.formGroups) {
			const index = taxReturn.formGroups.findIndex(x => x.group === DocumentGroups.AdditionalEsignDocument);
			if (index !== -1) {
				const forms = taxReturn.formGroups[index].forms;
				const eroControls = forms.reduce((controls: any[], f: TaxDocument.IFormBase) => {
					const form: TaxDocument.IEFile = f as TaxDocument.IEFile;
					const filteredControls = form.signatureControls.filter(x => x.signatureControlRole === SignatureControlRole.ERO);
					return controls.concat(filteredControls);
				}, []);
				return eroControls.length > 0;
			}
		}
		return false;
	}

	private onUpdateAddedEfile = (efile: IEFile, currentEFileClone: IEFile, taxingAuthority: ITaxingAuthority) => {
		let addedefileIndex: number = INVALID_INDEX;
		let efileIndex = this.props.taxReturn.formGroups
			.findIndex(x => x.group == DocumentGroups.AdditionalEsignDocument);
		addedefileIndex = (this.props.taxReturn.formGroups[efileIndex].forms).findIndex(x => x.pageNo[0] == currentEFileClone.pageNo[0]);

		if (addedefileIndex > INVALID_INDEX) {
			{
				var clone = cloneTaxReturn(this.props.taxReturn);
				clone.formGroups[efileIndex].forms[addedefileIndex] = { ...efile } as IEFile;
				this.props.updateTaxDocument(clone);
				VenusNotifier.Success(EfileTabConstats.UpdatedAuthority.AuthorityUpdateSuccess, "Success");
			}
		}
	}

	private setDropdownValues = () => {

		var signer = [];
		let selectedSigner = { ...this.state.selectedSigner };
		if (TaxDocument.isMutual(this.props.taxReturn)) {
			if (this.props.taxReturn.taxpayer && this.props.taxReturn.spouse) {
				signer.push({
					value: this.props.taxReturn.taxpayer.id, label: this.props.taxReturn.taxpayer.name, signatureRole: TaxDocument.SignatureControlRole.Taxpayer
					, disabled: this.props.taxReturn.taxpayer.isDeceased
				});
				signer.push({
					value: this.props.taxReturn.spouse.id, label: this.props.taxReturn.spouse.name, signatureRole: TaxDocument.SignatureControlRole.Spouse
					, disabled: this.props.taxReturn.spouse.isDeceased
				});
				selectedSigner = signer[0].disabled ? signer[1] : signer[0]
			}
		}
		else if (TaxDocument.isIndividual(this.props.taxReturn)) {
			this.props.taxReturn.taxpayer &&
				signer.push({
					value: this.props.taxReturn.taxpayer.id, label: this.props.taxReturn.taxpayer.name, signatureRole: TaxDocument.SignatureControlRole.Taxpayer
					, disabled: this.props.taxReturn.taxpayer.isDeceased
				});
			selectedSigner = signer[0]
		}
		else {
			signer.push({ value: this.props.taxReturn.partnerId, label: "Signer", signatureRole: TaxDocument.SignatureControlRole.PartnerShip });
		}

		this.setEroSignerDropdownValues(this.props);
		const focusedGroup = signer.length > 0 ? SignatureGroupType.Esign : (this.state.eroSigners.length > 0 ? SignatureGroupType.Stamp : SignatureGroupType.None)

		this.setState({
			signer: signer,
			selectedSigner: selectedSigner.value > 0 ? selectedSigner : signer[0],
			focusedGroup: focusedGroup
		});
	}

	private setEroSignerDropdownValues = (nextprops: IRecognizedAdditionalESignProps) => {
		const eroSigners: IEROSigner[] = getAllEroSigners(nextprops.company,
            this.props.auth.user,
			nextprops.taxReturn,
			nextprops.userSettings,
			nextprops.userSignatures
		);

		const selectedEROId = nextprops.taxReturn.documentSettings.documentSignatureSetting.signatureStampUser ?
			nextprops.taxReturn.documentSettings.documentSignatureSetting.signatureStampUser?.userId
			: this.state.selectedEROSigner.value;
		let selectedEROSigner = eroSigners.find(x => x.value == selectedEROId) as IEROSigner;
		selectedEROSigner = selectedEROSigner ? selectedEROSigner : eroSigners.find(x => x.value === 0) as IEROSigner;

		this.setState({
			eroSigners: eroSigners,
			selectedEROSigner: selectedEROSigner,
		});
	}

	private handleEROSignerChange = (selectedOption: IEROSigner) => {
		if (selectedOption && selectedOption.eroImage !== "") {
			this.setState({
				selectedEROSigner: {
					value: selectedOption.value,
					label: selectedOption.label,
					isEnableSignatureDelegation: selectedOption.isEnableSignatureDelegation,
					eroImage: selectedOption.eroImage
				}
			}, () => {
				this.props.updateEROSignatureStamp(selectedOption.value);
			})
		}
	}

	private setControlDefaultTooltipValue = (control: IAdditionalESignDocumentControl) => {
		const { CheckBox, RadioButton } = TaxDocument.SignatureControlTypes;
		if (control.type === CheckBox) {
			control.tooltip = "1";
		} else if (control.type === RadioButton) {
			control.tooltip = "2";
		} 
	}

	private showControlPropertyPopup = (control: TaxDocument.IEfileDocumentControl) => {
		this.setState({
			toolTipControl: control,
			showToolTipPopup: true
		})
	}

	createLeftPanel = (eEfile: TaxDocument.IEFile) => {
		const { pdfDocuments, bookmarks } = this.props;
		return <BookmarksPane
			title="Pages"
			docId={this.props.docId}
			pdfDocuments={pdfDocuments}
			sections={bookmarks}
			onPageDelete={this.onPageDelete}
			onPageSelect={this.onPageSelect}
			getAllTaxingAuthorities={this.props.getAllTaxingAuthorities}
			tabType={ProcessReturnTabs.EFile}
			taxReturn={this.props.taxReturn}
			onUpdateAddedEfile={this.onUpdateAddedEfile}
			efile={eEfile}
		/>
	}

	createRightPanel = (enableSignatureOption: boolean, controlBoxItems: IEfileSignControlBoxItem[]) => {
		return (<>
			{enableSignatureOption &&
				<EfileSignatureControlCard
					title="e-Signatures"
					selectedSigner={this.state.selectedSigner}
					signer={this.state.signer}
					signerChangeHandler={this.signerChange}
					isAssignedToLoggedinUser={this.props.isAssignedToLoggedinUser}
					ControlBoxItems={controlBoxItems}
					onSignatureControlFocus={this.onSignatureControlFocus}
                    dragStart={this.handleControlDragStart}
					dragEnd={this.dragEnd}
					keyPrefix={"efile-right-panel-control-"}
				/>
			}
			{(this.props.userSettings.settings.useSignatureStamp) &&

				<div>
					<EROSignatureCard
						title="ERO Signature Stamp"
						selectedEROSigner={this.state.selectedEROSigner}
						eroSigner={this.state.eroSigners}
						onEROSignerChange={this.handleEROSignerChange}
						isAssignedToLoggedinUser={this.props.isAssignedToLoggedinUser}
						onSignatureControlFocus={this.onSignatureControlFocus}
						dragStart={this.handleEROSignatureControl}
						dragEnd={this.dragEnd}
					/>
				</div>
			}
			{
				<SignerTooltipPopup
					control={this.state.toolTipControl}
					showState={this.state.showToolTipPopup}
					onHide={this.onCancelToolTipPopup}
					submitButtonClick={this.onSaveToolTip}
					initialControl={TaxDocument.initialControl}
					content={this.getTooltipContent(this.state.toolTipControl.type || 0)}
					signatureControlType={this.state.toolTipControl.type}
				/>
			}
		</>)
	}

	createMoveTo = () => {
		return <PageMoveTo
			documentGroup={TaxDocument.DocumentGroups.AdditionalEsignDocument}
			reGroupPreview={this.reGroupPreview}
			engagementType={this.props.taxReturn.engagementType}
			arrangement={this.props.taxReturn.arrangement}
			isEnableInvoice={this.props.isEnableInvoice}
		/>
	}

	reGroupPreview = (destinationGroup: DocumentGroups) => {
		const indexOfCurrentPage: number = this.props.pages.indexOf(this.state.currentPage);
		this.props.reGroupPreview([this.state.currentPage], destinationGroup)
		if (destinationGroup == TaxDocument.DocumentGroups.Deleted) {
			if ((this.props.pages.length - 1) == indexOfCurrentPage) {
				this.setState({
					currentPage: this.props.pages.indexOf(indexOfCurrentPage),
					currentPageIxdex: indexOfCurrentPage - 1
				});
			}
			else {
				this.setState({
					currentPage: this.props.pages.indexOf(indexOfCurrentPage),
					currentPageIxdex: this.props.pages.indexOf(this.state.currentPage)
				});
			}
		}
		else {
			this.setState({
				currentPage: this.props.pages[0]
			});
		}
    }

	createControlBoxItems = () => {
		const engagementType = Number(EngagementType[this.props.taxReturn.engagementType]);
		const controlBoxItems: IEfileSignControlBoxItem[] = [];
		if (isIndividualEnagagementType(engagementType)) {
			controlBoxItems.push(
				{
					caption: 'Add Signature Field',
					icon: 'fas fa-signature',
					controlType: TaxDocument.SignatureControlTypes.Signature,
					helpText: EsignHelpTextConstant.ClientSignature
				},
				{
					caption: 'Add Date Signed Field',
					icon: 'fas fa-calendar-alt',
					controlType: TaxDocument.SignatureControlTypes.Date,
					helpText: EsignHelpTextConstant.SignatureDate
				},
				{
					caption: 'Add Print Name Field',
					icon: 'fas fa-user-circle',
					controlType: TaxDocument.SignatureControlTypes.PrintName,
					helpText: EsignHelpTextConstant.PrintName
				},
				{
					caption: 'Add Title Field',
					icon: 'fas fa-address-card',
					controlType: TaxDocument.SignatureControlTypes.Title,
					helpText: EsignHelpTextConstant.Title
				});
		}
		else {
			controlBoxItems.push(
				{
					caption: 'Add Signature Field',
					icon: 'fas fa-signature',
					controlType: TaxDocument.SignatureControlTypes.Signature,
					helpText: EsignHelpTextConstant.ClientSignature
				},
				{
					caption: 'Add Date Signed Field',
					icon: 'fas fa-calendar-alt',
					controlType: TaxDocument.SignatureControlTypes.Date,
					helpText: EsignHelpTextConstant.SignatureDate
				},
				{
					caption: 'Add Name & Title Field',
					icon: 'fas fa-address-card',
					controlType: TaxDocument.SignatureControlTypes.NameAndTitle,
					helpText: EsignHelpTextConstant.NameAndTitle
				});
		}

		controlBoxItems.push(
			{
				caption: 'Add Initials Field',
				icon: 'fas fa-font',
				controlType: TaxDocument.SignatureControlTypes.Initial,
				helpText: EsignHelpTextConstant.Initial
			},
			{
				caption: 'Add Company Field',
				icon: 'fas fa-building',
				controlType: TaxDocument.SignatureControlTypes.Company,
				helpText: EsignHelpTextConstant.Company
			},
			{
				caption: 'Text',
				icon: 'fas fa-text-width',
				controlType: TaxDocument.SignatureControlTypes.Text,
				helpText: EsignHelpTextConstant.Text
			},
			{
				caption: 'Check Box',
				icon: 'fas fa-check-square',
				controlType: TaxDocument.SignatureControlTypes.CheckBox,
				helpText: EsignHelpTextConstant.Checkbox
			},
			{
				caption: 'Radio Button',
				icon: 'fas fa-dot-circle',
				controlType: TaxDocument.SignatureControlTypes.RadioButton,
				helpText: EsignHelpTextConstant.RadioButton
			}
		);

		return controlBoxItems;
    }

	onControlLoad = (control: TaxDocument.IEfileDocumentControl, element: HTMLElement) => {
		const { CheckBox, RadioButton } = TaxDocument.SignatureControlTypes;
		const controlType = control?.children?.props?.control?.type;
	
		if ((controlType === CheckBox || controlType === RadioButton) && control.draggable !== true) {
			this.applyControlStyles(control);
			this.makeDraggableControl(control, element , this.handleControlDragEnd);
			this.registerResizeEvent(control, this.handleResize);
		}
		this.setState({ pdfLoaded: true });
	}
	
	getTooltipContent = (controlType: number) => {
		const { CheckBox, RadioButton } = TaxDocument.SignatureControlTypes;
		const tooltipData = {
			[CheckBox]: {
				header: "Checkbox Properties",
				title: "How many checkbox(es) would you like to add?",
				tooltipContent: "Mention the count of checkbox(es) to be added. All checkboxes will be selectable by Taxpayer.",
			},
			[RadioButton]: {
				header: "Radio Button Properties",
				title: "How many radio button(s) would you like to add?",
				tooltipContent: "Mention the count of radio button(s) to be added. For one set, the Taxpayer can select only one option.",
			},
			default: {
				header: "Tool Tip",
				title: "Add Text",
				tooltipContent: "This text will help the signer to enter the necessary details.",
			},
		};
		return tooltipData[controlType] || tooltipData.default;
	};

	applyControlStyles(control: TaxDocument.IEfileDocumentControl) {
		const controlElement = document.getElementById(control.id);
		if(controlElement){
			controlElement.removeAttribute('class');
			controlElement.classList.add('choosable-control-group');
			controlElement.classList.add('resizable-both');
			const selectedSignerStyle= controlElement.children[0]?.getAttribute('data-signerstyle');
			controlElement.classList.add(selectedSignerStyle?selectedSignerStyle:'choosable-signature-control-border');
		}
	}
	makeDraggableControl(control: TaxDocument.IEfileDocumentControl, element: HTMLElement, dragEndCallBack: (controlGuid: string, top: number, left: number) => void) {
		const draggie = new Draggabilly('#' + control.id, {
            containment: element,
            handle: '.draggable-btn-control'
            });

        draggie.off('dragEnd', '#' + control.id);
       	draggie.on('dragEnd', function (event: Event, pointer: PointerEvent) {
			event = event;
			pointer = pointer;
			const controlElement = document.getElementById(control.id);
			controlElement && 
			dragEndCallBack(control.id.replace(SignatureControlConstants.ControlIdPrefix,''),
			controlElement.offsetTop, controlElement.offsetLeft)
		});
        this.setDraggableControlItems(this.handleControlItemsDragEnd);
	}

	setDraggableControlItems(dragEndCallBack: (id: string, controlGuid: string, offsetTop: number, offsetLeft: number) => void, handle?: string): void {
		const elements = document.querySelectorAll('.choosable-control-item');
		elements?.forEach((element: HTMLElement) => {
			if (!element.dataset.draggable) {
				element.dataset.draggable = "true";
				const draggie = new Draggabilly(element, {
					containment: element.parentElement,
					handle
				});
				draggie.off('dragEnd', element);
				draggie.on('dragEnd', () => {
					const id = element.dataset.id ?? '';
					const controlGuid = element.dataset.controlguid?.replace(SignatureControlConstants.ControlIdPrefix, '') ?? '';
					dragEndCallBack(
						id,
						controlGuid,
						element.parentElement?.offsetTop ?? 0,
						element.parentElement?.offsetLeft ?? 0
					);
				});
			}
		});
	}

	handleControlItemsDragEnd = (id: string, controlGuid: string, top: number, left: number) => {
		const controls = this.getSignatureControls();
		const control = controls.signatureControls.find((x) => x.controlGuid === controlGuid) as IRadioButtonControl | ICheckBoxControl;
		if (control) {
			const item = control.items.find((x) => x.id === id);
			if (item) {
				item.left = left;
				item.top = top;
				this.updateControlData(control);
			}
		}
	};

	updateControlData =(control: TaxDocument.IEfileDocumentControl)=>{
		let taxReturn = Helper.cloneTaxReturn(this.props.taxReturn);
		const groupIndex = this.props.taxReturn.formGroups
			.findIndex(x => x.group == DocumentGroups.AdditionalEsignDocument);
		const eFileForms: IEFile[] = groupIndex !== NO_INDEX ? this.props.taxReturn.formGroups[groupIndex].forms as IEFile[] : [];
		const eFileIndex = eFileForms.findIndex(x => x.pageNo[0] == this.state.currentPage);
		let efile = eFileForms.find(x => x.pageNo[0] === this.state.currentPage) as IEFile;
		const controlIndex = efile.signatureControls.findIndex(x => x.controlGuid == control.controlGuid);
		if (controlIndex !== INVALID_INDEX) {
			efile.signatureControls[controlIndex] = control;
			eFileForms[eFileIndex] = efile;
			taxReturn.formGroups[groupIndex].forms = eFileForms;
			this.props.updateTaxDocument(taxReturn);
		}
	}

	handleControlDragEnd= (controlGuid:string,top: number, left: number) => {
	let controls=this.getSignatureControls();
		const index=controls.signatureControls.findIndex(x=> x.controlGuid===controlGuid);
		   if(index!=-1){
				const controltop =top / this.state.scale;
				const controlLeft= left / this.state.scale;
				const pageSize = this._pdfViewer.getCurrentPageSize();
				controls.signatureControls[index].left  = ControlBase.getBackendControlLeftPosition(controlLeft);
				controls.signatureControls[index].top = ControlBase.getBackendControlTopPosition(pageSize.height, 
					controltop + getTopAdjustmentSignatureControlValue(controls.signatureControls[index].type));
				let control=controls.signatureControls[index] as IRadioButtonControl|ICheckBoxControl;
				this.updateControlData(control);
		}
	}

	addControlItems = (tooltipInput: string): ChoosableControlData[] => {
		const items: ChoosableControlData[] = [];
		const controlLeft = 20;
		const name = Guid.newGuid().toString();
		const count = Number(tooltipInput);
	
		if (tooltipInput && count > 0) {
			for (let i = 0; i < count; i++) {
				const data: ChoosableControlData = ChoosableControlData.createNullObject();
				data.left = controlLeft * (i + 1);
				data.top = 2;
				data.id = Guid.newGuid().toString();
				data.name = name;
				items.push(data);
			}
		}
	
		return items;
	};

	registerResizeEvent(control: TaxDocument.IEfileDocumentControl, onResize: (controlId: string, height: number, width: number) => void) {
		const element = document.getElementById(control.id);
		if (element) {
			element.onmouseup = function (event: MouseEvent) { 
				event=event;       
				onResize(control.id.replace(SignatureControlConstants.ControlIdPrefix,''),
				element.offsetHeight, element.offsetWidth);
			};
		}
	}
	handleResize = (controlGuid:string, height: number, width: number) => {
		let controls=this.getSignatureControls();
		const index=controls.signatureControls.findIndex(x=> x.controlGuid===controlGuid);
		if(index!=-1) {
			let control=controls.signatureControls[index];
			control.height = height;
			control.width = width;
		}
 }

	public render() {
		const { taxReturn, createLeftPanel } = this.props;
		const controlBoxItems = this.createControlBoxItems();	

		let signatureType: any = taxReturn.documentSettings?.documentSignatureSetting.signatureFormSelectionType;
		signatureType = (typeof signatureType == "number") ? TaxDocument.SignatureType[signatureType] : signatureType;
		let enableSignatureOption: boolean = true;

		if (signatureType == TaxDocument.SignatureType[TaxDocument.SignatureType.ManualSign]) {
			enableSignatureOption = false;
		}

		let eFileIndex = this.props.taxReturn.formGroups
			.findIndex(x => x.group == DocumentGroups.AdditionalEsignDocument);
		let eFileForms: IEFile[] = eFileIndex !== NO_INDEX ? this.props.taxReturn.formGroups[eFileIndex]?.forms as IEFile[] : [];
		let IEfile = eFileForms.find(x => x.pageNo[0] === this.state.currentPage) as IEFile;
		
		return (
			!this.props.isLoading ? 
				<AwesomePdfViewerUrl
					ref={(ref: any) => (this._pdfViewer = ref)}
					pdfDocument={this.props.pdfDocuments[this.props.docId].document as IPdfDocumentFacade}
					leftPanel={createLeftPanel ? createLeftPanel(IEfile) : ""}
					rightPanel={this.createRightPanel(enableSignatureOption, controlBoxItems)}
					currentPage={this.state.currentPage}
					pages={this.props.pages}
					enableMoveTo={true}
					moveToElement={this.createMoveTo()}
					signatureControls={enableSignatureOption ? this.getSignatureControls() : this.getEroSignatureControls()}
					isAssignedToLoggedinUser={this.props.isAssignedToLoggedinUser}
					isIndividual={TaxDocument.isIndividual(this.props.taxReturn)}
					keyPrefix={"efile-control-"}
					onControlRemove={this.onControlRemove}
					onControlUpdate={this.onControlUpdate}
					scale={this.state.scale}
					onScaleChange={(s) => this.setState({ scale: s })}
					onCurrentPageChange={(page) => this.setState({ currentPage: page })}
					showControlPropertyPopup={this.showControlPropertyPopup}
					onControlLoad={this.onControlLoad}
				/>
			:
			<LoadingOverlay style={{ height:'100%'}}>
					<Loader loading={this.props.isLoading} text={'Loading, please wait...'} />
			</LoadingOverlay>
		);
	}

	private onPageSelect = (page: number, voucherNo?: number, isPageSelectionFromBookMark?: boolean) => {
		this.setState({ currentPage: page });
		if (isPageSelectionFromBookMark) {
			this.setState({
				currentPageIxdex: this.props.pages.indexOf(page)
			});
		}
	}

	private onPageDelete = (page: number) => {
		this.props.deletePages([page]);
	}

	private signerChange = (selectedOption: ISigner) => {
		if (selectedOption) {

			this.setState({
				selectedSigner: {
					value: selectedOption.value,
					label: selectedOption.label,
					signatureRole: selectedOption.signatureRole
				}
			})
		}
	}

	private onSignatureControlFocus = (focusedGroup: SignatureGroupType) => {
		this.setState({
			focusedGroup: focusedGroup
		});
	}

	private getSignatureControls = (): PdfPageSignatureControls => {
		let signatureControls: TaxDocument.IEfileDocumentControl[] = [];
		let signatureType: any = this.props.taxReturn.documentSettings.documentSignatureSetting.signatureFormSelectionType;
		signatureType = (typeof signatureType == "number") ? TaxDocument.SignatureType[signatureType] : signatureType;
		signatureControls = this.filterSignatureControls(this.props.signatureControls[this.state.currentPage], signatureType);

		return {
			signatureControls: signatureControls,
			eroSigner: { id: this.state.selectedEROSigner.value, signPath: this.state.selectedEROSigner.eroImage },
			signer: { id: this.state.selectedSigner ? this.state.selectedSigner.value : 0, role: this.state.selectedSigner ? this.state.selectedSigner.signatureRole : TaxDocument.SignatureControlRole.None },
			focusedGroup: this.state.focusedGroup
		} as PdfPageSignatureControls;
	}

	private filterSignatureControls = (signatureControls: TaxDocument.IEfileDocumentControl[], signatureType: any):
		TaxDocument.IEfileDocumentControl[] => {
		let signControls: TaxDocument.IEfileDocumentControl[] = signatureControls;
		if (signControls) {
			if (signatureType === TaxDocument.SignatureType[TaxDocument.SignatureType.ManualSign] && this.props.userSettings.settings.useSignatureStamp) {
				signControls = signControls.filter(control => control.signatureControlRole === SignatureControlRole.ERO);
			}
			else if (signatureType === TaxDocument.SignatureType[TaxDocument.SignatureType.ManualSign] && !this.props.userSettings.settings.useSignatureStamp) {
				signControls = [];
			}
			else {
				if (!this.props.userSettings.settings.useSignatureStamp) {
					signControls = signControls.filter(control => control.signatureControlRole !== SignatureControlRole.ERO);
				}
			}
		}

		return signatureControls;
	}

	private getEroSignatureControls = (): PdfPageSignatureControls => {
		let signatureControls: TaxDocument.IEfileDocumentControl[] = [];
		let signatureType: any = this.props.taxReturn.documentSettings.documentSignatureSetting.signatureFormSelectionType;
		signatureType = (typeof signatureType == "number") ? TaxDocument.SignatureType[signatureType] : signatureType;
		signatureControls = this.filterSignatureControls(this.props.signatureControls[this.state.currentPage], signatureType);
		return {
			signatureControls: signatureControls,
			eroSigner: { id: this.state.selectedEROSigner.value, signPath: this.state.selectedEROSigner.eroImage },
			signer: { id: this.state.selectedSigner ? this.state.selectedSigner.value : 0, role: this.state.selectedSigner ? this.state.selectedSigner.signatureRole : TaxDocument.SignatureControlRole.None },
			focusedGroup: this.state.focusedGroup
		} as PdfPageSignatureControls;
	}

	private handleEROSignatureControl=(event: React.DragEvent<HTMLDivElement>, controlType: SignatureControlTypes, signatureRole: SignatureControlRole) => {
		let enableEROStampDeligation: any = this.props.taxReturn.documentSettings?.documentSignatureSetting?.enableEROStampDeligation;
		if(!enableEROStampDeligation && 
			this.state.selectedEROSigner.value>0 &&
			this.state.selectedEROSigner.value!= this.props.auth.user.profile.user_id){
			VenusNotifier.Warning(EfileTabConstats.SignerDelegation.EROSignatureControlDisabled, "");
			return;
		}

		this.dragStartSignatureControl(event,controlType,signatureRole);
	}

    private handleControlDragStart = (event: React.DragEvent<HTMLDivElement>, controlType: SignatureControlTypes, signatureRole: SignatureControlRole) => {
        switch (controlType) {
            case SignatureControlTypes.Date:
                if (this.props.taxReturn.documentSettings.documentSignatureSetting.isDoNotDisplayDatesEnabled) {
                    VenusNotifier.Warning(TabEFileConstants.Info.DoNotInsertDatesMessage, "");
                    return;
                }
                this.dragStartSignatureControl(event, controlType, signatureRole);
                break;
            default:
                this.dragStartSignatureControl(event, controlType, signatureRole);
                break;
        }
    }

	private dragStartSignatureControl = (event: React.DragEvent<HTMLDivElement>, controlType: SignatureControlTypes, signatureRole: SignatureControlRole) => {
		try {
			this.state.logger.trackTrace(`dragStartSignatureControl started for document: ${this.props.taxReturn.documentGuid}, SignatureControlTypes : ${controlType} and SignatureControlRole : ${signatureRole}`, { "DocumentId": this.props.taxReturn.id?.toString(), "DocumentGuid": this.props.taxReturn.documentGuid });
			const pageSize = this._pdfViewer.getCurrentPageSize();
			dragStartSignatureControl(
				event, controlType, signatureRole,
				this.state.selectedEROSigner.label,
				this.state.currentPage,
				this.state.scale,
				this.addSignatureControl,
				pageSize.height)
			this.state.logger.trackTrace(`dragStartSignatureControl ended for document: ${this.props.taxReturn.documentGuid}, SignatureControlTypes : ${controlType} and SignatureControlRole : ${signatureRole}`, { "DocumentId": this.props.taxReturn.id?.toString(), "DocumentGuid": this.props.taxReturn.documentGuid });
		}
		catch (error) {
			this.state.logger.trackWarning(`SignatureControlDragandDropError: dragStartSignatureControl throw error for document: ${this.props.taxReturn.documentGuid}, SignatureControlTypes : ${controlType} and SignatureControlRole : ${signatureRole}, error: ${error}`, { "DocumentId": this.props.taxReturn.id, "DocumentGuid": this.props.taxReturn.documentGuid });
		}
	}

	private dragEnd = (event: any) => {
		try {
			this.state.logger.trackTrace(`dragEnd started for document: ${this.props.taxReturn.documentGuid}`, { "DocumentId": this.props.taxReturn.id?.toString(), "DocumentGuid": this.props.taxReturn.documentGuid });
			let element = document.getElementById("dragging-element");
			if (element) {
				element.remove();
			}
			this.state.logger.trackTrace(`dragEnd ended for document: ${this.props.taxReturn.documentGuid}`, { "DocumentId": this.props.taxReturn.id?.toString(), "DocumentGuid": this.props.taxReturn.documentGuid });
		}
		catch (error) {
			this.state.logger.trackWarning(`SignatureControlDragandDropError: dragEnd throw error for document: ${this.props.taxReturn.documentGuid}, error : ${error}`, { "DocumentId": this.props.taxReturn.id?.toString(), "DocumentGuid": this.props.taxReturn.documentGuid });
		}
	}

	public addSignatureControl = (type: TaxDocument.SignatureControlTypes, role: TaxDocument.SignatureControlRole, left: number, top: number) => {
		try {
			this.state.logger.trackTrace(`addSignatureControl started for document: ${this.props.taxReturn.documentGuid} , SignatureControlTypes: ${type}, SignatureControlRole: ${role}, clientX : ${left}, clientY : ${top}`, { "DocumentId": this.props.taxReturn.id?.toString(), "DocumentGuid": this.props.taxReturn.documentGuid });
			const { currentPage } = this.state;
			let control = EsignControlHelper.getControlModal(type, role, left, top);
			const { Text, CheckBox, RadioButton } = TaxDocument.SignatureControlTypes;
			control.controlGuid = Guid.newGuid().toString();
			control.left = left;
			control.top = top;
			control.type = type;
			control.signatureControlRole = role;
			control.required = true;
			control.tooltip = control.type === CheckBox ? "1" : (control.type === RadioButton ? "2" : "");
			this.props.addSignatureControl(control, currentPage);
			switch (control.type) {
				case CheckBox:
				case RadioButton:
					this.onSaveToolTip(control, false);
					this.showControlPropertyPopup(control);
                    this.setControlDefaultTooltipValue(control);
					break;
				case Text:
					this.setState({
						toolTipControl: control,
						showToolTipPopup: true,
					});
					break;
				default:
					break;
			}
			this.state.logger.trackTrace(`addSignatureControl ended for document: ${this.props.taxReturn.documentGuid}, SignatureControlTypes: ${type}, SignatureControlRole: ${role}, clientX : ${left}, clientY : ${top}`, { "DocumentId": this.props.taxReturn.id?.toString(), "DocumentGuid": this.props.taxReturn.documentGuid });
		}
		catch (error) {
			this.state.logger.trackWarning(`SignatureControlDragandDropError: addSignatureControl throw error for document: ${this.props.taxReturn.documentGuid}, SignatureControlTypes: ${type}, SignatureControlRole: ${role}, clientX : ${left}, clientY : ${top}, error : ${error}`, { "DocumentId": this.props.taxReturn.id?.toString(), "DocumentGuid": this.props.taxReturn.documentGuid });
		}
	}

	private adjustSamePositionControl = (control: TaxDocument.IEfileDocumentControl): TaxDocument.IEfileDocumentControl => {
		const signatureControls = this.props.signatureControls[this.state.currentPage];
		const index = signatureControls.findIndex(x => x.controlGuid === control.controlGuid);
		if (index !== NO_INDEX) {
			control.left = control.left + 5;
			control.top = control.top + 5;
			return this.adjustSamePositionControl(control);
		}
		else {
			return control;
		}
	}

	private onControlRemove = (control: TaxDocument.IEfileDocumentControl) => {
		this.props.removeSignatureControl(control, this.state.currentPage);
	}

	private onControlUpdate = (oldControl: TaxDocument.IEfileDocumentControl, newControl: TaxDocument.IEfileDocumentControl) => {
		this.props.replaceSignatureControl(oldControl, newControl, this.state.currentPage);
	}

	private onSaveToolTip = (control: TaxDocument.IEfileDocumentControl, updateState:boolean=true) => {
		let taxReturn = Helper.cloneTaxReturn(this.props.taxReturn);
		const groupIndex = this.props.taxReturn.formGroups
			.findIndex(x => x.group == DocumentGroups.AdditionalEsignDocument);
		const eFileForms: IEFile[] = groupIndex !== NO_INDEX ? this.props.taxReturn.formGroups[groupIndex].forms as IEFile[] : [];
		const eFileIndex = eFileForms.findIndex(x => x.pageNo[0] == this.state.currentPage);
		let efile = eFileForms.find(x => x.pageNo[0] === this.state.currentPage) as IEFile;
		const controlIndex = efile.signatureControls.findIndex(x => x.controlGuid == control.controlGuid);
		if (controlIndex !== Helper.NO_INDEX) {
			if (control.type == SignatureControlTypes.RadioButton || control.type == SignatureControlTypes.CheckBox) {
				efile.signatureControls[controlIndex].items = this.addControlItems(control.tooltip);
				efile.signatureControls[controlIndex].required = control.required;
				efile.signatureControls[controlIndex].tooltip = control.tooltip;
            }else{
				efile.signatureControls[controlIndex].tooltip = control.tooltip;
				efile.signatureControls[controlIndex].required = control.required;
				efile.signatureControls[controlIndex].isCustomTextControl = true;
			}
		}

		(taxReturn.formGroups[groupIndex].forms[eFileIndex] as IEFile) = efile;
		this.props.updateTaxDocument(taxReturn);

		if(updateState){
			this.setState({
				toolTipControl: TaxDocument.initialControl,
				showToolTipPopup: false
			});
		}
	}

	private onCancelToolTipPopup = () => {
		this.setState({
			toolTipControl: TaxDocument.initialControl,
			showToolTipPopup: false
		});
	}
};